import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage, injectIntl } from 'react-intl';
import { Field, reduxForm } from 'redux-form';
import withStyles from 'isomorphic-style-loader/withStyles';
import { connect } from 'react-redux';
import {
  Button,
  Form,
  Row,
  FormGroup,
  Col,
  FormControl,
  Container,
} from 'react-bootstrap';
import cx from 'classnames';
import Loader from '../../../Common/Loader/Loader';
import messages from '../../../../locale/messages';
import submit from './submit';
import validate from './validate';
import { setLoaderComplete } from '../../../../actions/loader/loader';
import s from '../Payout.css';
import rs from '../../../storeCommon.css';
import logourl from '../../../../../public/StoreIcon/paypal.svg';

class Paypal extends Component {
  componentDidMount() {
    const { setLoaderComplete } = this.props;
    setLoaderComplete('addPayoutForm');
  }

  renderField = ({
    input,
    label,
    type,
    meta: { touched, error },
    placeholder,
  }) => {
    const { formatMessage } = this.props.intl;
    return (
      <Form.Group className={cx(rs.space5, 'positionRelative')}>
        <Form.Label className="inputLabel">{label}</Form.Label>
        <Form.Control
          {...input}
          placeholder={placeholder}
          type={type}
          className={cx(rs.formControlInputStore, 'formControlInputStoreRTL')}
        />
        {touched && error && (
          <span className={cx(s.errorMessage, 'errorMessageRTL')}>
            {formatMessage(error)}
          </span>
        )}
      </Form.Group>
    );
  };

  renderSelectField = ({
    input,
    label,
    type,
    meta: { touched, error },
    children,
    placeholder,
    className,
  }) => {
    const { formatMessage } = this.props.intl;
    return (
      <FormGroup className={cx(rs.space5, 'positionRelative')}>
        <label className="inputLabel">{label}</label>
        <FormControl
          {...input}
          as="select"
          placeholder={placeholder}
          className={className}
          type={type}
        >
          {children}
        </FormControl>
        {touched && error && (
          <span className={cx(s.errorMessage, 'errorMessageRTL')}>
            {formatMessage(error)}
          </span>
        )}
      </FormGroup>
    );
  };

  render() {
    const {
      handleSubmit,
      payoutLoading,
      pristine,
      submitting,
      error,
    } = this.props;
    const { availableCurrencies, siteName, previousPage } = this.props;
    const { formatMessage } = this.props.intl;
    return (
      <div className={cx('mainContainer', 'payoutSelectRTL')}>
        <Container fluid>
          <Row>
            <Col md={12} lg={12} sm={12} xs={12}>
              <Form onSubmit={handleSubmit(submit)}>
                <h1 className={cx(rs.storeTitleText, 'textAlignRightRTL')}>
                  <FormattedMessage {...messages.addPayout} />
                </h1>
                <div className={cx(rs.storeNewBg, rs.space5)}>
                  {' '}
                  <img src={logourl} />
                  <p>
                    <FormattedMessage {...messages.paypalIntro1} /> {siteName}.
                    <FormattedMessage {...messages.paypalIntro2} /> {siteName},{' '}
                    <FormattedMessage {...messages.paypalIntro3} />{' '}
                    <a
                      href="https://www.paypal.com/"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <FormattedMessage {...messages.paypalIntro4} />
                    </a>
                  </p>
                  <Field
                    name="payEmail"
                    component={this.renderField}
                    label={formatMessage(messages.paypalEmail)}
                    placeholder={formatMessage(messages.yourPaypalEmail)}
                  />
                  <Field
                    name="currency"
                    className={cx(
                      rs.formControlSelect,
                      rs.formControlInputStore,
                      'formControlInputStoreRTL',
                    )}
                    component={this.renderSelectField}
                    label={formatMessage(messages.paypalCurrency)}
                  >
                    <option value="">
                      {formatMessage(messages.chooseCurrency)}
                    </option>
                    {availableCurrencies.map((currency, key) => {
                      if (currency.isEnable === true) {
                        return (
                          <option key={key} value={currency.symbol}>
                            {currency.symbol}
                          </option>
                        );
                      }
                    })}
                  </Field>
                  <div className={s.btnDisplayFlex}>
                    <Button
                      onClick={previousPage}
                      className={cx(
                        rs.button,
                        rs.btnPrimaryBorder,
                        s.btnWidthMobile,
                      )}
                    >
                      <FormattedMessage {...messages.back} />
                    </Button>
                    <Loader
                      type="button"
                      buttonType="submit"
                      className={cx(rs.button, rs.btnPrimary, s.btnWidthMobile)}
                      disabled={
                        pristine || submitting || error || payoutLoading
                      }
                      show={payoutLoading}
                      label={formatMessage(messages.finish)}
                    />
                  </div>
                </div>
              </Form>
            </Col>
          </Row>
        </Container>
      </div>
    );
  }
}

Paypal = reduxForm({
  form: 'PayoutForm', // a unique name for this form
  destroyOnUnmount: false,
  forceUnregisterOnUnmount: true,
  validate,
})(Paypal);

const mapState = state => ({
  siteName: state.siteSettings.data.siteName,
  availableCurrencies: state.currency.availableCurrencies,
  payoutLoading: state.loader.addPayoutForm,
});

const mapDispatch = {
  setLoaderComplete,
};

export default injectIntl(
  withStyles(s, rs)(connect(mapState, mapDispatch)(Paypal)),
);
