
import React from 'react';
import Item from './Item';
import DashboardLayout from '../../../components/Shop/StoreLayout/Layout/DashboardLayout';
import messages from '../../../locale/messages';

const title = 'Item';

function action({ store, params, intl }) {
  const title = intl.formatMessage(messages.item);

  let isStoreAuthenticated = store.getState().runtime.isStoreAuthenticated;

  if (!isStoreAuthenticated) {
    return { redirect: '/store' };
  };

  let menuId = Number(params.id);

  return {
    title,
    component: (
      <DashboardLayout>
        <Item title={title} menuId={menuId} />
      </DashboardLayout>
    ),
  };
}

export default action;
