import React, { Component } from 'react'
import withStyles from 'isomorphic-style-loader/withStyles';
import { injectIntl } from 'react-intl';
import { flowRight as compose } from 'lodash';
import { graphql } from 'react-apollo';

import ViewMessageDetails from '../../../components/SiteAdmin/ViewMessageDetails';
import Loader from '../../../components/Common/Loader/Loader';
import NotFound from '../../not-found/NotFound';

import getAllThreadItemsQuery from './getAllThreadItems.graphql';
import getMoreThreads from './getMoreThreads.graphql';

import s from './ViewMessage.css';

class ViewMessage extends Component {

    loadMore = () => {
        const { threadItems: { getAllThreadItems, fetchMore }, bookingId } = this.props;
        fetchMore({
            query: getMoreThreads,
            variables: {
                bookingId,
                offset: getAllThreadItems.threadItems.length,
            },
            updateQuery: (previousResult, { fetchMoreResult }) => {
                if (!fetchMoreResult) { return previousResult; }
                return {
                    getAllThreadItems: {
                        ...previousResult.getAllThreadItems,
                        threadItems: [...previousResult.getAllThreadItems.threadItems, ...fetchMoreResult.getMoreThreads.threadItems],
                    },
                };
            },
        });
    }

    render() {
        const { threadItems, from, threadItems: { loading, getAllThreadItems } } = this.props;
        if (loading) {
            return <div><Loader type={"page"} show={loading}></Loader></div>;
        }
        else if (!getAllThreadItems || !getAllThreadItems.threadItems || !getAllThreadItems.threadItems[0] || !['completed-bookings', 'failed-bookings', 'bookings'].includes(from)) {
            return <NotFound title='Page Not Found' />;
        }
        else {
            return (
                <>
                    <ViewMessageDetails threadItems={threadItems} from={from} loadMore={this.loadMore} />
                </>
            );
        }
    }
}

export default compose(
    injectIntl,
    withStyles(s),
    graphql(getAllThreadItemsQuery, {
        name: 'threadItems',
        options: (props) => ({
            variables: {
                offset: 0,
                bookingId: props.bookingId
            },
            fetchPolicy: 'network-only',
            ssr: false
        })
    })
)(ViewMessage);