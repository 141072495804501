import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'react-apollo';
import { flowRight as compose } from 'lodash';
import withStyles from 'isomorphic-style-loader/withStyles';
import s from './ContentPageList.css';
import cx from 'classnames';
import getContentPageDetails from './getContentPageDetails.graphql';
import ContentPageList from '../../../components/SiteAdmin/ContentPage/ContentPageList';
import Loader from '../../../components/Common/Loader/Loader';
//meassage
import { FormattedMessage } from 'react-intl';
import messages from '../../../locale/messages';

export class ContentPage extends Component {

  static propTypes = {
    title: PropTypes.string.isRequired,
    data: PropTypes.shape({
      loading: PropTypes.bool,
      getContentPageDetails: PropTypes.any
    })
  };

  static defaultProps = {
    data: {
      loading: true
    }
  };

  render() {
    const { data: { loading, getContentPageDetails }, title } = this.props;
    return (
      <Loader type={"page"} show={loading}>
        <div className={s.root}>
          <div className={s.container}>
            <div className={s.paddingRoutesSection}>
              <div className={cx(s.heading, 'textAlignRightRTL')}>
                <FormattedMessage {...messages.contentPageManagement} />
              </div>
              <ContentPageList
                data={getContentPageDetails}
                title={title}
              />
            </div>
          </div>
        </div>
      </Loader>
    )
  }
}


export default compose(
  withStyles(s),
  graphql(getContentPageDetails,
    {
      options: {
        fetchPolicy: 'network-only',
        ssr: false
      }
    })
)(ContentPage)
