// General
import React from 'react';
import PropTypes from 'prop-types';

// Apollo
import { graphql } from 'react-apollo';
import gql from 'graphql-tag';
import { flowRight as compose } from 'lodash';

// Style
import withStyles from 'isomorphic-style-loader/withStyles';
import s from './ChangePasswordUsingToken.css';

// Components
import ChangePasswordForm from '../../../components/Shop/ForgotPassword/ChangePasswordForm/ChangePasswordForm';
import Loader from '../../../components/Common/Loader/Loader';
import NotFound from '../../not-found/NotFound';
import verifyForgotPasswordToken from './verifyForgotPasswordToken.graphql';

class ChangePasswordUsingToken extends React.Component {
  static propTypes = {
    email: PropTypes.string.isRequired,
    token: PropTypes.string.isRequired,
    data: PropTypes.shape({
      loading: PropTypes.bool,
      verifyForgotPasswordToken: PropTypes.object
    })
  };

  static defaultProps = {
    data: { loading: true }
  };

  render() {
    const { email, token, data: { loading, verifyForgotPasswordToken } } = this.props;
    let initialValues = { email, token };

    return (
      <div>
        {loading && <Loader type={"text"} />}
        {!loading && verifyForgotPasswordToken && verifyForgotPasswordToken.status === 200 && <ChangePasswordForm initialValues={initialValues} />}
        {!loading && verifyForgotPasswordToken && verifyForgotPasswordToken.status !== 200 && <NotFound title="Page Not Found" />}
      </div>
    );
  }
}

export default compose(
  withStyles(s),
  graphql(verifyForgotPasswordToken, {
    options: props => ({
      variables: {
        email: props.email,
        token: props.token,
      },
      fetchPolicy: 'network-only',
    }),
  }),
)(ChangePasswordUsingToken);
