exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ":root {\n  /*\n   * Typography\n   * ======================================================================== */\n\n  --font-family-base: 'Comfortaa', cursive;\n\n  /*\n   * Layout\n   * ======================================================================== */\n\n  --max-content-width: 1170px;\n\n  /*\n   * Media queries breakpoints\n   * ======================================================================== */\n\n  --screen-xs-min: 480px;\n  /* Extra small screen / phone */\n  --screen-sm-min: 768px;\n  /* Small screen / tablet */\n  --screen-md-min: 992px;\n  /* Medium screen / desktop */\n  --screen-lg-min: 1200px;\n  /* Large screen / wide desktop */\n\n  --btn-primary-bg: #FFB400;\n  --btn-boxShadow-bg: #FFB400;\n  --btn-bordercolor-bg: #FFB400;\n  --btn-text-color: #fff;\n  --btn-primary-color: #ffffff;\n  --common-color: #1C0303;\n  --btn-secondary-bg: #FFB400;\n  --title-color: #212121;\n  --content-color: #212121;\n  --chat-background-color: #F6F6F6;\n  --border-color: #CED4DA;\n\n}\n._3m-Rh {\n\tmargin: 0 auto;\n\tpadding: 0px;\n\tmax-width: 380px;\n}\n._2w64G {\n\tmax-width: 500px;\n\twidth: 100%;\n\tpadding: 10px;\n}\n._1NCAq {\n\tpadding: 0px;\n}\n.NFUzx {\n\tfont-weight: bold;\n\tfont-size: 20px;\n\tmargin-bottom: 10px;\n}\n@media screen and (max-width: 767px) {\n\t._2w64G {\n\t\tmargin: 0px auto;\n\t}\n\t._1qwXA {\n\t\tpadding: 15px;\n\t}\n}\n.MMaf2 ._9e3xV {\n\tpadding:12px 12px 10px 26px;\n\tborder-bottom:none;\n}\n.MMaf2 ._1RYVs {\n\tfont-size:30px !important;\n\tfont-weight:normal;\n\tcolor:rgb(118,\n\t118,\n\t118);\n\topacity:0.7;\n\tmargin-left:5px;\n\tdisplay:inline-block;\n\tmargin:-3px 0 0 0;\n\toutline:0;\n}\n.MMaf2 .X1lhx {\n\tborder-radius:0px;\n}\n@media screen and (max-width: 640px) {\n\t.MMaf2 .X1lhx {\n\t\tborder-radius:0px;\n\t}\n}", ""]);

// exports
exports.locals = {
	"container": "_3m-Rh",
	"logInModalContainer": "_2w64G",
	"logInModalBody": "_1NCAq",
	"forgetHead": "NFUzx",
	"root": "_1qwXA",
	"forgotModal": "MMaf2",
	"modal-header": "_9e3xV",
	"close": "_1RYVs",
	"modal-content": "X1lhx"
};