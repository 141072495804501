import { closeDeclineReasonModal } from '../../siteadmin/modalActions';
import { getPendingOrders } from './getPendingOrders';
import showToaster from '../../../helpers/toasterMessages/showToaster';
import { updateOrdermutation, getOrderDetailsQuery } from '../../../lib/graphql';

const updateOrderStatus = (id, bookingStatus, currentPage, reason, isOrderDetailPage, orderModal) => {

    return async (dispatch, getState, { client }) => {

        try {
            let refetchQueries = [];

            if (isOrderDetailPage) {
                refetchQueries = [{
                    query: getOrderDetailsQuery,
                    variables: { id }
                }];
            }
            const { data } = await client.mutate({
                mutation: updateOrdermutation,
                variables: {
                    id,
                    bookingStatus,
                    notes: reason,
                    orderModal
                },
                refetchQueries
            });

            if (orderModal){
                await dispatch(getPendingOrders());
            } 

            if (data?.updateOrderStatus?.status === 200) {
                showToaster({ messageId: 'updateOrderStatus', toasterType: 'success' })
                dispatch(closeDeclineReasonModal());
            } else {
                showToaster({ messageId: 'commonToasterError', toasterType: 'error', requestMessage: data?.updateOrderStatus?.errorMessage })
            }

        } catch (error) {
            showToaster({ messageId: 'commonToasterError', toasterType: 'error', requestMessage: error })
        }
    }
};
export default updateOrderStatus;