import { loadAccount } from '../userAccount/userAccount';
import showToaster from '../../../helpers/toasterMessages/showToaster';
import { getAllSubMenuQuery, subMenuStatusMutation } from '../../../lib/graphql';

const updateSubMenuStatus = (id, status, currentPage, menuId) => {

    return async (dispatch, getState, { client }) => {
        try {
            const { data } = await client.mutate({
                mutation: subMenuStatusMutation,
                variables: {
                    id,
                    status: status == 'false' ? 0 : 1
                },
                refetchQueries: [{ query: getAllSubMenuQuery, variables: { currentPage, menuId } }]
            });

            if (data?.updateSubMenuStatus?.status === 200) {
                showToaster({ messageId: 'updateSubMenuStatus', toasterType: 'success' });
                dispatch(loadAccount());
            } else {
                showToaster({ messageId: 'commonToasterError', toasterType: 'error', requestMessage: data?.updateSubMenuStatus?.errorMessage });
            }
        } catch (err) {
            showToaster({ messageId: 'commonToasterError', toasterType: 'error', requestMessage: err });

        }
    }
};
export default updateSubMenuStatus;
