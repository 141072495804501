import React from 'react';
import withStyles from 'isomorphic-style-loader/withStyles';
import s from './PromoCode.css';
import cx from 'classnames';
import PromoCodeList from '../../../../components/SiteAdmin/PromoCode/PromoCodeList';
import getAllPromoCodeQuery from './getAllPromoCode.graphql';
import { graphql } from 'react-apollo';
import { flowRight as compose } from 'lodash';
import Loader from '../../../../components/Common/Loader/Loader';
//meassage
import { FormattedMessage } from 'react-intl';
import messages from '../../../../locale/messages';

class PromoCode extends React.Component {
  static defaultProps = {
    promoCodes: {
      loading: true
    }
  }

  render() {
    const { promoCodes, promoCodes: { loading, getAllPromoCodes }, currency } = this.props;

    return (
      <Loader type={"page"} show={loading}>
        <div className={s.root}>
          <div className={s.container}>
            <div className={s.paddingRoutesSection}>
              <div className={cx(s.heading, 'textAlignRightRTL')}>
                <FormattedMessage {...messages.managePromoCode} />
              </div>
              <PromoCodeList promoCodes={promoCodes} currency={currency} />
            </div>
          </div>
        </div>
      </Loader>
    );
  }
}

export default compose(
  withStyles(s),
  graphql(getAllPromoCodeQuery, {
    name: 'promoCodes',
    options: {
      ssr: true,
      fetchPolicy: 'network-only',
      variables: {
        currentPage: 1
      }
    }
  }))(PromoCode);
