import { editDriver } from '../../../actions/siteadmin/editDriver';

async function submit(values, dispatch) {
    await dispatch(
        editDriver(
            values.id,
            values.firstName,
            values.lastName,
            values.email,
            values.password ? values.password : undefined,
            values.phoneDialCode,
            values.phoneNumber,
            values.vehicleName,
            values.vehicleNumber,
            values.vehicleType,
            values.userStatus,
            values.isBan,
            values.phoneCountryCode
        )
    )
}

export default submit;