import {
    UPDATE_SITE_SETTINGS_START,
    UPDATE_SITE_SETTINGS_ERROR,
    UPDATE_SITE_SETTINGS_SUCCESS
} from '../../../constants/index';
import { siteSettingsMuation } from '../../../lib/graphql';
import showToaster from '../../../helpers/toasterMessages/showToaster';

const updateSiteSettings = (siteName, siteTitle, metaDescription, facebookLink, twitterLink, instagramLink, logoHeight, logoWidth, metaKeyword, homeLogo, youtubeLink, currency, modifiers, favicon, contactNumber, contactEmail, contactSkype, notificationInterval, ogImage) => {

    return async (dispatch, getState, { client }) => {
        try {
            dispatch({
                type: UPDATE_SITE_SETTINGS_START
            })

            const { data } = await client.mutate({
                mutation: siteSettingsMuation,
                variables: {
                    siteName,
                    siteTitle,
                    metaDescription,
                    facebookLink,
                    twitterLink,
                    instagramLink,
                    logoHeight,
                    logoWidth,
                    metaKeyword,
                    homeLogo,
                    youtubeLink,
                    currency,
                    modifiers,
                    favicon,
                    contactNumber,
                    contactEmail,
                    contactSkype,
                    notificationInterval,
                    ogImage
                }
            })

            if (data?.updateSiteSettings?.status == 200) {
                dispatch({
                    type: UPDATE_SITE_SETTINGS_SUCCESS
                })
                showToaster({ messageId: 'updateSiteSettings', toasterType: 'success' })
            } else {
                dispatch({
                    type: UPDATE_SITE_SETTINGS_ERROR
                })
                showToaster({ messageId: 'failedError', toasterType: 'error', requestMessage: data?.updateSiteSettings?.errorMessage })
            }
        } catch (err) {
            dispatch({
                type: UPDATE_SITE_SETTINGS_ERROR
            })
            showToaster({ messageId: 'failedError', toasterType: 'error', requestMessage: err })
        }
    }
}
export default updateSiteSettings