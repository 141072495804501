import React from 'react';
import { injectIntl } from 'react-intl';
import { Field, reduxForm } from 'redux-form';
import messages from '../../../locale/messages';
import withStyles from 'isomorphic-style-loader/withStyles';
import cx from 'classnames';
import { connect } from 'react-redux';

import {
	Button,
	FormGroup,
	Col,
	Row,
	FormControl,
	Form,
	Container
} from 'react-bootstrap';

import validate from './validate';
import submit from './submit';
//Images
import ShowPassword from '../../../../public/StoreIcon/showPwsd.svg';
import HidePassword from '../../../../public/StoreIcon/hidePwsd.svg';
import s from './Password.css';
import rs from '../../storeCommon.css';

class Password extends React.Component {

	constructor(props) {
		super(props)
		this.state = {
			showPassword: '',
		};
		this.handleChange = this.handleChange.bind(this);
	}

	handleChange(fieldName) {
		this.setState({ showPassword: fieldName === this.state.showPassword ? "" : fieldName });
	}

	renderFormControl = ({ input, label, type, meta: { touched, error }, className, autocomplete, showPassword }) => {
		const { formatMessage } = this.props.intl;

		return (
			<FormGroup className={cx(rs.space5, 'positionRelative')}>
				<Form.Label>{label}</Form.Label>
				<div className={s.positionRelative}>
					<FormControl {...input} placeholder={label} type={showPassword === input.name ? input : type} className={className} autocomplete={autocomplete} />
					<span className={cx(s.pwdImage, 'pwdImageRTL')} onClick={() => this.handleChange(input.name)}>
						{showPassword === input.name ? <img src={ShowPassword} /> : <img src={HidePassword} />}
					</span>
					{touched && error && <span className={cx(rs.errorMessage, 'errorMessageRTL')}>{formatMessage(error)}</span>}
				</div>
			</FormGroup>
		);
	}

	render() {
		const { error, handleSubmit, submitting, label } = this.props;
		const { formatMessage } = this.props.intl;

		return (
			<div className={'mainContainer'}>
				<Container fluid>
					<Row>
						<Col md={12} lg={12} sm={12} xs={12}>
							<div>
								<h1 className={cx(s.titleBottom, rs.storeTitleText, 'textAlignRightRTL')}>{formatMessage(messages.changePassword)}</h1>
							</div>
							<Form onSubmit={handleSubmit(submit)}>
								<div className={cx(rs.storeNewBg, rs.space5)}>
									{error && <span className={cx(rs.errorMessage, 'errorMessageRTL')}>{error}</span>}
									<FormGroup className={s.formGroup}>
										<Field name="currentPassword" type="password"
											label={formatMessage(messages.currentPassword)}
											component={this.renderFormControl}
											placeholder={'Password'}
											className={cx(rs.passwordFormControlInput, 'passwordFormControlInputRtl')}
											autocomplete={'current-password'}
											showPassword={this.state.showPassword}
										/>
									</FormGroup>
									<FormGroup className={s.formGroup}>
										<Field name="newPassword" type="password"
											label={formatMessage(messages.newPassword)}
											component={this.renderFormControl}
											placeholder={'Password'}
											className={cx(rs.passwordFormControlInput, 'passwordFormControlInputRtl')}
											autocomplete={'new-password'}
											showPassword={this.state.showPassword}
										/>
									</FormGroup>
									<FormGroup className={s.formGroup}>
										<Field name="confirmNewPassword" type="password"
											label={formatMessage(messages.confirmNewPassword)}
											component={this.renderFormControl}
											placeholder={'Password'}
											className={cx(rs.passwordFormControlInput, 'passwordFormControlInputRtl')}
											autocomplete={'new-password'}
											showPassword={this.state.showPassword}
										/>
									</FormGroup>
								</div>
								<FormGroup className={cx(s.formGroup, 'floatLeftRTL')}>
									<Button className={cx(rs.button, rs.btnPrimary, s.btnWidth)} block type="submit" disabled={submitting}>
										{formatMessage(messages.updatePassword)}
									</Button>
								</FormGroup>
							</Form>
						</Col>
					</Row>
				</Container>
			</div>
		)
	}

}


Password = reduxForm({
	form: 'ChangePassword',
	validate
})(Password);

const mapState = (state) => ({});

const mapDispatch = {};

export default injectIntl(withStyles(s, rs)(connect(mapState, mapDispatch)(Password)));