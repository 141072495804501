import React, { Component } from 'react'
import { Field, reduxForm, formValueSelector, initialize } from 'redux-form';
import { connect } from 'react-redux'
import withStyles from 'isomorphic-style-loader/withStyles';
import { injectIntl } from 'react-intl';
import cx from 'classnames';
import Form from 'react-bootstrap/Form';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Container from 'react-bootstrap/Container';
import Link from '../../../Link';

import ImageUploadComponent from '../../../Common/ImageUploadComponent/ImageUploadComponent';
import Loader from '../../../Common/Loader';

import validate from './validate';
import submit from './submit'
import messages from '../../../../locale/messages';
import { api, categoryUploadDir } from '../../../../config';

import s from './AddCategoryForm.css';
export class AddCategoryForm extends Component {

	static defaultProps = {
		loading: false
	};

	renderField = ({ input, label, type, meta: { touched, error }, labelClass, fieldClass, placeholder }) => {
		const { formatMessage } = this.props.intl
		return (
			<Form.Group className={cx(s.space5, 'positionRelative')}>
				<Form.Label>{label}</Form.Label>
				<Form.Control {...input} placeholder={placeholder} type={type} className={fieldClass} />
				{touched && error && <span className={cx(s.errorMessage, 'errorMessageRTL')}>{formatMessage(error)}</span>}
			</Form.Group>
		)
	}

	renderSelectField = ({ input, label, type, meta: { touched, error }, labelClass, fieldClass, placeholder, children, maxlength }) => {
		const { formatMessage } = this.props.intl;
		return (
			<Form.Group className={cx(s.space5, 'positionRelative')}>
				<label>{label}</label>
				<Form.Control as="select" {...input} placeholder={placeholder} className={fieldClass} maxlength={maxlength}>
					{children}
				</Form.Control>
				{touched && error && <span className={cx(s.errorMessage, 'errorMessageRTL')}>{formatMessage(error)}</span>}
			</Form.Group>
		)
	}

	render() {
		const { handleSubmit, categoryImage, loading, submitting, deliveryTypes: { getActiveDeliveryType }, imageLoader } = this.props;
		const { formatMessage } = this.props.intl;

		return (
			<Container fluid>
				<div className={s.blackCenterSection}>
					<Form className={s.fullWidth} onSubmit={handleSubmit(submit)} >
						<Row>
							<Col lg={6} md={12} sm={12} xs={12}>
								<Form.Group className={s.space5}>
									<div className={cx(s.centerFlex, s.profileImgSection)}>
										<ImageUploadComponent
											className={cx(s.btnSecondary, s.profileNoPadding)}
											subTextClass={s.subText}
											subText={formatMessage(messages.maximumUploadSizeLabel)}
											defaultMessage={formatMessage(messages.chooseFile)}
											loaderName={'categoryImageLoader'}
											postUrl={api.apiEndpoint + '/uploadCategoryImage'}
											loader={imageLoader}
											fieldName={'categoryImage'}
											formName={'AddCategoryForm'}
											imageSrc={categoryImage ? api.apiEndpoint + categoryUploadDir + categoryImage : null}
											label={formatMessage(messages.categoryIcon)}
										/>
									</div>
								</Form.Group>
							</Col>
							<Col lg={6} md={12} sm={12} xs={12}>
								<Field
									name="categoryName"
									type="text"
									placeholder={formatMessage(messages.categoryName)}
									component={this.renderField}
									label={formatMessage(messages.categoryName)}
									labelClass={s.labelText}
									fieldClass={s.formControlInput}
								/>
								<Field
									name="deliveryType"
									placeholder={formatMessage(messages.deliveryName)}
									component={this.renderSelectField}
									label={formatMessage(messages.deliveryName)}
									labelClass={s.labelText}
									fieldClass={cx(s.formControlSelect, s.formControlInput)}
								>
									<option value={""}>{formatMessage(messages.deliveryName)}</option>
									{
										getActiveDeliveryType?.results && getActiveDeliveryType?.results?.map(item => (
											<option key={item.id} value={item.id}>{item.deliveryName}</option>
										))
									}
								</Field>
								<Form.Group className={cx(s.space5, 'positionRelative')}>
									<label>{formatMessage(messages.status)}</label>
									<Field name="isActive" className={cx(s.formControlSelect, s.formControlInput)} component="select">
										<option value={true}>{formatMessage(messages.active)}</option>
										<option value={false}>{formatMessage(messages.inactive)}</option>
									</Field>
								</Form.Group>
							</Col>
							<Col lg={12} md={12} sm={12} xs={12} className={cx(s.textAlignRight, 'textAlignLeftRTL')}>
								<Form.Group className={s.noMargin}>
									<Link to={"/siteadmin/category"} className={cx(s.backBtnTwo, s.btnSecondaryBorder, s.linkBtnInline, 'backBtnTwoRTL', s.backBtnTwoTop)} >{formatMessage(messages.goBack)}</Link>
									<div className={s.displayInlineBlock}>
										<Loader
											type={"button"}
											label={formatMessage(messages.submitButton)}
											show={loading}
											buttonType={'submit'}
											className={cx(s.button, s.btnPrimary)}
											disabled={submitting || loading}
											isSuffix={true}
										/>
									</div>
								</Form.Group>
							</Col>
						</Row>
					</Form>
				</div>
			</Container>
		)
	}
}

AddCategoryForm = reduxForm({
	form: 'AddCategoryForm',
	onSubmit: submit,
	validate
})(AddCategoryForm);

const selector = formValueSelector('AddCategoryForm');

const mapState = (state) => ({
	categoryImage: selector(state, 'categoryImage'),
	loading: state.loader.AddCategory,
	imageLoader: state.loader.categoryImageLoader
})

const mapDispatch = {
	initialize
}

export default injectIntl(withStyles(s)(connect(mapState, mapDispatch)(AddCategoryForm)));