import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Field, reduxForm, reset } from 'redux-form';
import { graphql } from 'react-apollo';
import gql from 'graphql-tag'
import { flowRight as compose } from 'lodash'
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';

// Style
import {
  FormGroup,
  Col,
  FormControl,
  Row,
  Container
} from 'react-bootstrap';
import cx from 'classnames';
import withStyles from 'isomorphic-style-loader/withStyles';
import Loader from '../../Common/Loader';
import { setLoaderStart, setLoaderComplete } from '../../../actions/loader/loader';
import { injectIntl } from 'react-intl';
import messages from '../../../locale/messages'
import ShowPassword from '../../../../public/StoreIcon/pswVisible.svg';
import HidePassword from '../../../../public/StoreIcon/pwdHidden.svg';
import showToaster from '../../../helpers/toasterMessages/showToaster';
import validate from './validate';
import s from './ChangeAdminForm.css';
import bt from '../../commonStyle.css';


class ChangeAdminForm extends Component {


  constructor(props) {
    super(props);
    this.state = {
      showPassword: ''
    };
    this.submitForm = this.submitForm.bind(this);
  }

  static defaultProps = {
    loading: false
  };

  handleChange(fieldName) {
    this.setState({ showPassword: fieldName === this.state.showPassword ? "" : fieldName })
  }

  renderFormControl = ({ input, label, type, meta: { touched, error }, className, note, showPassword }) => {
    const { formatMessage } = this.props.intl
    return (
      <FormGroup className={s.space5}>
        <label>{label}</label>
        <div className={'positionRelative'}>
          <FormControl {...input} placeholder={label} type={showPassword === input.name ? input : type} className={className} />
          {
            type == 'password' && <span className={cx(s.pwdImage, 'passwordImageRTL')} onClick={() => this.handleChange(input.name)}>
              {showPassword === input.name ? <img src={ShowPassword} /> : <img src={HidePassword} />}
            </span>
          }
        </div>
        {touched && error && <span className={cx(s.errorMessage, 'errorMessageRTL')}>{formatMessage(error)}</span>}
        {
          note && <p className={s.subtext}>{note}</p>
        }
      </FormGroup>
    );
  }

  async submitForm(values, dispatch) {

    dispatch(setLoaderStart('changePassword'));

    const { mutate } = this.props;
    const { data } = await mutate({ variables: values });

    if (data?.changeAdminUser) {
      if (data?.changeAdminUser?.status === 200) {
        showToaster({ messageId: 'addAdminDetails', toasterType: 'success' })
      } else if (data.changeAdminUser.status === 400) {
        showToaster({ messageId: 'emailAlreadyExist', toasterType: 'error' })
      } else {
        showToaster({ messageId: 'adminDetailsFailed', toasterType: 'error' })
      }
    }

    dispatch(setLoaderComplete('changePassword'));
    dispatch(reset('ChangeAdminForm'));

  }

  render() {

    const { error, handleSubmit, submitting, dispatch, title, isSuperAdmin, loading } = this.props;
    const { formatMessage } = this.props.intl;
    return (
      <Container fluid>
        <form onSubmit={handleSubmit(this.submitForm)}>
          <div className={cx(s.heading, 'textAlignRightRTL')}>
            <FormattedMessage {...messages.changeAdminEmail} />
          </div>
          <div className={s.blackCenterSection}>
            <Row>
              <Col lg={12} md={12} sm={12} xs={12}>
                {error && <strong>{error}</strong>}
                {
                  <Field
                    name="email"
                    type="text"
                    component={this.renderFormControl}
                    label={formatMessage(messages.email)}
                    note={formatMessage(messages.emailNotes)}
                    className={cx(s.formControlInput)}
                  />
                }
                <Field name="password" type="password" component={this.renderFormControl} label={formatMessage(messages.password)} className={cx(s.formControlInput, 'passwordInput')} showPassword={this.state.showPassword} />
                <Field name="confirmPassword" type="password" component={this.renderFormControl} label={formatMessage(messages.confirmPassword)} className={cx(s.formControlInput, 'passwordInput')} showPassword={this.state.showPassword} />
                <div className={cx(s.textAlignRight, 'textAlignLeftRTL')}>
                  <Loader
                    type={"button"}
                    label={formatMessage(messages.submitButton)}
                    show={loading}
                    buttonType={'submit'}
                    className={cx(s.button, s.btnPrimary)}
                    disabled={submitting || loading}
                    isSuffix={true}
                  />
                </div>
              </Col>
            </Row>
          </div>
        </form>
      </Container>
    );
  }

}

ChangeAdminForm = reduxForm({
  form: 'ChangeAdminForm',
  validate
})(ChangeAdminForm);

const mapState = (state) => ({
  isSuperAdmin: state.runtime.isSuperAdmin,
  loading: state.loader.changePassword
});

const mapDispatch = {};

export default injectIntl(compose(
  withStyles(s),
  connect(mapState, mapDispatch),
  graphql(gql`
    mutation changeAdminUser($email: String, $password: String!) {
      changeAdminUser (email: $email, password: $password) {
        status
      }
    }
  `),
)(ChangeAdminForm));