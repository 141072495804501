import {
    HOMEPAGE_SETTINGS_UPLOAD_START,
    HOMEPAGE_SETTINGS_UPLOAD_ERROR,
    HOMEPAGE_SETTINGS_UPLOAD_SUCCESS
} from '../../constants/index'
import showToaster from '../../helpers/toasterMessages/showToaster';
import { setLoaderStart, setLoaderComplete } from '../loader/loader';
import { updateHomePageSafety as mutation } from '../../lib/graphql';

const updateHomepageSettingsSafety = (values) => {

    return async (dispatch, getState, { client }) => {
        try {

            dispatch({
                type: HOMEPAGE_SETTINGS_UPLOAD_START
            })

            dispatch(setLoaderStart('SafetySettingsForm'))

            const { data } = await client.mutate({
                mutation,
                variables: {
                    safetyGridImage1: values?.safetyGridImage1,
                    safetyGridImage2: values?.safetyGridImage2,
                    safetyGridImage3: values?.safetyGridImage3,
                    safetyGridTitle1: values?.safetyGridTitle1,
                    safetyGridContent1: values?.safetyGridContent1,
                    safetyGridLink1: values?.safetyGridLink1,
                    safetyGridLink2: values?.safetyGridLink2,
                    userAppBgColor: values?.userAppBgColor
                }
            })

            dispatch(setLoaderComplete('SafetySettingsForm'))
            if (data?.updateHomePageSafety?.status == 200) {

                dispatch({
                    type: HOMEPAGE_SETTINGS_UPLOAD_SUCCESS
                })
                showToaster({ messageId: 'updateHomePageSettings', toasterType: 'success' })
            } else {
                dispatch({
                    type: HOMEPAGE_SETTINGS_UPLOAD_ERROR
                })
                showToaster({ messageId: 'settingsUploadError', toasterType: 'error' })
            }
        } catch (err) {
            dispatch({
                type: HOMEPAGE_SETTINGS_UPLOAD_ERROR
            })
            showToaster({ messageId: 'settingsUploadError', toasterType: 'error' })
        }
    }
}
export default updateHomepageSettingsSafety