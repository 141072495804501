import fx from 'money';
import { locales } from '../config';

const convert = (base, rates, amount, from, to) => {
    try {
        var toCurrency;
        fx.base = base;
        fx.rates = rates;
        if (to) {
            toCurrency = to
        } else {
            toCurrency = base
        }
        let value = fx.convert(amount, { from, to: toCurrency });
        return value;
    } catch (error) {
        console.log(error);
    }
}

const getCurrencySymbol = (currency, locale) => {
    try {
        let defaultValue = 0;
        let convertCurrency = currency ? currency : 'USD';
        let symbol = defaultValue.toLocaleString(locales[0], { style: 'currency', currency: convertCurrency, minimumFractionDigits: 2, maximumFractionDigits: 2 }).replace(/\d+([,.]\d+)?/g, "") || null;
        return symbol;
    } catch (error) {
        console.log(error);
    }
}
export { convert, getCurrencySymbol }