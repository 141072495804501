import React from 'react';
import AdminLayout from '../../../components/Layout/AdminLayout';
import AdminUser from './AdminUser';
import messages from '../../../locale/messages';


function action({ store, intl }) {
  const title = intl.formatMessage(messages.manageAdminUsers);

  //From Redux Store
  let isAdminAuthenticated = store.getState().runtime.isAdminAuthenticated;
  let isSuperAdmin = store.getState().runtime.isSuperAdmin;

  if (!isAdminAuthenticated) {
    return { redirect: '/siteadmin/login' };
  }

  if (!isSuperAdmin) {
    return { redirect: '/siteadmin/login' };
  }

  return {
    title,
    component: (
      <AdminLayout><AdminUser title={title} /></AdminLayout>
    ),
  }
}

export default action;
