import moment from 'moment';

const localeDict = {
    'en-US': 'English',
    'es': 'Español',
    'fr-FR': 'Français',
    'ru-RU': 'Pусский',
    'ja-JA': '日本人',
    'id-ID': 'Indonesia',
    'hr-HR': 'Croatian',
    'zh-CN': '中文',
    'sv-SE': 'Svenska',
    'ar': 'الْحُرُوف الْعَرَبِيَّة',
    'sk': 'slovenský',
    'cs': 'čeština'
};

const rtlLocateDict = ['he', 'ar'];

const formatLocale = (locale) => {
    return localeDict[locale] || 'English';
}

const isRTL = (locale) => {
    return locale && rtlLocateDict.indexOf(locale) >= 0;
}

const generateMomentLocaleSettings = (locale) => {
    var localeData = moment.localeData('en');
    let response = {
        ordinal: localeData.ordinal()
    };
    return response;
}
export {
    formatLocale,
    isRTL,
    generateMomentLocaleSettings
}