import React from 'react';
import withStyles from 'isomorphic-style-loader/withStyles';
import { graphql } from 'react-apollo';
import { compose } from 'redux';
// style
import s from './DashboardContainer.css';
// graphql
import getDashboardCount from './getDashboardCount.graphql';
import getDashboardOrderHistory from './getDashboardOrderHistory.graphql';
// component
import Loader from '../../../components/Common/Loader';
import Dashboard from '../../../components/Shop/Dashboard/Dashboard';
class DashboardContainer extends React.Component {
  static defaultProps = {
    dashboardData: {
      loading: true
    },
    dashboardOrderHistory: {
      loading: true
    }
  };

  render() {
    const { dashboardData, dashboardData : { loading, getShopDashboardData } } = this.props;
    const { dashboardOrderHistory, dashboardOrderHistory: { loading: historyLoading, getShopDashboardOrderHistory } } = this.props;
    
    return (
      <Loader type={'page'} show={loading}>
        <div className={s.root}>
          <div className={s.container}>
          {
            !loading && <Dashboard
              orders={!loading && dashboardData && getShopDashboardData && getShopDashboardData.result && getShopDashboardData.result.orders}
              earnings={!loading && dashboardData && getShopDashboardData && getShopDashboardData.result && getShopDashboardData.result.earnings}
              history={!historyLoading && dashboardOrderHistory && getShopDashboardOrderHistory && getShopDashboardOrderHistory.result}
              historyLoading={historyLoading}
              historyRefetch={dashboardOrderHistory && dashboardOrderHistory.refetch}
            />
          }
          </div>
        </div>  
      </Loader>
    )
  }
}

export default compose(
  withStyles(s),
  graphql(getDashboardCount, {
    name: 'dashboardData',
    options: {
      ssr: true,
      fetchPolicy: 'network-only',
      variables: {
        requestDate: new Date()
      }
    }
  }),
  graphql(getDashboardOrderHistory, {
    name: 'dashboardOrderHistory',
    options: {
      ssr: true,
      fetchPolicy: 'network-only',
      variables: {
        requestDate: new Date(),
        requestDays: 30
      }
    }
  })
) (DashboardContainer);
