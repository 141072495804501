import {
    DELETE_DELIVERY_VEHICLE_SUCCESS,
    DELETE_DELIVERY_VEHICLE_START,
    DELETE_DELIVERY_VEHICLE_ERROR
} from '../../../constants';
import showToaster from '../../../helpers/toasterMessages/showToaster';
import { deleteDeliveryVehicle as mutation } from '../../../lib/graphql';
import { removeImage } from '../../../helpers/removeImage';

export const deleteDeliveryVehicle = (id, fileName) => {

    return async (dispatch, getState, { client }) => {
        try {

            dispatch({
                type: DELETE_DELIVERY_VEHICLE_START
            });

            const { data } = await client.mutate({
                mutation,
                variables: {
                    id
                }
            });

            if (data?.deleteDeliveryVehicle?.status === 200) {
                dispatch({
                    type: DELETE_DELIVERY_VEHICLE_SUCCESS
                });

                showToaster({ messageId: 'deleteDeliveryVehicle', toasterType: 'success' })

                let url = '/deleteDeliveryVehicleImage'

                if (fileName) {
                    removeImage(url, fileName); // Remove image from the directory
                }
                return {
                    status: 200
                };
            } else {
                dispatch({
                    type: DELETE_DELIVERY_VEHICLE_ERROR
                });
                showToaster({ messageId: 'commonToasterError', toasterType: 'error', requestMessage: data?.deleteDeliveryVehicle?.errorMessage })
                return {
                    status: 400
                };
            }
        } catch (err) {
            showToaster({ messageId: 'commonToasterError', toasterType: 'error', requestMessage: err })
            dispatch({
                type: DELETE_DELIVERY_VEHICLE_ERROR
            });
            return {
                status: 400
            };
        }
    }
};



