import {
    CANCEL_REASON_DELETE_SUCCESS,
    CANCEL_REASON_DELETE_START,
    CANCEL_REASON_DELETE_ERROR
} from '../../constants/index';
import showToaster from '../../helpers/toasterMessages/showToaster';
import { removeCancelReasonMutation, getAllCancelReasonQuery } from '../../lib/graphql';

const removeCancelReason = (id, currentPage) => {
    return async (dispatch, getState, { client }) => {
        try {

            dispatch({
                type: CANCEL_REASON_DELETE_START
            });

            const { data } = await client.mutate({
                mutation: removeCancelReasonMutation,
                variables: {
                    id
                },
                refetchQueries: [{ query: getAllCancelReasonQuery, variables: { currentPage, searchList: '' } }]
            });

            if (data?.removeCancelReason?.status == "200") {
                dispatch({
                    type: CANCEL_REASON_DELETE_SUCCESS
                });
                showToaster({ messageId: 'removeCancelReason', toasterType: 'success' })
            } else {
                dispatch({
                    type: CANCEL_REASON_DELETE_ERROR
                });

                showToaster({ messageId: 'cancelReasonFailed', toasterType: 'error' })
            }
        } catch (err) {
            dispatch({
                type: CANCEL_REASON_DELETE_ERROR
            });
            showToaster({ messageId: 'cancelReasonFailed', toasterType: 'error' })
        }
    }
};
export default removeCancelReason