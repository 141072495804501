import {
  ADD_PAYOUT_START,
  ADD_PAYOUT_SUCCESS,
  ADD_PAYOUT_ERROR,
} from '../../../constants';
import { addPayoutMutation, checkPaymentMethod } from '../../../lib/graphql';
import { processStripePayment } from '../../../core/payment/stripe/processStripePayment';
import { setLoaderComplete, setLoaderStart } from '../../loader/loader';
import showToaster from '../../../helpers/toasterMessages/showToaster';
import history from '../../../history';

export const addPayout = (
  methodId,
  payEmail,
  address1,
  address2,
  city,
  state,
  country,
  zipcode,
  currency,
  firstname,
  lastname,
  accountNumber,
  routingNumber,
  ssn4Digits,
  businessType,
  bankCode,
  branchCode,
  bankName,
  branchName,
  accountOwnerName,
  transitNumber,
  institutionNumber,
  bsbNumber,
  sortCode,
  accountToken,
  personToken) => {

  return async (dispatch, getState, { client }) => {

    try {
      dispatch(setLoaderStart('addPayoutForm'));
      dispatch({
        type: ADD_PAYOUT_START
      });

      const { data } = await client.query({
        query: checkPaymentMethod,
        fetchPolicy: 'network-only'
      });

      if (data?.getPaymentMethods?.results?.length > 0) {
        if (!data.getPaymentMethods?.results[0]?.isEnable && !data?.getPaymentMethods?.results[1]?.isEnable) {
          dispatch({
            type: ADD_PAYOUT_ERROR,
          });
          dispatch(setLoaderComplete('addPayoutForm'));
          showToaster({ messageId: 'featureNotAvailable', toasterType: 'warning' })
          return;
        }
      };

      if (methodId == 5) { // PayPal
        const { data } = await client.mutate({
          mutation: addPayoutMutation,
          variables: {
            methodId,
            payEmail,
            address1,
            address2,
            city,
            state,
            country,
            zipcode,
            currency,
            isVerified: true
          }
        });

        if (data?.addPayout?.status === 200) {
          dispatch({
            type: ADD_PAYOUT_SUCCESS,
          });
        }
        history.push('/store/payout');
      } else {

        let userDetails = { payEmail };

        let bankDetails = {
          payEmail,
          address1,
          address2,
          city,
          state,
          country,
          zipcode,
          currency,
          firstname,
          lastname,
          accountNumber,
          routingNumber,
          ssn4Digits,
          businessType,
          bankCode,
          branchCode,
          bankName,
          branchName,
          accountOwnerName,
          transitNumber,
          institutionNumber,
          bsbNumber,
          sortCode,
          accountToken,
          personToken
        };

        const { status, errorMessage, accountId } = await processStripePayment(
          'addPayout',
          userDetails,
          bankDetails
        );
        if (status == '200' && accountId) {
          dispatch({
            type: ADD_PAYOUT_SUCCESS
          });
          dispatch(setLoaderComplete('addPayoutForm'));
        } else {
          showToaster({ messageId: 'commonToasterError', toasterType: 'error', requestMessage: errorMessage })
          dispatch({
            type: ADD_PAYOUT_ERROR
          });
          dispatch(setLoaderComplete('addPayoutForm'));
        }
      }
    } catch (error) {
      dispatch({
        type: ADD_PAYOUT_ERROR
      });

      dispatch(setLoaderComplete('addPayoutForm'));
    }
  };
};