
import React from 'react';
import FailedPayoutContainer from './FailedPayoutContainer';
import DashboardLayout from '../../../../components/Shop/StoreLayout/Layout/DashboardLayout';
import messages from '../../../../locale/messages';

async function action({ store, query, intl }) {
  const title = intl.formatMessage(messages.payoutPreferences);

  let isStoreAuthenticated = store.getState().runtime.isStoreAuthenticated;
  let currentAccountId = query && query.account;

  if (!isStoreAuthenticated) {
    return { redirect: '/store' };
  }

  return {
    title,
    component: (
      <DashboardLayout>
        <FailedPayoutContainer title={title} currentAccountId={currentAccountId} />
      </DashboardLayout>
    )
  };

};

export default action;
