exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "._3EqrH {\n  text-align: center;\n}\n\n._2cNZ6 {\n  padding: 34px 34px 34px;\n  background-color: #FFF2F2;\n}\n\n._3U3u2 {\n  font: normal normal bold 22px/29px Source Sans Pro;\n  font-size: 22px;\n  line-height: 29px;\n  font-weight: bold;\n  color: #212121;\n}\n\n._1qQU_ {\n  line-height: 23px;\n  font-size: 18px;\n  color: #212121;\n  margin: 0px;\n}\n\n._11EFG {\n  padding: 0px 32px;\n}\n\n.LNAKB {\n  width: 100%;\n}\n\n._1pGbP {\n  padding: 0px !important;\n  border-radius: 4px !important;\n  background-color: #FFFFFF !important;\n  border: 1px solid #FFFFFF !important;\n  -webkit-box-shadow: none !important;\n          box-shadow: none !important;\n  color: #FFB400 !important;\n  text-decoration: none !important;\n  text-align: center;\n  font-size: 18px;\n  font-weight: 500;\n}\n\n._1pGbP:hover,\n._1pGbP:focus {\n  padding: 0px !important;\n  border-radius: 4px !important;\n  background-color: #FFFFFF !important;\n  border: 1px solid #FFFFFF !important;\n  -webkit-box-shadow: none !important;\n          box-shadow: none !important;\n  color: #FFB400 !important;\n  text-decoration: none !important;\n  text-align: center;\n  font-size: 18px;\n  font-weight: 500;\n}", ""]);

// exports
exports.locals = {
	"textAlignCenter": "_3EqrH",
	"padding": "_2cNZ6",
	"titleText": "_3U3u2",
	"descriptionText": "_1qQU_",
	"formSection": "_11EFG",
	"fullWidth": "LNAKB",
	"modalCaptionLink": "_1pGbP"
};