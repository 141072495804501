import React from 'react';
import withStyles from 'isomorphic-style-loader/withStyles';
import { Button, FormControl, Table } from 'react-bootstrap';
import { connect } from 'react-redux';
import { FormattedMessage, injectIntl } from 'react-intl';
import cx from 'classnames';
import moment from 'moment';

// style
import s from './UsersList.css';

// helpers
import messages from '../../../../locale/messages';
import debounce from '../../../../helpers/debounce';
import { capitalizeFirstLetter } from '../../../../helpers/capitalizeFirst';

// components
import CustomPagination from '../../../CustomPagination/CustomPagination';
import Link from '../../../Link/Link';

// Images
import EditIcon from '../../../../../public/Icons/edit.png';
import TrashIcon from '../../../../../public/Icons/bin.svg';
import ExportImage from '../../../../../public/Icons/export.png';

// Redux actions
import deleteUser from '../../../../actions/siteadmin/deleteUser';
import updateShopStatus from '../../../../actions/siteadmin/ManageShop/updateShopStatus';

class UsersList extends React.Component {

  constructor(props) {
    super(props)

    this.state = {
      currentPage: 1,
      keyword: ''
    };

    this.paginationData = this.paginationData.bind(this);
    this.handleKeywordSearch = debounce(this.handleKeywordSearch.bind(this));
    this.handleStatus = this.handleStatus.bind(this);
    this.handleDelete = this.handleDelete.bind(this);
  }

  handleKeywordSearch(keyword) {
    const { usersDetails: { refetch }, requestType } = this.props;

    let variables = {
      currentPage: 1,
      keyword,
      requestType
    };

    this.setState({ keyword, currentPage: 1 });
    refetch(variables);
  }

  async handleDelete(id) {
    const { deleteUser, usersDetails: { refetch }, requestType } = this.props;
    const { keyword } = this.state;
    let userType = (requestType === 'drivers') ? 2 : 1;
    let variables = { currentPage: 1, keyword, requestType };
    const deleteUserResponse = await deleteUser(id, userType);
    this.setState({ currentPage: 1 });
    if (deleteUserResponse && deleteUserResponse.status === 200) {
      refetch(variables);
    }
  };

  paginationData(currentPage) {
    const { usersDetails: { refetch }, requestType } = this.props;
    const { keyword } = this.state;

    let variables = { currentPage, keyword, requestType };
    this.setState({ currentPage });
    refetch(variables);
  };

  async handleStatus(updateField, id, status, currentPage) {
    const { updateShopStatus, usersDetails: { refetch }, requestType } = this.props;
    const { keyword } = this.state;
    let variables = { currentPage, keyword, requestType };
    await updateShopStatus(updateField, id, status, currentPage, requestType == 'drivers' ? 2 : 1);
    refetch(variables);
  };

  render() {
    const { usersDetails, usersDetails: { getAllUsers }, requestType } = this.props;
    const { formatMessage } = this.props.intl;
    const { currentPage, keyword } = this.state;

    let exportType = 'users', pageType = 'users/';
    let paginationLabel = formatMessage(messages.users);
    if (requestType === 'drivers') {
      exportType = 'drivers';
      pageType = 'drivers/';
      paginationLabel = formatMessage(messages.drivers);
    }

    return (
      <div className={cx(s.widthInner, s.tableSection, 'whiteDropdown', 'widthInnerNoBorder')}>
        <div className={cx(s.padding5, 'adminSearchInputSection')}>
              <FormControl type='text' placeholder={formatMessage(messages.search)}
                onChange={(event) => this.handleKeywordSearch(event.target && event.target.value)}
                className={cx(s.formControlInput, s.searchInput)} />
            {
              usersDetails && getAllUsers && getAllUsers.count > 0 && <a
                href={`/export-admin-data?type=${exportType}&keyword=${keyword}`}
                className={s.exportText}>
                <span className={cx(s.vtrMiddle)}><FormattedMessage {...messages.exportDataIntoCSV} /></span>
                <span className={s.vtrTextBottom}>
                  <img src={ExportImage} className={s.exportImg} />
                </span>
              </a>
            }
        </div>
        <div>
          <div className={cx(s.tableCss, 'tableCss', 'tableSticky', 'NewAdminResponsiveTable', 'topScrollbarTable')}>
            <Table className={cx("table", 'topScrollbarTableContent')}>
              <thead>
                <tr>
                  <th scope="col"><FormattedMessage {...messages.id} /></th>
                  <th scope="col"><FormattedMessage {...messages.firstName} /></th>
                  <th scope="col"><FormattedMessage {...messages.lastName} /></th>
                  <th scope="col"><FormattedMessage {...messages.email} /></th>
                  <th scope="col"><FormattedMessage {...messages.country} /></th>
                  <th scope="col"><FormattedMessage {...messages.phoneNumber} /></th>
                  {requestType === 'drivers' && <th scope="col"><FormattedMessage {...messages.userActiveStatus} /></th>}
                  <th scope="col"><FormattedMessage {...messages.banStatus} /></th>
                  <th scope="col"><FormattedMessage {...messages.createdAt} /></th>
                  <th scope="col" className={s.minWidth}><FormattedMessage {...messages.action} /></th>
                </tr>
              </thead>
              <tbody>
                {
                  usersDetails && getAllUsers && getAllUsers.count > 0 && getAllUsers.results && getAllUsers.results.map((item, index) => {
                    return (
                      <tr key={index}>
                        <td data-label={formatMessage(messages.id)}>{item.profile?.profileId}</td>
                        <td data-label={formatMessage(messages.firstName)}>{item.profile && capitalizeFirstLetter(item.profile.firstName)}</td>
                        <td data-label={formatMessage(messages.lastName)}>{item.profile && capitalizeFirstLetter(item.profile.lastName)}</td>
                        <td data-label={formatMessage(messages.email)}>{item.email}</td>
                        <td data-label={formatMessage(messages.country)}>{item.profile && item.profile.country}</td>
                        <td data-label={formatMessage(messages.phoneNumber)}>{item.phoneDialCode + item.phoneNumber}</td>
                        {requestType === 'drivers' && <td data-label={formatMessage(messages.userActiveStatus)}>
                          <select value={item.userStatus} onChange={(e) => { this.handleStatus('userStatus', item.id, e.target.value, currentPage) }} className={s.selectInput}>
                            <option value={'pending'}>{formatMessage(messages.pending)}</option>
                            <option value={'active'}>{formatMessage(messages.approve)}</option>
                            <option value={'inactive'}>{formatMessage(messages.decline)}</option>
                          </select>
                        </td>}
                        <td data-label={formatMessage(messages.banStatus)}>
                          <select value={item.isBan} onChange={(e) => { this.handleStatus('isBan', item.id, e.target.value, currentPage) }} className={s.selectInput}>
                            <option value={1}>{formatMessage(messages.ban)}</option>
                            <option value={0}>{formatMessage(messages.permit)}</option>
                          </select>
                        </td>
                        <td data-label={formatMessage(messages.createdAt)}>{item.createdAt && moment(item.createdAt).format('DD-MM-YYYY HH:mm:ss')}</td>
                        <td data-label={formatMessage(messages.action)}>
                          <Link to={`/siteadmin/${pageType}${item.profile?.profileId}`} className={cx('editAlignIcon', s.editColorIcon)}>
                            <span><img src={EditIcon} className={cx(s.editIcon, 'editIconRTL')} /></span>
                            <span className={s.vtrMiddle}>
                              <FormattedMessage {...messages.editAction} />
                            </span>
                          </Link>
                          <Button onClick={() => this.handleDelete(item.id, currentPage)} className={cx(s.iconBtn, 'iconBtnRTL')}>
                            <img src={TrashIcon} className={cx(s.editIcon, 'editIconRTL')}/>
                            <span className={s.vtrMiddle}>
                              <FormattedMessage {...messages.deleteAction} />
                            </span>
                          </Button>
                        </td>
                      </tr>
                    )
                  })
                }
                {
                  usersDetails && getAllUsers && getAllUsers.count === 0 && <tr>
                    <td colSpan={9} className={s.noRecords}><FormattedMessage {...messages.noResult} /></td>
                  </tr>
                }
              </tbody>
            </Table>
          </div>
        </div>
        {
          usersDetails && getAllUsers && getAllUsers.count > 0 && <div className={cx(s.space5, s.spaceTop5)}>
            <CustomPagination
              total={getAllUsers.count}
              currentPage={currentPage}
              defaultCurrent={1}
              defaultPageSize={10}
              change={this.paginationData}
              paginationLabel={paginationLabel}
            />
          </div>
        }
      </div >
    );
  }
}

const mapDispatch = {
  deleteUser,
  updateShopStatus
};
const mapState = (state) => ({});

export default injectIntl(withStyles(s)(connect(mapState, mapDispatch)(UsersList)));