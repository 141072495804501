import history from '../../history';
import { setLoaderStart, setLoaderComplete } from '../../actions/loader/loader';
import showToaster from '../../helpers/toasterMessages/showToaster';
import { updateVehicleMutations, getAllVehiclesQuery } from '../../lib/graphql';

export const editVehicle = (id, vehicleName, vehicleType, vehicleNumber, vehicleStatus) => {
    return async (dispatch, getState, { client }) => {

        try {
            dispatch(setLoaderStart('EditVehicle'));

            const { data } = await client.mutate({
                mutation: updateVehicleMutations,
                variables: {
                    id,
                    vehicleName,
                    vehicleType,
                    vehicleNumber,
                    vehicleStatus
                },
                refetchQueries: [{
                    query: getAllVehiclesQuery,
                    variables: {
                        currentPage: 1
                    }
                }]
            })
            dispatch(setLoaderComplete('EditVehicle'));

            if (data?.updateVehicle?.status === 200) {
                history.push('/siteadmin/vehicles')
                showToaster({ messageId: 'updateVehicle', toasterType: 'success' })
            } else {
                showToaster({ messageId: 'commonToasterError', toasterType: 'error', requestMessage: data?.updateVehicle?.errorMessage })
            }
        } catch (error) {
            showToaster({ messageId: 'commonToasterError', toasterType: 'error', requestMessage: error })
        }
    }
}