import React from 'react';
import { connect } from 'react-redux';
import { FormattedMessage, injectIntl } from 'react-intl';
import { Field, reduxForm, formValueSelector, FieldArray } from 'redux-form';
import { change } from 'redux-form';
import withStyles from 'isomorphic-style-loader/withStyles';
import cx from 'classnames';
import Form from 'react-bootstrap/Form';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Container from 'react-bootstrap/Container';
import Button from 'react-bootstrap/Button';

import Loader from '../../Common/Loader';
import ImageUploadComponent from '../../Common/ImageUploadComponent/ImageUploadComponent';
import validate from './validate';
import submit from './submit';

import messages from '../../../locale/messages';
import { api, storeDocumentUploadDir } from '../../../config';

import closeIcon from '../../../../public/StoreIcon/closeIconRes.png';
import NoLogo from '../../../../public/StoreIcon/store-profile-default.svg';
import AddImage from '../../../../public/StoreIcon/addDocumentImage.svg';

import rs from '../../storeCommon.css';
import s from './DocumentForm.css';
class DocumentForm extends React.Component {

	static defaultProps = {
		loading: false
	}

	renderField = ({ input, label, type, meta: { touched, error }, labelClass, fieldClass, placeholder }) => {
		const { formatMessage } = this.props.intl
		return (
			<Form.Group className={cx(rs.noMargin, 'positionRelative')}>
				<Form.Label>{label}</Form.Label>
				<Form.Control {...input} placeholder={placeholder} type={type} className={fieldClass} />
				{touched && error && <span className={cx(s.errorMessage, 'errorMessageRTL')}>{formatMessage(error)}</span>}
			</Form.Group>
		)
	};

	renderDocument = ({ fields, meta: { touched, error } }) => {
		const { documentList, imageLoader } = this.props;
		const { formatMessage } = this.props.intl;

		return (
			<div>
				{fields && fields.map((document, index) => {
					let documentName = fields.get(index) && fields.get(index).fileName
					let fieldLength = fields.length - 1;

					return (
						<div className={s.displayInlineBock}>
							<div>
								<Col lg={12} md={12} sm={12} xs={12} className={rs.space2}>
									<Form.Group className={rs.space4}>
										<div className={cx(rs.profileImgSection, rs.profileImgWidth)}>
											<ImageUploadComponent
												className={cx(s.btnSecondary, s.profileNoPadding)}
												subTextClass={s.subText}
												subText={formatMessage(messages.maximumUploadSizeLabel)}
												defaultMessage={formatMessage(messages.chooseFile)}
												inputContainer={'.dzInputContainerRestaurantDocument'}
												inputContainerClass={'dzInputContainerRestaurantDocument'}
												loaderName={`documentLoader${index}`}
												postUrl={api.apiEndpoint + '/uploadStoreDocument'}
												loader={imageLoader[`documentLoader${index}`]}
												fieldName={`${document}.fileName`}
												formName={'DocumentForm'}
												imageSrc={documentName ? api.apiEndpoint + storeDocumentUploadDir + documentName : null}
												label={formatMessage(messages.documentImage)}
												documentDefault={true}
												isLoaderExist={true}
											/>
										</div>
									</Form.Group>
								</Col>
								<Col lg={12} md={12} sm={12} xs={12} className={rs.space1}>
									<Field
										name={`${document}.fileType`}
										type="text"
										placeholder={formatMessage(messages.documentName)}
										component={this.renderField}
										fieldClass={cx(rs.formControlInputStore, 'formControlInputStoreRTL')}
										label={formatMessage(messages.documentName)}
									/>
								</Col>
								{index != 0 && <div className={s.removeSection}>
									<Button
										variant="primary"
										onClick={() => fields.remove(index)}
										className={s.removeBtnSection}
									>
										<img src={closeIcon} />
									</Button>
								</div>}

							</div>
						</div>
					)
				})}
				<div className={cx(s.displayInlineBock, s.addImagePadding)}>
					<Button
						variant="primary"
						className={cx(rs.button, s.addImageSection)}
						onClick={() => fields.push({ fileType: '', fileName: '' })}
					>
						<img src={AddImage} className={s.addImage} />
						{formatMessage(messages.addDocumentLabel)}
					</Button>
				</div>
			</div>
		)
	}

	componentWillMount() {
		const { array, initialValues } = this.props
		initialValues.documentList && initialValues.documentList.length > 0 ? '' : array.push('documentList', {})
	}

	render() {
		const { loading, handleSubmit, submitting, imageLoader } = this.props;
		const { formatMessage } = this.props.intl;

		return (
			<div className={'mainContainer'}>
				<div>
					<Container className={s.container}>
						<h1 className={cx(rs.storeTitleText, 'textAlignRightRTL')}>
							<FormattedMessage {...messages.documentUpload} />
						</h1>
						<Form className={s.fullWidth} onSubmit={handleSubmit(submit)}>
							<Row>
								<Col lg={12} md={12} sm={12} xs={12}>
									<div className={cx(rs.storeNewBg, rs.space5, s.bgPadding)}>
										<FieldArray
											name="documentList"
											rerenderOnEveryChange={true}
											component={this.renderDocument}
										/>
									</div>
								</Col>
								<Col lg={12} md={12} sm={12} xs={12}>
									<Form.Group className={s.formGroup}>
										<div className={cx(rs.alignRightText, 'textAlignLeftRTL')}>
											<Loader
												type={"button"}
												label={formatMessage(messages.update)}
												show={loading}
												buttonType={'submit'}
												className={cx(rs.button, rs.btnPrimary)}
												disabled={submitting || loading}
												isSuffix={true}
											/>
										</div>
									</Form.Group>
								</Col>
							</Row>
						</Form>
					</Container>
				</div>
			</div>
		)
	}
}

DocumentForm = reduxForm({
	form: 'DocumentForm',
	onSubmit: submit,
	validate,
	enableReinitialize: true
})(DocumentForm);

const selector = formValueSelector('DocumentForm');

const mapState = (state) => ({
	documentList: selector(state, 'documentList'),
	imageLoader: state.loader
});

const mapDispatch = {
	change
};

export default injectIntl(withStyles(s, rs)(connect(mapState, mapDispatch)(DocumentForm)));