import React from 'react';
import withStyles from 'isomorphic-style-loader/withStyles';
import { Table, ButtonToolbar, Button, FormControl } from 'react-bootstrap';
import s from './PromoCodeList.css';
import { FormattedMessage, injectIntl } from 'react-intl';
import cx from 'classnames';
import messages from '../../../../locale/messages';
import moment from 'moment';
import { connect } from 'react-redux';
import { flowRight as compose } from 'lodash';
import { graphql } from 'react-apollo';
import Link from '../../../Link'
//import deleteUser from '../../../../actions/siteadmin/deleteUser';
import PropTypes from 'prop-types';

// Components
import CustomPagination from '../../../CustomPagination';
import CurrencyConverter from '../../../CurrencyConverter';

//Images
import TrashIcon from '../../../../../public/Icons/bin.svg';
import EditIcon from '../../../../../public/Icons/edit.png';
import addIcon from '../../../../../public/Icons/addNewIcon.svg';

import { deletePromoCode } from '../../../../actions/siteadmin/PromoCode/deletePromoCode';
import { addPromoCode } from '../../../../actions/siteadmin/PromoCode/addPromoCode';

import getAllPromoCode from './getAllPromoCode.graphql'

class PromoCodeList extends React.Component {
  static propTypes = {
    deleteDriver: PropTypes.any
  };

  constructor(props) {
    super(props)

    this.state = {
      currentPage: 1,
      searchList: '',
      typing: false,
      typingTimeout: 0
    }

    this.paginationData = this.paginationData.bind(this);
    this.handleDelete = this.handleDelete.bind(this);
    this.handleSearchChange = this.handleSearchChange.bind(this);
    this.handleSearchClick = this.handleSearchClick.bind(this);
  }

  handleSearchClick(searchList) {
    const { promoCodes: { refetch } } = this.props
    let variables = {
      currentPage: 1,
      searchList: searchList
    }
    this.setState({ currentPage: 1 })
    refetch(variables)
  }

  handleSearchChange(e) {
    let self = this
    if (self.state.typingTimeout) {
      clearTimeout(self.state.typingTimeout)
    }
    self.setState({
      searchList: e.target.value,
      typing: false,
      typingTimeout: setTimeout(function () {
        self.handleSearchClick(self.state.searchList)
      }, 450)
    })
  }

  async handleChange(e, id, title, description, code, type, promoValue, expiryDate, isPrivate) {
    const { addPromoCode, promoCodes: { refetch } } = this.props;
    let data = {};
    data = {
      id: id,
      title: title,
      description: description,
      code: code,
      type: type,
      promoValue: promoValue,
      isEnable: e.target.value,
      expiryDate,
      isPrivate
    }
    this.setState({ currentPage: 1 });
    let variables = { currentPage: 1 };
    await addPromoCode(data);
    refetch(variables);
  }

  async handleDelete(id, currentPage) {
    const { deletePromoCode, promoCodes: { refetch } } = this.props;
    this.setState({
      currentPage: 1
    });
    let variables = { currentPage: 1 };
    await deletePromoCode(id);
    refetch(variables);
  }

  paginationData(currentPage) {
    const { promoCodes: { refetch } } = this.props;
    let variables = { currentPage };
    this.setState({ currentPage });
    refetch(variables);
  }

  render() {
    const { promoCodes, promoCodes: { getAllPromoCodes }, currency } = this.props;
    const { currentPage, loading } = this.state;
    const { formatMessage } = this.props.intl;

    return (
      <div className={cx(s.widthInner, s.tableSection, 'whiteDropdown', 'widthInnerNoBorder')}>
        <div className={cx(s.padding5, 'adminSearchInputSection')}>
          <FormControl type='text' placeholder={formatMessage(messages.search)} onChange={(e) => this.handleSearchChange(e)}
            className={cx(s.formControlInput, s.searchInput)} />
          <Link to={'/siteadmin/promo-code/add'} className={s.addlinkBtn}>
            <FormattedMessage {...messages.addPromoCode} />
            <span className={cx(s.addIconCss, 'addIconCssRTL')}><img src={addIcon} /></span>
          </Link>
        </div>
        <div className={cx(s.tableCss, 'tableCss', 'tableSticky', 'NewAdminResponsiveTable')}>
          <Table className={cx("table")}>
            <thead>
              <tr>
                <th scope="col"><FormattedMessage {...messages.id} /></th>
                <th scope="col"><FormattedMessage {...messages.code} /></th>
                <th scope="col"><FormattedMessage {...messages.title} /></th>
                <th scope="col"><FormattedMessage {...messages.discount} /></th>
                <th scope="col"><FormattedMessage {...messages.promoType} /></th>
                <th scope="col"><FormattedMessage {...messages.status} /></th>
                <th scope="col"><FormattedMessage {...messages.action} /></th>
              </tr>
            </thead>
            <tbody>
              {
                promoCodes && promoCodes.getAllPromoCodes && promoCodes.getAllPromoCodes.data && promoCodes.getAllPromoCodes.data.length > 0 && promoCodes.getAllPromoCodes.data.map((item, index) => {
                  return (
                    <tr key={index}>
                      <td data-label={formatMessage(messages.id)}>{item && item.id}</td>
                      <td data-label={formatMessage(messages.code)}>{item && item.code}</td>
                      <td data-label={formatMessage(messages.title)}>{item && item.title}</td>
                      <td data-label={formatMessage(messages.discount)}>
                        {
                          item && item.type !== 1 && <span>
                            {item.promoValue + '%'}
                          </span>
                        }
                        {
                          item && item.type === 1 && <CurrencyConverter
                            amount={item.promoValue}
                            from={item.currency}
                            toCurrency={currency}
                          />
                        }
                      </td>
                      <td data-label={formatMessage(messages.promoType)}>{item && item.type === 0 ? formatMessage(messages.percentage) : formatMessage(messages.fixedAmount)}</td>
                      <td data-label={formatMessage(messages.status)}>
                        <select name="isEnable" onChange={(e) => this.handleChange(e, item.id, item.title, item.description, item.code, item.type, item.promoValue, item.expiryDate, item.isPrivate)} value={item.isEnable} className={s.selectInput}>
                          <option value="true">{formatMessage(messages.active)}</option>
                          <option value="false">{formatMessage(messages.inactive)}</option>
                        </select>
                        {/* {
                          item && item.isEnable && <FormattedMessage {...messages.active} />
                        }
                        {
                          item && !item.isEnable && <FormattedMessage {...messages.inactive} />
                        } */}
                      </td>
                      <td data-label={formatMessage(messages.action)}>
                        <Link to={'/siteadmin/promo-code/edit/' + item.id} className={cx('editAlignIcon', s.editColorIcon)}>
                          <span><img src={EditIcon} className={cx(s.editIcon, 'editIconRTL')} /></span>
                          <span className={s.vtrMiddle}>
                            <FormattedMessage {...messages.editAction} />
                          </span>
                        </Link>
                        <Button onClick={() => this.handleDelete(item.id, currentPage)} className={cx(s.iconBtn, 'iconBtnRTL')} disabled={loading}>
                          <img src={TrashIcon} className={cx(s.editIcon, 'editIconRTL')} />
                          <span className={s.vtrMiddle}>
                            <FormattedMessage {...messages.deleteAction} />
                          </span>
                        </Button>
                      </td>
                    </tr>
                  )
                })
              }
              {
                promoCodes && promoCodes.getAllPromoCodes && promoCodes.getAllPromoCodes.data && promoCodes.getAllPromoCodes.data.length == 0 && (
                  <tr>
                    <td colspan="12" className={s.noRecords}><FormattedMessage {...messages.noResult} /></td>
                  </tr>
                )
              }
            </tbody>
          </Table>
        </div>
        {
          promoCodes && promoCodes.getAllPromoCodes && promoCodes.getAllPromoCodes.count > 0
          && <div className={cx(s.space5, s.spaceTop5)}>
            <CustomPagination
              total={promoCodes.getAllPromoCodes.count}
              currentPage={currentPage}
              defaultCurrent={1}
              defaultPageSize={10}
              change={this.paginationData}
              paginationLabel={formatMessage(messages.promoCodeId)}
            />
          </div>
        }
      </div>
    );
  }
}

const mapState = (state) => ({
  loading: state.loader.deletePromoCode
});
const mapDispatch = {
  deletePromoCode,
  addPromoCode
};

export default compose(
  withStyles(s),
  injectIntl,
  graphql(getAllPromoCode, {
    name: 'promoCodes',
    options: {
      variables: {
        currentPage: 1,
        searchList: ''
      },
      ssr: true,
      fetchPolicy: 'network-only'
    }
  }),
  connect(mapState, mapDispatch)
)(PromoCodeList);