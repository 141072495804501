
import React from 'react';
import DashboardLayout from '../../../components/Shop/StoreLayout/Layout/DashboardLayout';
import OrderDetailsContainer from './OrderDetailsContainer';
import messages from '../../../locale/messages';

function action({ store, params, intl }) {
    const title = intl.formatMessage(messages.orderDetails);

    let isStoreAuthenticated = store.getState().runtime.isStoreAuthenticated;

    if (!isStoreAuthenticated) {
        return { redirect: '/store' };
    };

    const type = params.type;
    const bookingId = params.id;

    return {
        title,
        component: <DashboardLayout>
            <OrderDetailsContainer title={title} type={type} bookingId={bookingId} />
        </DashboardLayout>
    };
}

export default action;
