import messages from '../../../locale/messages';

const validate = values => {
    const errors = {};

    if(!values.currentPassword) {
        errors.currentPassword = messages.required;
    } else if (values.currentPassword.trim() == '') {
        errors.currentPassword = messages.required;
    }

    if (!values.newPassword) {
        errors.newPassword = messages.required;
    } else if (values.newPassword.trim() == "") {
        errors.newPassword = messages.required;
    } else if (values.newPassword.length < 4 ) {
        errors.newPassword = messages.required
    } else if (values.newPassword.length > 35 ) {
        errors.newPassword = messages.exceedLimit
    };

    if (!values.confirmNewPassword) {
        errors.confirmNewPassword = messages.required;
    } else if (values.confirmNewPassword.trim() == '') {
        errors.confirmNewPassword = messages.required;
    } else if (values.newPassword != values.confirmNewPassword) {
        errors.confirmNewPassword = messages.newPasswordMatch;        
    }

    return errors;
};

export default validate;