import React from 'react';
import PropTypes from 'prop-types';
import TransactionManagement from '../../../components/Shop/TransactionManagement/TransactionManagement';

class TransactionContainer extends React.Component {
  static propTypes = {
    title: PropTypes.string.isRequired,
  };

  render() {

    const { type } = this.props;

    return (
      <div>
        <div>
          <TransactionManagement type={type} />
        </div>
      </div>
    );
  }
}

export default TransactionContainer;
