
import { initializeApp, getApp, getApps, deleteApp } from "firebase/app";
import { getMessaging, getToken, isSupported } from "firebase/messaging";
import { getConfigSettings } from "../../helpers/getConfigSettings";


export async function firebaseConfiguration() {

    let requestParams, firebaseConfig, isEnable, vapidKey, messaging, isSupport;

    requestParams = ['fcmApiKey', 'fcmAuthDomain', 'fcmProjectId', 'fcmStorageBucket', 'fcmMessagingSenderId', 'fcmAppId', 'fcmMeasurementId', 'fcmVapidKey', 'enableFirebase'];

    try {
        const configData = await getConfigSettings({ name: requestParams });
        firebaseConfig = {
            apiKey: configData?.fcmApiKey,
            authDomain: configData?.fcmAuthDomain,
            projectId: configData?.fcmProjectId,
            storageBucket: configData?.fcmStorageBucket,
            messagingSenderId: configData?.fcmMessagingSenderId,
            appId: configData?.fcmAppId,
            measurementId: configData?.fcmMeasurementId,
        };

        isEnable = configData?.enableFirebase === 'true';
        vapidKey = configData?.fcmVapidKey;

        if (isEnable) {
            try {
                const app = initializeApp(firebaseConfig);
                isSupport = await isSupported();  // Check the supported browser
                messaging = isSupport ? getMessaging(app) : null;
            } catch (error) {
                const clientFcm = getApps();
                if (clientFcm.length > 0) {
                    deleteApp(getApp());
                    const app = initializeApp(firebaseConfig);
                    isSupport = await isSupported();  // Check the supported browser
                    messaging = isSupport ? getMessaging(app) : null;
                }
            }
        }

    } catch (err) {
        console.log('Firebase configuration failed', err);
    }

    return {
        messaging,
        isEnable,
        vapidKey,
        firebaseConfig
    };

}

export async function fcwServiceWorkerRegister() {
    try {
        await Notification.requestPermission();
        const { firebaseConfig } = await firebaseConfiguration();
        const fcmParams = encodeURIComponent(JSON.stringify(firebaseConfig));
        if ("serviceWorker" in navigator) await navigator.serviceWorker.register(`firebase-messaging-sw.js?firebaseConfig=${fcmParams}`);
        return true;
    } catch (error) {
        return false;
    }
}

export async function getDeviceId() {
    let token;
    try {
        const { messaging, vapidKey, isEnable } = await firebaseConfiguration();
        const serviceWorkerRegistration = await navigator.serviceWorker.getRegistration('/store/firebase-cloud-messaging-push-scope');
        const permission = await Notification.requestPermission();
        if (permission === 'granted' && messaging && isEnable) await getToken(messaging, { vapidKey, serviceWorkerRegistration }).then((currentToken) => {
            token = currentToken;
        });
    } catch (err) {
        console.log('An error occurred while retrieving token.', err)
    }
    return token;
}