import {
  INSURANCE_IMAGE_UPLOAD_ERROR,
  INSURANCE_IMAGE_UPLOAD_START,
  INSURANCE_IMAGE_UPLOAD_SUCCESS
} from '../../constants/index'
import showToaster from '../../helpers/toasterMessages/showToaster';
import { uploadInsuranceImage as mutation } from '../../lib/graphql';
import { removeImage } from '../../helpers/removeImage';

const uploadInsuranceImage = (userId, fileName, oldFile) => {

  return async (dispatch, getState, { client }) => {

    try {
      dispatch({
        type: INSURANCE_IMAGE_UPLOAD_START
      });

      const { data } = await client.mutate({
        mutation,
        variables: {
          userId,
          vehicleInsurance: fileName
        }
      });

      let url = '/deleteInsuranceImage'

      if (oldFile !== undefined) {
        removeImage(url, oldFile);
      };

      if (data?.uploadInsuranceImage?.status == "200") {
        dispatch({
          type: INSURANCE_IMAGE_UPLOAD_SUCCESS
        });
        showToaster({ messageId: 'uploadInsuranceImage', toasterType: 'success' });
      } else {
        dispatch({
          type: INSURANCE_IMAGE_UPLOAD_ERROR
        });
      }
    } catch (err) {
      dispatch({
        type: INSURANCE_IMAGE_UPLOAD_ERROR
      });
    }
  }
}

export default uploadInsuranceImage;