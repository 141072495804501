import {
	ADMIN_UPDATE_STATIC_START,
	ADMIN_UPDATE_STATIC_SUCCESS,
	ADMIN_UPDATE_STATIC_ERROR
} from '../../constants';
import showToaster from '../../helpers/toasterMessages/showToaster';
import { removeStaticPage, updateStaticPage } from '../../lib/graphql';
import { removeImage } from '../../helpers/removeImage';

const deleteStaticPageImage = (id, fileName) => {

	return async (dispatch, getState, { client }) => {

		dispatch({
			type: ADMIN_UPDATE_STATIC_START,
			payload: {
				loading: true
			}
		});

		try {
			// Send Request to create a record for logo
			const { data } = await client.mutate({
				mutation: removeStaticPage,
				variables: {
					id
				},
			});

			let url = '/deleteStaticBannerImage'
			if (data) {
				dispatch({
					type: ADMIN_UPDATE_STATIC_SUCCESS,
					payload: {
						loading: true
					}
				});
				await removeImage(url, fileName);
			}

		} catch (error) {
			dispatch({
				type: ADMIN_UPDATE_STATIC_ERROR,
				payload: {
					loading: true
				}
			});

			return false;
		}

		return true;
	};
}

const updateStaticPageImage = (id, pageBanner, oldPicture) => {

	return async (dispatch, getState, { client }) => {

		try {
			dispatch({
				type: ADMIN_UPDATE_STATIC_START,
				payload: {
					loading: true
				}
			})
			const { data } = await client.mutate({
				mutation: updateStaticPage,
				variables: {
					id,
					pageBanner
				}
			});

			if (data?.updateStaticPageImage?.status === "200") {
				dispatch({
					type: ADMIN_UPDATE_STATIC_SUCCESS,
					payload: {
						loading: false
					}
				});
				showToaster({ messageId: 'updateStaticPageImage', toasterType: 'success' })

				let url = '/deleteStaticBannerImage'

				if (oldPicture !== null) {
					removeImage(url, oldPicture);
				};
			} else {
				dispatch({
					type: ADMIN_UPDATE_STATIC_ERROR,
					payload: {
						loading: false
					}
				})
			}
		} catch (err) {
			dispatch({
				type: ADMIN_UPDATE_STATIC_ERROR,
				payload: {
					loading: false
				}
			})
		}
	}
}
export { updateStaticPageImage, deleteStaticPageImage }