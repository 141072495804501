import React from 'react';
import PropTypes from 'prop-types';
import withStyles from 'isomorphic-style-loader/withStyles';
import cx from 'classnames';
import s from './AddDeliveryVehicle.css';
import DeliveryVehicleForm from '../../../../components/SiteAdmin/DeliveryVehicle/DeliveryVehicleForm';
//meassage
import {  FormattedMessage } from 'react-intl';
import messages from '../../../../locale/messages';

class AddDeliveryVehicle extends React.Component {
  static propTypes = {
    title: PropTypes.string.isRequired
  };

  render() {
    const { title, locations, categories } = this.props;

    return (
        <div className={s.root}>
          <div className={s.container}>
           <div className={cx(s.heading, 'textAlignRightRTL', s.contentPageMargin)}>
            <FormattedMessage {...messages.addDeliveryVehicle} />
            </div>
            <div>
              <DeliveryVehicleForm initialValues={{ isActive: 'true' }}/>
            </div>
          </div>
        </div>
    );
  }
}

export default withStyles(s)(AddDeliveryVehicle);
