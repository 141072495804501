exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "._2mgPm {\n  display: inline-block;\n  width: 50%;\n  position: relative;\n  margin-bottom: 15px;\n  vertical-align: top;\n}\n\n._1cOkJ {\n  position: absolute;\n  top: -15px;\n  right: 2px;\n}\n\n._20XoN,\n._20XoN:active {\n  border-radius: 50%;\n  padding: 0;\n  width: 28px;\n  height: 28px;\n  text-align: center;\n  color: #000;\n  background: #fff !important;\n  border: 2px solid #FFB400 !important;\n  line-height: 8px;\n}\n\n._20XoN:focus {\n  -webkit-box-shadow: none !important;\n          box-shadow: none !important;\n}\n\n._20XoN:hover {\n  background: #fff !important;\n  border: 2px solid #FFB400;\n}\n\n._2n7lR {\n  padding-top: 42%;\n}\n\n._3XuO4 {\n  display: block;\n  width: 100%;\n  max-width: 76px;\n  margin: 0 auto;\n  margin-bottom: 12px;\n}\n\n._3is9n,\n._3is9n:hover,\n._3is9n:focus,\n._3is9n:active {\n  width: 100%;\n  height: 350px;\n  border-color: #DADADA !important;\n  background: #FFFFFF !important;\n  color: #FFB400 !important;\n  font-size: 18px;\n  border-radius: 4px;\n}\n\n.JMm8A {\n  padding: 0px 15px;\n}\n\n._1qVJ- {\n  padding-left: 9px;\n  padding-right: 9px;\n}\n\n.Doctz {\n  top: 35%;\n}\n\n@media screen and (max-width: 991px) {\n  ._2mgPm {\n    width: 100%;\n  }\n}\n\n@media screen and (max-width: 767px) {\n  .Tidwg {\n    padding: 0px;\n  }\n}\n\n._1hHFT {\n  color: red !important;\n}", ""]);

// exports
exports.locals = {
	"displayInlineBock": "_2mgPm",
	"removeSection": "_1cOkJ",
	"removeBtnSection": "_20XoN",
	"bgWidth": "_2n7lR",
	"addImage": "_3XuO4",
	"addImageSection": "_3is9n",
	"addImagePadding": "JMm8A",
	"bgPadding": "_1qVJ-",
	"iconTop": "Doctz",
	"responsiveNoPadding": "Tidwg",
	"errorMessage": "_1hHFT"
};