import React from 'react';
import Layout from '../../../components/Shop/StoreLayout/Layout/Layout';
import NotFound from '../../not-found/NotFound';
import ChangePasswordUsingToken from './ChangePasswordUsingToken';
import messages from '../../../locale/messages';

async function action({ store, query, intl }) {
  const title = intl.formatMessage(messages.resetPassword);

  // From Redux Store
  let isAuthenticated = store.getState().runtime.isAuthenticated;
  if (isAuthenticated) return { redirect: '/' };

  if (!query || !query.token && !query.email) {
    return {
      title,
      component: <Layout><NotFound title='Page Not Found' /></Layout>,
      status: 404,
    };
  }

  return {
    title,
    component: <Layout><ChangePasswordUsingToken email={query.email} token={query.token} /></Layout>,
  };
}

export default action;