import {
    SHOP_SIGNUP_ERROR,
    SHOP_SIGNUP_START,
    SHOP_SIGNUP_SUCCESS
} from '../../../constants';
import history from '../../../history';
import { setLoaderStart, setLoaderComplete } from '../../loader/loader';
import { setRuntimeVariable } from '../../runtime';
import { loadAccount } from '../../shop/userAccount/userAccount';
import { getAllShopModifier } from '../modifier/getAllShopModifier';
import { createShopUser } from '../../../lib/graphql';
import { getDeviceId } from '../../../core/firebase/firebase';
import showToaster from '../../../helpers/toasterMessages/showToaster';

export const shopSignup = (values) => {

    return async (dispatch, getState, { client }) => {
        try {

            dispatch({
                type: SHOP_SIGNUP_START,
                payload: {
                    storeSignupLoading: true
                }
            });
            dispatch(setLoaderStart('StoreSignup'));

            let deviceId = await getDeviceId();

            const { data } = await client.mutate({
                mutation: createShopUser,
                variables: {
                    shopName: values?.shopName,
                    firstName: values?.firstName,
                    lastName: values?.lastName,
                    email: values?.email,
                    password: values?.password,
                    phoneDialCode: values?.phoneDialCode,
                    phoneNumber: values?.phoneNumber,
                    phoneCountryCode: values?.phoneCountryCode,
                    address: values?.address,
                    lat: values?.lat,
                    lng: values?.lng,
                    city: values?.city,
                    state: values?.state,
                    zipcode: values?.zipcode,
                    country: values?.country,
                    categoryType: values?.categoryType,
                    deliveryType: values?.deliveryType,
                    deviceId
                }
            });

            if (data?.createShopUser?.status == 200) {
                showToaster({ messageId: 'createShopUser', toasterType: 'success' })
                dispatch({
                    type: SHOP_SIGNUP_SUCCESS,
                    payload: {
                        storeSignupLoading: false
                    }
                });
                dispatch(setRuntimeVariable({
                    name: 'isStoreAuthenticated',
                    value: true,
                }));
                dispatch(getAllShopModifier());
                dispatch(setLoaderComplete('StoreSignup'));
                dispatch(loadAccount());
                history.push('/store/dashboard');
            } else {
                showToaster({ messageId: 'commonToasterError', toasterType: 'error', requestMessage: data?.createShopUser?.errorMessage })

                dispatch({
                    type: SHOP_SIGNUP_ERROR,
                    payload: {
                        storeSignupLoading: false,
                        error: "Something went wrong"
                    }
                });
                dispatch(setLoaderComplete('StoreSignup'));
            }
        } catch (error) {
            showToaster({ messageId: 'commonToasterError', toasterType: 'error', requestMessage: error })

            dispatch({
                type: SHOP_SIGNUP_ERROR,
                payload: {
                    storeSignupLoading: false,
                    error: "Something went wrong"
                }
            });
            dispatch(setLoaderComplete('StoreSignup'));
        }
    }
};