import {
	SHOP_MENU_UPDATE_START,
	SHOP_MENU_UPDATE_SUCCESS,
	SHOP_MENU_UPDATE_ERROR
} from '../../../constants/index';
import { loadAccount } from '../userAccount/userAccount';
import showToaster from '../../../helpers/toasterMessages/showToaster';
import { getAllMenuQuery, deleteMenuMutation } from '../../../lib/graphql';

const deleteMenu = (id, currentPage) => {

	return async (dispatch, getState, { client }) => {
		try {
			dispatch({
				type: SHOP_MENU_UPDATE_START,
				payload: {
					updateLoading: true
				}
			});

			const { data } = await client.mutate({
				mutation: deleteMenuMutation,
				variables: {
					id
				},
				refetchQueries: [{ query: getAllMenuQuery, variables: { currentPage } }]
			});

			if (data?.deleteMenu?.status === 200) {
				dispatch({
					type: SHOP_MENU_UPDATE_SUCCESS,
					payload: {
						updateLoading: false
					}
				});
				showToaster({ messageId: 'deleteMenu', toasterType: 'success' })
				dispatch(loadAccount())
			} else {
				dispatch({
					type: SHOP_MENU_UPDATE_ERROR,
					payload: {
						updateLoading: false
					}
				});
				showToaster({ messageId: 'commonToasterError', toasterType: 'error', requestMessage: data?.deleteMenu?.errorMessag })
			}
		} catch (err) {
			showToaster({ messageId: 'commonToasterError', toasterType: 'error', requestMessage: err })
			dispatch({
				type: SHOP_MENU_UPDATE_ERROR,
				payload: {
					updateLoading: false
				}
			});
		}
	}
};
export default deleteMenu;