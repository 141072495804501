import { initialize } from 'redux-form';
import {
	SHOP_ITEM_UPDATE_START,
	SHOP_ITEM_UPDATE_SUCCESS,
	SHOP_ITEM_UPDATE_ERROR
} from '../../../constants/index';
import { getItemQuery } from '../../../lib/graphql';
import showToaster from '../../../helpers/toasterMessages/showToaster';

const editItem = (id) => {

	return async (dispatch, getState, { client }) => {
		try {
			dispatch({
				type: SHOP_ITEM_UPDATE_START,
				payload: {
					updateLoading: true
				}
			});

			const { data: { getItem } } = await client.query({
				query: getItemQuery,
				variables: {
					id
				},
				fetchPolicy: 'network-only',
			});

			if (getItem) {
				dispatch(initialize('AddItemForm', getItem));
				dispatch({
					type: SHOP_ITEM_UPDATE_SUCCESS,
					payload: {
						updateLoading: false
					}
				});
			} else {
				dispatch({
					type: SHOP_ITEM_UPDATE_ERROR,
					payload: {
						updateLoading: false
					}
				});
				showToaster({ messageId: 'commonToasterError', toasterType: 'error', requestMessage: getItem?.errorMessage })
			}
		} catch (err) {
			showToaster({ messageId: 'commonToasterError', toasterType: 'error', requestMessage: err })
			dispatch({
				type: SHOP_ITEM_UPDATE_ERROR,
				payload: {
					updateLoading: false
				}
			});
		}
	}
};
export default editItem
