
import React from 'react';
import EditMenu from './EditMenu';
import DashboardLayout from '../../../components/Shop/StoreLayout/Layout/DashboardLayout';
import messages from '../../../locale/messages';

function action({ store, params, intl }) {
  const title = intl.formatMessage(messages.craftMenu);
  let isStoreAuthenticated = store.getState().runtime.isStoreAuthenticated;
  if (!isStoreAuthenticated) {
    return { redirect: '/store' };
  };
  const id = Number(params.id);
  return {
    title,
    component: (
      <DashboardLayout>
        <EditMenu title={title} id={id} />
      </DashboardLayout>
    ),
  };
}

export default action;
