exports = module.exports = require("../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".nA_jh {\n  padding-left: 0;\n  padding-right: 0;\n  min-height: 100vh;\n  height: 100%;\n  width: 100%;\n  background: #d4d7e5;\n}\n.IQX7j {\n  margin: 0 auto;\n}\n._5Qtpb{\n  color: red\n}\n.KFi_Q {\n\tpadding-left: 8px;\n  font-size: 18px;\n  vertical-align: middle;\n  color: #3a3a3a !important;\n  font-weight: 100 !important;\n} ", ""]);

// exports
exports.locals = {
	"root": "nA_jh",
	"container": "IQX7j",
	"errorMessage": "_5Qtpb",
	"paddingLeft": "KFi_Q"
};