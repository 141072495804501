import fetch from 'node-fetch';
import {
    SEND_VERIFICATION_CODE_START,
    SEND_VERIFICATION_CODE_SUCCESS,
    SEND_VERIFICATION_CODE_ERROR
} from '../../../constants';
import { openSmsVerificationModal } from './smsVerificationModaAction';
import { change } from 'redux-form';
import { updateNumber } from '../../../lib/graphql';

const sendVerification = (phoneNumber, phoneDialCode, userId) => {

    return async (dispatch, getState, { client }) => {

        try {
            dispatch({
                type: SEND_VERIFICATION_CODE_START
            });

            const { data } = await client.mutate({
                mutation: updateNumber,
                variables: {
                    phoneNumber,
                    phoneDialCode
                }
            });

            if (data && data.updatePhoneNumber && data.updatePhoneNumber.status == 200) {
                dispatch(change("EditProfileForm", 'phoneNumber', phoneNumber));
                let variables = {
                    dialCode: phoneDialCode,
                    phoneNumber: phoneNumber,
                    id: userId
                }

                const response = await fetch('/send-verification-code', {
                    method: 'post',
                    headers: {
                        Accept: 'application/json',
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify(variables),
                    credentials: 'include'
                });
                const { status, errorMessage } = await response.json();

                if (status == 200) {
                    await dispatch(openSmsVerificationModal("verifyPhoneNumberForm"));
                    dispatch({
                        type: SEND_VERIFICATION_CODE_SUCCESS
                    });
                    return {
                        status: 200
                    }
                } else {
                    dispatch({
                        type: SEND_VERIFICATION_CODE_ERROR
                    });
                    return {
                        status: 400,
                        errorMessage
                    }
                }
            } else {
                dispatch({
                    type: SEND_VERIFICATION_CODE_ERROR
                });
                return {
                    status: data?.updatePhoneNumber?.status,
                    errorMessage: data?.updatePhoneNumber?.errorMessage
                }
            }
        } catch (error) {
            dispatch({
                type: SEND_VERIFICATION_CODE_ERROR
            });
        }
    }
}

export default sendVerification;
