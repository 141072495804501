import {
    SHOP_PREPARATION_TIME_UPDATE_ERROR,
    SHOP_PREPARATION_TIME_UPDATE_START,
    SHOP_PREPARATION_TIME_UPDATE_SUCCESS
} from '../../../constants';
import { setLoaderStart, setLoaderComplete } from '../../loader/loader';
import showToaster from '../../../helpers/toasterMessages/showToaster';
import { updatePrepareTime } from '../../../lib/graphql';

const updatePreparationTime = (shopEstimatedTime, preparationTime) => {
    return async (dispatch, getState, { client }) => {

        try {
            dispatch({
                type: SHOP_PREPARATION_TIME_UPDATE_START
            });

            dispatch(setLoaderStart('StorePreparationTime'));

            const { data } = await client.mutate({
                mutation: updatePrepareTime,
                variables: {
                    shopEstimatedTime,
                    preparationTime,
                }
            });

            if (data?.updatePreparationTime?.status == 200) {
                await dispatch({
                    type: SHOP_PREPARATION_TIME_UPDATE_SUCCESS
                });
                
                showToaster({ messageId: 'updatePreparationTime', toasterType: 'success' })
                await dispatch(setLoaderComplete('StorePreparationTime'));

            } else {
                await dispatch({
                    type: SHOP_PREPARATION_TIME_UPDATE_ERROR
                });

                showToaster({ messageId: 'commonToasterError', toasterType: 'error', requestMessage: data?.updatePreparationTime?.errorMessage })
                dispatch(setLoaderComplete('StorePreparationTime'));
            }
        } catch (error) {
            await dispatch({
                type: SHOP_PREPARATION_TIME_UPDATE_ERROR
            });

            showToaster({ messageId: 'commonToasterError', toasterType: 'error', requestMessage: error })
            dispatch(setLoaderComplete('StorePreparationTime'));
        }
    }
};
export default updatePreparationTime;