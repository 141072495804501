import {
  LICENCE_IMAGE_UPLOAD_ERROR,
  LICENCE_IMAGE_UPLOAD_START,
  LICENCE_IMAGE_UPLOAD_SUCCESS
} from '../../constants/index'
import showToaster from '../../helpers/toasterMessages/showToaster';
import { uploadLicenceBackImage as mutation } from '../../lib/graphql';
import { removeImage } from '../../helpers/removeImage';

const uploadLicenceBackImage = (id, licenceBack, oldImageBack) => {

  return async (dispatch, getState, { client }) => {

    try {
      dispatch({
        type: LICENCE_IMAGE_UPLOAD_START
      });

      const { data } = await client.mutate({
        mutation,
        variables: {
          userId: id,
          licenceBack
        }
      });
      let url = '/deleteLicenceImage'

      if (oldImageBack !== undefined) {
        removeImage(url, oldImageBack);
      };

      if (data?.uploadLicenceBackImage?.status == "200") {
        dispatch({
          type: LICENCE_IMAGE_UPLOAD_SUCCESS
        });
        showToaster({ messageId: 'uploadLicenseImage', toasterType: 'success' });
      } else {
        dispatch({
          type: LICENCE_IMAGE_UPLOAD_ERROR
        });
      }
    } catch (err) {
      dispatch({
        type: LICENCE_IMAGE_UPLOAD_ERROR
      });
    }
  }
}
export default uploadLicenceBackImage