import setOperationHours from './setOperationHours';
import showToaster from '../../helpers/toasterMessages/showToaster';
import {
    getOperationHours as query,
    updateOperationHours as mutation
} from '../../lib/graphql';

const updateOperationHours = (values) => {

    return async (dispatch, getState, { client }) => {
        try {
            const { data } = await client.mutate({
                mutation,
                variables: {
                    operationHours: values
                },
                refetchQueries: [{ query }]
            });

            if (data?.updatePartnerOperatingHours?.status == 200) {

                let operationHours = JSON.parse(values);
                dispatch(setOperationHours(operationHours));
                showToaster({ messageId: 'updatePartnerOperationHours', toasterType: 'success' })
            } else {
                showToaster({ messageId: 'unableToUpdateOperatingHours', toasterType: 'error', requestMessage: data?.updatePartnerOperatingHours?.errorMessage })
            }
        } catch (error) {
            showToaster({ messageId: 'commonToasterError', toasterType: 'error', requestMessage: error })
        }
    }
}

export default updateOperationHours;