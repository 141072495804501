import { closeSubMenuModal } from '../../siteadmin/modalActions';
import { loadAccount } from '../userAccount/userAccount';
import showToaster from '../../../helpers/toasterMessages/showToaster';
import { updateSubMenu, getAllSubMenuQuery } from '../../../lib/graphql';

const addSubMenu = (values) => {

	return async (dispatch, getState, { client }) => {
		try {

			let successMessage = values.id ? "menuUpdateChanges" : "addSubMenu"
			const { data } = await client.mutate({
				mutation: updateSubMenu,
				variables:
					values,
				refetchQueries: [{ query: getAllSubMenuQuery, variables: { currentPage: 1, searchList: '', menuId: values.menuId } }]
			});

			if (data?.updateSubMenu?.status == 200) {
				showToaster({ messageId: successMessage, toasterType: 'success' })
				dispatch(closeSubMenuModal());
				dispatch(loadAccount());

			} else {
				showToaster({ messageId: 'commonToasterError', toasterType: 'error', requestMessage: data.updateSubMenu.errorMessage })
			}
		} catch (error) {
			showToaster({ messageId: 'commonToasterError', toasterType: 'error', requestMessage: error.message })
		}
	}
}

export default addSubMenu;