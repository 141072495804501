import {
  SET_COMMON_DATA,
  GET_COMMON_SETTINGS_TYPE_SUCCESS,
  GET_COMMON_SETTINGS_TYPE_ERROR,
  SHOP_MODIFIER_SUCCESS
} from '../constants';

export default function common(state = {}, action) {
  switch (action.type) {
    case SET_COMMON_DATA:
      return {
        ...state,
        [action.payload.name]: action.payload.value,
      };

    case GET_COMMON_SETTINGS_TYPE_SUCCESS:
      return {
        ...state,
        commonSettingsTypes: action.payload.settings
      };

    case GET_COMMON_SETTINGS_TYPE_ERROR:
      return {
        ...state,
        commonSettingsTypes: action.payload.settings
      };

    case SHOP_MODIFIER_SUCCESS:
      return {
        ...state,
        modifier: action.payload.modifier
      };

    default:
      return state;
  }
}