import React, { Component } from 'react';
import { Field, reduxForm, formValueSelector } from 'redux-form';
import { connect } from 'react-redux';
import withStyles from 'isomorphic-style-loader/withStyles';
import { injectIntl } from 'react-intl';
import cx from 'classnames';
import Form from 'react-bootstrap/Form';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Container from 'react-bootstrap/Container';
import FormControl from 'react-bootstrap/FormControl';
import FormGroup from 'react-bootstrap/FormGroup';

import Loader from '../../Common/Loader';
import ImageUploadComponent from '../../Common/ImageUploadComponent/ImageUploadComponent.js';

import messages from '../../../locale/messages';
import validate from './validate';
import submit from './submit';
import { api, homepageUploadDir } from '../../../config';

import s from './HomeSettingsForm.css';
export class HomeSettingsForm extends Component {

    renderField = ({ input, label, type, meta: { touched, error }, labelClass, fieldClass, placeholder, maxlength }) => {
        const { formatMessage } = this.props.intl
        return (
            <Form.Group className={cx('positionRelative', s.space5)}>
                <Form.Label>{label}</Form.Label>
                <Form.Control {...input} placeholder={placeholder} type={type} className={cx(fieldClass, s.formControlInput)} maxlength={maxlength} />
                {touched && error && <span className={cx(s.errorMessage, 'errorMessageRTL')}>{formatMessage(error)}</span>}
            </Form.Group>
        )
    }

    renderFormControlTextArea = ({ input, label, meta: { touched, error }, children, className }) => {
        const { formatMessage } = this.props.intl
        return (
            <FormGroup className={cx(s.space5, 'positionRelative')}>
                <label>{label}</label>
                <FormControl
                    {...input}
                    className={className}
                    placeholder={label}
                    as="textarea"
                    rows="4"
                >
                    {children}
                </FormControl>
                {touched && error && <span className={cx(s.errorMessage, 'errorMessageRTL')}>{formatMessage(error)}</span>}
            </FormGroup>
        );
    }

    render() {
        const { handleSubmit, loading, submitting, homeSectionImage1, homeSectionImage2, homeSectionImage3,
            homeSectionImage4, homeSectionImage5, homeSectionImage6, imageLoader, homeimageTwoLoader } = this.props;
        const { formatMessage } = this.props.intl;
        return (
            <div>
                <Container fluid>
                    <h1 className={cx(s.heading, 'textAlignRightRTL')}>{formatMessage(messages.homeSectionSettings)}</h1>
                    <Form className={s.fullWidth} onSubmit={handleSubmit(submit)}>
                        <div className={s.blackCenterSection}>
                            <Row>
                                <Col lg={6} md={12} sm={12} xs={12}>
                                    <Form.Group className={s.space5}>
                                        <div className={cx(s.profileImgSection, s.profileImgWidth)}>
                                            <ImageUploadComponent
                                                className={cx(s.btnSecondary, s.profileNoPadding)}
                                                subTextClass={s.subText}
                                                subText={formatMessage(messages.maximumUploadSizeLabel)}
                                                defaultMessage={formatMessage(messages.chooseFile)}
                                                loaderName={'homeImageLoader'}
                                                postUrl={api.apiEndpoint + '/uploadHomepageImage'}
                                                loader={imageLoader}
                                                fieldName={'homeSectionImage1'}
                                                formName={'HomeSettingsForm'}
                                                imageSrc={homeSectionImage1 ? api.apiEndpoint + homepageUploadDir + 'medium_' + homeSectionImage1 : null}
                                                label={formatMessage(messages.homeImage1)}
                                            />
                                        </div>
                                    </Form.Group>
                                </Col>
                                <Col lg={6} md={12} sm={12} xs={12}>
                                    <Form.Group className={s.space5}>
                                        <div className={cx(s.profileImgSection, s.profileImgWidth)}>
                                            <ImageUploadComponent
                                                className={cx(s.btnSecondary, s.profileNoPadding)}
                                                subTextClass={s.subText}
                                                subText={formatMessage(messages.maximumUploadSizeLabel)}
                                                defaultMessage={formatMessage(messages.chooseFile)}
                                                loaderName={'homeImageTwoLoader'}
                                                postUrl={api.apiEndpoint + '/uploadHomepageImage'}
                                                loader={homeimageTwoLoader}
                                                fieldName={'homeSectionImage2'}
                                                formName={'HomeSettingsForm'}
                                                imageSrc={homeSectionImage2 ? api.apiEndpoint + homepageUploadDir + 'medium_' + homeSectionImage2 : null}
                                                label={formatMessage(messages.homeImage2)}
                                            />
                                        </div>
                                    </Form.Group>
                                </Col>
                                <Col lg={12} md={12} sm={12} xs={12}>
                                    <Field name="homeSectionTitle1" type="text" component={this.renderField} label={formatMessage(messages.homeTitle)} maxlength={250} />
                                </Col>
                                <Col lg={12} md={12} sm={12} xs={12} className={cx(s.textAlignRight, s.spaceTop1, 'textAlignLeftRTL')}>
                                    <Form.Group className={s.noMargin}>
                                        <div className={s.displayInlineBlock}>
                                            <Loader
                                                type={"button"}
                                                label={formatMessage(messages.submitButton)}
                                                show={loading}
                                                buttonType={'submit'}
                                                className={cx(s.button, s.btnPrimary)}
                                                disabled={submitting || loading}
                                                isSuffix={true}
                                            />
                                        </div>
                                    </Form.Group>
                                </Col>
                            </Row>
                        </div>
                    </Form>
                </Container>
            </div >
        )
    }
}

HomeSettingsForm = reduxForm({
    form: 'HomeSettingsForm',
    onSubmit: submit,
    validate
})(HomeSettingsForm);

const selector = formValueSelector('HomeSettingsForm')
const mapState = (state) => ({
    homeSectionImage1: selector(state, 'homeSectionImage1'),
    homeSectionImage2: selector(state, 'homeSectionImage2'),
    homeSectionImage3: selector(state, 'homeSectionImage3'),
    homeSectionImage4: selector(state, 'homeSectionImage4'),
    homeSectionImage5: selector(state, 'homeSectionImage5'),
    homeSectionImage6: selector(state, 'homeSectionImage6'),
    loading: state.loader.HomeSettingsForm,
    imageLoader: state.loader.homeImageLoader,
    homeimageTwoLoader: state.loader.homeImageTwoLoader
})

const mapDispatch = {
}

export default injectIntl(withStyles(s)(connect(mapState, mapDispatch)(HomeSettingsForm)));