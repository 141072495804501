import React from 'react';
import PropTypes from 'prop-types';
import withStyles from 'isomorphic-style-loader/withStyles';
import { graphql } from 'react-apollo';
import { flowRight as compose } from 'lodash';
import { connect } from 'react-redux';

import PartnerOperationHours from '../../../components/Shop/PartnerOperationHours/PartnerOperationHours';
import Loader from '../../../components/Common/Loader';

import s from './OperationHours.css';
import getOperationHours from './getOperationHours.graphql';
class OperationHours extends React.Component {
    static propTypes = {
        title: PropTypes.string
    }

    static defaultProps = {
        operationHoursData: {
            loading: true,
            getOperationHours: null
        }
    };

    render() {
        const { title } =  this.props;
        const { operationHoursData, operationHoursData: { loading, getOperationHours } } = this.props;
        let initialValues;

        if (!loading && getOperationHours && getOperationHours.results) {
            initialValues = {
                OperationHours: getOperationHours && getOperationHours.results || []
            };
        }
       
        return (
            <div>
                {
                    (loading || (loading && !operationHoursData)) && <Loader type="text" />
                }
                {
                    !loading && operationHoursData && <PartnerOperationHours title={title} initialValues={initialValues}/>
                }
            </div>
        )
    }
}

const mapState = (state) => ({});

const mapDispatch = {};

export default compose(
    withStyles(s),
    connect(mapState,mapDispatch),
    graphql(getOperationHours, {
        name: 'operationHoursData',
        options: {
            fetchPolicy: 'network-only'
        }
    })
)(OperationHours);