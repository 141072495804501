import React, { Component } from 'react';
import { Field, reduxForm, formValueSelector } from 'redux-form';
import { connect } from 'react-redux';
import withStyles from 'isomorphic-style-loader/withStyles';
import { injectIntl } from 'react-intl';
import cx from 'classnames';
import Form from 'react-bootstrap/Form';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Container from 'react-bootstrap/Container';
import FormGroup from 'react-bootstrap/FormGroup';
import FormControl from 'react-bootstrap/FormControl';

import ColorPickerModal from '../DeliveryModule/ColorPickerModal';
import ImageUploadComponent from '../../Common/ImageUploadComponent/ImageUploadComponent';
import Loader from '../../Common/Loader/Loader';

import validate from './validate';
import submit from './submit';
import { openColorModal } from '../../../actions/siteadmin/modalActions';
import { api, homepageUploadDir } from '../../../config';
import messages from '../../../locale/messages';

import colorPickerIcon from '../../../../public/Icons/colorPickerIcon.svg';

import s from './DriverSettingsForm.css';
export class DriverSettingsForm extends Component {

	renderField = ({ input, label, type, meta: { touched, error }, labelClass, fieldClass, placeholder, disabled }) => {
		const { formatMessage } = this.props.intl
		return (
			<Form.Group className={cx(s.space5, 'positionRelative')}>
				<Form.Label>{label}</Form.Label>
				<Form.Control {...input} placeholder={placeholder} type={type} className={cx(fieldClass, s.formControlInput)} disabled={disabled} />
				{touched && error && <span className={cx(s.errorMessage, 'errorMessageRTL')}>{formatMessage(error)}</span>}
			</Form.Group>
		)
	}

	renderFormControlTextArea = ({ input, label, meta: { touched, error }, children, className }) => {
		const { formatMessage } = this.props.intl
		return (
			<FormGroup className={cx(s.space5, 'positionRelative')}>
				<label>{label}</label>
				<FormControl
					{...input}
					className={className}
					placeholder={label}
					as="textarea"
					rows="4"
				>
					{children}
				</FormControl>
				{touched && error && <span className={cx(s.errorMessage, 'errorMessageRTL')}>{formatMessage(error)}</span>}
			</FormGroup>
		);
	}

	render() {
		const { handleSubmit, safetyGridImage4, safetyGridImage5, safetyGridImage6, loading, submitting, driverAppBgColor, openColorModal, imageLoader, playStoreImage, appStoreImage } = this.props;
		const { formatMessage } = this.props.intl;
		return (
			<div>
				<Container fluid>
					<ColorPickerModal formName={'DriverSettingsForm'} />
					<h1 className={cx(s.heading, 'textAlignRightRTL')}>{formatMessage(messages.signupSectionSettings)}</h1>
					<Form className={s.fullWidth} onSubmit={handleSubmit(submit)}>
						<div className={s.blackCenterSection}>
							<Row>
								<Col lg={6} md={6} sm={6} xs={12}>
									<Field name="safetyGridTitle2" type="text" component={this.renderField} label={formatMessage(messages.driverAppTitle)} />
									<Field name="safetyGridLink3" type="text" component={this.renderField} label={formatMessage(messages.playStoreLink)} />
									<Field name="safetyGridLink4" type="text" component={this.renderField} label={formatMessage(messages.appStoreLink)} />
									<div className={s.positionRelative}>
										<Field
											name="driverAppBgColor"
											type="text"
											placeholder={formatMessage(messages.bgColor)}
											component={this.renderField}
											label={formatMessage(messages.bgColor)}
											labelClass={s.labelText}
											fieldClass={s.formControlInput}
										/>
										<div className={cx(s.pickerIcon, 'pickerIconRTL')} onClick={(e) => openColorModal('driverAppBgColor', driverAppBgColor)} ><img src={colorPickerIcon} /></div>
									</div>
								</Col>
								<Col lg={6} md={6} sm={6} xs={12}>
									<Form.Group className={s.space3}>
										<div className={cx(s.profileImgSection, s.profileImgWidth)}>
											<ImageUploadComponent
												className={cx(s.btnSecondary, s.profileNoPadding)}
												subTextClass={s.subText}
												subText={formatMessage(messages.maximumUploadSizeLabel)}
												defaultMessage={formatMessage(messages.chooseFile)}
												loaderName={'driverImageLoader'}
												postUrl={api.apiEndpoint + '/uploadHomepageImage'}
												loader={imageLoader}
												fieldName={'safetyGridImage6'}
												formName={'DriverSettingsForm'}
												imageSrc={safetyGridImage6 ? api.apiEndpoint + homepageUploadDir + safetyGridImage6 : null}
												label={formatMessage(messages.signupImage3)}
											/>
										</div>
									</Form.Group>
								</Col>
								<Col lg={6} md={12} sm={12} xs={12}>
									<Form.Group className={s.space3}>
										<div className={cx(s.profileImgSection, s.profileImgWidth)}>
											<ImageUploadComponent
												className={cx(s.btnSecondary, s.profileNoPadding)}
												subTextClass={s.subText}
												subText={formatMessage(messages.maximumUploadSizeLabel)}
												defaultMessage={formatMessage(messages.chooseFile)}
												loaderName={'playStoreImageLoader'}
												postUrl={api.apiEndpoint + '/uploadHomepageImage'}
												loader={playStoreImage}
												fieldName={'safetyGridImage4'}
												formName={'DriverSettingsForm'}
												imageSrc={safetyGridImage4 ? api.apiEndpoint + homepageUploadDir + safetyGridImage4 : null}
												label={formatMessage(messages.safetyImage1)}
											/>
										</div>
									</Form.Group>
								</Col>
								<Col lg={6} md={12} sm={12} xs={12}>
									<Form.Group className={s.space3}>
										<div className={cx(s.profileImgSection, s.profileImgWidth)}>
											<ImageUploadComponent
												className={cx(s.btnSecondary, s.profileNoPadding)}
												subTextClass={s.subText}
												subText={formatMessage(messages.maximumUploadSizeLabel)}
												defaultMessage={formatMessage(messages.chooseFile)}
												loaderName={'appStoreImageLoader'}
												postUrl={api.apiEndpoint + '/uploadHomepageImage'}
												loader={appStoreImage}
												fieldName={'safetyGridImage5'}
												formName={'DriverSettingsForm'}
												imageSrc={safetyGridImage5 ? api.apiEndpoint + homepageUploadDir + safetyGridImage5 : null}
												label={formatMessage(messages.safetyImage2)}
											/>
										</div>
									</Form.Group>
								</Col>
								<Col lg={12} md={12} sm={12} xs={12} className={cx(s.textAlignRight, s.spaceTop1, 'textAlignLeftRTL')}>
									<Form.Group className={s.noMargin}>
										<div className={s.displayInlineBlock}>
											<Loader
												type={"button"}
												label={formatMessage(messages.submitButton)}
												show={loading}
												buttonType={'submit'}
												className={cx(s.button, s.btnPrimary)}
												disabled={submitting || loading}
												isSuffix={true}
											/>
										</div>
									</Form.Group>
								</Col>
							</Row>
						</div>
					</Form>
				</Container>
			</div>
		)
	}
}

DriverSettingsForm = reduxForm({
	form: 'DriverSettingsForm',
	onSubmit: submit,
	validate
})(DriverSettingsForm);

const selector = formValueSelector('DriverSettingsForm')
const mapState = (state) => ({
	safetyGridImage4: selector(state, 'safetyGridImage4'),
	safetyGridImage5: selector(state, 'safetyGridImage5'),
	safetyGridImage6: selector(state, 'safetyGridImage6'),
	driverAppBgColor: selector(state, 'driverAppBgColor'),
	loading: state.loader.DriverSettingsForm,
	imageLoader: state.loader.driverImageLoader,
	playStoreImage: state.loader.playStoreImageLoader,
	appStoreImage: state.loader.appStoreImageLoader
})

const mapDispatch = {
	openColorModal
}

export default injectIntl(withStyles(s)(connect(mapState, mapDispatch)(DriverSettingsForm)));