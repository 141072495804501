import {
  SET_USER_DATA_START,
  SET_USER_DATA_SUCCESS,
  USER_LOGOUT_SUCCESS,
  GET_ADMIN_USER_START,
  GET_ADMIN_USER_SUCCESS
} from '../constants';

export default function account(state = {}, action) {
  switch (action.type) {
    case SET_USER_DATA_START:
      return {
        ...state,
      };
    case SET_USER_DATA_SUCCESS:
      return {
        ...state,
        data: action.updatedProfileData,
      };
    case USER_LOGOUT_SUCCESS:
      return {
        ...state,
        data: null
      };
    case GET_ADMIN_USER_START:
      return {
        ...state,
      };
    case GET_ADMIN_USER_SUCCESS:
      return {
        ...state,
        data: action.payload.userData,
        privileges: action.payload.adminPrivileges
      };  
    default:
      return state;
  }
}
