import React, { Component } from 'react';
import { Field, reduxForm, formValueSelector } from 'redux-form';
import { connect } from 'react-redux';
import withStyles from 'isomorphic-style-loader/withStyles';
import { injectIntl } from 'react-intl';
import cx from 'classnames';
import Form from 'react-bootstrap/Form';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Container from 'react-bootstrap/Container';

import Link from '../../Link/Link';
import CountryList from '../../CountryList/CountryList';
import Loader from '../../Common/Loader/Loader';
import ImageUploadComponent from '../../Common/ImageUploadComponent/ImageUploadComponent';

import { api, profilePhotouploadDir } from '../../../config';
import messages from '../../../locale/messages'
import submit from './submit';
import validate from './validate';

import uploadProfileImage from '../../../actions/siteadmin/uploadProfileImage';

import s from './EditUserForm.css'
export class EditUserForm extends Component {
	constructor(props) {
		super(props)
		this.state = {
			countryCode: 'US',
			country: '+1',
		};
	}

	static defaultProps = {
		loading: false
	};

	renderField = ({ input, label, type, meta: { touched, error }, labelClass, fieldClass, placeholder, disabled }) => {
		const { formatMessage } = this.props.intl
		return (
			<Form.Group className='positionRelative'>
				<Form.Label>{label}</Form.Label>
				<Form.Control {...input} placeholder={placeholder} type={type} className={fieldClass} disabled={disabled} />
				{touched && error && <span className={cx(s.errorMessage, 'errorMessageRTL')}>{formatMessage(error)}</span>}
			</Form.Group>
		)
	}

	renderFieldPhoneNumber = ({ input, label, writeOnce, type, meta: { touched, error }, fieldClass, placeholder, disabled }) => {
		const { formatMessage } = this.props.intl
		return (
			<Form.Group className='positionRelative'>
				<Form.Control {...input} readOnly={writeOnce} placeholder={placeholder} type={type} className={fieldClass} disabled={disabled} />
				{touched && error && <span className={cx(s.errorMessage, 'errorMessageRTL')}>{formatMessage(error)}</span>}
			</Form.Group>
		)
	}

	handleChange = (event) => {
		this.setState({ [event.target.name]: event.target.value });
	}

	handleCountryChange = (e, selectedData) => {
		this.setState({
			country: selectedData.dialCode,
			countryCode: selectedData.countryCode
		});
	}

	componentDidMount() {
		const { initialValues } = this.props;
		if (initialValues && initialValues.country && initialValues.phoneCountryCode) {
			this.setState({
				countryCode: initialValues.phoneCountryCode,
				country: initialValues.country
			});
		}
	}

	handleDropzone = async (fileName) => {
		const { uploadProfileImage, id, picture, change } = this.props;
		uploadProfileImage(id, fileName, picture);
		await change("picture", fileName);
	}

	render() {
		const { handleSubmit, id, picture, loading, submitting, imageLoader } = this.props
		const { formatMessage } = this.props.intl;
		const { countryCode } = this.state;

		return (
			<div className={cx(s.space5, s.spaceTop5)}>
				<Container fluid>
					<Row>
						<Col xl={10} lg={12} md={12} sm={12} xs={12} className={s.blackCenter}>
							<div className={s.blackCenterSection}>
								<Form className={s.fullWidth} onSubmit={handleSubmit(submit)}>
									<Col lg={12} md={12} sm={12} xs={12}>
										<h1 className={cx(s.titleTextAdmin, 'textAlignRightRTL')}>{formatMessage(messages.editUser)}</h1>
									</Col>
									<Col lg={12} md={12} sm={12} xs={12}>
										<Row>
											<Col lg={12} md={12} sm={12} xs={12} className={s.space3}>
												<Form.Group className={s.space3} >
													<div className={s.profileImgSection}>
														<ImageUploadComponent
															className={cx(s.btnSecondary, s.profileNoPadding)}
															subTextClass={s.subText}
															subText={formatMessage(messages.maximumUploadSizeLabel)}
															defaultMessage={formatMessage(messages.chooseFile)}
															loaderName={'profileImageLoader'}
															postUrl={api.apiEndpoint + '/uploadProfileImage'}
															loader={imageLoader}
															fieldName={'picture'}
															formName={'EditUserForm'}
															imageSrc={picture ? api.apiEndpoint + profilePhotouploadDir + picture : null}
															label={formatMessage(messages.profilePicture)}
															handleDropzone={this.handleDropzone}
														/>
													</div>
												</Form.Group>
											</Col>
											<Col lg={6} md={12} sm={12} xs={12}>
												<Form.Group className={s.space3}>
													<div>
														<Field
															name="firstName"
															type="text"
															placeholder={formatMessage(messages.firstName)}
															component={this.renderField}
															label={formatMessage(messages.firstName)}
															labelClass={s.labelText}
															fieldClass={s.formControlInput}
														/>
													</div>
												</Form.Group>
											</Col>
											<Col lg={6} md={12} sm={12} xs={12}>
												<Form.Group className={s.space3}>
													<div>
														<Field
															name="lastName"
															type="text"
															placeholder={formatMessage(messages.lastName)}
															component={this.renderField}
															label={formatMessage(messages.lastName)}
															labelClass={s.labelText}
															fieldClass={s.formControlInput}
														/>
													</div>
												</Form.Group>
											</Col>
										</Row>
										<Row>
											<Col lg={6} md={12} sm={12} xs={12}>
												<Form.Group className={s.space3}>
													<div>
														<Field
															name="email"
															type="text"
															component={this.renderField}
															placeholder={formatMessage(messages.email)}
															label={formatMessage(messages.email)}
															labelClass={s.labelText}
															fieldClass={s.formControlInput}
															disabled
														/>
													</div>
												</Form.Group>
											</Col>
											<Col lg={6} md={12} sm={12} xs={12}>
												<Form.Group className={s.space3}>
													<div>
														<Field
															name="password"
															type="text"
															placeholder={formatMessage(messages.password)}
															component={this.renderField}
															label={formatMessage(messages.password)}
															labelClass={s.labelText}
															fieldClass={s.formControlInput}
														/>
													</div>
												</Form.Group>
											</Col>
										</Row>
										<Row>
											<Col lg={12} md={12} sm={12} xs={12}>
												<Col lg={12} md={12} sm={12} xs={12} className={s.noPadding}>
													<label>{formatMessage(messages.phoneNumber)}</label>
												</Col>
												<Row>
													<Col lg={6} md={12} sm={12} xs={12}>
														<CountryList
															input={
																{
																	name: 'phoneDialCode',
																	onChange: this.handleChange,
																	value: countryCode,
																}
															}
															className={cx(s.formControlSelect, s.formControlInput, s.space1)}
															dialCode={false}
															getSelected={this.handleCountryChange}
															formName={'EditUserForm'}
															disabled
														/>
													</Col>
													<Col lg={6} md={12} sm={12} xs={12} className='noRadiusLeft'>
														<div className={cx(s.displayInlineBlock, s.countryCode)}>
															<Form.Group className={s.space3}>
																<div>
																	<Field
																		name="phoneDialCode"
																		type="text"
																		placeholder={formatMessage(messages.phoneDialCode)}
																		component={this.renderFieldPhoneNumber}
																		labelClass={s.labelText}
																		fieldClass={cx(s.formControlInput, s.formControlInputCountryCode)}
																		writeOnce={true}
																		disabled
																	/>
																</div>
															</Form.Group>
														</div>
														<div className={cx(s.displayInlineBlock, s.countryPhoneNumber)}>
															<Form.Group className={s.space3}>
																<div className='positionRelative'>
																	<Field
																		name="phoneNumber"
																		type="text"
																		placeholder={formatMessage(messages.phoneNumber)}
																		component={this.renderFieldPhoneNumber}
																		labelClass={s.labelText}
																		fieldClass={cx(s.formControlInput, s.formControlInputCountry, 'formControlInputCountryRTL')}
																		disabled
																	/>
																</div>
															</Form.Group>
														</div>
													</Col>
												</Row>
											</Col>
											<Col lg={6} md={6} sm={6} xs={12}>
												<Form.Group className={s.space3} controlId="exampleForm.ControlSelect1">
													<div>
														<label>{formatMessage(messages.banStatus)}</label>
														<Field name="isBan" className={cx(s.formControlSelect, s.formControlInput)} component="select">
															<option value="0">{formatMessage(messages.permit)}</option>
															<option value="1">{formatMessage(messages.ban)}</option>
														</Field>
													</div>
												</Form.Group>
											</Col>
										</Row>
										<Row>
											<Col lg={12} md={12} sm={12} xs={12} className={cx(s.textAlignRight, s.spaceTop1, 'textAlignLeftRTL')}>
												<Form.Group className={s.noMargin}>
													<Link to={"/siteadmin/users"} className={cx(s.backBtnTwo, s.btnSecondaryBorder, s.linkBtnInline, 'backBtnTwoRTL', s.backBtnTwoTop)} >{formatMessage(messages.goBack)}</Link>
													<div className={s.displayInlineBlock}>
														<Loader
															type={"button"}
															label={formatMessage(messages.update)}
															show={loading}
															buttonType={'submit'}
															className={cx(s.button, s.btnPrimary)}
															disabled={submitting || loading}
															isSuffix={true}
														/>
													</div>
												</Form.Group>
											</Col>
										</Row>
									</Col>
								</Form>
							</div>
						</Col>
					</Row>
				</Container>
			</div>
		)
	}
}

EditUserForm = reduxForm({
	form: 'EditUserForm', // a unique name for this form
	validate,
	onSubmit: submit
})(EditUserForm)

const selector = formValueSelector('EditUserForm')
const mapState = state => ({
	id: selector(state, 'id'),
	picture: selector(state, 'picture'),
	loading: state.loader.EditUser,
	imageLoader: state.loader.profileImageLoader
})

const mapDispatch = {
	uploadProfileImage
}

export default injectIntl(withStyles(s)(connect(mapState, mapDispatch)(EditUserForm)));