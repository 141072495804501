import {
    SHOP_USER_DELETE_SUCCESS,
    SHOP_USER_DELETE_START,
    SHOP_USER_DELETE_ERROR
} from '../../../constants/index';
import socketNotification from '../../../core/socket/socketNotification';
import showToaster from '../../../helpers/toasterMessages/showToaster';
import { deleteShopMutation as mutation } from '../../../lib/graphql';

const deleteShop = (id) => {
    return async (dispatch, getState, { client }) => {

        try {
            dispatch({
                type: SHOP_USER_DELETE_START,
                payload: {
                    deleteLoading: true
                }
            });

            const { data } = await client.mutate({
                mutation,
                variables: {
                    id
                }
            });

            if (data?.deleteShop?.status === "200") {
                dispatch({
                    type: SHOP_USER_DELETE_SUCCESS,
                    payload: {
                        deleteLoading: false
                    }
                });
                let requestData = { id };
                showToaster({ messageId: 'deleteShop', toasterType: 'success' })
                socketNotification('webUserLogout', JSON.stringify(requestData));
                return {
                    status: 200
                };
            } else {
                dispatch({
                    type: SHOP_USER_DELETE_ERROR,
                    payload: {
                        deleteLoading: false
                    }
                });
                showToaster({ messageId: 'commonToasterError', toasterType: 'error', requestMessage: data?.deleteShop?.errorMessage })
                return {
                    status: 400
                }
            }
        } catch (err) {
            showToaster({ messageId: 'commonToasterError', toasterType: 'error', requestMessage: err })
            dispatch({
                type: SHOP_USER_DELETE_ERROR,
                payload: {
                    deleteLoading: false
                }
            });
            return {
                status: 400
            }
        }
    }
};
export default deleteShop