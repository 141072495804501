import { initialize, reset } from 'redux-form';
import {
    COMMON_SETTING_MODAL_OPEN,
    ITEM_MODAL_OPEN,
    ITEM_MODAL_CLOSE,
    SUB_MENU_MODAL_OPEN,
    SUB_MENU_MODAL_CLOSE,
    MODAL_OPEN,
    MODAL_CLOSE,
    TRANSACTION_MODAL_OPEN,
    TRANSACTION_MODAL_CLOSE,
    DECLINE_REASON_MODAL_CLOSE,
    DECLINE_REASON_MODAL_OPEN,
    OPEN_HEADER_MODAL,
    CLOSE_HEADER_MODAL,
    OPEN_ADMIN_ROLES_MODAL,
    CLOSE_ADMIN_ROLES_MODAL,
    OPEN_ADMIN_USER_MODAL,
    CLOSE_ADMIN_USER_MODAL,
    OPEN_COLOR_MODAL,
    CLOSE_COLOR_MODAL
} from '../../constants';

const openCommonSettingsModal = (formName, values) => {
    return (dispatch, getState) => {
        dispatch(initialize(formName, values));

        dispatch({
            type: COMMON_SETTING_MODAL_OPEN,
            isCommonSettingModalOpen: true
        })
    }
}

const closeCommonSettingsModal = () => {
    return (dispatch, getState) => {
        dispatch(reset('addCommonSetting'));
        dispatch(reset('editCommonSetting'));
        dispatch({
            type: COMMON_SETTING_MODAL_OPEN,
            isCommonSettingModalOpen: false
        })
    }
}

const openItemModal = (menuId, subMenuId) => {
    return (dispatch, getState) => {
        dispatch({
            type: ITEM_MODAL_OPEN,
            isItemModalOpen: true,
            menuId: menuId,
            subMenuId: subMenuId
        })
    }
}

const closeItemModal = () => {
    return (dispatch, getState) => {
        dispatch({
            type: ITEM_MODAL_CLOSE,
            isItemModalOpen: false
        })
    }
}

const openSubMenuModal = (menuId) => {
    return (dispatch, getState) => {
        dispatch({
            type: SUB_MENU_MODAL_OPEN,
            isSubMenuModalOpen: true,
            menuId: menuId
        })
    }
}

const closeSubMenuModal = () => {
    return (dispatch, getState) => {
        dispatch({
            type: SUB_MENU_MODAL_CLOSE,
            isSubMenuModalOpen: false
        })
    }
}

const openModal = (name, data) => {
    return (dispatch, getState) => {
        dispatch({
            type: MODAL_OPEN,
            payload: {
                name,
                status: true,
                data
            }
        })
    }
}

const closeModal = (name) => {
    return (dispatch, getState) => {
        dispatch({
            type: MODAL_CLOSE,
            payload: {
                name,
                status: false
            }
        })
    }
}

const openTransactionModal = () => {
    return (dispatch, getState) => {
        dispatch({
            type: TRANSACTION_MODAL_OPEN,
            isTransactionModalOpen: true,
        })
    }
}

const closeTransactionModal = () => {
    return (dispatch, getState) => {
        dispatch({
            type: TRANSACTION_MODAL_CLOSE,
            isTransactionModalOpen: false
        })
    }
}

const openDeclineReasonModal = (id, page) => {
    return (dispatch, getState) => {
        dispatch({
            type: DECLINE_REASON_MODAL_OPEN,
            isDeclineModalOpen: true,
            bookingId: id,
            currentPage: page
        })
    }
}

const closeDeclineReasonModal = () => {
    return (dispatch, getState) => {
        dispatch({
            type: DECLINE_REASON_MODAL_CLOSE,
            isDeclineModalOpen: false
        })
    }
}


const openHeaderModal = (modalType) => {
    return (dispatch, getState) => {
        dispatch({
            type: OPEN_HEADER_MODAL,
            payload: {
                modalType,
                actionValue: true
            }
        });
    };
}

const closeHeaderModal = (modalType) => {
    return (dispatch, getState) => {
        dispatch({
            type: CLOSE_HEADER_MODAL,
            payload: {
                modalType,
                actionValue: false
            }
        });
    };
}

const editItemModal = (name, index1, index2) => {

    return (dispatch, getState) => {
        let values = {};
        if (getState().form.AddItemForm && getState().form.AddItemForm.values) {
            values = getState().form.AddItemForm.values.modifiers[index1].modifierGroups[index2];
        }
        let variables = Object.assign({}, values, {
            editId: index1
        });
        dispatch(initialize(name, variables));
        dispatch({
            type: ITEM_MODAL_OPEN,
            isItemModalOpen: true,
        })
    }
}

const openAdminRolesModal = (type, formData) => {
    return (dispatch, getState) => {
        if (type === 'edit') {
            dispatch(initialize("AdminRolesForm", formData, true));
        }

        dispatch({
            type: OPEN_ADMIN_ROLES_MODAL,
            payload: {
                adminRolesModal: true,
                adminRolesModalType: type
            }
        });
    }
}

const closeAdminRolesModal = () => {
    return (dispatch, getState) => {
        dispatch({
            type: CLOSE_ADMIN_ROLES_MODAL,
            payload: {
                adminRolesModal: false
            }
        });
    }
}

const openAdminUserModal = (type, formData) => {
    return (dispatch, getState) => {
        if (type === 'edit') {
            dispatch(initialize("AdminUserForm", formData, true));
        }

        dispatch({
            type: OPEN_ADMIN_USER_MODAL,
            payload: {
                adminUserModal: true,
                adminUserModalType: type
            }
        });
    }
}

const closeAdminUserModal = () => {
    return (dispatch, getState) => {
        dispatch({
            type: CLOSE_ADMIN_USER_MODAL,
            payload: {
                adminUserModal: false
            }
        });
    }
}

const openColorModal = (fieldName, color) => {
    return (dispatch, getState) => {
        dispatch({
            type: OPEN_COLOR_MODAL,
            payload: {
                isOpenColor: true,
                fieldName,
                color
            }
        });
    };
}

const closeColorModal = () => {
    return (dispatch, getState) => {
        dispatch({
            type: CLOSE_COLOR_MODAL,
            payload: {
                isOpenColor: false,
            }
        });
    };
}

export {
    openCommonSettingsModal,
    closeCommonSettingsModal,
    openItemModal,
    closeItemModal,
    openSubMenuModal,
    closeSubMenuModal,
    openModal,
    closeModal,
    openTransactionModal,
    closeTransactionModal,
    openDeclineReasonModal,
    closeDeclineReasonModal,
    openHeaderModal,
    closeHeaderModal,
    editItemModal,
    openAdminRolesModal,
    closeAdminRolesModal,
    openAdminUserModal,
    closeAdminUserModal,
    openColorModal,
    closeColorModal
}