import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import { graphql } from 'react-apollo';

import {
    Form
} from 'react-bootstrap';

// Locale
import messages from '../../locale/messages';
import { connect } from 'react-redux';
import { flowRight as compose } from 'lodash';
import { change } from 'redux-form';
import getCountries from './getCountries.graphql';

class CountryListDropdown extends Component {

    static defaultProps = {
        data: {
            getCountries: []
        },
        isEmptyFirst: false,
        dialCode: false
    }

    constructor(props) {
        super(props);
        this.handleChange = this.handleChange.bind(this);
    }

    async handleChange(e, input) {
        return e;
    }

    render() {
        const { data: { loading, getCountries }, className, input, isEmptyFirst, dialCode, onChange } = this.props;
        const { formatMessage } = this.props.intl;
        
        return (
            <div>
                <Form.Control as="select"
                    className={className}
                    {...input}
                    onChange={(e) => {
                        input.onChange(e);
                        this.handleChange(e, input);
                    }}
                >
                    {
                        loading && <option>{formatMessage(messages.country)}</option>
                    }

                    {
                        !loading && isEmptyFirst && <option value="">{formatMessage(messages.chooseCountry)}</option>
                    }

                    {
                        !loading && getCountries != null && getCountries.length > 0 && !dialCode && getCountries.map((item) => {
                            return (
                                <option value={item.countryCode} key={item.id}>{item.countryName}</option>
                            )
                        })
                    }
                    {
                        !loading && getCountries != null && getCountries.length > 0 && dialCode && getCountries.map((item) => {
                            return (
                                <option value={item.dialCode} key={item.id}>{item.countryName}</option>
                            )
                        })
                    }
                </Form.Control>
            </div>
        );
    }
}

const mapState = (state) => ({});

const mapDispatch = {
    change
};

export default compose(
    injectIntl,
    connect(mapState, mapDispatch),
    graphql(getCountries, { options: { ssr: true } })
)(CountryListDropdown);
