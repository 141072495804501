import history from '../../history';
import { setLoaderStart, setLoaderComplete } from '../../actions/loader/loader';
import showToaster from '../../helpers/toasterMessages/showToaster';
import { updateDriverMutation as mutation } from '../../lib/graphql';
import { getAllDriversQuery as query } from '../../lib/graphql';

export const editDriver = (id, firstName, lastName, email, password, phoneDialCode, phoneNumber, vehicleName, vehicleNumber, vehicleType, userStatus, isBan, phoneCountryCode) => {
  return async (dispatch, getState, { client }) => {

    try {
      dispatch(setLoaderStart('EditDriver'));

      const { data } = await client.mutate({
        mutation,
        variables: {
          id,
          firstName,
          lastName,
          email,
          password,
          phoneDialCode,
          phoneNumber,
          vehicleName,
          vehicleNumber,
          vehicleType: Number(vehicleType),
          userStatus,
          isBan,
          phoneCountryCode
        },
        refetchQueries: [{
          query,
          variables: {
            currentPage: 1
          }
        }]
      });

      dispatch(setLoaderComplete('EditDriver'));

      if (data?.updateDriver?.status === 200) {
        history.push('/siteadmin/drivers')
        showToaster({ messageId: 'updateDriver', toasterType: 'success' })
      } else {
        showToaster({ messageId: 'commonToasterError', toasterType: 'error', requestMessage: data?.updateDriver?.errorMessage })
      }
    } catch (error) {
      showToaster({ messageId: 'commonToasterError', toasterType: 'error', requestMessage: error })
    }
  }
}