import React from 'react';
import PropTypes from 'prop-types';
import withStyles from 'isomorphic-style-loader/withStyles';
import s from './modifierGroups.css';
import { graphql } from 'react-apollo';
import { flowRight as compose } from 'lodash';
import getAllModifierQuery from './getAllModifier.graphql';
import Loader from '../../../components/Common/Loader/Loader';
import ModifierGroups from '../../../components/Shop/ModifierGroups/ModifierGroupsList/ModifierGroups';

class modifierGroups extends React.Component {
  static propTypes = {
    title: PropTypes.string.isRequired,
    modifierDetails: {
      loading: true
    }
  };

  render() {
    const { title, modifierDetails, modifierDetails: { loading } } = this.props;

    return (
      <Loader type={"page"} show={loading}>
        <div>
          <div>
            <div>
              {!loading && <ModifierGroups modifierDetails={modifierDetails} />}
            </div>
          </div>
        </div>
      </Loader>
    );
  }
}

export default compose(
  withStyles(s),
  graphql(getAllModifierQuery, {
    name: 'modifierDetails',
    options: {
      ssr: true,
      fetchPolicy: 'network-only',
      variables: {
        currentPage: 1,
        searchList: ''
      }
    }
  })
)(modifierGroups);