import React, { Component } from 'react';
import { Field, reduxForm, formValueSelector } from 'redux-form';
import { connect } from 'react-redux';
import withStyles from 'isomorphic-style-loader/withStyles';
import { injectIntl } from 'react-intl';
import cx from 'classnames';
import Form from 'react-bootstrap/Form';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Container from 'react-bootstrap/Container';
import FormControl from 'react-bootstrap/FormControl';
import FormGroup from 'react-bootstrap/FormGroup';

import ImageUploadComponent from '../../Common/ImageUploadComponent/ImageUploadComponent.js';
import Loader from '../../Common/Loader';

import validate from './validate';
import submit from './submit';
import { api, homepageUploadDir } from '../../../config';
import messages from '../../../locale/messages';

import s from './FooterSettingsForm.css';
export class FooterSettingsForm extends Component {

    renderField = ({ input, label, type, meta: { touched, error }, labelClass, fieldClass, placeholder, maxlength }) => {
        const { formatMessage } = this.props.intl
        return (
            <Form.Group className={cx(s.space5, 'positionRelative')}>
                <Form.Label>{label}</Form.Label>
                <Form.Control {...input} placeholder={placeholder} type={type} className={cx(fieldClass, s.formControlInput)} maxlength={maxlength}
                />
                {touched && error && <span className={cx(s.errorMessage, 'errorMessageRTL')}>{formatMessage(error)}</span>}
            </Form.Group>
        )
    }

    renderFormControlTextArea = ({ input, label, meta: { touched, error }, children, className }) => {
        const { formatMessage } = this.props.intl
        return (
            <FormGroup className={cx(s.space5, 'positionRelative')}>
                <label>{label}</label>
                <FormControl
                    {...input}
                    className={className}
                    placeholder={label}
                    as="textarea"
                    rows="4"
                >
                    {children}
                </FormControl>
                {touched && error && <span className={cx(s.errorMessage, 'errorMessageRTL')}>{formatMessage(error)}</span>}
            </FormGroup>
        );
    }

    render() {
        const { handleSubmit, footerLogo1, loading, submitting, imageLoader } = this.props;
        const { formatMessage } = this.props.intl;
        return (
            <Container fluid>
                <h1 className={cx(s.heading, 'textAlignRightRTL')}>{formatMessage(messages.footerSectionSettings)}</h1>
                <div className={s.blackCenterSection}>
                    <Form className={s.fullWidth} onSubmit={handleSubmit(submit)}>
                        <Row>
                            <Col lg={6} md={12} sm={12} xs={12}>
                                <Form.Group className={s.space3}>
                                    <div className={cx(s.profileImgSection, s.profileImgWidth)}>
                                        <ImageUploadComponent
                                            className={cx(s.btnSecondary, s.profileNoPadding)}
                                            subTextClass={s.subText}
                                            subText={formatMessage(messages.maximumUploadSizeLabel)}
                                            defaultMessage={formatMessage(messages.chooseFile)}
                                            loaderName={'footerImageLoader'}
                                            postUrl={api.apiEndpoint + '/uploadHomepageImage'}
                                            loader={imageLoader}
                                            fieldName={'footerLogo1'}
                                            formName={'FooterSettingsForm'}
                                            imageSrc={footerLogo1 ? api.apiEndpoint + homepageUploadDir + 'medium_' + footerLogo1 : null}
                                            label={formatMessage(messages.image)}
                                        />
                                    </div>
                                </Form.Group>
                            </Col>
                            <Col lg={6} md={12} sm={12} xs={12}>
                                <Field name="footerTitle1" type="text" component={this.renderField} label={formatMessage(messages.footerTitleLabel)} maxlength={250} />
                                <Field name="footerContent1" type="text" component={this.renderFormControlTextArea} label={formatMessage(messages.footerContentLabel)} />
                                <Field name="footerLinkTitle" type="text" component={this.renderField} label={formatMessage(messages.footerURLTitleLabel)} maxlength={250} />
                                <Field name="footerBottom" type="text" component={this.renderField} label={formatMessage(messages.copyRightsContentLabel)} />
                            </Col>
                            <Col lg={12} md={12} sm={12} xs={12} className={cx(s.textAlignRight, 'textAlignLeftRTL')}>
                                <Form.Group className={s.noMargin}>
                                    <div className={s.displayInlineBlock}>
                                        <Loader
                                            type={"button"}
                                            label={formatMessage(messages.submitButton)}
                                            show={loading}
                                            buttonType={'submit'}
                                            className={cx(s.button, s.btnPrimary)}
                                            disabled={submitting || loading}
                                            isSuffix={true}
                                        />
                                    </div>
                                </Form.Group>
                            </Col>
                        </Row>
                    </Form>
                </div>
            </Container>
        )
    }
}

FooterSettingsForm = reduxForm({
    form: 'FooterSettingsForm',
    onSubmit: submit,
    validate
})(FooterSettingsForm);

const selector = formValueSelector('FooterSettingsForm')
const mapState = (state) => ({
    footerLogo1: selector(state, 'footerLogo1'),
    loading: state.loader.SignupSettingsForm,
    imageLoader: state.loader.footerImageLoader
})

const mapDispatch = {
}

export default injectIntl(withStyles(s)(connect(mapState, mapDispatch)(FooterSettingsForm)));