import gql from "graphql-tag";

export const updateCategoryMutation = gql`
mutation updateCategory($id: Int, $categoryName: String, $description: String, $isActive: Boolean, $deliveryType: Int, $categoryImage: String) {
    updateCategory(id: $id, categoryName: $categoryName, description: $description, isActive: $isActive, deliveryType: $deliveryType, categoryImage: $categoryImage) {
      status
      errorMessage
    }
  }
  `
export const deleteCategoryMutation = gql`
  mutation deleteCategory($id: Int) {
      deleteCategory(id: $id) {
        status
        errorMessage
      }
    }
  `;


export const updateCategoryPrivilege = gql`
  mutation($id: Int!, $isActive: Boolean, $privilegeType: String!) {
      updateCategoryPrivilege(id: $id, isActive: $isActive, privilegeType: $privilegeType) {
          status
          errorMessage
      }
  }
  `
export const updateCategoryStatus = gql`
mutation updateCategoryStatus($id: Int, $isActive: Boolean) {
    updateCategoryStatus(id: $id, isActive: $isActive) {
      status
      errorMessage
    }
}
`;