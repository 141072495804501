import {
    SHOP_DOCUMENT_UPDATE_ERROR,
    SHOP_DOCUMENT_UPDATE_START,
    SHOP_DOCUMENT_UPDATE_SUCCESS
} from '../../../constants';
import { setLoaderStart, setLoaderComplete } from '../../loader/loader';
import showToaster from '../../../helpers/toasterMessages/showToaster';
import { uploadDocumentMutation as mutation } from '../../../lib/graphql';

const uploadDocument = (documentList) => {
    return async (dispatch, getState, { client }) => {

        try {

            dispatch({
                type: SHOP_DOCUMENT_UPDATE_START
            });

            dispatch(setLoaderStart('AdminDocumentUpdate'))

            const { data } = await client.mutate({
                mutation,
                variables: {
                    documentList
                }
            });

            if (data?.uploadDocument?.status == 200) {
                showToaster({ messageId: 'uploadShopDocument', toasterType: 'success' })

                await dispatch({
                    type: SHOP_DOCUMENT_UPDATE_SUCCESS
                });
                await dispatch(setLoaderComplete('AdminDocumentUpdate'));

            } else {
                showToaster({ messageId: 'commonToasterError', toasterType: 'error', requestMessage: data?.uploadDocument?.errorMessage })

                await dispatch({
                    type: SHOP_DOCUMENT_UPDATE_ERROR
                });
                dispatch(setLoaderComplete('AdminDocumentUpdate'));
            }
        } catch (error) {
            showToaster({ messageId: 'commonToasterError', toasterType: 'error', requestMessage: error })

            await dispatch({
                type: SHOP_DOCUMENT_UPDATE_ERROR
            });
            dispatch(setLoaderComplete('AdminDocumentUpdate'));
        }
    }
};

export default uploadDocument