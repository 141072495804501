import React, { Component } from 'react';
import PayoutBillingDetails from './PayoutBillingDetails';
import PayoutConfirm from './PayoutConfirm';
import PayoutMethods from './PayoutMethods';

class PayoutForm extends Component {

  constructor(props) {
    super(props);
    this.state = {
      page: 1
    };
  }

  nextPage = () => {
    this.setState({ page: this.state.page + 1 })
  }

  previousPage = () =>  {
    this.setState({ page: this.state.page - 1 })
  }

  render() {
    const { initialValues } = this.props;
    const { page } = this.state;

    return (
      <div>
        {
          page === 1 && <PayoutBillingDetails
            onSubmit={this.nextPage}
            initialValues={initialValues}
          />
        }
        {
          page === 2 && <PayoutMethods
            previousPage={this.previousPage}
            onSubmit={this.nextPage}
            initialValues={initialValues}
          />
        }
        {
          page === 3 && <PayoutConfirm
            previousPage={this.previousPage}
            onSubmit={this.nextPage}
            initialValues={initialValues}
          />
        }
      </div>
    );
  }
};

export default PayoutForm;