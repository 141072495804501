import React, { Component } from 'react';

import withStyles from 'isomorphic-style-loader/withStyles';
import { injectIntl } from 'react-intl';
import cx from 'classnames';

import { api, profilePhotouploadDir } from '../../../config';

import batchIcon from '../../../../public/Icons/delivery.svg';

import s from './ViewMessageDetails.css';
import bt from '../../../components/commonStyle.css';
class MessageItem extends Component {
  render() {
    const { avatarImage, text, userImage, chatPadding } = this.props;
    return (
      <>
        <div className={cx({ [s.displayFlexSender]: chatPadding == 'left' }, { [s.displayFlexReverse]: chatPadding == 'right' })}>
          <div className={cx({ [s.senderBgScetion]: chatPadding == 'left' }, { [s.receiverBgScetion]: chatPadding == 'right' }, { ['receiverBgScetionRTL']: chatPadding == 'right' }, { ['senderBgScetion']: chatPadding == 'left' })}>
            <span className={s.positionRelative}>
              {
                userImage ? <div>
                  <img src={`${api.apiEndpoint + profilePhotouploadDir}small_${userImage}`} className={s.bgImage} />
                  {chatPadding == 'right' && <span className={cx(s.batchIconContainer, "batchIconUserContainerRtl")}><img src={batchIcon} className={cx(s.batchIcon)} /></span>}
                </div> : <> <img src={avatarImage} className={s.avatarImage} />
                  {chatPadding == 'right' && <span className={cx(s.batchIconContainer, "batchIconUserContainerRtl")}><img src={batchIcon} className={s.batchIcon} /></span>}  </>}
            </span>
          </div>
          <div className={cx({ [s.senderRadius]: chatPadding == 'left' }, { [s.receiverRadius]: chatPadding == 'right' }, { ['receiverRadius']: chatPadding == 'right' }, { ['senderRadius']: chatPadding == 'left' })}>
            {text}</div>
        </div>
      </>
    )
  }
}

export default injectIntl(withStyles(s, bt)((MessageItem)));