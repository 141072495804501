import { addUpdateDeliveryVehicle } from '../../../../actions/siteadmin/DeliveryVehicle/addUpdateDeliveryVehicle';
import showToaster from '../../../../helpers/toasterMessages/showToaster';

async function submit(values, dispatch) {
    if (!values.vehicleImage) {
        showToaster({ messageId: 'vehicleTypeImageRequired', toasterType: 'error' })
        return;
    }

    await dispatch(
        addUpdateDeliveryVehicle(
            values?.id,
            values?.vehicleName,
            values?.vehicleImage,
            values?.description,
            values?.isActive
        )
    );
}

export default submit;