import updateOperationHours from '../../../actions/shop/updateOperationHours';
import showToaster from '../../../helpers/toasterMessages/showToaster';


async function submit(values, dispatch) {
    let isError = false;

    values.OperationHours && values.OperationHours.map((item, index) => {
        item.isOpen = item.isOpen == false ? '0' : '1';
        if(item.endTime && item.startTime) { 
            if(Number(item.startTime) < Number(item.endTime)) {
                isError = false;
            } else if(Number(item.startTime) > Number(item.endTime) || Number(item.startTime) == Number(item.endTime)) {
                isError  = true;
            }
        }
    });

    let operationHours = values.OperationHours && values.OperationHours.length > 0 ? JSON.stringify(values.OperationHours) : JSON.stringify([]);

    if (!isError) {
        await dispatch(updateOperationHours(operationHours));
    } else {
        showToaster({ messageId: 'checkTime', toasterType: 'error' })
    }
}

export default submit;