import React from 'react';
import withStyles from 'isomorphic-style-loader/withStyles';
import { Table } from 'react-bootstrap';
import { FormattedMessage, injectIntl } from 'react-intl';
import cx from 'classnames';
import PropTypes from 'prop-types';
import { Button, FormControl } from 'react-bootstrap';
import { connect } from 'react-redux';

//local
import messages from '../../../../locale/messages';
import CustomPagination from '../../../CustomPagination/CustomPagination';
import Link from '../../../Link/Link';
import history from '../../../../history';

import s from './DeliveryTypeList.css';

//Images
import EditIcon from '../../../../../public/Icons/edit.png';
import TrashIcon from '../../../../../public/Icons/bin.svg';
import addIcon from '../../../../../public/Icons/addNewIcon.svg';

import updateDeliveryStatus from '../../../../actions/siteadmin/DeliveryModule/updateDeliveryStatus';
import deleteDeliveryType from '../../../../actions/siteadmin/DeliveryModule/deleteDeliveryType';
import debounce from '../../../../helpers/debounce';


class DeliveryTypeList extends React.Component {
  static propTypes = {
    deliveryType: PropTypes.object,
  };

  constructor(props) {
    super(props)

    this.state = {
      currentPage: 1,
      searchList: '',
    }

    this.paginationData = this.paginationData.bind(this);
    this.handleClick = this.handleClick.bind(this);
    this.handleKeywordSearch = debounce(this.handleKeywordSearch.bind(this), 250);
    this.handleStatus = this.handleStatus.bind(this);
    this.handleDelete = this.handleDelete.bind(this);
  }

  handleClick() {
    history.push('/add')
  }

  handleKeywordSearch(searchList) { // Keyword search
    const { deliveryType: { refetch } } = this.props;
    this.setState({ searchList, currentPage: 1 });
    refetch({ currentPage: 1, searchList });
  }

  async handleDelete(id) {
    const { deleteDeliveryType, deliveryType: { refetch } } = this.props;
    let variables = { currentPage: 1 };
    const response = await deleteDeliveryType(id);
    if (response && response.status === 200) {
      this.setState({ currentPage: 1 });
      refetch(variables);
    }
  }

  paginationData(currentPage) {
    const { deliveryType: { refetch } } = this.props;
    let variables = { currentPage };
    this.setState({ currentPage });
    refetch(variables);
  }

  async handleStatus(e, id, currentPage) {
    const { updateDeliveryStatus, deliveryType: { refetch } } = this.props;
    let status = e.target.value == 'true' ? '1' : '0';
    let variables = { currentPage };
    const response = await updateDeliveryStatus(id, status);
    if (response && response.status === 200) {
      this.setState({ currentPage });
      refetch(variables);
    }
  }

  render() {
    const { deliveryType } = this.props;
    const { formatMessage } = this.props.intl;
    const { currentPage } = this.state;
    return (
      <div className={cx(s.widthInner, s.tableSection, 'whiteDropdown', 'widthInnerNoBorder')}>
        <div className={cx(s.padding5, 'adminSearchInputSection')}>
          <FormControl type='text' placeholder={formatMessage(messages.search)} onChange={(e) => this.handleKeywordSearch(e.target && e.target.value)}
            className={cx(s.formControlInput, s.searchInput)} />
          <Link to={"/siteadmin/delivery-type/add"} className={s.addlinkBtn}>{formatMessage(messages.addDeliveryModule)}  <span className={cx(s.addIconCss, 'addIconCssRTL')}><img src={addIcon} /></span></Link>
        </div>
        <div className={cx(s.tableCss, 'tableCss', 'tableSticky', 'NewAdminResponsiveTable')}>
          <Table className={cx("table")}>
            <thead>
              <tr>
                <th scope="col"><FormattedMessage {...messages.id} /></th>
                <th scope="col"><FormattedMessage {...messages.deliveryName} /></th>
                <th scope="col"><FormattedMessage {...messages.status} /></th>
                <th scope="col"><FormattedMessage {...messages.action} /></th>
              </tr>
            </thead>
            <tbody>
              {
                deliveryType && deliveryType.getAllDeliveryType && deliveryType.getAllDeliveryType.results && deliveryType.getAllDeliveryType.results.length > 0 && deliveryType.getAllDeliveryType.results.map((item, index) => {
                  return (
                    <tr key={index}>
                      <td data-label={formatMessage(messages.id)}>{item.id}</td>
                      <td data-label={formatMessage(messages.deliveryName)}>{item.deliveryName}</td>
                      <td data-label={formatMessage(messages.status)}>
                        <select value={item.status} onChange={(e) => { this.handleStatus(e, item.id, currentPage) }} className={s.selectInput}>
                          <option value={true}>{formatMessage(messages.active)}</option>
                          <option value={false}>{formatMessage(messages.inactive)}</option>
                        </select>
                      </td>
                      <td data-label={formatMessage(messages.action)}>
                        <Link to={'/siteadmin/delivery-type/edit/' + item.id} className={cx('editAlignIcon', s.editColorIcon)}>
                          <span><img src={EditIcon} className={cx(s.editIcon, 'editIconRTL')} /></span>
                          <span className={s.vtrMiddle}>
                            <FormattedMessage {...messages.editAction} />
                          </span>
                        </Link>
                        <Button onClick={() => this.handleDelete(item.id, currentPage)} className={cx(s.iconBtn, 'iconBtnRTL')}>
                          <img src={TrashIcon} className={cx(s.editIcon, 'editIconRTL')} />
                          <span className={s.vtrMiddle}>
                            <FormattedMessage {...messages.deleteAction} />
                          </span>
                        </Button>
                      </td>
                    </tr>
                  )
                })
              }
              {
                deliveryType && deliveryType.getAllDeliveryType && deliveryType.getAllDeliveryType.results.length == 0 && (
                  <tr>
                    <td colspan="12" className={s.noRecords}><FormattedMessage {...messages.noResult} /></td>
                  </tr>
                )
              }
            </tbody>
          </Table>
        </div>
        {
          deliveryType && deliveryType.getAllDeliveryType && deliveryType.getAllDeliveryType.results && deliveryType.getAllDeliveryType.results.length > 0
          && <div className={cx(s.space5, s.spaceTop5)}>
            <CustomPagination
              total={deliveryType.getAllDeliveryType.count}
              currentPage={currentPage}
              defaultCurrent={1}
              defaultPageSize={10}
              change={this.paginationData}
              paginationLabel={formatMessage(messages.categories)}
            />
          </div>
        }
      </div>
    );
  }
}

const mapDispatch = {
  updateDeliveryStatus,
  deleteDeliveryType,
};
const mapState = (state) => ({});

export default injectIntl(withStyles(s)(connect(mapState, mapDispatch)(DeliveryTypeList)));