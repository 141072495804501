import {
    SET_USER_DATA_START,
    SET_USER_DATA_SUCCESS,
    SET_USER_DATA_ERROR
} from '../../../constants';
import { userAccountQuery as query } from '../../../lib/graphql';

export const loadAccount = () => {

    return async (dispatch, getState, { client }) => {
        try {

            dispatch({
                type: SET_USER_DATA_START,
            });

            const { data } = await client.query({
                query,
                fetchPolicy: 'network-only'
            });

            if (data?.userAccount) {
                let userAccountState = getState().account.data

                let updatedProfileData = { ...userAccountState, ...data.userAccount }

                dispatch({
                    type: SET_USER_DATA_SUCCESS,
                    updatedProfileData
                });
            };
        } catch (error) {
            dispatch({
                type: SET_USER_DATA_ERROR,
            });
        };
    };
};
