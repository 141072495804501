import messages from '../../../../locale/messages';

const validate = values => {
    const errors = {};

    if (!values.vehicleName) {
        errors.vehicleName = messages.required;
    } else if (values.vehicleName && values.vehicleName.trim().toString() == "") {
        errors.vehicleName = messages.required;
    } else if(values.vehicleName.length > 100) {
        errors.vehicleName = messages.exceedLimit100
    }

    if (!values.description) {
        errors.description = messages.required;
    } else if (values.description && values.description.trim().toString() == "") {
        errors.description = messages.required;
    }

    return errors;
};

export default validate;