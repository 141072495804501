const errorMessage = (errorcode, error) => {

    const message = {
        catchError: `Oops! Something went wrong. ${error}`,
        catchError1: error || "Something went wrong! Please try again.",
        commonError: "Something went wrong! Please try again.",
        checkUserEmail: 'Oops, the provided email is already exits.',
        checkUserPhoneNumber: 'Oops, the provided phone number is already exits.',
        checkCategory: "Oops, the provided category type doesn't exist.",
        userLoggedIn: "Currently, you are logged in.",
        emailNoExist: 'No account exists for the given email id',
        invalidToken: 'Invalid token',
        loginError: 'Not Logged In as User',
        userLoginError: 'Please login with your account and try again!',
        checkModifierExist: "Sorry, Unable to delete the modifier due to mapped with the items. Please remove it from the items and try again!",
        updateModifierGroup: 'Oops! Unable to update a modifier group. Please try again.',
        invalidBookingDetails: 'Oops! It looks like the booking is not found and please try again.',
        checkDeliveryPartnerAvailable: 'Sorry, no delivery partner around your store now.',
        updateOrderStatus: 'Something went wrong! Item status not updated',
        checkBookingStatus: `It looks like the order has already ${error}`,
        orderIsNotAvailable: "Oops! It looks like the order is not available.",
        failedPayout: 'Failed to create a payout',
        deleteRecord: 'Error in deleting a record',
        userNotLoggedIn: "Not LoggedIn",
        updateData: 'Not updated',
        invalidVerificationCode: 'Invalid verification code',
        invalidConfirmPassword: 'Wrong confirm password',
        invalidCurrentPassword: 'Wrong current password',
        adminLoggedIn: 'Sorry, It looks like you are already logged-in as an admin. Please logout and try again.',
        emailExist: 'Sorry, it looks like the email is already exist.',
        phoneNumberExist: 'Sorry, it looks like the email or phone number is already exist.',
        addItem: 'Please add a item.',
        updateOperationHours: 'Operation hours not updated',
        isAdminRoleExist: 'Sorry, unable to find this admin role.',
        adminLogin: 'Oops! Please login and continue.',
        isAdminUsingRoles: 'Oops! Unable to delete the admin role due to some admin user using this role.',
        invalidAdminRole: 'Oops, it looks like the chosen role is not valid. Please try with different valid role.',
        emailAlreadyExist: 'Oops! this email address is already exist.',
        loginAuthenticate: 'You haven\'t authorized for this action.',
        paymentMethodOption: 'At least one payment method option must be active.',
        enableStripePayment: 'Please enable Stripe or PayPal Payment to activate the wallet',
        adminLoginError: 'Please login as an admin user and try again.',
        checkCuisineCategoryExist: "Can't delete the category as the category is used by stores",
        categoryUpdateStatus: "Can't update the status as the category is used by stores",
        checkFindSettingsUsed: 'Oops! Unable to delete the dietary setting due to the stores are using it. Please change the dietary setting on their profile and try again to delete.',
        disableBaseCurrency: "Sorry, you can't disable the base currency. Please change the platform's base currency and try again.",
        checkSiteCurrency: 'Oops! Unable to disable this currency. The currency is used for the platform\'s preferred currency. Please change the platform\'s preferred currency and try again',
        disableUserCurrency: 'Oops! Unable to disable this currency. Our users are using this currency.',
        changeCurrencyStatus: 'Oops! Unable to change the currency status. Please try again.',
        deleteDeliveryType: "Can't delete the delivery type as it is used by categories",
        updateDeliveryType: "Can't update the status delivery type as it is used by categories",
        updateVehicleImage: 'Oops! something went wrong! Unable to update the delivery vehicle image.',
        checkShopHasActiveBooking: 'Oops! Unable to delete the store. They have some active orders.',
        isCategoryExist: "Oops, the provided category type doesn't exist.",
        findActiveBooking: 'Oops! Please do not change the status. It looks like the driver is having an active delivery order and try again later.',
        isPromoCodeExist: 'Oops! It looks like already this promo code is used. Please try again with different promo code name.',
        createPromoCode: 'Oops! Something went wrong! Unable to create a new promo code. Please try again.',
        deletePromoCode: "Oops! something went wrong. Unable to delete the promo code.",
        updatePromoCode: 'Oops! Something went wrong! Unable to update the promo code. Please try again.',
        createAdminUser: "Oops, something went wrong. Unable to create an admin user.",
        activeBooking: 'Oops! It looks like the user has an order and unable to delete them',
        checkUserEmailExist: 'Oops, the provided email is already exits with the other user.',
        checkUserPhoneNumberExist: 'Oops, the provided phone number is already exits with the other user.',
        changeUserStatus: 'Please do not change the user status. It looks like the user is having an active delivery order and try again later.',
        banUser: 'Please do not ban the user. It looks like the user is having an active delivery order and try again later.',
        updateUser: 'Oops! Something went wrong, unable to update the user information.',
        userBanned: 'Oops! It looks like your account has been blocked. Please our contact support for the help.',
        userNotApproved: 'Oops! You account has not been approved yet. Please our contact support for the help.',
        activateItem: 'Oops! Please activate at least one item to go available.',
        activateSubItem: 'Oops! Please activate at least one sub- menu to go available.',
        activeMenu: 'Oops! Please activate at least one menu to go available.',
        accountNotAvailable: 'Oops! We are unable to find your account. Please logout and try again.',
        checkUserDeleted: 'Oops! We are unable to find your account. Please contact support for help.',
        checkUserBanned: 'Oops! Your account has been deactivated. Please contact support for help.',
        checkOrder: 'Oops! Something went wrong! Unable to find the order.',
        checkUserBannedStatus: 'Sorry, it looks like your account has been blocked. Please contact our support team.',
        checkEmail: "Oops! We don't recognize this email address",
        invalidPassword: 'Oops! The password you’ve entered is incorrect.',
        adminLogin: 'Please login as an admin user and try again.',
        checkBookingInformation: 'Oops! Unable to find the booking information. Please try again',
        noRecord: 'No record found',
        checkDeliveryVehicle: 'Unable to find the requested delivery vehicle.',
        pageNotFound: 'Oops! The page not found!',
        deliveryPartnerNotFound: 'Sorry, no delivery partner found.',
        userLogin: 'Oops! Please login with your account and continue',
        somethingWrong: "Oops! Something went wrong Please try again.",
        invalidError: 'Something went wrong',
        userLogout: 'Sorry, It looks like you are already logged-in with your account. Please logout and try again.',
        login: 'Please login and continue',
        changeDietarySetting: 'Oops! Unable to disable the dietary setting due to the stores are using it. Please change the dietary setting on their profile and try again to disable.',
        notLoggedIn: 'Not logged in',
        loggedIn: "LoggedIn",
        adminLoginUser: 'Please login as an admin and try again',
        errorLogin: 'Please login',
        loginAccount: 'Oops! It looks like you have not logged-in. Please login with your account.',
        loginUser: 'Please logged in as user',
        cancelError: 'Oops! Something went wrong.',
        userAccount: 'Please login with your account and continue.',
        reviewNotFound: 'Something went wrong! Review not found!',
        orderNotFound: 'Oops! Something went wrong! Order Not Found!',
        unableToRefund: 'Oops! Something went wrong! Unable to proceed the refund.',
        stripeRefund: `Unable to proceed the refund due to  ${error}`,
        unableToProceed: 'Oops! Unable to proceed the requested action. Please try again.',
        createSettings: `Oops! Something went wrong. Unable to  ${error ? 'update' : 'create'}  the setting.`,
        unableToUpdateOperatingHours: error || `Oops! Something went wrong. Unable to update the operating hours and try again.`,
        addVehicleType: `The vehicle type has been added successfully.`,
        updateVehicleType: `The vehicle type has been updated successfully.`,
        deletePayout: 'error in deleting a record',
        defaultPayoutError: 'Unable to set Default',

        // Toaster Error Message
        success: 'Success',
        error: 'Error!',
        warning: 'Warning',
        uploadShopDocument: `Your Document has been successfully uploaded.`,
        commonToasterError: error || 'Something went wrong, please try again.',
        defaultError: `Something went wrong, please try again.`,
        updateShopProfile: `Your profile has been successfully updated.`,
        resetEmailLink: `Reset link email is on its way to your inbox`,
        updateMenu: `The menu has been updated successfully.`,
        addMenu: `The new menu has been added successfully`,
        menuUpdateChanges: `Your changes has been updated successfully`,
        addSubMenu: `A new sub menu added successfully`,
        deleteMenu: `The menu has been deleted successfully.`,
        deleteSubMenu: `The sub menu has been deleted successfully.`,
        updateItemStatus: `The item status has been updated successfully`,
        updateMenuStatus: `The menu status has been updated successfully.`,
        updateSubMenuStatus: `The sub menu status has been updated successfully.`,
        updateModifier: `The modifier changes have been ${error} successfully.`,
        addModifier: `The modifier has been created successfully.`,
        deleteModifier: `The modifier has been deleted successfully.`,
        updateModifierStatus: `The modifier status has been updated successfully.`,
        updateOrderStatus: `The order status has been updated successfully!`,
        removePayout: `Payout account have been removed.`,
        setDefaultPayout: `Default payment method has been set.`,
        updatePreparationTime: `Your preparation times have been successfully updated.`,
        createShopUser: `You have successfully signed up.`,
        verifyOtp: `Phone number verified successfully`,
        updatePartnerOperationHours: `The operating hours have been updated successfully.`,
        addUpdateAdminRole: `Admin role has been ${error ? 'updated' : 'added'} successfully!`,
        addUpdateAdminUser: `Admin user has been  ${error ? 'updated' : 'added'} successfully!`,
        deleteAdminError: `Oops, something went wrong ${error}`,
        deleteAdminRole: `Admin role has been deleted successfully!`,
        deleteAdminUser: `Admin user has been deleted successfully!`,
        updateCategory: `The category has been updated successfully.`,
        addCategory: `The category has been added successfully.`,
        deleteCategory: `The category has been deleted.`,
        updateCategoryImage: `Category image has been uploaded successfully!`,
        updateCategoryPrivilege: `The category has been updated.`,
        updateCategoryStatus: `The category status has been updated.`,
        updateCommonSettings: `The setting has been updated successfully.`,
        addCommonSettings: `The setting has been created successfully.`,
        updateCommonSettingsStatus: `The setting status has been updated successfully!`,
        deleteCommonSetting: `'The setting has been deleted Successfully!`,
        updateConfigSettings: `Configurations updated successfully!`,
        updateCurrencyStatus: `The currency status has been updated successfully.`,
        setBaseCurrency: `Base currency is set successfully`,
        allowPaymentCurrency: `Allowed payment currency success`,
        deliveryTypeDelete: `The delivery type has been deleted.`,
        updateDeliveryTypeStatus: `The delivery type status has been updated.`,
        addUpdateDeliveryType: `The delivery type has been ${error ? 'updated' : 'added'} successfully.`,
        deleteDeliveryVehicle: `The vehicle has been deleted.`,
        updateDeliveryVehicleImage: `The vehicle type image has been uploaded!`,
        updateDeliveryVehicleStatus: `The vehicle type status has been updated.`,
        deleteShop: `The Store has been deleted successfully.`,
        updateShop: `The Store details has been updated successfully.`,
        updateShopStatus: `The ${error} status has been updated successfully.`,
        uploadDocument: `Your document has been successfully uploaded.`,
        updatePartnerHomePage: `The store homepage has been updated successfully.`,
        updatePayout: `The requested changes has been updated successfully.`,
        updatePaymentStatus: `Payment gateway status has been updated successfully.`,
        addUpdatePromoCode: `Promo code has been ${error ? 'updated' : 'added'} successfully.`,
        promoCodeDelete: `Promo code has been deleted successfully.`,
        updateMobileSettings: `Mobile settings changes have been successfully applied`,
        updateSiteSettings: `Site settings changes have been successfully applied`,
        updateTempImages: `${error ? 'Your image has been uploaded.' : 'Your image has been removed.'}`,
        updateCancelReason: `Cancel reason has been ${error ? 'updated' : 'added'} successfully.`,
        deleteContentPage: `Deleted successfully!`,
        failedContentPage: `Deleted failed!`,
        updateContentPageStatus: `Status has changed`,
        updateStaticPageImage: `The image has been uploaded!`,
        deleteUser: `${error === 2 ? 'The driver' : 'The user'} has been deleted successfully.`,
        updateDriver: `Driver has been updated!`,
        userUpdate: `The user information has been updated successfully!`,
        updateVehicle: `Vehicle has been updated!`,
        removeCancelReason: `Cancel reason has been deleted successfully.`,
        updateHomePageSettings: `Homepage settings changes have been successfully applied`,
        updateStaticPage: `Changes are updated!`,
        uploadInsuranceImage: `Insurance image has been uploaded!`,
        uploadLicenseImage: `License images has been uploaded!`,
        uploadProfileImage: `Profile image has been uploaded!`,
        uploadRCbookImage: `RC book image has been uploaded!`,
        invalidImage: `You are trying to upload invalid image file. Please upload ${error} format image file.`,
        updateServiceFee: `Service fee Updated!`,
        sentNotification: `The notification has been sent successfully.`,
        addContentPageDetails: `Changes are ${error ? 'updated' : 'added'} successfully!`,
        pageURLAlreadyExist: `The page URL already exist!`,
        addAdminDetails: `Admin details changed successfully!`,
        emailAlreadyExist: `Oops! this email address is already exist.`,
        checkTimeSlot: `Please check your time slot. Either time slot is invalid or already available.`,
        featureNotAvailable: `This feature is currently not available. Please contact admin for more information.`,
        changePassword: `Password is updated successfully`,
        updateForgotPassword: `Your password has changed, you can try login now!`,
        invalidError: `Something went wrong, please try again later. ${error}`,
        modifierGroupActivate: `The modifier is unavailable at the moment. Please activate it on the "Modifier Groups" section.`,
        storeHomePage: `The Store Homepage has been updated successfully.`,
        failedError: `Oops! Something went wrong! ${error}`,
        updatePayoutError: `${error ? error : 'Failed to update status!'}`,
        maximumImageSize: 'Maximum upload size Exceeded! , Try with smallest size image',
        invalidAudioFile: `You are trying to upload invalid file. Please upload mp3 format audio file`,
        addContent: "Please add content for the page.",
        uploadNotificationSound: 'Please upload a request notification sound.',
        documentUpload: 'Please upload document.',
        serviceFeeUpdated: "Service Fee Updated!",
        updateServiceFeeFailed: "Update Service Fee failed",
        uploadImage: 'Please upload the image.',
        vehicleTypeImageRequired: "Vehicle type image is required.",
        imageRequired: "Image is required.",
        contentPageUpdateFailed: "Updating Content Page Details failed",
        addContentPage: "Please Add  Content",
        adminDetailsFailed: "Your changes to admin details failed!",
        categoryImageRequired: "Category image is required.",
        provideAddress: 'Please provide address.',
        providePhoneNumber: 'Please provide phone number.',
        checkTime: 'The end time must be higher than the start time.',
        uploadImageForItem: 'Please upload the image for item',
        checkEndTime: 'End Time must be more than Start Time',
        passwordMismatch: "Password mismatch.Please try again.",
        enterPhoneNumber: 'Please Enter Phone Number',
        uploadStoreImage: 'Please upload store image.',
        updateStatusFailed: "Failed to update status!",
        updatePaymentCurrencyFailed: "Failed to set allowed payment currency success",
        setBaseCurrencyFailed: "Failed to set Base Currency",
        updateStaticError: "Something went wrong.",
        updateFailed: "Updating failed",
        settingsUploadError: 'Something went wrong',
        cancelReasonFailed: 'Cancel Reason deletion failed',
        changeStatusFailed: "Failed to change  status",
        paymentTypeError: 'Oops! you are not allowed to remove all the currencies. Please make sure that single currency is added.',
        networkFailed: 'You are offline. Please try again later'
    }
    return message[errorcode];
}
export default errorMessage;