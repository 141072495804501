import {
  CHANGE_CURRENCY_STATUS_START,
  CHANGE_CURRENCY_STATUS_SUCCESS,
  CHANGE_CURRENCY_STATUS_ERROR,
  SET_BASE_CURRENCY_START,
  SET_BASE_CURRENCY_SUCCESS,
  SET_BASE_CURRENCY_ERROR,
  CURRENCY_RATES_FETCH_SUCCESS
} from '../../../constants';
import {
  getCurrency,
  getCurrencyRatesQuery,
  updateCurrency,
  SetBaseCurrencyMutation,
  paymentCurrencyMutation
} from '../../../lib/graphql';
import showToaster from '../../../helpers/toasterMessages/showToaster';


const updateCurrencyStatus = (id, isEnable, symbol, currentPage) => {

  return async (dispatch, getState, { client }) => {

    try {

      dispatch({
        type: CHANGE_CURRENCY_STATUS_START,
      });

      const { data } = await client.mutate({
        mutation: updateCurrency,
        variables: { id, isEnable, symbol },
        refetchQueries: [{ query: getCurrency, variables: { currentPage, searchList: '' } }]
      });

      if (data?.updateCurrency?.status === 200) {
        dispatch({
          type: CHANGE_CURRENCY_STATUS_SUCCESS,
        });
        showToaster({ messageId: 'updateCurrencyStatus', toasterType: 'success' })
      } else if (data?.updateCurrency?.status !== 200) {
        dispatch({
          type: CHANGE_CURRENCY_STATUS_ERROR,
          payload: {
            error: data?.updateCurrency?.errorMessage
          }
        });
        showToaster({ messageId: 'commonToasterError', toasterType: 'error', requestMessage: data?.updateCurrency?.errorMessage })
        return false;
      }
    } catch (error) {
      dispatch({
        type: CHANGE_CURRENCY_STATUS_ERROR,
        payload: {
          error
        }
      });
      showToaster({ messageId: 'deleteAdminError', toasterType: 'error', requestMessage: error })
      return false;
    }
    return true;
  };
}

const setBaseCurrency = (id) => {

  return async (dispatch, getState, { client }) => {

    try {

      dispatch({
        type: SET_BASE_CURRENCY_START,
      });

      const { data } = await client.mutate({
        mutation: SetBaseCurrencyMutation,
        variables: { id },
        refetchQueries: [{ query: getCurrency, variables: { currentPage: '1', searchList: '' } }]
      });

      if (data?.setBaseCurrency?.status == 200) {
        dispatch({
          type: SET_BASE_CURRENCY_SUCCESS,
        });

        const currency = await client.query({ query: getCurrencyRatesQuery, fetchPolicy: 'network-only' })

        if (currency?.data?.getCurrencyRates) {

          let currencyRates, base;
          base = currency?.data?.getCurrencyRates?.base;

          if (currency?.data?.getCurrencyRates?.rates != null) {
            currencyRates = JSON.parse(currency?.data?.getCurrencyRates?.rates);
          }

          showToaster({ messageId: 'setBaseCurrency', toasterType: 'success' })

          dispatch({
            type: CURRENCY_RATES_FETCH_SUCCESS,
            payload: {
              base,
              to: "",
              rates: currencyRates
            }
          })

        }
      }
    } catch (error) {

      dispatch({
        type: SET_BASE_CURRENCY_ERROR,
        payload: {
          error
        }
      });
      showToaster({ messageId: 'setBaseCurrencyFailed', toasterType: 'error' })
      return false;
    }
    return true;
  };
}

const allowPaymentCurrency = (id, isPayment) => {

  return async (dispatch, getState, { client }) => {

    try {

      dispatch({
        type: SET_BASE_CURRENCY_START,
      });

      const { data } = await client.mutate({
        mutation: paymentCurrencyMutation,
        variables: { id, isPayment },
        refetchQueries: [{ query: getCurrency }]
      });

      dispatch({
        type: data?.allowPaymentCurrency?.status === 200 ? SET_BASE_CURRENCY_SUCCESS : SET_BASE_CURRENCY_ERROR,
      });
      showToaster(data?.allowPaymentCurrency?.status === 200 ? { messageId: 'allowPaymentCurrency', toasterType: 'success' } :
        { messageId: 'catchError1', toasterType: 'error', requestMessage: data?.allowPaymentCurrency?.errorMessage })

    } catch (error) {

      dispatch({
        type: SET_BASE_CURRENCY_ERROR,
        payload: {
          error
        }
      });
      showToaster({ messageId: 'updatePaymentCurrencyFailed', toasterType: 'error' })
    }

  };
}

export { setBaseCurrency, updateCurrencyStatus, allowPaymentCurrency }