import { addPayout } from '../../../../actions/shop/payout/addPayout';
import showToaster from '../../../../helpers/toasterMessages/showToaster';

async function submit(values, dispatch) {
       
	let removeValues = ['firstname', 'lastname', 'accountNumber', 'routingNumber',
		'ssn4Digits', 'businessType', 'bankCode', 'branchCode',
		'bankName', 'branchName', 'accountOwnerName', 'transitNumber',
		'institutionNumber', 'bsbNumber', 'sortCode']

	if (!values.isEnable) {
		showToaster({ messageId: 'featureNotAvailable', toasterType: 'warning' })
		return;
	}

	if (values.methodId === 5) {
		values.email = values.payEmail
		removeValues.map((item) => {
			values[item] = null;
		})
	}
	
	dispatch(
		addPayout(
			values?.methodId,
			values?.email,
			values?.address1,
			values?.address2,
			values?.city,
			values?.state,
			values?.country,
			values?.zipcode,
			values?.currency,
			values?.firstname,
			values?.lastname,
			values?.accountNumber,
			values?.routingNumber,
			values?.ssn4Digits,
			values?.businessType,
			values?.bankCode,
			values?.branchCode,
			values?.bankName,
			values?.branchName,
			values?.accountOwnerName,
			values?.transitNumber,
			values?.institutionNumber,
			values?.bsbNumber,
			values?.sortCode,
			values?.accountToken,
			values?.personToken
		)
	);

};

export default submit;
