import React from 'react';
import Document from './Document';
import AdminLayout from '../../../../components/Layout/AdminLayout';
import { restrictUrls } from '../../../../helpers/adminPrivileges';
import messages from '../../../../locale/messages';

function action({ store, params, intl }) {
    const title = intl.formatMessage(messages.document);

    // From Redux Store
    let isAdminAuthenticated = store.getState().runtime.isAdminAuthenticated;
    let adminPrivileges = store.getState().account.privileges && store.getState().account.privileges.privileges;
    let privileges = store.getState().commonSettings && store.getState().commonSettings.privileges;

    if (!isAdminAuthenticated) {
        return { redirect: '/siteadmin/login' }
    }


    // Admin restriction
    if (!restrictUrls('/siteadmin/store/document/', adminPrivileges, privileges)) {
        return { redirect: '/siteadmin' };
    }

    const id = Number(params.id);

    return {
        title,
        component: (
            <AdminLayout>
                <Document title={title} id={id} />
            </AdminLayout>
        )
    }
}

export default action;