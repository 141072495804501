import messages from '../../../locale/messages';


const validate = (values) => {
    const errors = {};

    if (!values.documentName1) {
        errors.documentName1 = messages.required
    } else if (values.documentName1 && values.documentName1.length > 200) {
        errors.documentName1 = messages.exceedLimit;
    } else if (values.documentName1 && values.documentName1.trim() == '') {
        errors.documentName1 = messages.required
    }

    if (!values.documentList || !values.documentList.length) {
        errors.documentList = { _error: messages.required }
    } else {
        const documentArrayErrors = [];

        values.documentList.forEach((document, index) => {
            const documentErrors = {}

            if (!document || !document.fileName) {
                documentErrors.fileName = messages.required;
                documentArrayErrors[index] = documentErrors;
            }

            if (!document || !document.fileType) {
                documentErrors.fileType = messages.required;
                documentArrayErrors[index] = documentErrors
            } else if (document.fileType && document.fileType.trim() == '') {
                documentErrors.fileType = messages.required;
                documentArrayErrors[index] = documentErrors;
            }
        })

        if (documentArrayErrors.length) {
            errors.documentList = documentArrayErrors;
        }
    }

    return errors;
}

export default validate;