import {
  RCBOOK_IMAGE_UPLOAD_ERROR,
  RCBOOK_IMAGE_UPLOAD_START,
  RCBOOK_IMAGE_UPLOAD_SUCCESS
} from '../../constants/index'
import showToaster from '../../helpers/toasterMessages/showToaster';
import { removeImage } from '../../helpers/removeImage';
import { uploadRcbookImage as mutation } from '../../lib/graphql';

const uploadRcbookImage = (userId, fileName, oldFile) => {

  return async (dispatch, getState, { client }) => {
    try {

      dispatch({
        type: RCBOOK_IMAGE_UPLOAD_START
      });

      const { data } = await client.mutate({
        mutation,
        variables: {
          userId,
          vehicleRC: fileName
        }
      });

      let url = '/deleteRcbookImage'
      if (oldFile !== undefined) {
        removeImage(url, oldFile);
      };

      if (data?.uploadRcbookImage?.status == "200") {
        dispatch({
          type: RCBOOK_IMAGE_UPLOAD_SUCCESS
        });
        showToaster({ messageId: 'uploadRCbookImage', toasterType: 'success' });
      } else {
        dispatch({
          type: RCBOOK_IMAGE_UPLOAD_ERROR
        });
      }
    } catch (err) {
      dispatch({
        type: RCBOOK_IMAGE_UPLOAD_ERROR
      });
    }
  }
}
export default uploadRcbookImage;
