import { updateDeliveryType } from '../../../../actions/siteadmin/DeliveryModule/updateDeliveryType';
import showToaster from '../../../../helpers/toasterMessages/showToaster';

async function submit(values, dispatch) {

    if (!values.imageName) {
        showToaster({ messageId: 'imageRequired', toasterType: 'error' })
        return;
    }

    await dispatch(
        updateDeliveryType(
            values
        )
    )
}

export default submit;