import {
    ADMIN_UPDATE_STATIC_START,
    ADMIN_UPDATE_STATIC_SUCCESS,
    ADMIN_UPDATE_STATIC_ERROR
} from '../../constants';
import history from '../../history';
import showToaster from '../../helpers/toasterMessages/showToaster';
import { updateStaticPageMuation, getEditStaticPage } from '../../lib/graphql';

export const updateStaticPage = (values) => {

    return async (dispatch, getState, { client }) => {
        try {
            dispatch({ type: ADMIN_UPDATE_STATIC_START });
            const { data } = await client.mutate({
                mutation: updateStaticPageMuation,
                variables: {
                    content: values?.content,
                    metaTitle: values?.metaTitle,
                    metaDescription: values?.metaDescription,
                    pageBanner: values?.pageBanner,
                    id: values?.id
                },
                refetchQueries: [{ query: getEditStaticPage, variables: { id: values.id } }]
            });

            if (data?.updateStaticPage?.status == 200) {
                showToaster({ messageId: 'updateStaticPage', toasterType: 'success' })
                history.push('/siteadmin/staticpage/manage');
                dispatch({ type: ADMIN_UPDATE_STATIC_SUCCESS });
            }
            else {
                showToaster({ messageId: 'updateFailed', toasterType: 'error' })
                dispatch({ type: ADMIN_UPDATE_STATIC_ERROR });
            }
        } catch (error) {
            showToaster({ messageId: 'updateStaticError', toasterType: 'error' })
            dispatch({ type: ADMIN_UPDATE_STATIC_ERROR });
            return false;
        }
        return true;
    };
}