import React, { Component } from 'react';
import { injectIntl } from 'react-intl';
import { graphql } from 'react-apollo';
import { Form } from 'react-bootstrap';
import { connect } from 'react-redux';
import { flowRight as compose } from 'lodash';
import { change } from 'redux-form';
import getCountries from './getCountries.graphql';
import messages from '../../../locale/messages';

class CountryList extends Component {


    static defaultProps = {
        data: {
            getCountries: []
        },
        // defaultCountry: 'US'
    };

    constructor(props) {
        super(props);

        this.handleChange = this.handleChange.bind(this);
    };


    async handleChange(countryCode) {
        const { data: { loading, getCountries }, handleCountryChange } = this.props;

        let countryDetails = getCountries.find((country) => countryCode == country.countryCode);

        handleCountryChange(countryDetails);

    };

    render() {
        const { data: { loading, getCountries }, className, defaultCountry, disabled } = this.props;
        const { formatMessage } = this.props.intl;
        
        return (
            <div>

                {defaultCountry &&
                    <Form.Control as="select"
                        className={className}
                        value={defaultCountry}
                        onChange={(e) => {
                            this.handleChange(e.target.value);
                        }}
                        disabled={disabled}
                    >

                        {
                            !loading && getCountries != null && getCountries.length > 0 && getCountries.map((item) => {
                                return (
                                    <option value={item.countryCode} key={item.id}>{item.countryName}</option>
                                )
                            })
                        }

                        
                    </Form.Control>
                }

            </div>
        );
    }
};

const mapState = (state) => ({});

const mapDispatch = {
    change
};

export default compose(
    injectIntl,
    connect(mapState, mapDispatch),
    graphql(getCountries, { options: { ssr: true } })
)(CountryList);
