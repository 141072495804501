import history from '../../../history';
import {
	SHOP_MODIFIER_UPDATE_START,
	SHOP_MODIFIER_UPDATE_SUCCESS,
	SHOP_MODIFIER_UPDATE_ERROR
} from '../../../constants/index';
import { updateModifierMutation } from '../../../lib/graphql';
import { getAllShopModifier } from './getAllShopModifier';
import showToaster from '../../../helpers/toasterMessages/showToaster';

const addModifier = (values) => {

	return async (dispatch, getState, { client }) => {
		try {
			let requestMessage = values.id ? 'updated' : 'created'

			dispatch({
				type: SHOP_MODIFIER_UPDATE_START,
				payload: {
					updateLoading: true
				}
			});

			const { data: { updateModifierGroup } } = await client.mutate({
				mutation: updateModifierMutation,
				variables: values
			});

			if (updateModifierGroup?.status == 200) {
				dispatch({
					type: SHOP_MODIFIER_UPDATE_SUCCESS,
					payload: {
						updateLoading: false
					}
				});
				showToaster({ messageId: 'updateModifier', toasterType: 'success', requestMessage })
				history.push('/store/menu/modifier-groups');
			} else {
				dispatch({
					type: SHOP_MODIFIER_UPDATE_ERROR,
					payload: {
						updateLoading: false
					}
				});
				showToaster({ messageId: 'commonToasterError', toasterType: 'error', requestMessage: updateModifierGroup.errorMessage })
			}
			dispatch(getAllShopModifier());

		} catch (error) {
			showToaster({ messageId: 'commonToasterError', toasterType: 'error', requestMessage: error })
			dispatch({
				type: SHOP_MODIFIER_UPDATE_ERROR,
				payload: {
					updateLoading: false
				}
			});
		}
	}
}

export default addModifier;