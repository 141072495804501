import showToaster from '../../../helpers/toasterMessages/showToaster';
import history from '../../../history';
import { updateForgotPassword as mutation } from '../../../lib/graphql';

export const updatePassword = (email, newPassword, token) => {

  return async (dispatch, getState, { client }) => {

    try {
      const { data } = await client.mutate({
        mutation,
        variables: {
          email,
          token,
          newPassword
        }
      });

      if (data?.updateForgotPassword?.status === 200) {
        showToaster({ messageId: 'updateForgotPassword', toasterType: 'success' });
        history.push('/store');
      } else {
        showToaster({ messageId: 'invalidError', toasterType: 'error', requestMessage: data.updateForgotPassword.errorMessage });
        return false;
      }
    } catch (error) {
      showToaster({ messageId: 'invalidError', toasterType: 'error', requestMessage: error });
      return false;
    }
    return true;
  };
}