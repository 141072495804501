import {
  ADD_PAYOUT_START,
  ADD_PAYOUT_SUCCESS,
  ADD_PAYOUT_ERROR,
} from '../../../constants';
import { checkPaymentMethod } from '../../../lib/graphql';
import history from '../../../history';
import showToaster from '../../../helpers/toasterMessages/showToaster';

export const checkPayout = () => {

  return async (dispatch, getState, { client }) => {
    try {

      dispatch({
        type: ADD_PAYOUT_START
      });

      const { data } = await client.query({
        query: checkPaymentMethod,
        fetchPolicy: 'network-only'
      });

      if (data?.getPaymentMethods?.results?.length > 0) {
        dispatch({
          type: ADD_PAYOUT_SUCCESS
        });
        history.push('/store/addpayout');
      } else {
        dispatch({
          type: ADD_PAYOUT_ERROR
        });
        showToaster({ messageId: 'featureNotAvailable', toasterType: 'warning' })
      }

    } catch (error) {
      dispatch({
        type: ADD_PAYOUT_ERROR
      });
    }
  };
};