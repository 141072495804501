import { addPromoCode } from '../../../../actions/siteadmin/PromoCode/addPromoCode';
import { toastr } from 'react-redux-toastr';

async function submit(values, dispatch) {
  if (!values.promoCodeImage) {
    toastr.error('Error!', 'Promo code image is required!');
    return;
  }
  await dispatch(addPromoCode(values));
}

export default submit;
