
import React from 'react';
import AddPayoutContainer from './AddPayoutContainer';
import DashboardLayout from '../../../../components/Shop/StoreLayout/Layout/DashboardLayout';
import { getPayouts } from '../../../../actions/shop/payout/getPayouts';
import messages from '../../../../locale/messages';


async function action({ store, query, intl }) {
  const title = intl.formatMessage(messages.payoutPreferences);

  let isStoreAuthenticated = store.getState().runtime.isStoreAuthenticated;
  let initialData;

  if (!isStoreAuthenticated) {
    return { redirect: '/store' };
  }

  let accountData = store.getState().account.data;

  if (accountData) {
    initialData = {
      country: accountData && accountData.profile && accountData.profile.country,
      email: accountData.email
    };
  }

  return {
    title,
    component: (
      <DashboardLayout>
        <AddPayoutContainer title={title} initialData={initialData} />
      </DashboardLayout>
    )
  };
};

export default action;
