import React, { Component } from 'react';
import { graphql } from 'react-apollo';
import { flowRight as compose } from 'lodash';
import PropTypes from 'prop-types';
import withStyles from 'isomorphic-style-loader/withStyles';
// style
import s from './ViewReview.css';
// components
import ViewReviewDetails from '../../../components/SiteAdmin/ViewReviewDetails';
import Loader from '../../../components/Common/Loader/Loader';
// graphql
import viewReview from './viewReview.graphql';

export class ViewReview extends Component {
    static propTypes = {
        title: PropTypes.string.isRequired  
    };

    static defaultProps = {
        reviewData: {
            loading: true
        }
    };

    render() {
        const { reviewData, reviewData: { loading }, title } = this.props;
        
        return (
            <Loader type={"page"} show={loading}>
                <div className={s.paddingRoutesSection}>
                    {
                        !loading && <ViewReviewDetails
                            data={reviewData}
                            title={title}
                        />
                    }
                </div>
            </Loader>
        );
    }
}

export default compose(
    withStyles(s),
    graphql(viewReview, {
        name: 'reviewData',
        options: (props) => ({
            variables: {
                id: props.id
            },
            fetchPolicy: 'network-only'
        })
    })
)(ViewReview);
