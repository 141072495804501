import React, { Component } from 'react';
import { Field, reduxForm, formValueSelector } from 'redux-form';
import { connect } from 'react-redux';
import withStyles from 'isomorphic-style-loader/withStyles';
import { injectIntl } from 'react-intl';
import cx from 'classnames';
import Form from 'react-bootstrap/Form';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Container from 'react-bootstrap/Container';

import Loader from '../../../Common/Loader/Loader';
import ImageUploadComponent from '../../../Common/ImageUploadComponent/ImageUploadComponent';

import submit from './submit';
import validate from './validate';
import messages from '../../../../locale/messages';
import { api, partnerHomepageUploadDir } from '../../../../config';

import s from './ContactForm.css';
export class ContactForm extends Component {

	renderField = ({ input, label, type, meta: { touched, error }, labelClass, fieldClass, placeholder, maxlength }) => {
		const { formatMessage } = this.props.intl
		return (
			<Form.Group className={cx(s.space5, 'positionRelative')}>
				<Form.Label>{label}</Form.Label>
				<Form.Control {...input} placeholder={placeholder} type={type} className={cx(fieldClass, s.formControlInput)} maxlength={maxlength}
				/>
				{touched && error && <span className={cx(s.errorMessage, 'errorMessageRTL')}>{formatMessage(error)}</span>}
			</Form.Group>
		)
	}

	render() {
		const { handleSubmit, sliderImage1, sliderImage2, sliderImage3, sliderImage5, loading, submitting, imageLoader } = this.props;
		const { formatMessage } = this.props.intl;
		return (
			<Container fluid>
				<h1 className={cx(s.heading, 'textAlignRightRTL')}>{formatMessage(messages.sliderContact)}</h1>
				<div className={s.blackCenterSection}>
					<Form className={s.fullWidth} onSubmit={handleSubmit(submit)}>
						<Row>
							<Col lg={6} md={12} sm={12} xs={12}>
								<Form.Group className={cx(s.space3)}>
									<div className={cx(s.profileImgSection, s.profileImgWidth)}>
										<ImageUploadComponent
											className={cx(s.btnSecondary, s.profileNoPadding)}
											subTextClass={s.subText}
											subText={formatMessage(messages.maximumUploadSizeLabel)}
											defaultMessage={formatMessage(messages.chooseFile)}
											loaderName={'contactImageLoader'}
											postUrl={api.apiEndpoint + '/uploadPartnerHomepageImage'}
											loader={imageLoader}
											fieldName={'sliderImage5'}
											formName={'ContactForm'}
											imageSrc={sliderImage5 ? api.apiEndpoint + partnerHomepageUploadDir + 'medium_' + sliderImage5 : null}
											label={formatMessage(messages.sliderContactImage)}
										/>
									</div>
								</Form.Group>
							</Col>
							<Col lg={6} md={12} sm={12} xs={12}>
								<Field name="sliderTitle5" type="text" component={this.renderField} label={formatMessage(messages.contactUsTitle)} maxlength={250} />
								<Field name="buttonName" type="text" component={this.renderField} label={formatMessage(messages.buttonName)} maxlength={250} />
								<Field name="buttonLink" type="text" component={this.renderField} label={formatMessage(messages.buttonLink)} maxlength={250} />
							</Col>
							<Col lg={12} md={12} sm={12} xs={12} className={cx(s.textAlignRight, 'textAlignLeftRTL')}>
								<Form.Group className={s.noMargin}>
									<div className={s.displayInlineBlock}>
										<Loader
											type={"button"}
											label={formatMessage(messages.submitButton)}
											show={loading}
											buttonType={'submit'}
											className={cx(s.button, s.btnPrimary)}
											disabled={submitting || loading}
											isSuffix={true}
										/>
									</div>
								</Form.Group>
							</Col>
						</Row>
					</Form>
				</div>
			</Container>
		)
	}
}

ContactForm = reduxForm({
	form: 'ContactForm',
	onSubmit: submit,
	validate
})(ContactForm);

const selector = formValueSelector('ContactForm')

const mapState = (state) => ({
	sliderImage1: selector(state, 'sliderImage1'),
	sliderImage2: selector(state, 'sliderImage2'),
	sliderImage3: selector(state, 'sliderImage3'),
	sliderImage5: selector(state, 'sliderImage5'),
	loading: state.loader.UpdatePartnerHomepage,
	imageLoader: state.loader.contactImageLoader
})

const mapDispatch = {
}

export default injectIntl(withStyles(s)(connect(mapState, mapDispatch)(ContactForm)));