import React from 'react';
import { Container } from 'react-bootstrap';
import cx from 'classnames';
import Swiper from 'react-id-swiper';
import { injectIntl } from 'react-intl';
import withStyles from 'isomorphic-style-loader/withStyles';

import HomeItem from '../HomeItem';
import Loader from '../../Common/Loader';

import { isRTL } from '../../../helpers/formatLocale';

//Images
import LeftArrowIcon from '../../../../public/Icons/homeSliderLeft.png';
import RightArrowIcon from '../../../../public/Icons/homeSliderRight.png';

import s from './HomeSlider.css';

class HomeSlider extends React.Component {

    static defaultProps = {
        data: []
    }

    constructor(props) {
        super(props);
        this.state = {
            load: false
        };
    }

    componentDidMount() {
        this.setState({
            load: true
        });
    }

    UNSAFE_componentWillReceiveProps(prevProps) {
        const { locale } = this.props.intl;
        const { locale: prevLocale } = prevProps.intl;

        if (locale !== prevLocale) {
            this.setState({
                load: false
            });
            clearTimeout(this.loadSync);
            this.loadSync = null;
            this.loadSync = setTimeout(() => {
                this.setState({
                    load: true
                })
            }, 1);
        }
    }

    showCategories = (data) => {
        const { intl: { locale } } = this.props;
        const { load } = this.state;

        const params = {
            slidesPerView: 4,
            loop: true,
            breakpoints: {
                1440: {
                    slidesPerView: 4,
                    centeredSlides: false,
                },
                1280: {
                    slidesPerView: 4,
                    centeredSlides: false,
                },
                1024: {
                    slidesPerView: 3,
                    centeredSlides: false,
                },
                768: {
                    slidesPerView: 2,
                    centeredSlides: false,
                    spaceBetween: 10,
                },
                320: {
                    slidesPerView: 1,
                    centeredSlides: true,
                },
                280:{
                    slidesPerView: 1,
                    centeredSlides: true,
                }
            }
        };

        let specificParams = {
            nextEl: '.swiper-button-next',
            prevEl: '.swiper-button-prev'
        };

        if (data) {
            if (data.length > 4) params['navigation'] = specificParams;
            else if (typeof window !== 'undefined') {
                if ((window.matchMedia('(max-width: 768px) and (min-width: 640px)').matches && data.length > 3)
                    || (window.matchMedia('(max-width: 640px)').matches && data.length > 1)) {
                    params['navigation'] = specificParams;
                }
            }
        }

        return (
            <div className={'manualBookingSlider'}>
                {
                    !load && <div>
                        <Loader type="text" />
                    </div>
                }
                {
                    load &&
                    <Swiper {...params} rtl={isRTL(locale)} >
                        {
                            data.map((item, key) => {
                                return (
                                    <div><HomeItem key={key} item={item} /></div>
                                );
                            })
                        }
                    </Swiper>
                }
            </div>
        );
    };

    render() {
        const { data } = this.props;

        return (
            <Container>
                <div className={s.root}>
                    <div className={s.paddingTopBottom}>
                        <div className={cx(s.sliderMain, 'sliderCommon')}>
                            {data?.length > 0 && this.showCategories(data)}
                        </div>
                    </div>
                </div>
            </Container>
        )
    }
}


export default injectIntl(withStyles(s)(HomeSlider));
