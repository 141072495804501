import React from 'react';
import PropTypes from 'prop-types';
import withStyles from 'isomorphic-style-loader/withStyles';
import s from './DeliveryVehicle.css';
import cx from 'classnames';
import DeliveryVehicleList from '../../../../components/SiteAdmin/DeliveryVehicle/DeliveryVehicleList';
import getAllVehicleTypesQuery from './getAllDeliveryVehicle.graphql';
import { graphql } from 'react-apollo';
import { flowRight as compose } from 'lodash';
import Loader from '../../../../components/Common/Loader/Loader';
//meassage
import { FormattedMessage } from 'react-intl';
import messages from '../../../../locale/messages';

class DeliveryVehicle extends React.Component {
  static propTypes = {
    title: PropTypes.string.isRequired
  };

  static defaultProps = {
    vehicleTypeDetails: {
      loading: true
    }
  }

  render() {
    const { title, vehicleTypeDetails, vehicleTypeDetails: { loading } } = this.props;

    return (
      <Loader type={"page"} show={loading}>
        <div className={s.root}>
          <div className={s.container}>
            <div className={s.paddingRoutesSection}>
              <div className={cx(s.heading, 'textAlignRightRTL')}>
                <FormattedMessage {...messages.manageVehicleTypes} />
              </div>
              {!loading && <DeliveryVehicleList vehicleTypeDetails={vehicleTypeDetails} />}
            </div>
          </div>
        </div>
      </Loader>
    );
  }
}

export default compose(
  withStyles(s),
  graphql(getAllVehicleTypesQuery, {
    name: 'vehicleTypeDetails',
    options: {
      ssr: true,
      fetchPolicy: 'network-only',
      variables: {
        currentPage: 1,
        searchKeyword: ''
      }
    }
  })
)(DeliveryVehicle);
