import history from '../../history';
import { setLoaderStart, setLoaderComplete } from '../loader/loader';
import showToaster from '../../helpers/toasterMessages/showToaster';
import { updateUserMutation as mutation } from '../../lib/graphql';

export const editUser = (id, firstName, lastName, email, password, phoneDialCode, phoneNumber, userStatus, isBan, phoneCountryCode) => {
  return async (dispatch, getState, { client }) => {
    try {
      dispatch(setLoaderStart('EditUser'));

      const { data } = await client.mutate({
        mutation,
        variables: {
          id,
          firstName,
          lastName,
          email,
          password,
          phoneDialCode,
          phoneNumber,
          userStatus,
          isBan,
          phoneCountryCode
        }
      });

      dispatch(setLoaderComplete('EditUser'));

      if (data?.updateUser?.status === 200) {
        history.push('/siteadmin/users')
        showToaster({ messageId: 'userUpdate', toasterType: 'success' })
      } else {
        showToaster({ messageId: 'commonToasterError', toasterType: 'error', requestMessage: data?.updateUser?.errorMessage })
      }

    } catch (error) {
      showToaster({ messageId: 'commonToasterError', toasterType: 'error', requestMessage: error })
    }
  }
}