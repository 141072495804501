import React from 'react';
import AdminLayout from '../../../components/Layout/AdminLayout';
import Dashboard from './Dashboard';
import messages from '../../../locale/messages';

function action({ store, intl }) {
  const title = intl.formatMessage(messages.dashboard);

  // From Redux Store
  let isAdminAuthenticated = store.getState().runtime.isAdminAuthenticated;
  let isAccountAvailable = store.getState().account;


  if (!isAdminAuthenticated) {
    return { redirect: '/siteadmin/login' };
  }

  // if (Object.keys(isAccountAvailable).length === 0 && isAccountAvailable.constructor === Object) {
  //   return { redirect: '/siteadmin/login' };
  // }

  return {
    title,
    component: (
      <AdminLayout>
        <Dashboard title={title} />
      </AdminLayout>
    ),
  };
}

export default action;
