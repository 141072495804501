import {
    SITE_SETTINGS_SUCCESS,
    UPDATE_SITE_SETTINGS_START,
    UPDATE_SITE_SETTINGS_SUCCESS,
    UPDATE_SITE_SETTINGS_ERROR
} from '../constants/index'

export default function user(state = {}, action) {
    switch(action.type) {
        case SITE_SETTINGS_SUCCESS:
            return {
                ...state,
                data: action.data
            };
        
        case UPDATE_SITE_SETTINGS_START:
            return {
                ...state,
                updateSiteSettings: true
            }  
        
        case UPDATE_SITE_SETTINGS_SUCCESS:
            return {
                ...state,
                updateSiteSettings: false
            }   
                
        case UPDATE_SITE_SETTINGS_ERROR:
            return {
                ...state,
                updateSiteSettings: false
            }  

        default:
            return {
              ...state
            };
    }
};
  