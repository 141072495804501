exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "._4KnjQ {\n  text-align: right;\n}\n\n._2hmKA {\n  text-align: center;\n}\n\n.nXSZh {\n  width: 100%;\n  max-width: 220px;\n  margin: auto 0 auto auto;\n}\n\n.H1ILg {\n  position: relative;\n}\n\n.H1ILg::after {\n  bottom: 40px !important;\n  left: 0 !important;\n}\n\n._3RH78 {\n  position: relative;\n}\n\n._16nhV {\n  position: absolute;\n  top: 14px;\n  right: 10px;\n  cursor: pointer;\n}", ""]);

// exports
exports.locals = {
	"textAlignRight": "_4KnjQ",
	"textAlignCenter": "_2hmKA",
	"btnWidth": "nXSZh",
	"titleBottom": "H1ILg",
	"positionRelative": "_3RH78",
	"pwdImage": "_16nhV"
};