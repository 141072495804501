import React from 'react';
import PropTypes from 'prop-types';

import Footer from '../../../Footer/Footer';
import Toaster from '../../../Toaster/Toaster';
import StoreHeader from '../StoreHeader/StoreHeader';
import SocketContext from '../../../SocketContext';
import CookiesDisclaimer from '../../../CookiesDisclaimer/CookiesDisclaimer';

class Layout extends React.Component {
  static propTypes = {
    children: PropTypes.node.isRequired,
  };

  render() {
    return (
      <SocketContext.Consumer>
        {
          socket => (
            <div className={'store'}>
              <Toaster />
              <StoreHeader />
              {this.props.children}
              <Footer />
              <CookiesDisclaimer />
            </div>
          )
        }
      </SocketContext.Consumer>
    );
  }
};

export default Layout;