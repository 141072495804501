import { change } from 'redux-form';
import {
    ADDITEM_MODIFIER_START,
    ADDITEM_MODIFIER_SUCCESS,
    ADDITEM_MODIFIER_ERROR
} from '../../../constants/index';
import { closeItemModal } from '../../siteadmin/modalActions';

const addItemModifier = (values) => {
    return async (dispatch, getState, { client }) => {

        try {
            let updatedModifierData = [], existingModifierData = [], modifiers = {}, arrayData = [];

            dispatch({
                type: ADDITEM_MODIFIER_START,
            });

            if (getState().form.AddItemForm && getState().form.AddItemForm.values) {
                existingModifierData = getState().form.AddItemForm.values.modifiers;
            }
            if (existingModifierData && existingModifierData.length > 0) {
                existingModifierData.map((item, key) => {
                    if (values.editId != key) {
                        updatedModifierData.push(item);
                    }
                });
            }
            arrayData.push(values);
            modifiers['modifierGroups'] = arrayData;
            updatedModifierData.push(modifiers);
            dispatch({
                type: ADDITEM_MODIFIER_SUCCESS,
            });
            await dispatch(change('AddItemForm', 'modifiers', updatedModifierData));
            dispatch(closeItemModal());

        } catch (error) {
            dispatch({
                type: ADDITEM_MODIFIER_ERROR,
            });
            dispatch(closeItemModal());
        }
    }
}

export default addItemModifier;