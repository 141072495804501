import React from 'react';
import withStyles from 'isomorphic-style-loader/withStyles';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import Button from 'react-bootstrap/Button';
import FormControl from 'react-bootstrap/FormControl';
import { FormattedMessage, injectIntl } from 'react-intl';
import cx from 'classnames';
import { connect } from 'react-redux';

import CustomPagination from '../../../CustomPagination/CustomPagination';
import Link from '../../../Link/Link';
import AddSubMenuModal from '../SubMenu/AddSubMenuModal';
import CurrencyConverter from '../../../CurrencyConverter';
import ConfirmationPopup from '../../../Common/ConfirmationPopup';

import messages from '../../../../locale/messages';
import updateItemStatus from '../../../../actions/shop/menu/updateItemStatus';
import deleteItem from '../../../../actions/shop/menu/deleteItem';
import editItem from '../../../../actions/shop/menu/editItem';
import updateSubMenuStatus from '../../../../actions/shop/menu/updateSubMenuStatus';
import deleteSubMenu from '../../../../actions/shop/menu/deleteSubMenu';
import editSubMenu from '../../../../actions/shop/menu/editSubMenu';
import debounce from '../../../../helpers/debounce';
import history from '../../../../history';
import { openSubMenuModal, openModal, closeModal } from '../../../../actions/siteadmin/modalActions';
import { api, itemImageUploadDir } from '../../../../config'

import DefaultImage from '../../../../../public/StoreIcon/defaultFood.svg';
import EditIcon from '../../../../../public/StoreIcon/edit.svg';
import TrashIcon from '../../../../../public/StoreIcon/dlty.svg';
import VegIcon from '../../../../../public/StoreIcon/veg.svg';
import NonVegIcon from '../../../../../public/StoreIcon/non-veg.svg';
import ManageItemIcon from '../../../../../public/StoreIcon/Manage-items.svg';

import s from './ItemList.css';
import rs from '../../../storeCommon.css';

class ItemList extends React.Component {

  constructor(props) {
    super(props)

    this.state = {
      currentPage: 1,
      searchList: ''
    };
  }

  isValueDefined = (value) => {
    if (value && value !== undefined && value !== null && String(value) !== '') {
      return true
    }

    return false;
  }

  handleKeywordSearch(searchList) {
    const { itemDetails: { refetch }, menuId } = this.props;

    let variables = {
      currentPage: 1,
      searchList,
      menuId
    };

    this.setState({ currentPage: 1, searchList })
    refetch(variables);
  }

  handleSearchClick(searchList) {
    const { itemDetails: { refetch }, menuId } = this.props
    let variables = {
      currentPage: 1,
      searchList: searchList,
      menuId: menuId
    }
    this.setState({ currentPage: 1 })
    refetch(variables)
  }

  handleSearchChange(e) {
    let self = this
    if (self.state.typingTimeout) {
      clearTimeout(self.state.typingTimeout)
    }
    self.setState({
      searchList: e.target.value,
      typing: false,
      typingTimeout: setTimeout(function () {
        self.handleSearchClick(self.state.searchList)
      }, 450)
    })
  }

  handleDelete = async (id, currentPage, section) => {
    const { deleteItem, itemDetails: { refetch }, menuId, deleteSubMenu, closeModal } = this.props;
    let variables = { currentPage, menuId };

    if (section == 'subMenu') {
      await deleteSubMenu(id, currentPage, menuId)
      await closeModal('subMenuDeleteModal')
      refetch(variables)
    } else {
      await deleteItem(id, currentPage, menuId);
      refetch(variables);
    }
  };

  paginationData = (currentPage) => {
    const { itemDetails: { refetch }, menuId } = this.props;
    let variables = { currentPage, menuId };

    this.setState({ currentPage });
    refetch(variables);
  };

  handleStatus = async (id, status, currentPage, section) => {
    const { updateItemStatus, itemDetails: { refetch }, menuId, updateSubMenuStatus } = this.props;
    let variables = { currentPage, menuId };


    if (section == 'subMenu') {
      await updateSubMenuStatus(id, status, currentPage, menuId)
      refetch(variables)
    } else {
      await updateItemStatus(id, status, currentPage, menuId);
      refetch(variables);
    }
  };

  calculateOverallAmount = (amount, tax) => {
    let taxValue = 0
    if (amount && Number(amount) > 0 && tax && Number(tax) > 0) {
      taxValue = Number(amount) * (Number(tax) / 100);
      return amount + taxValue;
    } else {
      return amount;
    }
  }

  render() {
    const { itemDetails, openSubMenuModal, menuId, editItem, editSubMenu, modalData } = this.props;
    const { closeModal, openModal, subMenuDeleteModal, profile } = this.props;
    const { formatMessage } = this.props.intl;
    const { currentPage } = this.state;

    return (
      <div className={'mainContainer'}>
        <div>
          <ConfirmationPopup
            modalStatus={subMenuDeleteModal}
            title={formatMessage(messages.deleteMenuTitle)}
            body={formatMessage(messages.deleteConfirmation)}
            closeModal={() => closeModal('subMenuDeleteModal')}
            popupButtonLeftName={formatMessage(messages.cancelButton)}
            popupButtonRightName={formatMessage(messages.deleteAction)}
            popupButtonLeftFunction={() => closeModal('subMenuDeleteModal')}
            popupButtonRightFunction={() => this.handleDelete(modalData?.id, modalData?.currentPage, 'subMenu')}
          />
        </div>
        <Container fluid>
          <AddSubMenuModal />
          <Row>
            <Col lg={12} md={12} sm={12} xs={12}>
              <h1 className={cx(rs.storeTitleText, 'textAlignRightRTL')}>
                {
                  itemDetails?.getAllSubMenu?.menuName
                }
              </h1>
            </Col>
            <Col lg={12} md={12} sm={12} xs={12}>
              <div className={cx(rs.searchInputStore)}>
                <FormControl type='text' placeholder={formatMessage(messages.search)}
                  onChange={(e) => debounce(this.handleKeywordSearch(e.target.value), 250)} className={cx(rs.formControlInputStore, rs.searchInputWidth, 'formControlInputStoreRTL')} />
              </div>
              <div className={cx(s.displayInlineBlock, rs.searchTopBtn, 'textAlignLeftRTL')}>
                <Button className={cx(rs.button, rs.btnPrimary, rs.textOverFlow)} onClick={() => openSubMenuModal(menuId)}>
                  <FormattedMessage {...messages.addSubMenu} />
                </Button>
              </div>
            </Col>
          </Row>
          {
            itemDetails?.getAllSubMenu?.results?.length > 0 && itemDetails?.getAllSubMenu?.results?.map((item, index) => {
              return (
                <Row key={index}>
                  <Col xs={12} lg={12} md={12} sm={12}>
                    <div className={rs.menuItemGrid}>
                      <div className={cx(rs.menuFirstSection, s.marginBottomlistItem, rs.subMenuGridWidth, s.paddintTop)}>
                        <div className={cx(rs.alignLeft, rs.tabSection, 'textAlignRightRTL')}>
                          <p className={rs.idTitle}>
                            {item?.name}
                          </p>
                        </div>
                        <div className={cx('storeActiveSelect', 'itemAddSelectRTL')}>
                          <select value={item?.isEnable} onChange={(e) => { this.handleStatus(item?.id, e.target.value, currentPage, 'subMenu') }} className={cx(rs.activeSelect, rs.storeSelectInput)}>
                            <option value={true}>{formatMessage(messages.active)}</option>
                            <option value={false}>{formatMessage(messages.inactive)}</option>
                          </select>
                        </div>
                        <div className={rs.editCenterBtn}>
                          <Button className={cx(rs.deleteBtn, s.editBtn, s.IconLeft, rs.textOverFlow)} onClick={() => editSubMenu(item?.id)}>
                            <img src={EditIcon} className={cx(s.iconsWidth, 'iconsWidthStoreRTL')} />
                            <span className={cx(rs.vtrMiddle, rs.linkFontSize)}>{formatMessage(messages.editItem)}</span>
                          </Button>
                        </div>
                        <div className={rs.editCenterBtn}>
                          <Button className={cx(rs.deleteBtn, s.editBtn, s.IconLeft, rs.textOverFlow)} onClick={() => openModal('subMenuDeleteModal', { id: item?.id, modalName: 'subMenuDeleteModal', currentPage })}>
                            <img src={TrashIcon} className={cx(s.iconsWidth, 'iconsWidthStoreRTL')} />
                            <span className={cx(rs.vtrMiddle, rs.linkFontSize)}>{formatMessage(messages.deleteAction)}</span>
                          </Button>

                        </div>
                        <div className={cx(s.IconRight)}>
                          <Link to={"/store/menu/" + item?.menuId + "/item/" + item?.id + "/add"} className={cx(rs.button, rs.btnPrimary, s.displayInlineBlock)}>
                            <img src={ManageItemIcon} className={cx(s.iconsWidth, 'iconsWidthStoreRTL')} />
                            {formatMessage(messages.addItem)}
                          </Link>
                        </div>
                      </div>
                      {item?.item?.length == '0' && (
                        <div className={cx(rs.spaceTop5, rs.space2, rs.alignCenter, rs.activeDays)}>
                          <p colspan="12"><FormattedMessage {...messages.noItem} />.</p>
                        </div>
                      )}
                      {
                        item?.item?.map((itemData, index) => {
                          let itemLength, itemPrice;
                          itemLength = item?.item?.length - 1;
                          itemPrice = this.calculateOverallAmount(itemData?.price, itemData?.tax);
                          return (
                            <Col lg={12} md={12} sm={12} xs={12} className={cx(s.noPadding, rs.spaceTop3)}>
                              <div className={cx(rs.menuFirstSection, s.noborderImg, s.itemBottomBorder, rs.submenuGrid, rs.subMenuGridWidth, s.noBgColor)}>
                                <div className={cx(rs.alignLeft, rs.tabSection, 'textAlignRightRTL')}>
                                  {itemData?.image ?
                                    <div
                                      className={cx(s.itemBgImg, (itemLength === index ? '' : s.itemBgImgBorder), (!itemData?.isEnable ? s.inactive : '', 'itemBgImgRTL'))}
                                      style={{ backgroundImage: `url(${api.apiEndpoint + itemImageUploadDir + itemData?.image})` }}
                                    />
                                    :
                                    <div
                                      className={cx(s.itemBgImg, (itemLength === index ? '' : s.itemBgImgBorder), (!itemData?.isEnable ? s.inactive : '', 'itemBgImgRTL'))}
                                      style={{ backgroundImage: `url(${DefaultImage})` }}
                                    />
                                  }
                                  <p className={cx(s.activeDaysText, s.itemListContent)}>
                                    <span className={s.itemNameSection}>{itemData?.itemName}</span>
                                    <span className={s.priceColor}>
                                      <CurrencyConverter from={profile.preferredCurrency} toCurrency={profile.preferredCurrency} amount={itemPrice} />
                                    </span>
                                    {
                                      itemData && this.isValueDefined(itemData?.tax) && <span className={cx(s.subText, 'itemSubRTL')}>(<FormattedMessage {...messages.includesTax} />)</span>
                                    }
                                  </p>
                                </div>
                                <div className={cx('storeActiveSelect', 'itemAddSelectRTL')}>
                                  <select value={itemData?.isEnable} onChange={(e) => { this.handleStatus(itemData?.id, e.target.value, currentPage) }} className={cx(rs.activeSelect, rs.storeSelectInput, s.itemBorderColor)}>
                                    <option value={true}>{formatMessage(messages.active)}</option>
                                    <option value={false}>{formatMessage(messages.inactive)}</option>
                                  </select>
                                </div>
                                <div className={rs.editCenterBtn}>
                                  <Link to={"/store/menu/" + item?.menuId + "/item/" + item?.id + "/edit/" + itemData?.id} className={cx(rs.linkColor, s.displayInlineBlock)} >
                                    <img src={EditIcon} className={cx(s.iconsWidth, 'iconsWidthStoreRTL')} />
                                    <span className={cx(rs.vtrMiddle, rs.linkFontSize)}>{formatMessage(messages.editItem)}</span>
                                  </Link>
                                </div>
                                <div className={rs.editCenterBtn}>
                                  <Button className={cx(rs.deleteBtn, s.editBtn, s.IconLeft, rs.textOverFlow)} onClick={() => this.handleDelete(itemData?.id, currentPage)}>
                                    <img src={TrashIcon} className={cx(s.iconsWidth, 'iconsWidthStoreRTL')} />
                                    <span className={cx(rs.vtrMiddle, rs.linkFontSize)}>{formatMessage(messages.deleteAction)}</span>
                                  </Button>
                                </div>
                                {itemData?.itemType != "0" && <div className={'textAlignRightRTL'}>
                                  <p className={cx(rs.noMargin, s.vegNonvegCss)}>
                                    <span><img src={itemData?.itemType == "1" ? VegIcon : NonVegIcon} className={cx(s.activeImg, "activeImgRtl")} alt='VegImage' /></span>
                                    <span className={cx(rs.vtrMiddle, rs.linkFontSize)}>
                                      {itemData?.itemType == "1" ? formatMessage(messages.itemType1) : formatMessage(messages.itemType2)}
                                    </span>
                                  </p>
                                </div>}
                              </div>
                            </Col>
                          )
                        })
                      }
                    </div>
                  </Col>
                </Row>
              )
            })
          }
          {
            itemDetails?.getAllSubMenu?.results?.length == 0 && (
              <span className={rs.notFoundText}><FormattedMessage {...messages.noSubMenu} />.</span>
            )
          }
          <div class="clearfix"></div>
          <div className={cx(rs.spaceTop5, rs.alignRightText, 'textAlignLeftRTL', s.backTopCss)}>
            <div className={cx(s.paddingTop2, s.displayInlineBlock)}>
              <Button className={cx(rs.button, rs.btnPrimaryBorder)} onClick={() => history.push('/store/menu')}>
                <FormattedMessage {...messages.back} />
              </Button>
            </div>
          </div>

          {
            itemDetails?.getAllSubMenu?.results?.length > 0
            && <div className={cx(s.space5, s.spaceTop5, 'store')}>
              <CustomPagination
                total={itemDetails?.getAllSubMenu?.count}
                currentPage={currentPage}
                defaultCurrent={1}
                defaultPageSize={10}
                change={this.paginationData}
                paginationLabel={formatMessage(messages.subMenus)}
              />
            </div>
          }
        </Container>
      </div >
    );
  }
}

const mapDispatch = {
  deleteItem,
  updateItemStatus,
  editItem,
  openSubMenuModal,
  updateSubMenuStatus,
  deleteSubMenu,
  editSubMenu,
  openModal,
  closeModal
};
const mapState = (state) => ({
  subMenuDeleteModal: state?.modalStatus?.subMenuDeleteModal,
  modalData: state?.modalStatus?.data && state?.modalStatus?.data[0],
  profile: state?.account?.data?.profile,
});

export default injectIntl(withStyles(s, rs)(connect(mapState, mapDispatch)(ItemList)));