import gql from "graphql-tag";

export const updateDeliverymutation = gql`
  mutation updateDeliveryStatus($id: Int!, $status: String) {
    updateDeliveryStatus(id: $id, status: $status) {
      status
      errorMessage
      successMessage
    }
  }
`;
export const updateOrdermutation = gql`
  mutation updateOrderStatus($id: Int!, $bookingStatus: String!, $notes: String, $orderModal: Boolean) {
    updateOrderStatus(id: $id, bookingStatus: $bookingStatus, notes: $notes, orderModal: $orderModal) {
      status
      errorMessage
    }
  }
 `;

export const getPendingOrdersQuery = gql`
 query {
    getPendingOrders {
      count
      results {
        id
        orderId
        userName
        orderItemsCount
        bookingStatus
        shopPreparationTime
        second_difference
      }
      status
      errorMessage
    }
  }
 `
export const getOrderDetailsQuery = gql`
query getOrders($id: Int!) {
    getOrders(id: $id) {
      count
      result {
        id
        orderId
        userId
        shopId
        deliveryPartnerId
        bookingStatus
        dropOffLocation
        shopPreparationTime
        assignDeliveryAt
        assignDeliveryTime
        confirmationCode
        deliveryInstruction
        isDoorStepDelivery
        userDetails {
          firstName
          picture
          phoneNumber
        }
        deliveryPartnerDetails {
          firstName
          picture
          phoneNumber
        }
        subTotal
        shopServiceFare
        shopTotalFare
        currency
        orderItemsCount
        orderNotes
        orderItemDetails {
          quantity
          itemName
          price
          total
          modifierTotal
          orderModifierGroup {
            id
            orderId
            modifierName
            total
            currency
            orderModifierItem {
              id
              orderId
              modifierGroupId
              modifierItemName
              modifierItemPrice
              currency
              total
              quantity
            }
          }
        }
      }
      status
      errorMessage
    }
  }
`