// General
import React, { Component } from 'react';
import PropTypes from 'prop-types';

// Redux form
import { Field, reduxForm } from 'redux-form';

// Intl
import { FormattedMessage, injectIntl } from 'react-intl';

// Style
import withStyles from 'isomorphic-style-loader/withStyles';
import cx from 'classnames';

import { Button, FormGroup, FormControl } from 'react-bootstrap';

// Internal Helpers
import validate from './validate';
import submit from './submit';
import messages from '../../../../locale/messages';

import s from './ForgotPasswordForm.css';
import bt from '../../../storeCommon.css';

class ForgotPasswordForm extends Component {
  static propTypes = {
    openLoginModal: PropTypes.func.isRequired,
  };

  renderFormControl = ({
    input,
    label,
    type,
    meta: { touched, error },
    className,
  }) => {
    const { formatMessage } = this.props.intl;
    return (
      <div>
        <label>{label}</label>
        <FormControl
          {...input}
          placeholder={label}
          type={type}
          className={className}
        />
        {touched && error && (
          <span className={bt.errorMessage}>{formatMessage(error)}</span>
        )}
      </div>
    );
  };

  render() {
    const { handleSubmit, submitting, dispatch } = this.props;
    const { formatMessage } = this.props.intl;
    const { openLoginModal } = this.props;
    return (
      <div className={s.container}>
        <form onSubmit={handleSubmit(submit)}>
          <FormGroup className={s.formGroup}>
            <p>
              <FormattedMessage {...messages.forgotPasswordInfo} />
            </p>
          </FormGroup>
          <FormGroup className={s.formGroup}>
            <Field
              name="email"
              label={formatMessage(messages.email)}
              component={this.renderFormControl}
              placeholder={formatMessage(messages.email)}
              className={cx(
                bt.formControlInputStore,
                s.meassageicon,
                'formControlInputStoreRTL',
                'meassageIconRTL',
              )}
            />
          </FormGroup>
          <div className={cx(s.formGroup, s.formSection)}>
            <div className={s.mobileColumn}>
              <a
                href="#"
                onClick={openLoginModal}
                className={cx(
                  s.modalCaptionLink,
                  s.modalCaptionLinkLarge,
                  s.displayTableCell,
                )}
              >
                <FormattedMessage {...messages.backToLogin} />
              </a>
              <Button
                className={cx(bt.btnPrimaryBorder, s.btn)}
                type="submit"
                disabled={submitting}
              >
                <FormattedMessage {...messages.sendLink} />
              </Button>
            </div>
          </div>
        </form>
      </div>
    );
  }
}

ForgotPasswordForm = reduxForm({
  form: 'ForgotPasswordForm', // a unique name for this form
  validate,
  destroyOnUnmount: true,
})(ForgotPasswordForm);

export default withStyles(s, bt)(injectIntl(ForgotPasswordForm));
