import React from 'react';
import { connect } from 'react-redux';
import { FormattedMessage, injectIntl } from 'react-intl';
import { Field, reduxForm, formValueSelector, FieldArray } from 'redux-form';
import { change } from 'redux-form';
import withStyles from 'isomorphic-style-loader/withStyles';
import cx from 'classnames';
import rs from '../../../storeCommon.css';
import Form from 'react-bootstrap/Form';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Container from 'react-bootstrap/Container';
import Button from 'react-bootstrap/Button';

import Loader from '../../../Common/Loader/Loader';
import ImageUploadComponent from '../../../Common/ImageUploadComponent/ImageUploadComponent';
import Link from '../../../Link/Link';

import validate from './validate';
import submit from './submit';
import messages from '../../../../locale/messages';
import { api, storeDocumentUploadDir } from '../../../../config';

import closeIcon from '../../../../../public/StoreIcon/closeIconRes.png';

import s from './DocumentForm.css';
class DocumentForm extends React.Component {

    static defaultProps = {
        loading: false
    }

    renderField = ({ input, label, type, meta: { touched, error }, labelClass, fieldClass, placeholder }) => {
        return (
            <Form.Group>
                <Form.Label>{label}</Form.Label>
                <Form.Control {...input} placeholder={placeholder} type={type} className={fieldClass} />
                {touched && error && <span className={cx(s.errorMessage, 'errorMessageRTL')}>{error.defaultMessage}</span>}
            </Form.Group>
        )
    };

    renderDocument = ({ fields, meta: { touched, error } }) => {
        const { imageLoader } = this.props;
        const { formatMessage } = this.props.intl;

        return (
            <div>
                {fields && fields.map((document, index) => {
                    let documentName = fields.get(index) && fields.get(index).fileName
                    let fieldLength = fields.length - 1;
                    return (
                        <div className={s.displayInlineBock}>
                            <div>
                                <Col lg={12} md={12} sm={12} xs={12} className={rs.space2}>
                                    <Form.Group className={s.formGroup}>
                                        <div className={cx(rs.profileImgSection)}>
                                            <ImageUploadComponent
                                                className={cx(s.btnSecondary, s.profileNoPadding)}
                                                subTextClass={s.subText}
                                                subText={formatMessage(messages.maximumUploadSizeLabel)}
                                                defaultMessage={formatMessage(messages.chooseFile)}
                                                loaderName={`documentLoader${index}`}
                                                postUrl={api.apiEndpoint + '/uploadStoreDocument'}
                                                loader={imageLoader[`documentLoader${index}`]}
                                                fieldName={`${document}.fileName`}
                                                formName={'DocumentForm'}
                                                imageSrc={documentName ? api.apiEndpoint + storeDocumentUploadDir + documentName : null}
                                                label={formatMessage(messages.documentImage)}
                                                documentDefault={true}
                                            />
                                        </div>
                                    </Form.Group>
                                </Col>
                                <Col lg={12} md={12} sm={12} xs={12} className={rs.space1}>
                                    <Form.Group className={s.formGroup}>
                                        <div>
                                            <Field
                                                name={`${document}.fileType`}
                                                type="text"
                                                placeholder={formatMessage(messages.documentName)}
                                                component={this.renderField}
                                                fieldClass={cx(rs.formControlInputStore, 'formControlInputStoreRTL')}
                                                label={formatMessage(messages.documentName)}
                                            />
                                        </div>
                                    </Form.Group>
                                </Col>
                                {index != 0 && <div className={s.removeSection}>
                                    <Button
                                        variant="primary"
                                        onClick={() => fields.remove(index)}
                                        className={s.removeBtnSection}
                                    >
                                        <img src={closeIcon} />
                                    </Button>
                                </div>}
                            </div>
                        </div>
                    )
                })}
            </div>
        )
    }

    render() {
        const { loading, handleSubmit, submitting, initialValues } = this.props;
        const { formatMessage } = this.props.intl;
        let documentExist = initialValues?.documentList?.length == '0' ? false : true;

        return (
            <div className={cx(s.space5, s.spaceTop5)}>
                <Container fluid>
                    <Row>
                        <Col xl={10} lg={12} md={12} sm={12} xs={12} className={s.blackCenter}>
                            <div className={s.blackCenterSection}>
                                <Col lg={12} md={12} sm={12} xs={12}>
                                    <h1 className={cx(s.titleTextAdmin, 'textAlignRightRTL')}>{formatMessage(messages.storeDocuments)}</h1>
                                </Col>
                                <Form className={s.fullWidth} onSubmit={handleSubmit(submit)}>
                                    <Col lg={12} md={12} sm={12} xs={12}>
                                        <Row>
                                            <Col lg={12} md={12} sm={12} xs={12} className={s.noPadding}>
                                                <FieldArray
                                                    name="documentList"
                                                    rerenderOnEveryChange={true}
                                                    component={this.renderDocument}
                                                />
                                            </Col>
                                            {!documentExist && <Col lg={12} md={12} sm={12} xs={12} className={cx(rs.space4, 'textAlignRightRTL')}>
                                                <span><FormattedMessage {...messages.noDocument} /></span>
                                            </Col>}
                                            <Col lg={12} md={12} sm={12} xs={12} className={rs.alignRightText}>
                                                <Form.Group className={s.space2}>
                                                    <Link to={"/siteadmin/store/list"} className={cx(s.btnSecondary, s.linkBtnInline)}>{formatMessage(messages.goBack)}</Link>
                                                    {documentExist && <div className={s.displayInlineBlock}>
                                                        <Loader
                                                            type={"button"}
                                                            label={formatMessage(messages.update)}
                                                            show={loading}
                                                            buttonType={'submit'}
                                                            className={cx(s.button, s.btnPrimary, s.backBtn, 'backBtnBorderRTL')}
                                                            disabled={submitting || loading}
                                                            isSuffix={true}
                                                        />
                                                    </div>}
                                                </Form.Group>
                                            </Col>
                                        </Row>
                                    </Col>
                                </Form>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>
        )
    }
}

DocumentForm = reduxForm({
    form: 'DocumentForm',
    onSubmit: submit,
    validate,
    enableReinitialize: true
})(DocumentForm);

const selector = formValueSelector('DocumentForm');

const mapState = (state) => ({
    documentList: selector(state, 'documentList'),
    imageLoader: state.loader
});

const mapDispatch = {
    change
};

export default injectIntl(withStyles(s, rs)(connect(mapState, mapDispatch)(DocumentForm)));