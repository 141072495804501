import {
  ADD_UPDATE_DELIVERY_VEHICLE_START,
  ADD_UPDATE_DELIVERY_VEHICLE_SUCCESS,
  ADD_UPDATE_DELIVERY_VEHICLE_ERROR
} from '../../../constants';
import history from '../../../history';
import { updateDeliveryVehicle as mutation } from '../../../lib/graphql';
import { setLoaderStart, setLoaderComplete } from '../../../actions/loader/loader';
import showErrorMessage from '../../../helpers/showErrorMessage';
import showToaster from '../../../helpers/toasterMessages/showToaster';

export const addUpdateDeliveryVehicle = (id, vehicleName, vehicleImage, description, isActive) => {

  return async (dispatch, getState, { client }) => {
    try {

      let successMessage, variables;

      dispatch({
        type: ADD_UPDATE_DELIVERY_VEHICLE_START,
        payload: {
          pricingLoading: true
        }
      });

      dispatch(setLoaderStart('DeliveryVehicleForm'));

      variables = {
        id,
        vehicleName,
        vehicleImage,
        description,
        isActive: (isActive == true || isActive == 'true') ? 1 : 0
      };

      const { data } = await client.mutate({
        mutation,
        variables
      });

      const response = data?.updateDeliveryVehicle

      successMessage = id ? 'updateVehicleType' : 'addVehicleType'

      if (response?.status === 200) {
        history.push('/siteadmin/vehicle-type/list');

        showToaster({ messageId: successMessage, toasterType: 'success' })
        await dispatch({
          type: ADD_UPDATE_DELIVERY_VEHICLE_SUCCESS,
          payload: {
            pricingLoading: false
          }
        });
        dispatch(setLoaderComplete('DeliveryVehicleForm'));

      } else {
        showToaster({ messageId: 'commonToasterError', toasterType: 'error', requestMessage: response?.errorMessage })
        
        await dispatch({
          type: ADD_UPDATE_DELIVERY_VEHICLE_ERROR,
          payload: {
            pricingLoading: false,
            error: response?.errorMessage
          }
        });
        dispatch(setLoaderComplete('DeliveryVehicleForm'));
      }

    } catch (error) { 
      showToaster({ messageId: 'deleteAdminError', toasterType: 'error', requestMessage: error });
      await dispatch({
        type: ADD_UPDATE_DELIVERY_VEHICLE_ERROR,
        payload: {
          pricingLoading: false,
          error: showErrorMessage({ errorCode: 'catchError', error })
        }
      });
      dispatch(setLoaderComplete('DeliveryVehicleForm'));

    }
  }
}