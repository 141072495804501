import {
    ADMIN_DELETE_CONTENTPAGE_START,
    ADMIN_DELETE_CONTENTPAGE_SUCCESS,
    ADMIN_DELETE_CONTENTPAGE_ERROR
} from '../../constants';
import history from '../../history';
import showToaster from '../../helpers/toasterMessages/showToaster';
import { getContentPageQuery, deleteContentPage, updateContentPage } from '../../lib/graphql/commonSiteAdmin';

const deleteContentPageDetails = (id) => {
    return async (dispatch, getState, { client }) => {
        try {

            dispatch({
                type: ADMIN_DELETE_CONTENTPAGE_START,
                data: id
            });

            const { data } = await client.mutate({
                mutation: deleteContentPage,
                variables: { id },
                refetchQueries: [{ query: getContentPageQuery }]
            });

            if (data?.deleteContentPage?.status == 200) {
                dispatch({
                    type: ADMIN_DELETE_CONTENTPAGE_SUCCESS
                });
                showToaster({ messageId: 'deleteContentPage', toasterType: 'success' })
                history.push('/siteadmin/contentpage/manage');
            } else {
                showToaster({ messageId: 'failedContentPage', toasterType: 'error' })
            }

        } catch (error) {
            dispatch({
                type: ADMIN_DELETE_CONTENTPAGE_ERROR,
                payload: {
                    error
                }
            });
        }
    }
}

const updateContentPageStatus = (id, isEnable) => {
    return async (dispatch, getState, { client }) => {
        try {
            const { data } = await client.mutate({
                mutation: updateContentPage,
                variables: { id, isEnable },
                refetchQueries: [{ query: getContentPageQuery }]
            });

            if (data?.updateContentPageStatus?.status == 200) {
                showToaster({ messageId: 'updateContentPageStatus', toasterType: 'success' })
            } else {
                showToaster({ messageId: 'commonToasterError', toasterType: 'error', requestMessage: data?.updateContentPageStatus?.errorMessage })
            }
        } catch (error) {
            showToaster({ messageId: 'changeStatusFailed', toasterType: 'error' })
        }
    }
}

export { updateContentPageStatus, deleteContentPageDetails }
