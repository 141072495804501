import {
    SITE_SETTINGS_ERROR,
    SITE_SETTINGS_START,
    SITE_SETTINGS_SUCCESS
} from '../../../constants';
import { getSiteSettings as query } from '../../../lib/graphql';

export const siteSettings = () => {

    return async (dispatch, getState, { client }) => {
        try {
            dispatch({
                type: SITE_SETTINGS_START
            })

            const type = "site_settings";
            const { data } = await client.query({
                query,
                variables: { type },
                fetchPolicy: 'network-only'
            })

            let settingsData = {};

            if (data?.getSiteSettings?.results?.length > 0) {

                data?.getSiteSettings?.results?.map((item, key) => {
                    settingsData[item.name] = item.value;
                });
                dispatch({
                    type: SITE_SETTINGS_SUCCESS,
                    data: settingsData
                })
            } else {
                dispatch({
                    type: SITE_SETTINGS_ERROR
                });
            }
        } catch (error) {
            dispatch({
                type: SITE_SETTINGS_ERROR,
            });
        }
    }
}