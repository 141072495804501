
import React from 'react';
import DashboardContainer from './DashboardContainer';
import DashboardLayout from '../../../components/Shop/StoreLayout/Layout/DashboardLayout';
import messages from '../../../locale/messages';

function action({ store, intl }) {
  const title = intl.formatMessage(messages.dashboard);

  let isStoreAuthenticated = store.getState().runtime.isStoreAuthenticated;

  if (!isStoreAuthenticated) {
    return { redirect: '/store' };
  };

  return {
    title,
    component: (
      <DashboardLayout>
        <DashboardContainer title={title} />
      </DashboardLayout>
    ),
  };
}

export default action;
