import React from 'react';
import { graphql } from 'react-apollo';
import { FormattedMessage } from 'react-intl';
import { flowRight as compose } from 'lodash';

import withStyles from 'isomorphic-style-loader/withStyles';
import s from './Store.css';
import cx from 'classnames';
import StoresList from '../../../../components/SiteAdmin/ManageShop/StoresList'
import Loader from '../../../../components/Common/Loader/Loader';

import getAllShops from './getAllShops.graphql';

//meassage
import messages from '../../../../locale/messages';

class Store extends React.Component {

  static defaultProps = {
    storeDetails: {
      loading: true
    }
  }

  render() {
    const { storeDetails, storeDetails: { loading } } = this.props;

    return (
      <Loader type={"page"} show={loading}>
        <div className={s.root}>
          <div className={s.container}>
            <div className={s.paddingRoutesSection}>
              <div className={cx(s.heading, 'textAlignRightRTL')}>
                <FormattedMessage {...messages.manageStoresHeading} />
              </div>
              {!loading && <StoresList storeDetails={storeDetails} />}
            </div>
          </div>
        </div>
      </Loader>
    );
  }
}

export default compose(
  withStyles(s),
  graphql(getAllShops, {
    name: 'storeDetails',
    options: {
      ssr: true,
      fetchPolicy: 'network-only',
      variables: {
        currentPage: 1,
        searchList: ''
      }
    }
  })
)(Store);
