import showToaster from '../../../helpers/toasterMessages/showToaster';
import { closeForgotPasswordModal } from '../../modalActions';
import { sendForgotPasswordLink as mutation } from '../../../lib/graphql';

export const sendForgotPasswordLink = (email) => {

    return async (dispatch, getState, { client }) => {

        try {
            dispatch(closeForgotPasswordModal());

            const { data } = await client.mutate({
                mutation,
                variables: { email }
            });

            if (data?.sendForgotPasswordLink?.status === 200) {
                showToaster({ messageId: 'resetEmailLink', toasterType: 'success' });
                return true;
            } else {
                showToaster({ messageId: 'commonToasterError', toasterType: 'error', requestMessage: data.sendForgotPasswordLink.errorMessage });
                return false;
            }

        } catch (error) {
            showToaster({ messageId: 'invalidError', toasterType: 'error', requestMessage: error });
            return false;
        }
    };
}