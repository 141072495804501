import React from 'react';
import withStyles from 'isomorphic-style-loader/withStyles';
import { Table } from 'react-bootstrap';
import s from './CategoryList.css';
import { FormattedMessage, injectIntl } from 'react-intl';
import cx from 'classnames';
import moment from 'moment';
//local
import messages from '../../../../locale/messages';
import CustomPagination from '../../../CustomPagination';
import PropTypes from 'prop-types';
import Link from '../../../Link';
import history from '../../../../history';
import { Button, FormControl, ButtonToolbar } from 'react-bootstrap';
import { connect } from 'react-redux';

//Images
import EditIcon from '../../../../../public/Icons/edit.png';
import TrashIcon from '../../../../../public/Icons/bin.svg';
import addIcon from '../../../../../public/Icons/addNewIcon.svg';

import updateCategoryStatus from '../../../../actions/siteadmin/Category/updateCategoryStatus';
import deleteCategory from '../../../../actions/siteadmin/Category/deleteCategory';
import updateCategoryPrivilege from '../../../../actions/siteadmin/Category/updateCategoryPrivilege';

class CategoryList extends React.Component {
  static propTypes = {
    categoryDetails: PropTypes.object,
  };

  constructor(props) {
    super(props)

    this.state = {
      currentPage: 1,
      searchList: '',
      typing: false,
      typingTimeout: 0,
      isDisabled: 0
    }
    this.paginationData = this.paginationData.bind(this);
    this.handleClick = this.handleClick.bind(this)
    this.handleSearchChange = this.handleSearchChange.bind(this)
    this.handleSearchClick = this.handleSearchClick.bind(this)
    this.handleStatus = this.handleStatus.bind(this)
    this.handleDelete = this.handleDelete.bind(this)
    this.handleSpecialStatus = this.handleSpecialStatus.bind(this)
  }

  handleClick() {
    history.push('/add')
  }

  handleSearchClick(searchList) {
    const { categoryDetails: { refetch } } = this.props
    let variables = {
      currentPage: 1,
      searchList: searchList
    }
    this.setState({ currentPage: 1 })
    refetch(variables)
  }

  handleSearchChange(e) {
    let self = this
    if (self.state.typingTimeout) {
      clearTimeout(self.state.typingTimeout)
    }
    self.setState({
      searchList: e.target.value,
      typing: false,
      typingTimeout: setTimeout(function () {
        self.handleSearchClick(self.state.searchList)
      }, 450)
    })
  }

  async handleDelete(id) {
    const { deleteCategory, categoryDetails: { refetch } } = this.props;
    let variables = { currentPage: 1 };
    this.setState({
      isDisabled: 1
    })
    const response = await deleteCategory(id);

    if (response && response.status === 200) {
      this.setState({ currentPage: 1 });
      refetch(variables);
    }
    this.setState({
      isDisabled: 0
    })
  }

  paginationData(currentPage) {
    const { categoryDetails: { refetch } } = this.props;
    let variables = { currentPage };
    this.setState({ currentPage });
    refetch(variables);
  }

  async handleStatus(e, id, currentPage) {
    const { updateCategoryStatus, categoryDetails: { refetch } } = this.props;
    let status = e.target.value == 'true' ? '1' : '0';
    let variables = { currentPage };

    const response = await updateCategoryStatus(id, status);

    if (response && response.status === 200) {
      this.setState({ currentPage });
      refetch(variables);
    }
  }

  async handleSpecialStatus(e, id, privilegeType) {
    const { updateCategoryPrivilege, categoryDetails: { refetch } } = this.props;
    const { currentPage } = this.state;

    let status = e.target.value == 'true' ? true : false;
    let variables = { currentPage };

    const response = await updateCategoryPrivilege(id, status, privilegeType);

    if (response && response.status === 200) {
      this.setState({ currentPage });
      refetch(variables);
    }
  }




  render() {
    const { categoryDetails } = this.props;
    const { formatMessage } = this.props.intl;
    const { currentPage, isDisabled } = this.state;
    return (
      <div className={cx(s.widthInner, s.tableSection, 'whiteDropdown', 'widthInnerNoBorder')}>
        <div className={cx(s.padding5, 'adminSearchInputSection')}>
          <FormControl type='text' placeholder={formatMessage(messages.search)} onChange={(e) => this.handleSearchChange(e)}
            className={cx(s.formControlInput, s.searchInput)} />
          <Link to={"/siteadmin/category/add"} className={s.addlinkBtn}>{formatMessage(messages.addCategory)}  <span className={cx(s.addIconCss, 'addIconCssRTL')}><img src={addIcon} /></span></Link>
        </div>
        <div className={cx(s.tableCss, 'tableCss', 'tableSticky', 'NewAdminResponsiveTable')}>
          <Table className={cx("table")}>
            <thead>
              <tr>
                <th scope="col"><FormattedMessage {...messages.id} /></th>
                <th scope="col"><FormattedMessage {...messages.deliveryName} /></th>
                <th scope="col"><FormattedMessage {...messages.categoryName} /></th>
                <th scope="col"><FormattedMessage {...messages.status} /></th>
                <th scope="col"><FormattedMessage {...messages.isTop} /></th>
                <th scope="col"><FormattedMessage {...messages.isMore} /></th>
                <th scope="col"><FormattedMessage {...messages.createdAt} /></th>
                <th scope="col"><FormattedMessage {...messages.action} /></th>
              </tr>
            </thead>
            <tbody>
              {
                categoryDetails && categoryDetails.getAllCategory && categoryDetails.getAllCategory.categoryData && categoryDetails.getAllCategory.categoryData.length > 0 && categoryDetails.getAllCategory.categoryData.map((item, index) => {
                  return (
                    <tr key={index}>
                      <td data-label={formatMessage(messages.id)}>{item.id}</td>
                      <td data-label={formatMessage(messages.deliveryName)}>{item.deliveryName}</td>
                      <td data-label={formatMessage(messages.categoryName)}>{item.categoryName}</td>
                      <td data-label={formatMessage(messages.status)}>
                        <select value={item.isActive} onChange={(e) => { this.handleStatus(e, item.id, currentPage) }} className={s.selectInput}>
                          <option value={true}>{formatMessage(messages.active)}</option>
                          <option value={false}>{formatMessage(messages.inactive)}</option>
                        </select>
                      </td>
                      <td data-label={formatMessage(messages.isTop)}>
                        <select value={item.isTop} onChange={(e) => { this.handleSpecialStatus(e, item.id, 'isTop') }} className={s.selectInput}>
                          <option value={true}>{formatMessage(messages.yes)}</option>
                          <option value={false}>{formatMessage(messages.no)}</option>
                        </select>
                      </td>
                      <td data-label={formatMessage(messages.isMore)}>
                        <select value={item.isMore} onChange={(e) => { this.handleSpecialStatus(e, item.id, 'isMore') }} className={s.selectInput}>
                          <option value={true}>{formatMessage(messages.yes)}</option>
                          <option value={false}>{formatMessage(messages.no)}</option>
                        </select>
                      </td>
                      <td data-label={formatMessage(messages.createdAt)}>{moment(item.createdAt).format('DD-MM-YYYY hh:mm A')}</td>
                      <td data-label={formatMessage(messages.action)} className={s.minWidth}>
                        <Link to={'/siteadmin/category/edit/' + item.id} className={cx('editAlignIcon', s.editColorIcon)}>
                          <span><img src={EditIcon} className={cx(s.editIcon, 'editIconRTL')} /></span>
                          <span className={s.vtrMiddle}>
                            <FormattedMessage {...messages.editAction} />
                          </span>
                        </Link>
                        <Button onClick={() => this.handleDelete(item.id, currentPage)} className={cx(s.iconBtn, 'iconBtnRTL')} disabled={isDisabled}>
                          <img src={TrashIcon} className={cx(s.editIcon, 'editIconRTL')} />
                          <span className={s.vtrMiddle}>
                            <FormattedMessage {...messages.deleteAction} />
                          </span>
                        </Button>
                      </td>

                    </tr>
                  )
                })
              }
              {
                categoryDetails && categoryDetails.getAllCategory && categoryDetails.getAllCategory.categoryData.length == 0 && (
                  <tr>
                    <td colspan="12" className={s.noRecords}><FormattedMessage {...messages.noResult} /></td>
                  </tr>
                )
              }
            </tbody>
          </Table>
        </div>
        {
          categoryDetails && categoryDetails.getAllCategory && categoryDetails.getAllCategory.categoryData && categoryDetails.getAllCategory.categoryData.length > 0
          && <div className={cx(s.space5, s.spaceTop5)}>
            <CustomPagination
              total={categoryDetails.getAllCategory.count}
              currentPage={currentPage}
              defaultCurrent={1}
              defaultPageSize={10}
              change={this.paginationData}
              paginationLabel={formatMessage(messages.categories)}
            />
          </div>
        }
      </div>
    );
  }
}

const mapDispatch = {
  updateCategoryStatus,
  deleteCategory,
  updateCategoryPrivilege
};
const mapState = (state) => ({});

export default injectIntl(withStyles(s)(connect(mapState, mapDispatch)(CategoryList)));