import {
    ADD_CANCEL_REASON_START,
    ADD_CANCEL_REASON_SUCCESS,
    ADD_CANCEL_REASON_ERROR
} from '../../constants/index';
import history from '../../history';
import { setLoaderStart, setLoaderComplete } from '../../actions/loader/loader';
import showToaster from '../../helpers/toasterMessages/showToaster';
import { updateCancelReason as mutation } from '../../lib/graphql';

export const addCancelReason = (values) => {

    return async (dispatch, getState, { client }) => {
        try {

            dispatch({
                type: ADD_CANCEL_REASON_START
            });
            dispatch(setLoaderStart('AddCancellation'));

            const { data } = await client.mutate({
                mutation,
                variables: {
                    id: values?.id,
                    reason: values?.reason,
                    userType: values?.userType,
                    isActive: values?.isActive == '0' ? 0 : 1
                }
            })

            let status = data?.updateCancelReason?.status

            if (status && status == 200) {
                history.push('/siteadmin/cancel-reasons');

                showToaster({ messageId: 'updateCancelReason', toasterType: 'success', requestMessage: values?.id })

                dispatch(setLoaderComplete('AddCancellation'));
                await dispatch({
                    type: ADD_CANCEL_REASON_SUCCESS,
                });

            } else {
                dispatch(setLoaderComplete('AddCancellation'));
                showToaster({ messageId: 'commonToasterError', toasterType: 'error', requestMessage: errorMessage })

                await dispatch({
                    type: ADD_CANCEL_REASON_ERROR,
                });
            }
        } catch (error) {
            showToaster({ messageId: 'commonToasterError', toasterType: 'error', requestMessage: error })
            dispatch({ type: ADD_CANCEL_REASON_ERROR })
        }
    }
}