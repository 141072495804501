import {
    USER_DELETE_SUCCESS,
    USER_DELETE_START,
    USER_DELETE_ERROR
} from '../../constants/index';
import showToaster from '../../helpers/toasterMessages/showToaster';
import { deleteUserMutation as mutation } from '../../lib/graphql';

const deleteUser = (id, userType) => {

    return async (dispatch, getState, { client }) => {
        try {
            dispatch({
                type: USER_DELETE_START,
                payload: {
                    deleteLoading: true
                }
            });

            const { data } = await client.mutate({
                mutation,
                variables: {
                    id
                }
            });

            if (data?.deleteUser?.status === 200) {
                dispatch({
                    type: USER_DELETE_SUCCESS,
                    payload: {
                        deleteLoading: false
                    }
                });
                showToaster({ messageId: 'deleteUser', toasterType: 'success', requestMessage: userType })
                return {
                    status: data?.deleteUser?.status
                }
            } else {
                dispatch({
                    type: USER_DELETE_ERROR,
                    payload: {
                        deleteLoading: false
                    }
                });
                showToaster({ messageId: 'commonToasterError', toasterType: 'error', requestMessage: data?.deleteUser?.errorMessage })
                return {
                    status: 400
                }
            }
        } catch (err) {
            showToaster({ messageId: 'commonToasterError', toasterType: 'error', requestMessage: err })
            dispatch({
                type: USER_DELETE_ERROR,
                payload: {
                    deleteLoading: false
                }
            });
            return {
                status: 400
            }
        }
    }
};

export default deleteUser;