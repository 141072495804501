import axios from 'axios';
import {
    ADMIN_USER_LOGOUT_START,
    ADMIN_USER_LOGOUT_SUCCESS,
    ADMIN_USER_LOGOUT_ERROR,
} from '../../constants';

export const adminLogout = () => {
    return async (dispatch, getState, { client }) => {

        try {
            dispatch({
                type: ADMIN_USER_LOGOUT_START
            });

            await axios.post("/adminLogout", null, {
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                withCredentials: true
            })

            dispatch({
                type: ADMIN_USER_LOGOUT_SUCCESS
            });
        } catch (error) {
            dispatch({
                type: ADMIN_USER_LOGOUT_ERROR,
                payload: {
                    error
                }
            });
            return false;
        }
        return true;
    }
}