import {
	SHOP_MODIFIER_START,
	SHOP_MODIFIER_SUCCESS,
	SHOP_MODIFIER_ERROR
} from '../../../constants/index';
import { getAllShopModifierQuery } from '../../../lib/graphql';

export const getAllShopModifier = () => {
	return async (dispatch, getState, { client }) => {
		
		try {             
			dispatch({
				type: SHOP_MODIFIER_START,
			});
			
			const { data: { getAllShopModifier } } = await client.query({
				query: getAllShopModifierQuery,
				fetchPolicy: 'network-only'
			});

			if (getAllShopModifier?.status === 200) {
				dispatch({
					type: SHOP_MODIFIER_SUCCESS,
					payload: {
						modifier: getAllShopModifier.results
					}
				});

			} else {
				dispatch({
					type: SHOP_MODIFIER_ERROR,
				});
			}

		} catch (error) {
			dispatch({
				type: SHOP_MODIFIER_ERROR,
			});
		}
	}
}