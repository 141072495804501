import { setLoaderStart, setLoaderComplete } from '../../loader/loader';
import history from '../../../history';
import showToaster from '../../../helpers/toasterMessages/showToaster';
import { getAllSubMenuQuery, updateItemMutation } from '../../../lib/graphql';
import { loadAccount } from '../userAccount/userAccount';

const addItem = (values) => {

	return async (dispatch, getState, { client }) => {

		try {
			let successMessage;
			dispatch(setLoaderStart('CommonSettings'));

			const { data: { updateItem } } = await client.mutate({
				mutation: updateItemMutation,
				variables: values,
				refetchQueries: [{ query: getAllSubMenuQuery, variables: { currentPage: 1, searchList: '', menuId: values.menuId } }]
			});

			successMessage = values?.id ? "updateMenu" : "addMenu"

			if (updateItem?.status == 200) {
				showToaster({ messageId: successMessage, toasterType: 'success' })
				history.push('/store/menu/' + values.menuId + '/item');
			} else {
				showToaster({ messageId: 'commonToasterError', toasterType: 'error', requestMessage: updateItem?.errorMessage })
			}

			dispatch(setLoaderComplete('CommonSettings'));
			dispatch(loadAccount());
			return true;
			
		} catch (error) {
			dispatch(setLoaderComplete('CommonSettings'));
			return false;
		}
	}
}

export default addItem;