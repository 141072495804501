import { api } from "../config";
import axios from "axios";

export const removeImage = async (url, fileName) => {
    try {
        const response = await axios.post(`${api.apiEndpoint}${url}`, { fileName }, {
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
            },
        })
        const { status } = response
        if (status) {
            return {
                status
            };
        }
    } catch (error) {
        console.log(error);
    }
}