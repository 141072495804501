exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ":root {\n  /*\n   * Typography\n   * ======================================================================== */\n\n  --font-family-base: 'Comfortaa', cursive;\n\n  /*\n   * Layout\n   * ======================================================================== */\n\n  --max-content-width: 1170px;\n\n  /*\n   * Media queries breakpoints\n   * ======================================================================== */\n\n  --screen-xs-min: 480px;\n  /* Extra small screen / phone */\n  --screen-sm-min: 768px;\n  /* Small screen / tablet */\n  --screen-md-min: 992px;\n  /* Medium screen / desktop */\n  --screen-lg-min: 1200px;\n  /* Large screen / wide desktop */\n\n  --btn-primary-bg: #FFB400;\n  --btn-boxShadow-bg: #FFB400;\n  --btn-bordercolor-bg: #FFB400;\n  --btn-text-color: #fff;\n  --btn-primary-color: #ffffff;\n  --common-color: #1C0303;\n  --btn-secondary-bg: #FFB400;\n  --title-color: #212121;\n  --content-color: #212121;\n  --chat-background-color: #F6F6F6;\n  --border-color: #CED4DA;\n\n}\n/** RentAll - Begin **/\n._3Vqxt {\n\tmax-width: 1024px;\n\tmargin: 54px auto;\n\twidth: 100%;\n\tpadding: 10px;\n}\n.m4o2M {\n\tpadding: 0px;\n\tmargin: 30px 15px;\n}\n._2jh6D{\n\ttext-align: center;\n\twidth:100%;\n\tmargin-top: 5px;\n}\n._3TBPz{\n\tfont-size: 22px;\n\tfont-weight: 600;\n\tmargin: 0;\n}\n@media screen and (max-width: 767px) {\n\t._3Vqxt {\n\t\tmargin: 0px auto;\n\t}\n}", ""]);

// exports
exports.locals = {
	"logInModalContainer": "_3Vqxt",
	"logInModalBody": "m4o2M",
	"textAlignCenter": "_2jh6D",
	"headerH2": "_3TBPz"
};