import gql from "graphql-tag";

export const verifyOtp = gql`
mutation verifyOtpCode($otp: Int!) {
    verifyOtpCode(otp: $otp) {
      status
      errorMessage
    }
  }                
`;
export const updateNumber = gql`
mutation updatePhoneNumber($phoneNumber: String!, $phoneDialCode: String!) {
    updatePhoneNumber(phoneNumber: $phoneNumber, phoneDialCode: $phoneDialCode) {
      status
      errorMessage
    }
  }         
`;

export const updateShopProfile = gql`
mutation ($shopName: String, $firstName: String, $lastName: String, $email: String, $phoneDialCode: String, $phoneNumber: String, $phoneCountryCode: String, $address: String, $lat: Float, $lng: Float, $city: String, $state: String, $zipcode: String, $country: String, $categoryType: String, $description: String, $priceRangeId: Int, $picture: String, $preferredCurrency: String, $shopEstimatedTime: Int, $isDoorStepDelivery: Boolean, $isTakeAway: Boolean) {
  updateShopProfile(shopName: $shopName, firstName: $firstName, lastName: $lastName, email: $email, phoneDialCode: $phoneDialCode, phoneNumber: $phoneNumber, phoneCountryCode: $phoneCountryCode, address: $address, lat: $lat, lng: $lng, city: $city, state: $state, zipcode: $zipcode, country: $country, categoryType: $categoryType, description: $description, priceRangeId: $priceRangeId, picture: $picture, preferredCurrency: $preferredCurrency, shopEstimatedTime: $shopEstimatedTime, isDoorStepDelivery: $isDoorStepDelivery, isTakeAway: $isTakeAway) {
    status
    errorMessage
  }
}`

export const updateOperationHours = gql`mutation ($operationHours: String){
    updatePartnerOperatingHours(operationHours: $operationHours){
        status
        errorMessage
    }
}`;
export const getOperationHours = gql`query getOperationHours {
    getOperationHours{
        results{
            id
            shopId
            operationDay,
            isOpen,
            isAllHours
            startTime,
            endTime
        }
    }
}`;

export const uploadShopDocument = gql`
mutation ($documentList: String) {
    uploadShopDocument(documentList: $documentList) {
      status
      errorMessage
    }
  }  
`;


export const shopUserActivity = gql`
mutation ($isActive: Boolean) {
    shopUserActivityChange (isActive: $isActive) {
        status
        errorMessage
    }
  }
`;
export const updatePrepareTime = gql`
mutation (
  $shopEstimatedTime: Int,
  $preparationTime: String
) {
  updatePreparationTime (
    shopEstimatedTime: $shopEstimatedTime,
    preparationTime: $preparationTime
  ) {
      status
      errorMessage                     
  }
}`;

export const createShopUser = gql`
mutation(
    $shopName: String!
    $firstName: String!
    $lastName: String!
    $email: String!
    $password: String!
    $phoneDialCode: String!
    $phoneNumber: String!
    $phoneCountryCode: String!
    $address: String!
    $lat: Float!
    $lng: Float!
    $city: String
    $state: String
    $zipcode: String
    $country: String
    $categoryType: Int!
    $deliveryType: Int!
    $deviceId: String
  ) {
    createShopUser(
      shopName: $shopName
      firstName: $firstName
      lastName: $lastName
      email: $email
      password: $password
      phoneDialCode: $phoneDialCode
      phoneNumber: $phoneNumber
      phoneCountryCode: $phoneCountryCode
      address: $address
      lat: $lat
      lng: $lng
      city: $city
      state: $state
      zipcode: $zipcode
      country: $country
      categoryType: $categoryType
      deliveryType: $deliveryType
      deviceId: $deviceId
    ) {
      status
      errorMessage
      result {
        id
        email
      }
    }
  }
`
export const deleteShopMutation = gql`
mutation deleteShop($id: String!) {
    deleteShop(id: $id) {
      status
      errorMessage
    }
  }
`;

export const updateShopMutation = gql`
mutation ($id: ID, $shopName: String, $firstName: String, $lastName: String, $email: String, $phoneDialCode: String, $phoneNumber: String, $phoneCountryCode: String, $address: String, $lat: Float, $lng: Float, $city: String, $state: String, $zipcode: String, $country: String, $categoryType: [Int], $description: String, $priceRangeId: Int, $picture: String, $preferredCurrency: String, $shopEstimatedTime: Int, $password: String, $userStatus: String, $isBan: Int, $isDoorStepDelivery: Boolean, $isTakeAway: Boolean) {
  updateShop(id: $id, shopName: $shopName, firstName: $firstName, lastName: $lastName, email: $email, phoneDialCode: $phoneDialCode, phoneNumber: $phoneNumber, phoneCountryCode: $phoneCountryCode, address: $address, lat: $lat, lng: $lng, city: $city, state: $state, zipcode: $zipcode, country: $country, categoryType: $categoryType, description: $description, priceRangeId: $priceRangeId, picture: $picture, preferredCurrency: $preferredCurrency, shopEstimatedTime: $shopEstimatedTime, password: $password, userStatus: $userStatus, isBan: $isBan, isDoorStepDelivery: $isDoorStepDelivery, isTakeAway: $isTakeAway) {
    status
    errorMessage
  }
}`

export const updateShopStatusMutation = gql`
mutation updateShopStatus($id: ID, $updateField: String, $status: String, $userType: Int) {
    updateShopStatus(id: $id, updateField:$updateField, status:$status, userType: $userType) {
      status
      errorMessage
    }
  }
`;

export const uploadDocumentMutation = gql`
mutation ($documentList: String) {
  uploadDocument(documentList: $documentList) {
    status
    errorMessage
  }
}
`;