import React from 'react';
import withStyles from 'isomorphic-style-loader/withStyles';

import { graphql } from 'react-apollo';
import { flowRight as compose } from 'lodash';
import getDeliveryVehicleQuery from './getDeliveryVehicleQuery.graphql';
import cx from 'classnames';
import s from './EditDeliveryVehicle.css';
import DeliveryVehicleForm from '../../../../components/SiteAdmin/DeliveryVehicle/DeliveryVehicleForm';
//meassage
import { FormattedMessage } from 'react-intl';
import messages from '../../../../locale/messages';

class EditDeliveryVehicle extends React.Component {
  static defaultProps = {
    deliveryVehicle: {
      loading: true
    }
  };

  render() {
    const { title, id, deliveryVehicle: { getDeliveryVehicle, loading } } = this.props;

    let initialValues = {};

    if (!loading && getDeliveryVehicle) {
      initialValues = getDeliveryVehicle && getDeliveryVehicle.result;
    }

    return (
      <div className={s.root}>
        <div className={s.container}>
          <div className={s.paddingRoutesSection}>
            <div className={cx(s.heading, 'textAlignRightRTL', s.contentPageMargin)}>
              <FormattedMessage {...messages.editDeliveryVehicle} />
            </div>
            {
              !loading && <DeliveryVehicleForm initialValues={initialValues} id={id} />
            }
          </div>
        </div>
      </div>
    );
  }
}

export default compose(
  withStyles(s),
  graphql(getDeliveryVehicleQuery, {
    name: 'deliveryVehicle',
    options: (props) => ({
      ssr: false,
      fetchPolicy: 'network-only',
      variables: {
        id: props.id
      }
    })
  }),
)(EditDeliveryVehicle);
