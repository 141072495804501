import React, { Component } from 'react';
import { Field, reduxForm, formValueSelector } from 'redux-form';
import { connect } from 'react-redux';
import withStyles from 'isomorphic-style-loader/withStyles';
import { injectIntl, FormattedMessage } from 'react-intl';
import cx from 'classnames';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Container from 'react-bootstrap/Container';
import FormControl from 'react-bootstrap/FormControl';
import FormGroup from 'react-bootstrap/FormGroup';

import Link from '../../Link';
import ImageUploadComponent from '../../Common/ImageUploadComponent/ImageUploadComponent';

import validate from './validate';
import submit from './submit';
import { deleteStaticPageImage } from '../../../actions/siteadmin/deleteStaticPageImage';
import { updateStaticPageImage } from '../../../actions/siteadmin/deleteStaticPageImage';
import messages from '../../../locale/messages';
import { api, staticpageUploadDir } from '../../../config';

import s from './StaticPageEditForm.css';
export class StaticPageEditForm extends Component {

    constructor(props) {
        super(props)
        if (typeof window !== 'undefined') {
            this.ReactQuill = require('react-quill')
        }
        this.state = { editorHtml: '' } // You can also pass a Quill Delta here
    }

    renderField = ({ input, label, type, meta: { touched, error }, labelClass, fieldClass, placeholder }) => {
        return (
            <Form.Group className={cx(s.space5, 'positionRelative')}>
                <Form.Label>{label}</Form.Label>
                <Form.Control {...input} placeholder={placeholder} type={type} className={cx(fieldClass, s.formControlInput)} />
                {touched && error && <span className={cx(s.errorMessage, 'errorMessageRTL')}>{error.defaultMessage}</span>}
            </Form.Group>
        )
    }

    renderFormControl = ({ input, label, type, meta: { touched, error }, className, maxlength }) => {
        const { formatMessage } = this.props.intl;
        return (
            <FormGroup className={cx(s.space5, 'positionRelative')}>
                <label>{label}</label>
                <FormControl {...input} placeholder={label} type={type} className={cx(className, s.formControlInput)} maxlength={maxlength} />
                {touched && error && <span className={cx(s.errorMessage, 'errorMessageRTL')}>{formatMessage(error)}</span>}
            </FormGroup>
        );
    }

    renderFormControlTextArea = ({ input, label, meta: { touched, error }, children, className }) => {
        const { formatMessage } = this.props.intl;
        return (
            <FormGroup className={cx(s.space5, 'positionRelative')}>
                <label>{label}</label>
                <FormControl
                    {...input}
                    className={className}
                    placeholder={label}
                    as="textarea"
                    rows="4"
                >
                    {children}
                </FormControl>
                {touched && error && <span className={cx(s.errorMessage, 'errorMessageRTL')}>{formatMessage(error)}</span>}
            </FormGroup>
        );
    }

    renderQuill = ({ input, label, type, meta: { touched, error }, className }) => {
        const ReactQuill = this.ReactQuill;
        const { formatMessage } = this.props.intl;
        let modules = {
            toolbar: [
                [{ 'header': '1' }, { 'header': '2' }],
                [{ size: [] }],
                ['bold', 'italic', 'underline', 'strike', 'blockquote'],
                [{ 'list': 'ordered' }, { 'list': 'bullet' },
                { 'indent': '-1' }, { 'indent': '+1' }],
                ['link'],
                // ['link', 'image'],
            ],
            clipboard: {
                matchVisual: false,
            }
        };

        let formats = [
            'header', 'size',
            'bold', 'italic', 'underline', 'strike', 'blockquote',
            'list', 'bullet', 'indent',
            'link'
            // 'link', 'image'
        ];
        return (
            <div>
                <ReactQuill
                    {...input}
                    onChange={(newValue, delta, source) => {
                        if (source === 'user') {
                            input.onChange(newValue);
                        }
                    }}
                    onBlur={(range, source, quill) => {
                        if (quill.getHTML() == '<p><br></p>') {
                            input.onBlur('');
                        }
                        else {
                            input.onBlur(quill.getHTML());
                        }
                    }}
                    modules={modules}
                    formats={formats}
                    theme="snow"
                />

                {touched && error && <span className={cx(s.errorMessage, 'errorMessageRTL')}>{formatMessage(error)}</span>}
            </div>
        );
    }

    handleDelete = async () => {
        const { change, deleteStaticPageImage, id, pageBanner } = this.props;
        await deleteStaticPageImage(id, pageBanner);
        await change('pageBanner', null);
    }

    handleDropzone = async (fileName) => {
        const { change, updateStaticPageImage, id, pageBanner } = this.props;
        if (id) {
            await updateStaticPageImage(id, fileName, pageBanner);
        }
        await change("pageBanner", fileName);
    }

    render() {
        const { error, handleSubmit, submitting, pageBanner, id, imageLoader } = this.props;
        const { formatMessage } = this.props.intl;
        const ReactQuill = this.ReactQuill;

        if (typeof window !== 'undefined' && ReactQuill) {
            return (
                <Container fluid>
                    <Form className={s.fullWidth} onSubmit={handleSubmit(submit)}>
                        <div className={cx(s.heading, 'textAlignRightRTL')}>
                            <FormattedMessage {...messages.editPageDetails} />
                        </div>
                        <Row>
                            <Col lg={12} md={12} sm={12} xs={12} className={s.blackCenter}>
                                <div className={s.blackCenterSection}>
                                    <Row>
                                        <Col lg={6} md={12} sm={12} xs={12} className={s.paddingTop}>
                                            <Form.Group className={s.space3}>
                                                <div className={cx(s.profileImgSection)}>
                                                    <ImageUploadComponent
                                                        className={cx(s.btnSecondary, s.profileNoPadding)}
                                                        subTextClass={s.subText}
                                                        subText={formatMessage(messages.maximumUploadSizeLabel)}
                                                        defaultMessage={formatMessage(messages.chooseFile)}
                                                        loaderName={'staticPageImageLoader'}
                                                        postUrl={api.apiEndpoint + '/uploadStaticBannerImage'}
                                                        loader={imageLoader}
                                                        fieldName={'pageBanner'}
                                                        formName={'StaticPageEditForm'}
                                                        imageSrc={pageBanner ? api.apiEndpoint + staticpageUploadDir + 'large_' + pageBanner : null}
                                                        label={formatMessage(messages.pageBanner)}
                                                        handleDropzone={this.handleDropzone}
                                                        handleDelete={this.handleDelete}
                                                        isRemove={pageBanner}
                                                    />
                                                </div>
                                            </Form.Group>
                                        </Col>
                                        <Col lg={6} md={12} sm={12} xs={12}>
                                            <Field
                                                name="metaTitle"
                                                type="text"
                                                placeholder={formatMessage(messages.metaTitle)}
                                                component={this.renderFormControl}
                                                label={formatMessage(messages.metaTitle)}
                                                labelClass={s.labelText}
                                                fieldClass={s.formControlInput}
                                            />
                                            <Field
                                                name="metaDescription"
                                                type="text"
                                                placeholder={formatMessage(messages.metaDescriptionText)}
                                                component={this.renderFormControlTextArea}
                                                label={formatMessage(messages.metaDescriptionText)}
                                                labelClass={s.labelText}
                                                fieldClass={s.formControlInput}
                                            />

                                        </Col>
                                    </Row>
                                    <FormGroup className={cx(s.space5, 'positionRelative', s.spaceTop5)}>
                                        <label><FormattedMessage {...messages.content} /></label>
                                        <Field name="content" component={this.renderQuill} />
                                    </FormGroup>
                                    <div className={cx(s.textAlignRight, 'textAlignLeftRTL')}>
                                        <Form.Group className={s.noMargin}>
                                            <Link to={"/siteadmin/staticpage/manage"} className={cx(s.backBtnTwo, s.btnSecondaryBorder, s.linkBtnInline, 'backBtnTwoRTL')} >{formatMessage(messages.goBack)}</Link>
                                            <Button
                                                type="submit"
                                                className={cx(s.button, s.btnPrimary)}
                                            >
                                                {formatMessage(messages.submitButton)}
                                            </Button>
                                        </Form.Group>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </Form>
                </Container>
            )
        } else {
            return (
                <div></div>
            )
        }

    }
}

StaticPageEditForm = reduxForm({
    form: 'StaticPageEditForm',
    onSubmit: submit,
    validate
})(StaticPageEditForm);

const selector = formValueSelector('StaticPageEditForm')

const mapState = (state) => ({
    pageBanner: selector(state, 'pageBanner'),
    id: selector(state, 'id'),
    imageLoader: state.loader.staticPageImageLoader
})

const mapDispatch = {
    deleteStaticPageImage,
    updateStaticPageImage
}

export default injectIntl(withStyles(s)(connect(mapState, mapDispatch)(StaticPageEditForm)));