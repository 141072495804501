import React from 'react';
import withStyles from 'isomorphic-style-loader/withStyles';
import s from './PayoutContainer.css';
import Payout from '../../../../components/Shop/Payout/Payout';

class PayoutContainer extends React.Component {

  render() {
    const { currentAccountId } = this.props;
    return (
      <div>
        <div>
          <Payout />
        </div>
      </div>
    );
  }

}

export default withStyles(s)(PayoutContainer);
