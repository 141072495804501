import React, { Component } from 'react'
import withStyles from 'isomorphic-style-loader/withStyles';
import { Table, FormControl } from 'react-bootstrap';
import { FormattedMessage, injectIntl } from 'react-intl';
import cx from 'classnames';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import messages from '../../../locale/messages';
import { updateCurrencyStatus, setBaseCurrency, allowPaymentCurrency } from '../../../actions/siteadmin/Currency/updateCurrency';

import CustomPagination from '../../CustomPagination';
import s from './CurrencyList.css';
export class CurrencyList extends Component {

    static propTypes = {
        CurrencyData: PropTypes.object,
        updateCurrencyStatus: PropTypes.any.isRequired,
        setBaseCurrency: PropTypes.any.isRequired,
        managePaymentCurrency: PropTypes.any.isRequired,
    }

    constructor(props) {
        super(props)

        this.state = {
            currentPage: 1,
        }
    }

    handleSearchClick = (searchList) => {
        const { CurrencyData: { refetch } } = this.props
        let variables = {
            currentPage: 1,
            searchList: searchList
        }
        this.setState({ currentPage: 1 })
        refetch(variables)
    }

    handleSearchChange = (e) => {
        let self = this
        if (self.state.typingTimeout) {
            clearTimeout(self.state.typingTimeout)
        }
        self.setState({
            searchList: e.target.value,
            typing: false,
            typingTimeout: setTimeout(function () {
                self.handleSearchClick(self.state.searchList)
            }, 450)
        })
    }

    handleUpdateStatus = async (id, status, symbol) => {
        const { updateCurrencyStatus } = this.props;
        const { currentPage } = this.state;
        updateCurrencyStatus(id, status, symbol, currentPage);

    }

    handleBaseCurrency = async (id) => {
        const { setBaseCurrency, CurrencyData: { refetch } } = this.props;
        await setBaseCurrency(id);
        await refetch()
    }

    managePaymentCurrency = async (id, isPayment) => {
        const { allowPaymentCurrency, CurrencyData: { refetch } } = this.props;
        await allowPaymentCurrency(id, isPayment);
        await refetch();
    }

    paginationData = (currentPage) => {
        const { CurrencyData: { refetch } } = this.props;
        let variables = { currentPage };
        this.setState({ currentPage });
        refetch(variables);
    }

    render() {
        const { CurrencyData, CurrencyData: { getCurrency } } = this.props;
        const { currentPage } = this.state;
        const { formatMessage } = this.props.intl;

        return (
            <div className={cx(s.widthInner, s.tableSection, 'widthInnerNoBorder')}>
                <div className={cx(s.exportDisplay, s.tabelExport)}>
                    <div className={'textAlignRightRTL'}>
                        <div className={s.searchInput}>
                            <FormControl type='text' placeholder={formatMessage(messages.search)} onChange={(e) => this.handleSearchChange(e)} className={s.formControlInput} />
                        </div>
                    </div>
                </div>
                <div className={cx(s.tableCss, 'tableCss', 'tableSticky', 'NewAdminResponsiveTable')}>
                    <Table className={cx("table")}>
                        <thead>
                            <tr>
                                <th scope="col"><FormattedMessage {...messages.id} /></th>
                                <th scope="col"><FormattedMessage {...messages.currency} /></th>
                                <th scope="col"><FormattedMessage {...messages.currencyType} /></th>
                                <th scope="col"><FormattedMessage {...messages.status} /></th>
                                <th scope="col"><FormattedMessage {...messages.enableDisable} /></th>
                                <th scope="col"><FormattedMessage {...messages.setBaseCurrency} /></th>
                                <th scope="col"><FormattedMessage {...messages.allowedPaymentCurrency} /></th>
                            </tr>
                        </thead>
                        <tbody>
                            {

                                CurrencyData && CurrencyData.getCurrency && CurrencyData.getCurrency.currencyList && CurrencyData.getCurrency.currencyList.length > 0 && CurrencyData.getCurrency.currencyList.map((item, index) => {

                                    return (

                                        <tr key={index}>
                                            <td data-label={formatMessage(messages.id)}>{item.id}</td>
                                            <td data-label={formatMessage(messages.currency)}>{item.symbol}</td>
                                            {item.isBaseCurrency == 1 && <td data-label={formatMessage(messages.currencyType)}>{formatMessage(messages.baseCurrency)}</td>}
                                            {item.isBaseCurrency == 0 && <td data-label={formatMessage(messages.currencyType)}></td>}
                                            {item.isEnable && <td data-label={formatMessage(messages.status)}>{formatMessage(messages.enabled)}</td>}
                                            {!item.isEnable && <td data-label={formatMessage(messages.status)}>{formatMessage(messages.disabled)}</td>}
                                            <td data-label={formatMessage(messages.enableDisable)}> <a href="javascript:void(0)" onClick={() => this.handleUpdateStatus(item.id, item.isEnable, item.symbol)} >
                                                {
                                                    item.isEnable && <span> {formatMessage(messages.disable)} </span>
                                                }

                                                {
                                                    !item.isEnable && <span> {formatMessage(messages.enable)} </span>
                                                }
                                            </a>
                                            </td>
                                            <td data-label={formatMessage(messages.setBaseCurrency)}>
                                                <span>
                                                    {
                                                        !item.isBaseCurrency && item.isEnable && <a href="javascript:void(0)" onClick={() => this.handleBaseCurrency(item.id)}>
                                                            {formatMessage(messages.setAsBaseCurrency)}
                                                        </a>
                                                    }
                                                </span>
                                            </td>
                                            <td data-label={formatMessage(messages.allowedPaymentCurrency)}>
                                                <span>
                                                    {
                                                        <a href="javascript:void(0)" onClick={() => this.managePaymentCurrency(item.id, item.isPayment)}>
                                                            {item.isPayment ? formatMessage(messages.remove) : formatMessage(messages.add)}
                                                        </a>
                                                    }
                                                </span>
                                            </td>
                                        </tr>
                                    )
                                })
                            }
                            {
                                ((CurrencyData && CurrencyData.getCurrency && CurrencyData.getCurrency.currencyList.length == 0)) && (
                                    <tr>
                                        <td colspan="12" className={s.noRecords}><FormattedMessage {...messages.noResult} /></td>
                                    </tr>
                                )
                            }
                        </tbody>
                    </Table>
                </div>
                {
                    CurrencyData && CurrencyData.getCurrency && CurrencyData.getCurrency.currencyList && CurrencyData.getCurrency.currencyList.length > 0
                    && <div className={cx(s.space5, s.spaceTop5)}>
                        <CustomPagination
                            total={CurrencyData.getCurrency.count}
                            currentPage={currentPage}
                            defaultCurrent={1}
                            defaultPageSize={10}
                            change={this.paginationData}
                            paginationLabel={formatMessage(messages.currenciesLabel)}
                        />
                    </div>
                }
            </div >
        )
    }
}


const mapState = (state) => ({});

const mapDispatch = {
    updateCurrencyStatus,
    allowPaymentCurrency,
    setBaseCurrency
};

export default injectIntl(withStyles(s)(connect(mapState, mapDispatch)(CurrencyList)));