import gql from "graphql-tag";

export const deleteDeliveryType = gql`
mutation deleteDeliveryType($id: Int) {
    deleteDeliveryType(id: $id) {
      status
      errorMessage
    }
  }
`
export const updateDeliveryTypeStatus = gql`
mutation updateDeliveryTypeStatus($id: Int, $status: Boolean) {
    updateDeliveryTypeStatus(id: $id, status: $status) {
      status
      errorMessage
    }
  }`

export const updateDeliveryType = gql`
  mutation updateDeliveryType($id: Int, $deliveryName: String, $deliveryDescription: String, $imageName: String, $bannerName: String, $status: Boolean, $textColor: String, $backgroundColor: String, $iconBackgroundColor: String) {
    updateDeliveryType(id: $id, deliveryName: $deliveryName, deliveryDescription: $deliveryDescription, imageName: $imageName, bannerName: $bannerName, status: $status, textColor: $textColor, backgroundColor: $backgroundColor, iconBackgroundColor: $iconBackgroundColor) {
      status
      errorMessage
    }
  }
  `
export const addDeliveryVehicle = gql`
mutation addDeliveryVehicle($vehicleName: String, $vehicleImage: String, $description: String, $isActive: Boolean) {
    addDeliveryVehicle(vehicleName: $vehicleName, vehicleImage: $vehicleImage, description: $description, isActive: $isActive) {
      status
      errorMessage
    }
  }  
`;
export const updateDeliveryVehicle = gql`
mutation ($id: Int, $vehicleName: String, $vehicleImage: String, $description: String, $isActive: Boolean) {
    updateDeliveryVehicle(id: $id, vehicleName: $vehicleName, vehicleImage: $vehicleImage, description: $description, isActive: $isActive) {
      status
      errorMessage
    }
  }
  
`;
export const deleteDeliveryVehicle = gql`
mutation($id: Int) {
    deleteDeliveryVehicle(id: $id) {
        status
        errorMessage
    }
}
`;

export const updateDeliveryVehicleStatus = gql`
mutation($id: Int, $isActive: Boolean) {
    updateDeliveryVehicleStatus(id: $id, isActive: $isActive) {
        status
        errorMessage
    }
}
`;

export const updateDeliveryVehicleImage = gql`
mutation($id: Int, $fileName: String) {
  updateDeliveryVehicleImage(id: $id, fileName: $fileName) {
      status
  }
}`;
