exports = module.exports = require("../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ":root {\n  /*\n   * Typography\n   * ======================================================================== */\n\n  --font-family-base: 'Comfortaa', cursive;\n\n  /*\n   * Layout\n   * ======================================================================== */\n\n  --max-content-width: 1170px;\n\n  /*\n   * Media queries breakpoints\n   * ======================================================================== */\n\n  --screen-xs-min: 480px;\n  /* Extra small screen / phone */\n  --screen-sm-min: 768px;\n  /* Small screen / tablet */\n  --screen-md-min: 992px;\n  /* Medium screen / desktop */\n  --screen-lg-min: 1200px;\n  /* Large screen / wide desktop */\n\n  --btn-primary-bg: #FFB400;\n  --btn-boxShadow-bg: #FFB400;\n  --btn-bordercolor-bg: #FFB400;\n  --btn-text-color: #fff;\n  --btn-primary-color: #ffffff;\n  --common-color: #1C0303;\n  --btn-secondary-bg: #FFB400;\n  --title-color: #212121;\n  --content-color: #212121;\n  --chat-background-color: #F6F6F6;\n  --border-color: #CED4DA;\n\n}\n\n._3Bm9a {\n\tborder-radius: 0;\n\twidth: 100%;\n\tdisplay: block;\n\tcolor: #39483A;\n\tfont-size: 18px;\n\tpadding-bottom: 12px;\n\tborder-bottom: 1px solid #dce0e0;\n}\n\n._1g4Iq {\n\tdisplay: block;\n\tpadding: 8px 10px !important;\n\twidth: 100%;\n}\n\n._1MteI {\n\ttext-align: right;\n}\n\n._1RGho {\n\tmargin-bottom: 6px;\n}\n\n._3c4Fn{\n\tfont-size: 22px;\n    text-align: center;\n    margin-bottom: 20px;\n}\n\n._2hcXF{\n\twidth: 100%;\n    max-width: 140px;\n}\n\n.UpK5J{\n\ttext-align: center;\n}\n\n@media screen and (max-width: 767px) {\n\t._1MteI {\n\t\ttext-align: left;\n\t}\n}\n\n._3A3Ty {\n\tmargin-bottom: 25px;\n}\n\n.Zx1og {\n\twidth: 96%;\n    margin: 0 2%;\n}\n\n._3dp4O {\n\tpadding-left: 20px;\n\tpadding-right: 20px;\n\twidth: 100%;\n}\n\n._3T981 {\n\tmargin: 50px auto;\n\tmax-width: 420px;\n\tborder: 1px solid #ccc;\n\tpadding: 20px;\n}", ""]);

// exports
exports.locals = {
	"panelHeader": "_3Bm9a",
	"formControlInput": "_1g4Iq",
	"textRight": "_1MteI",
	"formGroup": "_1RGho",
	"heading": "_3c4Fn",
	"saveBtnWidth": "_2hcXF",
	"textAlignCenter": "UpK5J",
	"marginBottom": "_3A3Ty",
	"btnWidth": "Zx1og",
	"root": "_3dp4O",
	"container": "_3T981"
};