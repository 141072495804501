import { initialize } from 'redux-form';
import { openSubMenuModal } from '../../../actions/siteadmin/modalActions';
import showToaster from '../../../helpers/toasterMessages/showToaster';
import { getSubMenuQuery } from '../../../lib/graphql';

const editItem = (id) => {
	
	return async (dispatch, getState, { client }) => {
		try {
			const { data } = await client.query({
				query: getSubMenuQuery,
				variables: {
					id
				},
				fetchPolicy: 'network-only'
			});

			if (data?.getSubMenu) {
				dispatch(initialize('AddSubMenuForm', data.getSubMenu));
				dispatch(openSubMenuModal());

			} else {
				showToaster({ messageId: 'commonToasterError', toasterType: 'error', requestMessage: data?.getSubMenu?.errorMessage })
			}
		} catch (err) {
			showToaster({ messageId: 'commonToasterError', toasterType: 'error', requestMessage: err })
		}
	}
};
export default editItem