import React from 'react';
import {
  Modal,
} from 'react-bootstrap';
import { closeSubMenuModal } from '../../../../../actions/siteadmin/modalActions';
import { connect } from 'react-redux';
import messages from '../../../../../locale/messages';
import AddSubMenuForm from '../AddSubMenuForm/AddSubMenuForm';
import { FormattedMessage, injectIntl } from 'react-intl';

class AddSubMenuModal extends React.Component {
  static defaultProps = {
    setShow: false
  }

  constructor(props) {
    super(props);
    this.state = {
      show: false,
      setShow: false
    };

  }

  componentDidMount() {
    const { isSubMenuModalOpen } = this.props;
    if (isSubMenuModalOpen === true) {
      this.setState({ setShow: true });
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { isSubMenuModalOpen } = nextProps;

    if (isSubMenuModalOpen === true) {
      this.setState({ setShow: true });
    } else {
      this.setState({ setShow: false });
    }
  }

  render() {
    const { closeSubMenuModal, menuId } = this.props;
    const { setShow } = this.state;
    let initialValues = {
      menuId: menuId
    }
    return (
      <div>
        <Modal show={setShow} onHide={closeSubMenuModal} className={'listItemModal'}>
          <Modal.Header closeButton>
            <FormattedMessage {...messages.subMenu} />
          </Modal.Header>
          <Modal.Body>
            <AddSubMenuForm initialValues={initialValues} />
          </Modal.Body>
        </Modal>
      </div>
    )
  }
}

const mapState = (state) => ({
  isSubMenuModalOpen: state.modalStatus.isSubMenuModalOpen,
  menuId: state.modalStatus.menuId,
});

const mapDispatch = {
  closeSubMenuModal,
};

export default injectIntl(connect(mapState, mapDispatch)(AddSubMenuModal));
