import {
    SHOP_DOCUMENT_UPDATE_ERROR,
    SHOP_DOCUMENT_UPDATE_START,
    SHOP_DOCUMENT_UPDATE_SUCCESS
} from '../../../constants';
import { setLoaderStart, setLoaderComplete } from '../../loader/loader';
import showToaster from '../../../helpers/toasterMessages/showToaster';
import { uploadShopDocument as mutation } from '../../../lib/graphql';

const uploadShopDocument = (documentList) => {
    return async (dispatch, getState, { client }) => {

        try {
            dispatch({
                type: SHOP_DOCUMENT_UPDATE_START
            });
            dispatch(setLoaderStart('StoreDocumentUpdate'));

            const { data } = await client.mutate({
                mutation,
                variables: { documentList }
            });

            if (data?.uploadShopDocument?.status == 200) {
                showToaster({ messageId: 'uploadShopDocument', toasterType: 'success' });
                await dispatch({
                    type: SHOP_DOCUMENT_UPDATE_SUCCESS
                });
                await dispatch(setLoaderComplete('StoreDocumentUpdate'));

            } else {
                showToaster({ messageId: 'commonToasterError', toasterType: 'error', requestMessage: data?.uploadShopDocument?.errorMessage });
                await dispatch({
                    type: SHOP_DOCUMENT_UPDATE_ERROR
                });
                dispatch(setLoaderComplete('StoreDocumentUpdate'));
            }
        } catch (error) {
            showToaster({ messageId: 'commonToasterError', toasterType: 'error', requestMessage: error })
            await dispatch({
                type: SHOP_DOCUMENT_UPDATE_ERROR
            });
            dispatch(setLoaderComplete('StoreDocumentUpdate'));
        }
    }
};

export default uploadShopDocument;