import { change } from 'redux-form';
import {
	MOBILE_SETTINGS_UPLOAD_START,
	MOBILE_SETTINGS_UPLOAD_ERROR,
	MOBILE_SETTINGS_UPLOAD_SUCCESS
} from '../../../constants/index';
import { mobileSettings, updateToneMutation } from '../../../lib/graphql';
import showToaster from '../../../helpers/toasterMessages/showToaster';
import { removeImage as removeTone } from '../../../helpers/removeImage';

const updateMobileSettings = (
	distanceUnits, appForceUpdate,
	userAndroidVersion, userIosVersion,
	driverAndroidVersion, driverIosVersion,
	preferredDelivery, requestTimeTone, isRequestTimerToneEnable,
	openAppOnRequest, requestToneFile, orderAcceptInterval) => {

	return async (dispatch, getState, { client }) => {

		try {

			dispatch({
				type: MOBILE_SETTINGS_UPLOAD_START
			})

			const { data } = await client.mutate({
				mutation: mobileSettings,
				variables: {
					distanceUnits,
					appForceUpdate,
					userAndroidVersion,
					userIosVersion,
					driverAndroidVersion,
					driverIosVersion,
					preferredDelivery,
					requestTimeTone,
					isRequestTimerToneEnable,
					openAppOnRequest,
					requestToneFile,
					orderAcceptInterval
				}
			})

			if (data?.updateMobileSettings?.status == 200) {
				dispatch({
					type: MOBILE_SETTINGS_UPLOAD_SUCCESS
				})
				showToaster({ messageId: 'updateMobileSettings', toasterType: 'success' })
			} else {
				dispatch({
					type: MOBILE_SETTINGS_UPLOAD_ERROR
				})
				showToaster({ messageId: 'failedError', toasterType: 'error', requestMessage: data?.updateMobileSettings?.errorMessage })
			}
		} catch (err) {
			dispatch({
				type: MOBILE_SETTINGS_UPLOAD_ERROR
			})
			showToaster({ messageId: 'failedError', toasterType: 'error', requestMessage: err })
		}

	}
}

const deleteTone = (fileName) => {
	return async (dispatch, getState, { client }) => {
		dispatch({
			type: MOBILE_SETTINGS_UPLOAD_START
		})

		try {

			dispatch(change("MobileSettingsForm", "requestTimeTone", null));

			let url = "/deleteTone"

			const response = removeTone(url, fileName)

			const { status } = await response

			if (status == 200) {
				await client.mutate({
					mutation: updateToneMutation
				})

				dispatch({
					type: MOBILE_SETTINGS_UPLOAD_SUCCESS
				})
			} else {
				dispatch({
					type: MOBILE_SETTINGS_UPLOAD_ERROR
				})
			}

		} catch (err) {
			dispatch({
				type: MOBILE_SETTINGS_UPLOAD_ERROR
			})
			showToaster({ messageId: 'catchMessage', requestContent: { content: err }, toasterType: 'error' });
			return true;
		}
	}
}

export { deleteTone, updateMobileSettings }