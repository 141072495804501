import {
    UPDATE_CONFIG_SETTINGS_START,
    UPDATE_CONFIG_SETTINGS_SUCCESS,
    UPDATE_CONFIG_SETTINGS_ERROR,
} from '../../../constants';
import { updateConfigSettings as mutation } from '../../../lib/graphql';
import { setLoaderStart, setLoaderComplete } from '../../loader/loader';
import { siteSettings } from '../SiteSettings/siteSettings'
import showToaster from '../../../helpers/toasterMessages/showToaster';

export const updateConfigSettings = (values) => {

    return async (dispatch, getState, { client }) => {
        try {

            dispatch({
                type: UPDATE_CONFIG_SETTINGS_START,
            });
            dispatch(setLoaderStart('configSettings'));

            const { data } = await client.mutate({
                mutation,
                variables: values,
            })
            dispatch(setLoaderComplete('configSettings'));

            if (data?.updateConfigSettings?.status == 200) {
                dispatch({ type: UPDATE_CONFIG_SETTINGS_SUCCESS });
                showToaster({ messageId: 'updateConfigSettings', toasterType: 'success' })
                await dispatch(siteSettings());
            }
            else {
                dispatch({ type: UPDATE_CONFIG_SETTINGS_ERROR });
                showToaster({ messageId: 'commonToasterError', toasterType: 'error', requestMessage: data?.updateConfigSettings?.errorMessage })
            }
        }
        catch (err) {
            dispatch({ type: UPDATE_CONFIG_SETTINGS_ERROR });
            showToaster({ messageId: 'deleteAdminError', toasterType: 'error', requestMessage: err })
        }
    }
}
