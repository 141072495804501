import { loadAccount } from '../userAccount/userAccount';
import showToaster from '../../../helpers/toasterMessages/showToaster';
import { getAllSubMenuQuery, deleteSubMenuMutation } from '../../../lib/graphql';

const deleteSubMenu = (id, currentPage, menuId) => {

    return async (dispatch, getState, { client }) => {
        try {
            const { data } = await client.mutate({
                mutation: deleteSubMenuMutation,
                variables: {
                    id
                },
                refetchQueries: [{ query: getAllSubMenuQuery, variables: { currentPage, searchList: '', menuId } }]
            });

            if (data?.deleteSubMenu?.status === 200) {
                showToaster({ messageId: 'deleteSubMenu', toasterType: 'success' })
                dispatch(loadAccount())
            } else {
                showToaster({ messageId: 'commonToasterError', toasterType: 'error', requestMessage: data?.deleteSubMenu?.errorMessage })
            }
        } catch (err) {
            showToaster({ messageId: 'commonToasterError', toasterType: 'error', requestMessage: err })

        }
    }
};
export default deleteSubMenu