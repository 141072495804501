import axios from 'axios';
import { pushNotificationMessage } from './pushNotificationMessage';
import { siteUrl } from '../../config';
import { getShortPushNotification } from './getShortPushNotification';

export async function sendNotifications(type, requestContent, userId, userType, lang) {
    let content = {};
    try {
        if (type !== 'notification') {
            const { title, message } = await pushNotificationMessage(type, requestContent, lang);
            content = requestContent;
            content['title'] = title;
            content['message'] = message;
        } else {
            content = requestContent;
        }

        const resp = await fetch(siteUrl + '/push-notification', {
            method: 'post',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                content,
                userId,
                userType
            }),
            credentials: 'include'
        });

        const { status, errorMessge } = await resp.json();

        return await {
            status,
            errorMessge
        };
    } catch (error) {
        return {
            status: 400,
            errorMessge: error
        }
    }
}

export default sendNotifications;
