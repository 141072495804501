import messages from '../../../../locale/messages';

const validate = values => {
    const errors = {};

    if (!values.menuName) {
        errors.menuName = messages.required;
    } else if (!isNaN(values.menuName)) {
        errors.menuName = messages.required
    } else if (values.menuName.trim() == "") {
        errors.menuName = messages.required;
    } else if (values.menuName.length > 100) {
        errors.menuName = messages.exceedLimit
    };



    return errors;
};

export default validate