import {
	SHOP_MODIFIER_UPDATE_START,
	SHOP_MODIFIER_UPDATE_SUCCESS,
	SHOP_MODIFIER_UPDATE_ERROR
} from '../../../constants/index';
import { getAllShopModifier } from './getAllShopModifier';
import { getAllModifierQuery, updateModifierStatusMutation } from '../../../lib/graphql';
import showToaster from '../../../helpers/toasterMessages/showToaster';

const updateModifierStatus = (id, status, currentPage, searchList) => {
	return async (dispatch, getState, { client }) => {
		
		try {

			dispatch({
				type: SHOP_MODIFIER_UPDATE_START,
				payload: {
					updateLoading: true
				}
			});
			
			const { data: { updateModifierStatus } } = await client.mutate({
				mutation: updateModifierStatusMutation,
				variables: {
					id,
					status: status == 'false' ? 0 : 1
				},
				refetchQueries: [{ query: getAllModifierQuery, variables: { currentPage, searchList } }]
			});
			if (updateModifierStatus?.status === 200) {
				dispatch({
					type: SHOP_MODIFIER_UPDATE_SUCCESS,
					payload: {
						updateLoading: false
					}
				});
				showToaster({ messageId: 'updateModifierStatus', toasterType: 'success' })
			} else {
				dispatch({
					type: SHOP_MODIFIER_UPDATE_ERROR,
					payload: {
						updateLoading: false
					}
				});
				showToaster({ messageId: 'commonToasterError', toasterType: 'error', requestMessage: updateModifierStatus?.errorMessage })
			}
			dispatch(getAllShopModifier());

		} catch (err) {
			showToaster({ messageId: 'commonToasterError', toasterType: 'error', requestMessage: err })
			dispatch({
				type: SHOP_MODIFIER_UPDATE_ERROR,
				payload: {
					updateLoading: false
				}
			});
		}
	}
};

export default updateModifierStatus
