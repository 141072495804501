import React from 'react';
import PropTypes from 'prop-types';
import { Field, reduxForm, formValueSelector, change, FieldArray } from 'redux-form';
import { injectIntl, FormattedMessage } from 'react-intl';
import messages from '../../../locale/messages';
import { connect } from 'react-redux';
import { generateTimes } from '../../../helpers/generateTimes';
import withStyles from 'isomorphic-style-loader/withStyles';
import cx from 'classnames';
import {
    Button,
    Row,
    Col,
    Form,
    FormGroup,
    Container
} from 'react-bootstrap';
import SwitchButton from './Switch';
import submit from './submit';
import s from './PartnerOperationHours.css';
import rs from '../../storeCommon.css';
import validate from './validate';

class PartnerOperationHours extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            isDisabled: false
        }
    }

    renderSwitch = ({ input, meta: { touched, error }, index, switchOnLabel, switchOffLabel }) => {
        const { formatMessage } = this.props.intl;
        input.value = input.value == '1' ? true : false;

        return (
            <div>
                <SwitchButton
                    {...input}
                    index={index}
                    defaultValue={input.value}
                    switchOnLabel={switchOnLabel}
                    switchOffLabel={switchOffLabel}
                />
            </div>
        )
    }

    async changeTimeSlot(index, type) {
        const { change } = this.props;
        if (type) {
            await change(`OperationHours[${index}].startTime`, 0);
            await change(`OperationHours[${index}].endTime`, 0.5);
        } else {

            await change(`OperationHours[${index}].startTime`, null);
            await change(`OperationHours[${index}].endTime`, null);
        }
    }

    renderSelectField = ({ input, label, type, meta: { touched, error }, labelClass, fieldClass, placeholder, children }) => {
        const { formatMessage } = this.props.intl;

        return (
            <FormGroup className={cx(rs.space5, 'positionRelative')}>
                <Form.Label>{label}</Form.Label>
                <Form.Control as="select" {...input} className={fieldClass} >
                    {children}
                </Form.Control>
                {touched && error && <span className={cx(s.errorMessage, s.errorMessageSection)}>{formatMessage(error)}</span>}
            </FormGroup>
        )
    }

    renderOperationHours = ({ meta: { error, submitFailed } }) => {
        const { operationHours, currentLocale } = this.props;
        let timeLookups = generateTimes(0, 1410);
        const { formatMessage } = this.props.intl;

        return (
            <div>
                {
                    operationHours && operationHours.length > 0 && operationHours.map((item, index) => {
                        return (
                            <tr className={cx(s.tableText)}>
                                <td className={cx(s.noBorder, s.tableText, 'textAlignRightRTL')}>
                                    <div className={s.daysText}>
                                        {formatMessage(messages[item.operationDay])}
                                    </div>
                                    <div className={cx(s.displayInlineblock, s.switchSection, s.vtrTop)}>
                                        <FormGroup >
                                            <Field
                                                name={`OperationHours[${index}].isOpen`}
                                                component={this.renderSwitch}
                                                index={index}
                                                switchOnLabel={formatMessage(messages.openLabel)}
                                                switchOffLabel={formatMessage(messages.closeLabel)}
                                            />
                                        </FormGroup>
                                    </div>
                                    {
                                        operationHours[index].isOpen == '1' && <div className={cx(s.displayInlineblock, s.radioBtnSection, s.vtrTop)}>
                                            <FormGroup className={cx(s.formGroup, s.displayInlineblock, s.radioBtnLabelSection)}>
                                                <div>
                                                    <label>
                                                        <span>
                                                            <Field
                                                                name={`OperationHours[${index}].isAllHours`}
                                                                component="input"
                                                                type="radio"
                                                                value='1'
                                                                onChange={(e) => this.changeTimeSlot(index, false)}
                                                            />
                                                        </span>
                                                        <span className={cx(s.paddingLeft, s.vtrMiddle, s.labelTitleText, rs.cursorPointer, 'hourPaddingRTL')}>
                                                            <FormattedMessage {...messages.allHours} />
                                                        </span>
                                                    </label>
                                                </div>
                                            </FormGroup>
                                            <FormGroup className={cx(s.formGroup, s.displayInlineblock, s.radioBtnLabelSection, s.vtrTop)}>
                                                <div>
                                                    <label>
                                                        <span>
                                                            <Field
                                                                name={`OperationHours[${index}].isAllHours`}
                                                                component="input"
                                                                type="radio"
                                                                value="0"
                                                                onChange={(e) => this.changeTimeSlot(index, true)}
                                                            />
                                                        </span>
                                                        <span className={cx(s.paddingLeft, s.vtrMiddle, s.labelTitleText, rs.cursorPointer, 'hourPaddingRTL')}>
                                                            <FormattedMessage {...messages.specificHours} />
                                                        </span>
                                                    </label>
                                                </div>
                                            </FormGroup>
                                            {
                                                operationHours[index].isAllHours == '0' && <div className={cx(s.timeSection, 'storeActiveSelect', 'floatLeftRTL')}>
                                                    <div className={cx(s.timeInline)}>
                                                        <Field
                                                            name={`OperationHours[${index}].startTime`}
                                                            component={this.renderSelectField}
                                                            label={formatMessage(messages.startTimeLabel)}
                                                            fieldClass={cx(rs.formControlInputStore, s.selectText, 'formControlInputStoreRTL')}
                                                        >
                                                            {
                                                                timeLookups && timeLookups.length > 0 && timeLookups.map((item, key) => {
                                                                    return (<option key={key} value={item.value}>{item.label}</option>)
                                                                })
                                                            }
                                                        </Field>
                                                    </div>
                                                    <div className={cx(s.timeInline)}>
                                                        <Field
                                                            name={`OperationHours[${index}].endTime`}
                                                            component={this.renderSelectField}
                                                            label={formatMessage(messages.endTimeLabel)}
                                                            fieldClass={cx(rs.formControlInputStore, s.selectText, 'formControlInputStoreRTL')}
                                                        >
                                                            {
                                                                timeLookups && timeLookups.length > 0 && timeLookups.map((item, key) => {
                                                                    return (<option key={key} value={item.value}>{item.label}</option>)
                                                                })
                                                            }
                                                        </Field>
                                                    </div>
                                                </div>
                                            }
                                        </div>
                                    }
                                </td>
                            </tr>
                        )
                    })
                }
            </div>
        )
    }

    render() {
        const { handleSubmit, submitting } = this.props;

        return (
            <div className={'mainContainer'}>
                <div>
                    <Container fluid>
                        <Row className={'customRatioButtonStore'}>
                            <Col lg={12} md={12} sm={12} xs={12} className={rs.noPadding}>
                                <Col lg={12} md={12} sm={12} xs={12} className={rs.spaceTop3}>
                                    <h1 className={cx(rs.storeTitleText, rs.space3, 'textAlignRightRTL')}>
                                        <FormattedMessage {...messages.opratingHours} />
                                    </h1>
                                </Col>
                                <Col lg={12} md={12} sm={12} xs={12}>
                                    <Form.Group className={s.formGroup}>
                                        <div className={s.landingMainContent}>
                                            <form onSubmit={handleSubmit(submit)}>
                                                <table className={cx('table')}>
                                                    <tbody>
                                                        <div className={cx(rs.storeNewBg, rs.space5)}>
                                                            <FieldArray
                                                                name='OperationHours'
                                                                component={this.renderOperationHours}
                                                            />
                                                        </div>
                                                    </tbody>
                                                </table>
                                                <Col lg={12} md={12} sm={12} xs={12}>
                                                    <div className={cx(rs.alignRightText, 'textAlignLeftRTL')}>
                                                        <FormGroup className={cx(s.formGroup, s.displayInlineblock)}>
                                                            <Button block type="submit" disabled={submitting} className={cx(rs.button, rs.btnPrimary)}>
                                                                <FormattedMessage {...messages.save} />
                                                            </Button>
                                                        </FormGroup>
                                                    </div>
                                                </Col>
                                            </form>
                                        </div>
                                    </Form.Group>
                                </Col>
                            </Col>
                        </Row>
                    </Container>
                </div>
            </div>
        )
    }
}

PartnerOperationHours = reduxForm({
    form: 'OperationHours',
    destroyOnUnmount: false,
    forceUnregisterOnUnmount: true,
    validate
})(PartnerOperationHours);

const selector = formValueSelector('OperationHours');

const mapState = (state) => ({
    operationHours: selector(state, 'OperationHours'),
    formErrors: state.form.OperationHours,
    currentLocale: state.intl.locale
})

const mapDispatch = {
    change
}

export default injectIntl(withStyles(s, rs)(connect(mapState, mapDispatch)(PartnerOperationHours)));