import {
    SHOP_ORDER_SUCCESS,
    SHOP_ORDER_ERROR,
    SHOP_ORDER_START,
} from '../constants';

export default function orderData(state = {}, action) {
    switch (action.type) {
        case SHOP_ORDER_START:
            return {
                ...state,
                orderLoading: action.payload.orderLoading
            }

        case SHOP_ORDER_ERROR:
            return {
                ...state,
                orderLoading: action.payload.orderLoading
            }

        case SHOP_ORDER_SUCCESS:
            return {
                ...state,
                orderLoading: action.payload.orderLoading,
                data: action.payload.data,
                count: action.payload.count,
            }
        default:
            return {
                ...state
            }
    }
}

