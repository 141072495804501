import React from 'react';
import { graphql } from 'react-apollo';
import { flowRight as compose } from 'lodash';
import s from './Item.css';
import withStyles from 'isomorphic-style-loader/withStyles';
import ItemList from '../../../components/Shop/ManageMenu/ItemList/ItemList'
import Loader from '../../../components/Common/Loader/Loader';
import getAllSubMenu from './getAllSubMenu.graphql';
class Item extends React.Component {

  static defaultProps = {
    itemDetails: {
      loading: true
    }
  }

  render() {
    const { title, itemDetails, itemDetails: { loading }, menuId } = this.props;

    return (
      <Loader type={"page"} show={loading}>
        <div>
          <div>
            <div>
              {!loading && <ItemList itemDetails={itemDetails} menuId={menuId} />}
            </div>
          </div>
        </div>
      </Loader>
    );
  }
}

export default compose(
  withStyles(s),
  graphql(getAllSubMenu, {
    name: 'itemDetails',
    options: (props) => ({
      ssr: true,
      fetchPolicy: 'network-only',
      variables: {
        currentPage: 1,
        searchList: '',
        menuId: props.menuId
      }
    })
  })
)(Item);
