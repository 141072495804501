import React from 'react';
import withStyles from 'isomorphic-style-loader/withStyles';
import { graphql } from 'react-apollo';
import { compose } from 'redux';
// style
import s from './Dashboard.css';
// graphql
import getDashboardCount from './getAdminDashboardData.graphql';
// components
import Loader from '../../../components/Common/Loader/Loader';
import AdminDashboard from '../../../components/SiteAdmin/AdminDashboard/AdminDashboard';

class Dashboard extends React.Component {
  static defaultProps = {
    dashboardData: {
      loading: true
    }
  };

  render() {
    const { dashboardData, dashboardData: { loading, getAdminDashboardData }, title } = this.props;
    
    return (
      <Loader type={"page"} show={loading}>
        <div className={s.root}>
          <div className={s.container}>
            {
              !loading && dashboardData && <AdminDashboard
                title={title} 
                orders={getAdminDashboardData && getAdminDashboardData.result && getAdminDashboardData.result.orders}
                earnings={getAdminDashboardData && getAdminDashboardData.result && getAdminDashboardData.result.earnings}
                users={getAdminDashboardData && getAdminDashboardData.result && getAdminDashboardData.result.users}
                drivers={getAdminDashboardData && getAdminDashboardData.result && getAdminDashboardData.result.drivers}
                shops={getAdminDashboardData && getAdminDashboardData.result && getAdminDashboardData.result.shops}
              />
            }
          </div>
        </div>
      </Loader>
    );
  }
}

export default compose(
  withStyles(s),
  graphql(getDashboardCount, {
    name: 'dashboardData',
    options: {
      ssr: true,
      fetchPolicy: 'network-only',
      variables: {
        requestDate: new Date()
      }
    }
  })
) (Dashboard);
