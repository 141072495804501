import gql from "graphql-tag";

export const getCurrency = gql`
query getCurrency($currentPage: Int, $searchList: String){
  getCurrency(currentPage: $currentPage, searchList:$searchList){
    count
    currencyList{
        id
        symbol
        isEnable
        isPayment
        isBaseCurrency
    }
  }
}`;


export const getCurrencyRatesQuery = gql`{
    getCurrencyRates {
        base
        rates
    }
  }
  `;

export const updateCurrency = gql`
  mutation updateCurrency ($id: Int, $isEnable: Boolean, $symbol: String){
    updateCurrency(id: $id, isEnable: $isEnable, symbol: $symbol){
          status
          errorMessage
      }
  }
`;
export const SetBaseCurrencyMutation = gql`
mutation setBaseCurrency($id: Int){
  setBaseCurrency(id: $id){
        status
    }
}
`;
export const paymentCurrencyMutation = gql`
mutation allowPaymentCurrency($id: Int,$isPayment: Int){
  allowPaymentCurrency(id: $id,isPayment: $isPayment){
        status
        errorMessage
    }
}
`;