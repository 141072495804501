const validatePrivilege = (requestId, permittedPrevileges) => {
    return permittedPrevileges && permittedPrevileges.length > 0 && permittedPrevileges.indexOf(requestId) >= 0;
}

const restrictUrls = (requestURL, permittedPrevileges, privileges) => {

    try {
        const findRequestedUrlId = privileges.find((o) => o && o.permittedUrls
            && o.permittedUrls.length > 0 && o.permittedUrls.indexOf(requestURL) >= 0);

        if (findRequestedUrlId) {
            let checkAccess = permittedPrevileges && permittedPrevileges.length > 0
                && permittedPrevileges.indexOf(findRequestedUrlId.id) >= 0;

            return checkAccess ? true : false

        } else {
            return false;
        }
    } catch (error) {
        return false;
    }
}

export { validatePrivilege, restrictUrls }