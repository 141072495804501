import React, { Component } from 'react'
import PropTypes from 'prop-types';
import { graphql } from 'react-apollo';
import { flowRight as compose } from 'lodash';
import withStyles from 'isomorphic-style-loader/withStyles';
import cx from 'classnames';
import s from './AdminRoles.css';
import adminRolesQuery from './adminRolesQuery.graphql';
import AdminRolesManagement from '../../../components/SiteAdmin/AdminRolesManagement/AdminRolesManagement';
import Loader from '../../../components/Common/Loader/Loader';
//meassage
import { FormattedMessage } from 'react-intl';
import messages from '../../../locale/messages';

export class AdminRoles extends Component {


  render() {
    const { adminRoles, adminRoles: { loading } } = this.props;

    return (
      <Loader type={"page"} show={loading}>
        <div className={s.root}>
          <div className={s.container}>
            <div className={s.paddingRoutesSection}>
              <div className={cx(s.heading, 'textAlignRightRTL')}>
                <FormattedMessage {...messages.manageAdminRoles} />
              </div>
              {
                !loading && <AdminRolesManagement adminRoles={adminRoles} />
              }
            </div>
          </div>
        </div>
      </Loader>
    );
  }

}

export default compose(
  withStyles(s),
  graphql(adminRolesQuery, {
    name: 'adminRoles',
    options: (props) => ({
      variables: {
        searchList: props.searchList,
        currentPage: 1,
      },
      fetchPolicy: 'network-only',
      ssr: true
    })
  }),
)(AdminRoles);