import {
	DELIVERY_TYPE_UPDATE_SUCCESS,
	DELIVERY_TYPE_UPDATE_START,
	DELIVERY_TYPE_UPDATE_ERROR
} from '../../../constants';
import { deleteDeliveryType as mutation } from '../../../lib/graphql';
import showToaster from '../../../helpers/toasterMessages/showToaster';

const deleteDeliveryType = (id) => {
	return async (dispatch, getState, { client }) => {

		try {

			dispatch({
				type: DELIVERY_TYPE_UPDATE_START
			});

			const { data } = await client.mutate({
				mutation,
				variables: {
					id
				}
			});

			if (data?.deleteDeliveryType?.status === 200) {
				dispatch({
					type: DELIVERY_TYPE_UPDATE_SUCCESS
				});

				showToaster({ messageId: 'deliveryTypeDelete', toasterType: 'success' })
				return {
					status: 200
				};
			} else {
				dispatch({
					type: DELIVERY_TYPE_UPDATE_ERROR
				});

				showToaster({ messageId: 'commonToasterError', toasterType: 'error', requestMessage: data?.deleteDeliveryType?.errorMessage })
				return {
					status: 400
				};
			}
		} catch (err) {
			showToaster({ messageId: 'commonToasterError', toasterType: 'error', requestMessage: err })
			dispatch({
				type: DELIVERY_TYPE_UPDATE_ERROR
			});
			return {
				status: 400
			};
		}
	}
};
export default deleteDeliveryType;