import React, { Component } from 'react';
import { Field, reduxForm, formValueSelector } from 'redux-form';
import { connect } from 'react-redux';
import withStyles from 'isomorphic-style-loader/withStyles';
import { injectIntl } from 'react-intl';
import cx from 'classnames';
import Form from 'react-bootstrap/Form';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Container from 'react-bootstrap/Container';
import FormControl from 'react-bootstrap/FormControl';
import FormGroup from 'react-bootstrap/FormGroup';
import Badge from 'react-bootstrap/Badge';
import { graphql } from 'react-apollo';
import { flowRight as compose } from 'lodash';

import Loader from '../../../Common/Loader/Loader';
import Link from '../../../Link/Link';
import PlacesSuggest from '../../../Shop/PlacesSuggest/PlacesSuggest';
import CountryList from '../../../Shop/CountryList/CountryList';
import CustomCheckbox from '../../../Common/CustomCheckbox/CustomCheckbox';
import ImageUploadComponent from '../../../Common/ImageUploadComponent/ImageUploadComponent';

import validate from './validate';
import submit from './submit';
import messages from '../../../../locale/messages';
import getCategoryCategories from '../../../Shop/EditProfileForm/getCategoryCategories.graphql';
import { api, storeImageUploadDir } from '../../../../config';

import rs from '../../../storeCommon.css';
import s from './EditStoreForm.css';
export class EditStoreForm extends Component {

	constructor(props) {
		super(props);
		this.state = {
			phoneCountryCode: '',
			phoneDialCode: '',
			defaultPreparationTime: 55
		};
	}

	static defaultProps = {
		loading: false
	};

	componentDidMount() {
		const { initialValues } = this.props;
		const { defaultPreparationTime } = this.state;
		let preparationTime = initialValues && initialValues.shopEstimatedTime ? initialValues.shopEstimatedTime : defaultPreparationTime;

		this.setState({
			phoneCountryCode: initialValues && initialValues.phoneCountryCode,
			phoneDialCode: initialValues && initialValues.phoneDialCode,
			defaultPreparationTime: preparationTime
		});
	}

	renderField = ({ input, label, type, meta: { touched, error }, labelClass, fieldClass, placeholder, disabled }) => {
		const { formatMessage } = this.props.intl
		return (
			<Form.Group className={cx(s.space5, 'positionRelative')}>
				<Form.Label>{label}</Form.Label>
				<Form.Control {...input} placeholder={placeholder} type={type} className={fieldClass} disabled={disabled} />
				{touched && error && <span className={cx(s.errorMessage, 'errorMessageRTL')}>{formatMessage(error)}</span>}
			</Form.Group>
		)
	};

	renderCheck = ({ input, label, options, checked, meta: { touched, error } }) => {
		const { formatMessage } = this.props.intl;
		return (
			<div>
				<p>{label}</p>
				<CustomCheckbox
					name={input.value}
					onChange={event => {
						input.onChange(event);
					}}
					checked={checked}
					value={true}
				/>
				{touched && error && <span className={cx(rs.errorMessage)}>{formatMessage(error)}</span>}
			</div>
		)
	}

	renderFieldPhoneNumber = ({ input, label, writeOnce, type, meta: { touched, error }, fieldClass, placeholder, disabled }) => {
		const { formatMessage } = this.props.intl
		return (
			<Form.Group className={s.noMargin}>
				<Form.Control {...input} readOnly={writeOnce} placeholder={placeholder} type={type} className={fieldClass} disabled={disabled} />
				{touched && error && <span className={cx(s.errorMessage, 'errorMessageRTL')}>{formatMessage(error)}</span>}
			</Form.Group>
		)
	};

	renderFormControlTextArea = ({ input, label, meta: { touched, error }, children, className }) => {
		const { formatMessage } = this.props.intl
		return (
			<FormGroup className={cx(s.space5, 'positionRelative')}>
				<label>{label}</label>
				<FormControl
					{...input}
					className={cx(s.inputNoRadius, className)}
					placeholder={label}
					as="textarea"
					rows="4"
				>
					{children}
				</FormControl>
				{touched && error && <span className={cx(s.errorMessage, 'errorMessageRTL')}>{formatMessage(error)}</span>}
			</FormGroup>
		);
	};

	checkboxGroup = ({ label, name, options, input, meta: { touched, error } }) => {
		const { formatMessage } = this.props.intl;
		return (
			<div className={'adminCheckbox'}>
				<ul className={s.listStyle}>
					{
						options && options.map((option, index) => {
							if (option.isActive) {
								return (
									<li className={cx(s.listSectionWidth, "listSectionWidthRtl")} key={index}>
										<span className={s.checkBoxSection}>
											<CustomCheckbox
												key={index}
												className={'icheckbox_square-green'}
												name={`${input.name}[${index}]`}
												value={option.id}
												checked={input.value.indexOf(option.id) !== -1}
												onChange={event => {
													const newValue = [...input.value]
													if (event === true) {
														newValue.push(option.id);
													} else {
														newValue.splice(newValue.indexOf(option.id), 1);
													}
													input.onChange(newValue);
												}
												}
											/>
										</span>
										<span className={cx(s.checkBoxLabel, 'checkBoxLabelRTL')}>
											{option.categoryName}
										</span>
									</li>
								)
							}
						})
					}
				</ul>
				{error && <div className={cx(s.errorMessage, 'errorMessageRTL')}>{formatMessage(error)}</div>}
			</div>
		);
	}

	renderFormControlSelect = ({ input, label, meta: { touched, error }, children, className }) => {
		const { formatMessage } = this.props.intl;
		return (
			<FormGroup className={cx(s.space5, 'positionRelative')}>
				<label>{label}</label>
				<FormControl as="select" {...input} className={className} >
					{children}
				</FormControl>
				{touched && error && <span className={cx(s.errorMessage, 'errorMessageRTL')}>{formatMessage(error)}</span>}
			</FormGroup>
		)
	}

	handleLocationData = (location) => {
		const { change } = this.props;
		let addressLabel = location?.label;
		let addressPosition = location?.location, country, state, city;

		location?.gmaps && location?.gmaps?.address_components?.map((item, key) => {
			if (item?.types[0] == "administrative_area_level_1") {
				state = item.long_name;
			} else if (item?.types[0] == "country") {
				country = item.long_name;
			} else if (item?.types[0] == "administrative_area_level_2" || item?.types[0] == "political") {
				city = item.long_name;
			}
		});

		change('address', addressLabel)
		change('city', city ? city : null);
		change('state', state ? state : null);
		change('country', country ? country : null);
		change('lat', addressPosition && addressPosition.lat);
		change('lng', addressPosition && addressPosition.lng);
	};

	handleCountryChange = (country) => {
		const { change } = this.props;

		this.setState({
			phoneCountryCode: country.countryCode,
			phoneDialCode: country.dialCode
		});

		change('phoneCountryCode', country.countryCode)
		change('phoneDialCode', country.dialCode)
	};

	handleDefaultPreparationTime = (action) => {
		const { defaultPreparationTime } = this.state;
		const { change } = this.props;

		let preparationTime = action == 'add' ? defaultPreparationTime + 5 : defaultPreparationTime - 5;

		if (preparationTime <= 55 && preparationTime >= 5) {
			this.setState({ defaultPreparationTime: preparationTime })
			change('shopEstimatedTime', preparationTime)
		}
	};

	checkboxHorizontalGroup = ({ label, input, checked, meta: { error } }) => {
		const { formatMessage } = this.props.intl;
		return (
			<>
				<li className={cx(s.listSectionWidth, "listSectionWidthRtl")}>
					<span className={s.checkBoxSection}>
						<CustomCheckbox
							className={'icheckbox_square-green'}
							onChange={event => {
								input.onChange(event);
							}}
							checked={checked}
							value={true}
						/>
					</span>
					<span className={cx(s.checkBoxLabel, 'checkBoxLabelRTL')}>{label}</span>
				</li>
				{error && <div className={cx(s.errorMessage, s.errorTop, 'errorMessageRTL')}>{formatMessage(error)}</div>}
			</>
		)
	};

	render() {
		const { handleSubmit, loading, submitting, initialValues, getCategory: { getCategoryDelivery }, getDietary, getShop, isDoorStepDelivery, isTakeAway, imageLoader, picture } = this.props;
		const { phoneCountryCode, phoneDialCode, defaultPreparationTime } = this.state;
		const { formatMessage } = this.props.intl;

		return (
			<div className={cx(s.space5, s.spaceTop5, 'tabLabelText')}>
				<Container fluid>
					<div className={s.blackCenterSection}>
						<Form className={s.fullWidth} onSubmit={handleSubmit(submit)}>
							<Row>
								<Col lg={12} md={12} sm={12} xs={12}>
									<h1 className={cx(s.titleTextAdmin, 'textAlignRightRTL')}>
										{formatMessage(messages.editStore)}
										<span className={s.userStatusBadge}>
											<Badge pill variant={getShop && getShop.isActive === 1 && getShop.isBan === 0 ? 'success' : 'danger'}>
												{getShop && getShop.isActive === 1 && getShop.isBan === 0 ? formatMessage(messages.online) : formatMessage(messages.offline)}
											</Badge>
											{' '}
											{
												getShop.isActive === 1 && getShop.isBan === 0 && <Badge pill variant={getShop.activeStatus === "active" ? 'success' : 'danger'}>
													{getShop.activeStatus === "active" ? formatMessage(messages.eligibleForAOrder) : formatMessage(messages.notEligibleForAOrder)}
												</Badge>
											}
										</span>
									</h1>
								</Col>
								<Col lg={6} md={6} sm={12} xs={12}>
									<Form.Group className={cx(s.space5)}>
										<div className={cx(s.profileImgSection)}>
											<ImageUploadComponent
												className={cx(s.btnSecondary, s.profileNoPadding)}
												subTextClass={s.subText}
												subText={formatMessage(messages.maximumUploadSizeLabel)}
												defaultMessage={formatMessage(messages.chooseFile)}
												loaderName={'storesImageLoader'}
												postUrl={api.apiEndpoint + '/uploadStoreImage'}
												loader={imageLoader}
												fieldName={'picture'}
												formName={'EditStoreForm'}
												imageSrc={picture ? api.apiEndpoint + storeImageUploadDir + picture : null}
												label={formatMessage(messages.storeImage)}
											/>
										</div>
									</Form.Group>
								</Col>
								<Col lg={6} md={6} sm={12} xs={12}>
									<Field
										name="shopName"
										type="text"
										placeholder={formatMessage(messages.shopName)}
										component={this.renderField}
										label={formatMessage(messages.shopName)}
										labelClass={s.labelText}
										fieldClass={s.formControlInput}
									/>
									<Field
										name="description"
										type="text"
										placeholder={formatMessage(messages.storeDescription)}
										component={this.renderFormControlTextArea}
										label={formatMessage(messages.storeDescription)}
										labelClass={s.labelText}
									/>
								</Col>
								<Col lg={6} md={6} sm={12} xs={12}>
									<Field
										name="firstName"
										type="text"
										placeholder={formatMessage(messages.firstName)}
										component={this.renderField}
										label={formatMessage(messages.firstName)}
										labelClass={s.labelText}
										fieldClass={s.formControlInput}
									/>
								</Col>
								<Col lg={6} md={6} sm={12} xs={12}>
									<Field
										name="lastName"
										type="text"
										placeholder={formatMessage(messages.lastName)}
										component={this.renderField}
										label={formatMessage(messages.lastName)}
										labelClass={s.labelText}
										fieldClass={s.formControlInput}
									/>
								</Col>
								<Col xl={12} lg={12} md={12} sm={12} xs={12}>
									<Field
										name="email"
										type="text"
										placeholder={formatMessage(messages.email)}
										component={this.renderField}
										label={formatMessage(messages.email)}
										labelClass={s.labelText}
										fieldClass={s.formControlInput}
										disabled
									/>
								</Col>
								<Col xl={12} lg={12} md={12} sm={12} xs={12} className={s.space2}>
									<div className={cx(s.space5, 'positionRelative')}>
										<label>{formatMessage(messages.phoneNumber)}</label>
										<div className={s.phoneNumberGridSection}>
											{phoneCountryCode && <CountryList
												className={cx(s.formControlSelect, s.formControlInput, s.noMargin)}
												defaultCountry={phoneCountryCode}
												handleCountryChange={this.handleCountryChange}
												disabled
											/>}
											<Form.Group className={s.noMargin}>
												<div className={cx(s.countrySection, 'countrySectionRTL')}>
													<span className={s.space1}>
														{phoneDialCode}
													</span>
												</div>
												<div className={s.phoneNumberSection}>
													<Field
														name="phoneNumber"
														type="text"
														placeholder={formatMessage(messages.phoneNumber)}
														component={this.renderFieldPhoneNumber}
														fieldClass={cx(s.formControlInput, s.inputRadius, 'inputRadiusRTL')}
														disabled
													/>
												</div>
											</Form.Group>
										</div>
									</div>
								</Col>
								<Col lg={8} md={8} sm={12} xs={12}>
									<Form.Group className={cx(s.space5, 'positionRelative')}>
										<div className={cx('siteAdminProfile', 'adminEditForm')}>
											<label>{formatMessage(messages.address)}</label>
											<PlacesSuggest
												label={formatMessage(messages.address)}
												className={s.space3}
												handleData={this.handleLocationData}
												defaultValue={initialValues && initialValues.address}
											/>
										</div>
									</Form.Group>
								</Col>
								<Col lg={4} md={4} sm={12} xs={12}>
									<Field
										name="zipcode"
										type="text"
										placeholder={formatMessage(messages.zipcode)}
										component={this.renderField}
										label={formatMessage(messages.zipcode)}
										labelClass={s.labelText}
										fieldClass={s.formControlInput}
									/>
								</Col>
								<Col lg={6} md={6} sm={12} xs={12}>
									<Field name="priceRange"
										type="Select"
										className={cx(s.formControlSelect, s.formControlSelectWidth, s.commonBorder, s.formControlInput)}
										component={this.renderFormControlSelect}
										placeholder={formatMessage(messages.priceRange)}
										label={formatMessage(messages.priceRange)}
									>
										<option value="">{formatMessage(messages.choosePriceRange)}</option>
										<option value={1}>$</option>
										<option value={2}>$$</option>
										<option value={3}>$$$</option>
										<option value={4}>$$$$</option>
									</Field>
								</Col>

								<Col lg={6} md={6} sm={12} xs={12}>
									<Field
										name="password"
										type="text"
										placeholder={formatMessage(messages.password)}
										component={this.renderField}
										label={formatMessage(messages.password)}
										labelClass={s.labelText}
										fieldClass={s.formControlInput}
									/>
								</Col>
								<Col lg={6} md={6} sm={6} xs={12}>
									<Form.Group className={cx(s.space5, 'positionRelative')}>
										<label>{formatMessage(messages.userActiveStatus)}</label>
										<Field name="userStatus" className={cx(s.formControlSelect, s.formControlInput)} component="select">
											<option value={'pending'}>{formatMessage(messages.pending)}</option>
											<option value={'active'}>{formatMessage(messages.approve)}</option>
											<option value={'inactive'}>{formatMessage(messages.decline)}</option>
										</Field>
									</Form.Group>
								</Col>
								<Col lg={6} md={6} sm={6} xs={12}>
									<Form.Group className={cx(s.space5, 'positionRelative')} controlId="exampleForm.ControlSelect1">
										<label>{formatMessage(messages.banStatus)}</label>
										<Field name="isBan" className={cx(s.formControlSelect, s.formControlInput)} component="select">
											<option value="0">{formatMessage(messages.permit)}</option>
											<option value="1">{formatMessage(messages.ban)}</option>
										</Field>
									</Form.Group>
								</Col>
								<Col lg={12} md={12} sm={12} xs={12} >
									<div className="textAlignRightRTL">
										<Badge pill variant={'success'} className=''>
											{initialValues.deliveryName}
										</Badge>
									</div>
									<Form.Group className={s.space3}>
										<label className={s.labelNumberText}>{formatMessage(messages.category)}</label>
										<Field name="categoryType" component={this.checkboxGroup} options={getCategoryDelivery && getCategoryDelivery.results} />
									</Form.Group>
								</Col>
								<Col lg={12} md={12} sm={12} xs={12}>
									<label className={s.labelNumberText}>{formatMessage(messages.deliveryTypeLabel)}</label>
									<div className={'adminCheckbox'}>
										<ul className={s.listStyle}>
											<Field name="isDoorStepDelivery"
												component={this.checkboxHorizontalGroup}
												label={formatMessage(messages.doorStepDelivery)}
												checked={isDoorStepDelivery}
											/>
											<Field name="isTakeAway"
												component={this.checkboxHorizontalGroup}
												label={formatMessage(messages.takeAway)}
												checked={isTakeAway}
											/>
										</ul>
									</div>
								</Col>
								<Col lg={12} md={12} sm={12} xs={12} className={cx(s.textAlignRight, s.spaceTop3)}>
									<Form.Group className={s.noMargin}>
										<Link to={"/siteadmin/store/list"} className={cx(s.backBtnTwo, s.btnSecondaryBorder, s.linkBtnInline, 'backBtnTwoRTL', s.backBtnTwoTop)} >{formatMessage(messages.goBack)}</Link>
										<div className={s.displayInlineBlock}>
											<Loader
												type={"button"}
												label={formatMessage(messages.update)}
												show={loading}
												buttonType={'submit'}
												className={cx(s.button, s.btnPrimary)}
												disabled={submitting || loading}
												isSuffix={true}
											/>
										</div>
									</Form.Group>
								</Col>
							</Row>
						</Form>
					</div>
				</Container>
			</div>
		)
	}
}

EditStoreForm = reduxForm({
	form: 'EditStoreForm',
	onSubmit: submit,
	validate
})(EditStoreForm);

const selector = formValueSelector('EditStoreForm');

const mapState = state => ({
	loading: state?.loader?.UpdateStore,
	picture: selector(state, 'picture'),
	isTakeAway: selector(state, 'isTakeAway'),
	isDoorStepDelivery: selector(state, 'isDoorStepDelivery'),
	imageLoader: state?.loader?.storesImageLoader
});

const mapDispatch = {};

export default compose(
	injectIntl,
	withStyles(s),
	connect(mapState, mapDispatch),
	graphql(getCategoryCategories, {
		name: 'getCategory',
		options: (props) => ({
			variables: {
				deliveryType: props.deliveryType,
			},
			fetchPolicy: 'network-only',
			ssr: true
		})
	})
)(EditStoreForm);