import {
    OPEN_LOGIN_MODAL,
    CLOSE_LOGIN_MODAL,
    OPEN_FORGOT_PASSWORD_MODAL,
    CLOSE_FORGOT_PASSWORD_MODAL,
    OPEN_ORDER_MODAL,
    CLOSE_ORDER_MODAL,
    OPEN_LOGOUT_MODAL,
    CLOSE_LOGOUT_MODAL,
} from '../constants';

const openLoginModal = () => {
    return (dispatch, getState) => {
        dispatch({
            type: OPEN_LOGIN_MODAL,
            isLoginModalOpen: true,
            isForgotPasswordModalOpen: false
        });
    }
}

const closeLoginModal = () => {
    return (dispatch, getState) => {
        dispatch({
            type: CLOSE_LOGIN_MODAL,
            isLoginModalOpen: false
        });
    }
}

const openForgotPasswordModal = () => {
    return (dispatch, getState) => {
        dispatch({
            type: OPEN_FORGOT_PASSWORD_MODAL,
            isForgotPasswordModalOpen: true,
            isLoginModalOpen: false
        });
    }
}

const closeForgotPasswordModal = () => {
    return (dispatch, getState) => {
        dispatch({
            type: CLOSE_FORGOT_PASSWORD_MODAL,
            isForgotPasswordModalOpen: false
        });
    }
}


const openOrderModal = () => {
    return (dispatch, getState) => {
        dispatch({
            type: OPEN_ORDER_MODAL,
            isOrderModalOpen: true,
        });
    }
}

const closeOrderModal = () => {
    return (dispatch, getState) => {
        dispatch({
            type: CLOSE_ORDER_MODAL,
            isOrderModalOpen: false
        });
    }
}

const openLogoutModal = () => {
    return (dispatch, getState) => {
        dispatch({
            type: OPEN_LOGOUT_MODAL,
            isLogoutModalOpen: true,
        });
    }
}

const closeLogoutModal = () => {
    return (dispatch, getState) => {
        dispatch({
            type: CLOSE_LOGOUT_MODAL,
            isLogoutModalOpen: false
        });
    }
}

export {
    closeLogoutModal,
    openLogoutModal,
    closeOrderModal,
    openOrderModal,
    closeForgotPasswordModal,
    openForgotPasswordModal,
    closeLoginModal,
    openLoginModal
};