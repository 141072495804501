import React from 'react';
import withStyles from 'isomorphic-style-loader/withStyles';
import { Table, FormControl } from 'react-bootstrap';
import s from './VehicleList.css';
import { FormattedMessage } from 'react-intl';
import cx from 'classnames';
//local
import messages from '../../../../locale/messages';
import moment from 'moment';
import CustomPagination from '../../../CustomPagination';
import { graphql } from 'react-apollo';
import { flowRight as compose } from 'lodash';
import getAllVehicles from './getAllVehicles.graphql'
import PropTypes from 'prop-types';
import Link from '../../../Link';
import { capitalizeFirstLetter } from '../../../../helpers/capitalizeFirst';

//Images
import EditIcon from '../../../../../public/Icons/edit.png';

class VehicleList extends React.Component {
  static propTypes = {
    vehicleListDetails: PropTypes.object,
  };

  constructor(props) {
    super(props)

    this.state = {
      currentPage: 1,
      searchList: '',
      typing: false,
      typingTimeout: 0
    }

    this.paginationData = this.paginationData.bind(this);
    this.handleSearchClick = this.handleSearchClick.bind(this);
    this.handleSearchChange = this.handleSearchChange.bind(this)
  }
  handleSearchClick(searchList) {
    const { vehicleListDetails: { refetch } } = this.props
    let variables = {
      currentPage: 1,
      searchList: searchList
    }
    this.setState({ currentPage: 1 })
    refetch(variables)
  }

  handleSearchChange(e) {
    let self = this
    if (self.state.typingTimeout) {
      clearTimeout(self.state.typingTimeout)
    }
    self.setState({
      searchList: e.target.value,
      typing: false,
      typingTimeout: setTimeout(function () {
        self.handleSearchClick(self.state.searchList)
      }, 450)
    })
  }
  paginationData(currentPage) {
    const { vehicleListDetails: { refetch } } = this.props;
    let variables = { currentPage };
    this.setState({ currentPage });
    refetch(variables);
  }
  render() {
    const { vehicleListDetails, vehicleListDetails: { getAllVehicles } } = this.props;
    const { currentPage } = this.state;
    return (
      <div className={s.widthInner}>
        <div className={s.searchInput}>
          <FormControl type='text' placeholder='Search' onChange={(e) => this.handleSearchChange(e)} className={s.formControlInput} />
        </div>
        <div className={cx(s.tableCss, 'tableCss', 'tableSticky')}>
          <Table className="table">
            <thead>
              <tr>
                <th>{<FormattedMessage {...messages.id} />}</th>
                <th><FormattedMessage {...messages.vehicleName} /></th>
                <th><FormattedMessage {...messages.vehicleType} /></th>
                <th><FormattedMessage {...messages.vehicleNumber} /></th>
                <th><FormattedMessage {...messages.vehicleOwnerName} /></th>
                <th><FormattedMessage {...messages.vehicleOwnerEmail} /></th>
                {/* <th><FormattedMessage {...messages.status} /></th> */}
                <th><FormattedMessage {...messages.createdAt} /></th>
                <th><FormattedMessage {...messages.action} /></th>
              </tr>
            </thead>
            <tbody>
              {
                vehicleListDetails && vehicleListDetails.getAllVehicles && vehicleListDetails.getAllVehicles.vehicleData && vehicleListDetails.getAllVehicles.vehicleData.length > 0 && vehicleListDetails.getAllVehicles.vehicleData.map((item, index) => {
                  return (
                    <tr key={index}>
                      <td>{item.id}</td>
                      <td>{capitalizeFirstLetter(item.vehicleName)}</td>
                      <td>{item && item.category && item.category.categoryName}</td>
                      <td>{item.vehicleNumber}</td>
                      <td>{item && item.user && item.user.profile && capitalizeFirstLetter(item.user.profile.firstName)}</td>
                      <td>{item && item.user && item.user.email}</td>
                      {/* <td>{capitalizeFirstLetter(item.vehicleStatus)}</td> */}
                      <td>{moment(item.createdAt).format('DD-MM-YYYY HH:mm:ss')}</td>
                      <td>
                        <Link to={'/siteadmin/vehicles/' + item.id}>
                          <img src={EditIcon} className={cx(s.editIcon, 'editIconRTL')} />
                          <span className={s.vtrMiddle}>
                            <FormattedMessage {...messages.editAction} />
                          </span>
                        </Link>
                      </td>
                    </tr>
                  )
                })
              }
              {
                    vehicleListDetails && vehicleListDetails.getAllVehicles && vehicleListDetails.getAllVehicles.vehicleData.length ==0 && (   
                    <tr>
                        <td colspan="12" className={s.noRecords}><FormattedMessage {...messages.noResult} /></td>
                    </tr>
                    )
              }
            </tbody>
          </Table>
        </div>
        {
          vehicleListDetails && vehicleListDetails.getAllVehicles && vehicleListDetails.getAllVehicles.vehicleData && vehicleListDetails.getAllVehicles.vehicleData.length > 0
          && <div className={cx(s.space5, s.spaceTop5)}>
            <CustomPagination
              total={vehicleListDetails.getAllVehicles.count}
              currentPage={currentPage}
              defaultCvehicleListDetailsurrent={1}
              defaultPageSize={10}
              change={this.paginationData}
              paginationLabel={'Vehicles'}
            />
          </div>
        }
      </div>
    );
  }
}


export default compose(
  withStyles(s),
  graphql(getAllVehicles, {
    name: 'vehicleListDetails',
    options: {
      variables: {
        currentPage: 1,
        searchList: ''
      },
      fetchPolicy: 'network-only'
    }
  })
)(VehicleList);