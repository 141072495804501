import showToaster from '../../../helpers/toasterMessages/showToaster';
import { closeModal } from '../../siteadmin/modalActions';
import { updateDeliverymutation, getOrderDetailsQuery } from '../../../lib/graphql';

const updateDeliveryStatus = (id, isOrderDetailPage, status) => {
    return async (dispatch, getState, { client }) => {
        try {
            let refetchQueries = [];

            if (isOrderDetailPage) {
                refetchQueries = [{
                    query: getOrderDetailsQuery,
                    variables: { id }
                }];
            }

            const { data } = await client.mutate({
                mutation: updateDeliverymutation,
                variables: {
                    id,
                    status
                },
                refetchQueries
            });

            if (data?.updateDeliveryStatus?.status === 200) {
                showToaster({ messageId: 'commonToasterError', toasterType: 'success', requestMessage: data?.updateDeliveryStatus?.successMessage })
                dispatch(closeModal('completeConfirmModal'));
            } else {
                showToaster({ messageId: 'commonToasterError', toasterType: 'error', requestMessage: data?.updateDeliveryStatus?.errorMessage })
            }

            return true;
        } catch (error) {
            showToaster({ messageId: 'commonToasterError', toasterType: 'error', requestMessage: error })

        }
    }
};
export default updateDeliveryStatus