import {
  SMS_VERIFICATION_MODAL_OPEN,
  SMS_VERIFICATION_MODAL_CLOSE
} from '../../../constants';

const openSmsVerificationModal = (formType) => {

  return (dispatch, getState) => {
    dispatch({
      type: SMS_VERIFICATION_MODAL_OPEN,
      payload: {
        isSmsVerificationModalOpen: true,
        formType
      }
    });
  };
}

const closeSmsVerificationModal = () => {

  return (dispatch, getState) => {
    dispatch({
      type: SMS_VERIFICATION_MODAL_CLOSE,
      payload: {
        isSmsVerificationModalOpen: false,
      }
    });
  };
}

export { openSmsVerificationModal, closeSmsVerificationModal }