import errorMessage from "./errorMessages/errorMessage_en";

const showErrorMessage = async ({ errorCode, error, language, data }) => {
    try {
        const lang = language ? language : "en"

        if (lang == "en") {
            return await errorMessage(errorCode, error, data);
        }
    } catch (error) {
        console.log(error);
        return false;
    }
}

export default showErrorMessage;