import { updateMobileSettings } from '../../../actions/siteadmin/SiteSettings/updateMobileSettings';
import showToaster from '../../../helpers/toasterMessages/showToaster';

const submit = async (values, dispatch) => {

    if (values?.isRequestTimerToneEnable == '1' && !values.requestTimeTone) {
        showToaster({ messageId: 'uploadNotificationSound', toasterType: 'error' })
        return;
    }

    await dispatch(
        updateMobileSettings(
            values.distanceUnits,
            values.appForceUpdate,
            values.userAndroidVersion,
            values.userIosVersion,
            values.driverAndroidVersion,
            values.driverIosVersion,
            values.preferredDelivery,
            values.requestTimeTone,
            values.isRequestTimerToneEnable,
            values.openAppOnRequest,
            values.requestToneFile,
            values.orderAcceptInterval
        )
    )
}

export default submit;