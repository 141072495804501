import { change } from 'redux-form';
import showErrorMessage from '../../../helpers/showErrorMessage';
import { checkShopUser as query } from '../../../lib/graphql';

const checkUserEmail = (values) => {

    try {
        return async (dispatch, getState, { client }) => {

            const { data } = await client.query({
                query,
                variables: {
                    email: values.email
                }
            })
            if (data?.checkShopUser?.status === 200) {
                await dispatch(change('LoginForm', 'page', '2'));
                return {
                    status: 200
                };
            } else {
                return {
                    status: 400,
                    errorMessage: data?.checkShopUser?.errorMessage
                }
            }
        }
    } catch (error) {
        return {
            status: 400,
            errorMessage: showErrorMessage({ errorCode: 'catchError', error })
        };
    }
}

export default checkUserEmail;