import React from 'react';
import PropTypes from 'prop-types';
import withStyles from 'isomorphic-style-loader/withStyles';
import s from './AddItem.css';
import AddItemForm from '../../../components/Shop/ManageMenu/ItemList/AddItemForm/AddItemForm';


class AddModifierGroups extends React.Component {
  static propTypes = {
    title: PropTypes.string.isRequired,
  };

  render() {
    const { menuId, subMenuId } = this.props;
    let initialValues = {
      menuId,
      subMenuId
    };
    return (
      <div>
        <AddItemForm initialValues={initialValues} />
      </div>
    );
  }
}

export default withStyles(s)(AddModifierGroups);
