import React from 'react';
import OperationHours from './OperationHours';
import DashboardLayout from '../../../components/Shop/StoreLayout/Layout/DashboardLayout';
import messages from '../../../locale/messages';

function action ({ store, intl }) {
  const title = intl.formatMessage(messages.operationHours);
    
    //from Redux store
    let isAuthenticated = store.getState().runtime.isStoreAuthenticated;
    if (!isAuthenticated) {
        return {
            redirect: '/store'
        }
    }

    return {
        title,
        component: <DashboardLayout>
            <OperationHours title={title}/>
        </DashboardLayout>
    }
}

export default action;