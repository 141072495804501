import {
	UPDATE_SITE_SETTINGS_START,
	UPDATE_SITE_SETTINGS_ERROR,
	UPDATE_SITE_SETTINGS_SUCCESS
} from '../../constants/index';
import showToaster from '../../helpers/toasterMessages/showToaster';
import { updateFavIconMutation as mutation } from '../../lib/graphql';

const updateFavIcon = (favicon) => {

	return async (dispatch, getState, { client }) => {

		dispatch({
			type: UPDATE_SITE_SETTINGS_START
		})

		try {
			const { data } = await client.mutate({
				mutation,
				variables: {
					favicon
				}
			})

			if (data?.updateFavIcon?.status == 200) {

				dispatch({
					type: UPDATE_SITE_SETTINGS_SUCCESS
				})
				showToaster({ messageId: 'updateSiteSettings', toasterType: 'success' })
			} else {
				dispatch({
					type: UPDATE_SITE_SETTINGS_ERROR
				})
				showToaster({ messageId: 'settingsUploadError', toasterType: 'error' })
			}
		} catch (err) {
			dispatch({
				type: UPDATE_SITE_SETTINGS_ERROR
			})
			showToaster({ messageId: 'settingsUploadError', toasterType: 'error' })
		}

	}
}
export default updateFavIcon;