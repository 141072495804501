import {
  DELETE_PROMOCODE_START,
  DELETE_PROMOCODE_SUCCESS,
  DELETE_PROMOCODE_ERROR
} from '../../../constants';
import { deletePromoCodeMutation as mutation } from '../../../lib/graphql';
import { getAllPromoCode as query } from '../../../lib/graphql';
import { setLoaderStart, setLoaderComplete } from '../../loader/loader'
import showToaster from '../../../helpers/toasterMessages/showToaster';
import showErrorMessage from '../../../helpers/showErrorMessage';

export const deletePromoCode = (id) => {
  return async (dispatch, getState, { client }) => {

    try {
      dispatch({
        type: DELETE_PROMOCODE_START,
        payload: {
          promoCodeLoading: true
        }
      });
      dispatch(setLoaderStart('deletePromoCode'))

      const { data } = await client.mutate({
        mutation,
        variables: {
          id
        },
        refetchQueries: [{
          query, variables: { currentPage: 1 }
        }]
      });

      if (data?.deletePromoCode?.status === 200) {
        showToaster({ messageId: 'promoCodeDelete', toasterType: 'success' })

        await dispatch({
          type: DELETE_PROMOCODE_SUCCESS,
          payload: {
            promoCodeLoading: false
          }
        });
      } else {
        showToaster({ messageId: 'commonToasterError', toasterType: 'error', requestMessage: data?.deletePromoCode?.errorMessage })

        await dispatch({
          type: DELETE_PROMOCODE_ERROR,
          payload: {
            promoCodeLoading: false,
            error: data?.deletePromoCode?.errorMessage
          }
        });
      }
      dispatch(setLoaderComplete('deletePromoCode'))

    } catch (error) {
      showToaster({ messageId: 'failedError', toasterType: 'error', requestMessage: error })

      await dispatch({
        type: DELETE_PROMOCODE_ERROR,
        payload: {
          promoCodeLoading: false,
          error: showErrorMessage({ errorCode: 'catchError', error })
        }
      });
      dispatch(setLoaderComplete('deletePromoCode'))
    }
  }
}