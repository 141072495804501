import {
	SHOP_MENU_UPDATE_START,
	SHOP_MENU_UPDATE_SUCCESS,
	SHOP_MENU_UPDATE_ERROR
} from '../../../constants/index';
import { loadAccount } from '../userAccount/userAccount';
import showToaster from '../../../helpers/toasterMessages/showToaster';
import { getAllSubMenuQuery, updateItemStatusMutation } from '../../../lib/graphql';

const updateItemStatus = (id, status, currentPage, menuId) => {
	return async (dispatch, getState, { client }) => {

		try {
			dispatch({
				type: SHOP_MENU_UPDATE_START,
				payload: {
					updateLoading: true
				}
			});

			const { data } = await client.mutate({
				mutation: updateItemStatusMutation,
				variables: {
					id,
					status: status == 'false' ? 0 : 1
				},
				refetchQueries: [{ query: getAllSubMenuQuery, variables: { currentPage, menuId } }]
			});

			if (data?.updateItemStatus?.status === 200) {
				dispatch({
					type: SHOP_MENU_UPDATE_SUCCESS,
					payload: {
						updateLoading: false
					}
				});
				showToaster({ messageId: 'updateItemStatus', toasterType: 'success' })
				dispatch(loadAccount())
			} else {
				dispatch({
					type: SHOP_MENU_UPDATE_ERROR,
					payload: {
						updateLoading: false
					}
				});
				showToaster({ messageId: 'commonToasterError', toasterType: 'error', requestMessage: data?.updateItemStatus?.errorMessage })
			}
		} catch (err) {
			showToaster({ messageId: 'commonToasterError', toasterType: 'error', requestMessage: err })
			dispatch({
				type: SHOP_MENU_UPDATE_ERROR,
				payload: {
					updateLoading: false
				}
			});
		}
	}
};
export default updateItemStatus;
