import {
  ADD_PROMOCODE_START,
  ADD_PROMOCODE_SUCCESS,
  ADD_PROMOCODE_ERROR
} from '../../../constants';
import history from '../../../history';
import { setLoaderStart, setLoaderComplete } from '../../../actions/loader/loader';
import { updatePromoCodeMutation as mutation } from '../../../lib/graphql';
import showToaster from '../../../helpers/toasterMessages/showToaster';

export const addPromoCode = (values) => {

  return async (dispatch, getState, { client }) => {

    try {
      let status;

      dispatch({
        type: ADD_PROMOCODE_START,
        payload: {
          promoCodeLoading: true
        }
      });

      dispatch(setLoaderStart('AddPromoCode'));

      const { data } = await client.mutate({
        mutation,
        variables: {
          id: values?.id,
          title: values?.title,
          description: values?.description,
          code: values?.code,
          type: values?.type == 1 ? 1 : 0,
          promoValue: values?.promoValue,
          currency: values?.currency,
          expiryDate: values?.expiryDate && values?.expiryDate !== '' ? values?.expiryDate : null,
          isEnable: values?.isEnable,
          promoCodeImage: values?.promoCodeImage,
          isPrivate: values?.isPrivate
        }
      });

      status = data?.updatePromoCode?.status;

      if (status && status === 200) {

        history.push('/siteadmin/promo-code/list');
        showToaster({ messageId: 'addUpdatePromoCode', toasterType: 'success', requestMessage: values?.id })

        await dispatch({
          type: ADD_PROMOCODE_SUCCESS,
          payload: {
            promoCodeLoading: false
          }
        });
        dispatch(setLoaderComplete('AddPromoCode'));

      } else {

        showToaster({ messageId: 'commonToasterError', toasterType: 'error', requestMessage: data?.updatePromoCode?.errorMessage })

        await dispatch({
          type: ADD_PROMOCODE_ERROR,
          payload: {
            promoCodeLoading: false,
            error: "Something went wrong"
          }
        });
        dispatch(setLoaderComplete('AddPromoCode'));
      }
    } catch (error) {

      showToaster({ messageId: 'commonToasterError', toasterType: 'error', requestMessage: error })

      dispatch(setLoaderComplete('AddPromoCode'));
      await dispatch({
        type: ADD_PROMOCODE_ERROR,
        payload: {
          promoCodeLoading: false,
          error: "Something went wrong"
        }
      });
    }
  }
}