import histroy from '../../../history';
import { setLoaderStart, setLoaderComplete } from '../../loader/loader';
import { updateDeliveryType as mutation } from '../../../lib/graphql';
import showToaster from '../../../helpers/toasterMessages/showToaster';

export const updateDeliveryType = (values) => {

  return async (dispatch, getState, { client }) => {

    try {

      dispatch(setLoaderStart('updateDelivery'));

      const { data } = await client.mutate({
        mutation,
        variables: {
          id: values?.id,
          deliveryName: values?.deliveryName,
          deliveryDescription: values?.deliveryDescription,
          imageName: values?.imageName,
          bannerName: values?.bannerName,
          status: (values?.status === true || values?.status === 'true') ? 1 : 0,
          textColor: values?.textColor,
          backgroundColor: values?.backgroundColor,
          iconBackgroundColor: values?.iconBackgroundColor,
        }
      });

      dispatch(setLoaderComplete('updateDelivery'));

      let status = data?.updateDeliveryType?.status

      if (status === 200) {
        showToaster({ messageId: 'addUpdateDeliveryType', toasterType: 'success', requestMessage: values.id })
        histroy.push('/siteadmin/delivery-type');
      } else if (status !== 200) {
        showToaster({ messageId: 'commonToasterError', toasterType: 'error', requestMessage: data?.updateDeliveryType?.errorMessage })
      } else {
        showToaster({ messageId: 'defaultError', toasterType: 'error' })
      }
    } catch (error) {
      showToaster({ messageId: 'commonToasterError', toasterType: 'error', requestMessage: error })
    }
  }
};