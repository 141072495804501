exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ":root {\n  /*\n   * Typography\n   * ======================================================================== */\n\n  --font-family-base: 'Comfortaa', cursive;\n\n  /*\n   * Layout\n   * ======================================================================== */\n\n  --max-content-width: 1170px;\n\n  /*\n   * Media queries breakpoints\n   * ======================================================================== */\n\n  --screen-xs-min: 480px;\n  /* Extra small screen / phone */\n  --screen-sm-min: 768px;\n  /* Small screen / tablet */\n  --screen-md-min: 992px;\n  /* Medium screen / desktop */\n  --screen-lg-min: 1200px;\n  /* Large screen / wide desktop */\n\n  --btn-primary-bg: #FFB400;\n  --btn-boxShadow-bg: #FFB400;\n  --btn-bordercolor-bg: #FFB400;\n  --btn-text-color: #fff;\n  --btn-primary-color: #ffffff;\n  --common-color: #1C0303;\n  --btn-secondary-bg: #FFB400;\n  --title-color: #212121;\n  --content-color: #212121;\n  --chat-background-color: #F6F6F6;\n  --border-color: #CED4DA;\n\n}\n._79jX9 {\n  padding-left: 20px;\n  padding-right: 20px;\n}\n._3rznM {\n  margin: 0 auto;\n  padding: 0 0 40px;\n  max-width: 1170px;\n  max-width: var(--max-content-width);\n}\n._1slhP {\n  margin: 0 0 32px;\n  margin: 0 0 2rem;\n}\n._1lRcu {\n  font-size: 24px;\n  font-size: 1.5rem;\n}\n._2a_Yz h1, ._2a_Yz h2, ._2a_Yz h3, ._2a_Yz h4, ._2a_Yz h5, ._2a_Yz h6 {\n  font-size: 18px;\n  font-size: 1.125rem;\n}\n._2a_Yz pre {\n  white-space: pre-wrap;\n  font-size: 14px;\n  font-size: 0.875rem;\n}\n._2a_Yz img {\n  max-width: 100%;\n}\n._39g60 {\n  font-size: 0.8em;\n  color: #777;\n}\n", ""]);

// exports
exports.locals = {
	"root": "_79jX9",
	"container": "_3rznM",
	"newsItem": "_1slhP",
	"newsTitle": "_1lRcu",
	"newsDesc": "_2a_Yz",
	"publishedDate": "_39g60"
};