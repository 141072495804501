import {
    HOMEPAGE_SETTINGS_UPLOAD_START,
    HOMEPAGE_SETTINGS_UPLOAD_ERROR,
    HOMEPAGE_SETTINGS_UPLOAD_SUCCESS
} from '../../constants/index'
import showToaster from '../../helpers/toasterMessages/showToaster';
import { setLoaderStart, setLoaderComplete } from '../loader/loader'
import { updateHomePageCity as mutation } from '../../lib/graphql';

const updateHomepageSettingsCity = (values) => {

    return async (dispatch, getState, { client }) => {
        try {

            dispatch({
                type: HOMEPAGE_SETTINGS_UPLOAD_START
            })

            dispatch(setLoaderStart('CitySettingsForm'))

            const { data } = await client.mutate({
                mutation,
                variables: {
                    citySectionTitle1: values?.citySectionTitle1,
                    citySectionContent1: values?.citySectionContent1
                }
            })

            dispatch(setLoaderComplete('CitySettingsForm'))
            if (data?.updateHomePageCity?.status == 200) {

                dispatch({
                    type: HOMEPAGE_SETTINGS_UPLOAD_SUCCESS
                })
                showToaster({ messageId: 'updateHomePageSettings', toasterType: 'success' })
            } else {
                dispatch({
                    type: HOMEPAGE_SETTINGS_UPLOAD_ERROR
                })
                showToaster({ messageId: 'settingsUploadError', toasterType: 'error' })
            }
        } catch (err) {
            dispatch({
                type: HOMEPAGE_SETTINGS_UPLOAD_ERROR
            })
            showToaster({ messageId: 'settingsUploadError', toasterType: 'error' })
        }
    }
}
export default updateHomepageSettingsCity