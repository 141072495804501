import React, { Component } from 'react';
import { Field, reduxForm, formValueSelector } from 'redux-form';
import { connect } from 'react-redux';
import withStyles from 'isomorphic-style-loader/withStyles';
import { injectIntl } from 'react-intl';
import cx from 'classnames';
import Form from 'react-bootstrap/Form';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Container from 'react-bootstrap/Container';
import FormControl from 'react-bootstrap/FormControl';
import FormGroup from 'react-bootstrap/FormGroup';

import Loader from '../../../Common/Loader/Loader';
import ImageUploadComponent from '../../../Common/ImageUploadComponent/ImageUploadComponent';

import validate from './validate';
import submit from './submit';
import messages from '../../../../locale/messages';
import { api, partnerHomepageUploadDir } from '../../../../config';

import s from './InfoForm.css';
export class InfoForm extends Component {

	renderField = ({ input, label, type, meta: { touched, error }, labelClass, fieldClass, placeholder, maxlength }) => {
		const { formatMessage } = this.props.intl
		return (
			<Form.Group className={cx(s.space5, 'positionRelative')}>
				<Form.Label>{label}</Form.Label>
				<Form.Control {...input} placeholder={placeholder} type={type} className={cx(fieldClass, s.formControlInput)} maxlength={maxlength}
				/>
				{touched && error && <span className={cx(s.errorMessage, 'errorMessageRTL')}>{formatMessage(error)}</span>}
			</Form.Group>
		)
	}

	renderFormControlTextArea = ({ input, label, meta: { touched, error }, children, className }) => {
		const { formatMessage } = this.props.intl
		return (
			<FormGroup className={cx(s.space5, 'positionRelative')}>
				<label>{label}</label>
				<FormControl
					{...input}
					className={className}
					placeholder={label}
					as="textarea"
					rows="4"
				>
					{children}
				</FormControl>
				{touched && error && <span className={cx(s.errorMessage, 'errorMessageRTL')}>{formatMessage(error)}</span>}
			</FormGroup>
		);
	}

	render() {
		const { handleSubmit, infoImage1, infoImage2, infoImage3, loading, submitting, imageLoader, imageLoaderTwo, imageLoaderThree } = this.props;
		const { formatMessage } = this.props.intl;
		return (

			<div>
				<Container fluid>
					<h1 className={cx(s.heading, 'textAlignRightRTL')}>{formatMessage(messages.infoSection)}</h1>
					<Form className={s.fullWidth} onSubmit={handleSubmit(submit)}>
						<div className={s.blackCenterSection}>
							<Row>
								<Col lg={4} md={12} sm={12} xs={12}>
									<Form.Group className={s.space5}>
										<div className={cx(s.profileImgSection, s.profileImgWidth)}>
											<ImageUploadComponent
												className={cx(s.btnSecondary, s.profileNoPadding)}
												subTextClass={s.subText}
												subText={formatMessage(messages.maximumUploadSizeLabel)}
												defaultMessage={formatMessage(messages.chooseFile)}
												loaderName={'infoImageLoader'}
												postUrl={api.apiEndpoint + '/uploadPartnerHomepageImage'}
												loader={imageLoader}
												fieldName={'infoImage1'}
												formName={'InfoForm'}
												imageSrc={infoImage1 ? api.apiEndpoint + partnerHomepageUploadDir + infoImage1 : null}
												label={formatMessage(messages.image1Label)}
											/>
										</div>
									</Form.Group>
									<Field name="infoTitle1" type="text" component={this.renderField} label={formatMessage(messages.imageTitle1Label)} maxlength={250} />
									<Field name="infoContent1" type="text" component={this.renderFormControlTextArea} label={formatMessage(messages.description1Label)} />
								</Col>
								<Col lg={4} md={12} sm={12} xs={12}>
									<Form.Group className={s.space5}>
										<div className={cx(s.profileImgSection, s.profileImgWidth)}>
											<ImageUploadComponent
												className={cx(s.btnSecondary, s.profileNoPadding)}
												subTextClass={s.subText}
												subText={formatMessage(messages.maximumUploadSizeLabel)}
												defaultMessage={formatMessage(messages.chooseFile)}
												loaderName={'infoImageLoaderTwo'}
												postUrl={api.apiEndpoint + '/uploadPartnerHomepageImage'}
												loader={imageLoaderTwo}
												fieldName={'infoImage2'}
												formName={'InfoForm'}
												imageSrc={infoImage2 ? api.apiEndpoint + partnerHomepageUploadDir + infoImage2 : null}
												label={formatMessage(messages.image2Label)}
											/>
										</div>
									</Form.Group>
									<Field name="infoTitle2" type="text" component={this.renderField} label={formatMessage(messages.imageTitle2Label)} maxlength={250} />
									<Field name="infoContent2" type="text" component={this.renderFormControlTextArea} label={formatMessage(messages.description2Label)} />
								</Col>
								<Col lg={4} md={12} sm={12} xs={12}>
									<Form.Group className={s.space5}>
										<div className={cx(s.profileImgSection, s.profileImgWidth)}>
											<ImageUploadComponent
												className={cx(s.btnSecondary, s.profileNoPadding)}
												subTextClass={s.subText}
												subText={formatMessage(messages.maximumUploadSizeLabel)}
												defaultMessage={formatMessage(messages.chooseFile)}
												loaderName={'infoImageLoaderThree'}
												postUrl={api.apiEndpoint + '/uploadPartnerHomepageImage'}
												loader={imageLoaderThree}
												fieldName={'infoImage3'}
												formName={'InfoForm'}
												imageSrc={infoImage3 ? api.apiEndpoint + partnerHomepageUploadDir + infoImage3 : null}
												label={formatMessage(messages.image3Label)}
											/>
										</div>
									</Form.Group>
									<Field name="infoTitle3" type="text" component={this.renderField} label={formatMessage(messages.imageTitle3Label)} maxlength={250} />
									<Field name="infoContent3" type="text" component={this.renderFormControlTextArea} label={formatMessage(messages.description3Label)} />
								</Col>
								<Col lg={12} md={12} sm={12} xs={12} className={cx(s.textAlignRight, s.spaceTop1, 'textAlignLeftRTL')}>
									<Form.Group className={s.noMargin}>
										<div className={s.displayInlineBlock}>
											<Loader
												type={"button"}
												label={formatMessage(messages.submitButton)}
												show={loading}
												buttonType={'submit'}
												className={cx(s.button, s.btnPrimary)}
												disabled={submitting || loading}
												isSuffix={true}
											/>
										</div>
									</Form.Group>
								</Col>
							</Row>
						</div>
					</Form>
				</Container>
			</div>
		)
	}
}

InfoForm = reduxForm({
	form: 'InfoForm',
	onSubmit: submit,
	validate
})(InfoForm);

const selector = formValueSelector('InfoForm')

const mapState = (state) => ({
	infoImage1: selector(state, 'infoImage1'),
	infoImage2: selector(state, 'infoImage2'),
	infoImage3: selector(state, 'infoImage3'),
	loading: state.loader.UpdatePartnerHomepage,
	imageLoader: state.loader.infoImageLoader,
	imageLoaderTwo: state.loader.infoImageLoaderTwo,
	imageLoaderThree: state.loader.infoImageLoaderThree

})

const mapDispatch = {
}

export default injectIntl(withStyles(s)(connect(mapState, mapDispatch)(InfoForm)));