import React, { Component } from 'react';
import DocumentForm from '../../../components/Shop/DocumentForm/DocumentForm';
import { graphql } from 'react-apollo';
import { flowRight as compose } from 'lodash';
import getShopDocuments from './getShopDocuments.graphql';
import withStyles from 'isomorphic-style-loader/withStyles';
import s from './Document.css';
import Loader from '../../../components/Common/Loader/Loader';


export class Document extends Component {

    static defaultProps = {
        data: {
            loading: false
        }
    }
    render() {
        const { getShopDocuments: { loading, getShopDocuments } } = this.props;
        
        
        if (loading) {
            return <div><Loader type={"page"} show={loading}></Loader></div>
        } else {
            return (
                <div className={s.paddingRoutesSection}>
                    <DocumentForm initialValues={getShopDocuments} />
                </div>
            )
        }
    }
}

export default compose(withStyles(s),
    graphql(getShopDocuments, {
        name: 'getShopDocuments',
        options: {
            fetchPolicy: 'network-only',
            ssr: false
        }
    })
)(Document)