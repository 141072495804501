import React, { Component } from 'react';
import { connect } from 'react-redux';
import withStyles from 'isomorphic-style-loader/withStyles';
import Col from 'react-bootstrap/Col';
import { injectIntl, FormattedMessage } from 'react-intl';
import cx from 'classnames';

import Link from '../../Link/Link';
import CurrencyConverter from '../../CurrencyConverter';

import messages from '../../../locale/messages';

import s from './AdminDashboard.css';

export class AdminDashboardUserCard extends Component {
    static defaultProps = {
        data: {
            total: 0,
            today: 0,
            week: 0,
            month: 0,
            currency: 'USD'
        },
        link: '/',
        hideSection: false,
        hideBox: false,
    };

    constructor(props) {
        super(props);
    }

    renderAmount = (amount, from) => {
        const { toCurrency } = this.props;
        if (String(amount) && amount >= 0 && from && toCurrency) {
            return (
                <div>
                    <CurrencyConverter from={from} amount={amount} to={toCurrency} />
                </div>
            );
        }
        return 0;
    }

    render() {
        const { data, link, heading, displayAmount, largeBox, boxOneBg, hideSection, icon, boxTwoBg, boxThreeBg, hideBox, boxFour, iconTwo, toCurrency } = this.props;

        return (
            <Col xl={largeBox ? 6 : 4} lg={12} md={12} sm={12} xs={12}>
                <Link to={link} className={s.noTextDecoration}>
                    <div className={cx(s.box, boxOneBg, boxTwoBg, boxThreeBg, boxFour)}>
                        {!hideBox && (<h2 className={s.titleFontSize}>{heading}</h2>)}
                        {!hideSection && (<div className={cx(s.textLeft, 'textAlignRightRTL')}>
                            <div className={s.iconTwoCss}>{iconTwo && (<img src={iconTwo} className={s.imgWidth} />)}</div>
                            <h2 className={cx(s.titleFontSize, s.titleTwoCss, 'titleTwoCssRTL')}>{heading}</h2>
                        </div>
                        )}
                        {!hideBox && (<div className={s.iconPosition}>
                            {icon && (<img src={icon} className={s.imgWidth} />)}
                        </div>)}
                        {!hideSection && (
                            <div className={cx(s.boxInner, 'textAlignRightRTL')}>
                                <div className={s.displayTable}>
                                    <div className={s.displayTableRow}>
                                        <div className={cx(s.displayTableCell, s.widthText)}>
                                            <div><FormattedMessage {...messages.today} /></div>
                                            <div className={s.priceCircle}>{displayAmount === true ? this.renderAmount(data?.today, data?.currency) : data?.today}</div>
                                        </div>
                                        <div className={cx(s.displayTableCell, s.widthText)}>
                                            <div><FormattedMessage {...messages.weekly} /></div>
                                            <div className={s.priceCircle}>{displayAmount === true ? this.renderAmount(data?.week, data?.currency) : data?.week}</div>
                                        </div>
                                        <div className={cx(s.displayTableCell, s.widthText)}>
                                            <div><FormattedMessage {...messages.montly} /></div>
                                            <div className={s.priceCircle}>{displayAmount === true ? this.renderAmount(data?.month, data?.currency) : data?.month}</div>
                                        </div>
                                    </div>
                                </div>
                            </div>)}
                        {!hideBox && (<div className={s.totalCount}>{displayAmount === true ? this.renderAmount(data?.total, data?.currency) : data?.total}</div>)}
                        {!hideSection && (<div className={s.totalCss}>
                            <div><FormattedMessage {...messages.overAll} />: {displayAmount === true ? <CurrencyConverter from={data?.currency} amount={data?.total} to={toCurrency} /> : data?.total}</div>
                        </div>)}
                    </div>
                </Link>
            </Col>
        )
    }
}

const mapStateToProps = (state) => ({});

const mapDispatchToProps = {};

export default injectIntl(withStyles(s)(connect(mapStateToProps, mapDispatchToProps)(AdminDashboardUserCard)));