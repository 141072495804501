import {
    SHOP_USER_LOGIN_START,
    SHOP_USER_LOGIN_SUCCESS,
    SHOP_USER_LOGIN_ERROR
} from '../../../constants';
import { setRuntimeVariable } from '../../runtime';
import { closeLoginModal } from '../../modalActions';
import { getAllShopModifier } from '../modifier/getAllShopModifier';
import { loadAccount } from '../userAccount/userAccount';
import { getDeviceId } from '../../../core/firebase/firebase';
import { shopUserLogin } from '../../../lib/graphql';

const userLogin = (email, password) => {

    return async (dispatch, getState, { client }) => {

        try {
            dispatch({
                type: SHOP_USER_LOGIN_START
            });
            let deviceId = await getDeviceId();

            const { data } = await client.query({
                query: shopUserLogin,
                variables: {
                    email: email,
                    password: password,
                    deviceId
                },
                fetchPolicy: 'network-only'
            });
            if (data?.shopUserLogin?.status == 200) {
                await dispatch(loadAccount());
                await dispatch(setRuntimeVariable({
                    name: 'isStoreAuthenticated',
                    value: true,
                }));
                await dispatch(getAllShopModifier());
                await dispatch(closeLoginModal());
                dispatch({ type: SHOP_USER_LOGIN_SUCCESS });
                return {
                    status: 200
                };
            } else {
                dispatch({ type: SHOP_USER_LOGIN_ERROR });
                return {
                    status: 400,
                    errorMessage: data?.shopUserLogin?.errorMessage
                };
            }
        } catch (error) {
            dispatch({ type: SHOP_USER_LOGIN_ERROR });
            return false;
        }
    }
}

export default userLogin;