import addModifier from '../../../../actions/shop/modifier/addModifier';
import addItemModifier from '../../../../actions/shop/modifier/addItemModifier';

async function submit(values, dispatch) {
    let minModifierItems = values.modifierType == "2" ? values.minModifierItems : 0;
    let modifierItems = JSON.stringify(values.modifierItems);
    if (values.modifierFrom === 'item') {
        let variables = Object.assign({}, values,
            {
                minModifierItems
            });
        await dispatch(addItemModifier(variables));
    } else {
        let variables = Object.assign({}, values,
            {
                minModifierItems
            },
            {
                modifierItems
            });
        await dispatch(addModifier(variables));
    }
}

export default submit;