import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Elements } from 'react-stripe-elements';
import Stripe from './Stripe';
import Paypal from './Paypal';
import { formValueSelector } from 'redux-form';

class PayoutConfirm extends Component {

  render() {
    const { previousPage, paymentMethodId } = this.props;

    return (
      <>
        {paymentMethodId == 2 ?
          <Elements>
            <Stripe previousPage={previousPage}></Stripe>
          </Elements> : <Paypal previousPage={previousPage} />}
      </>
    );

  }
};
const selector = formValueSelector('PayoutForm');

const mapState = (state) => ({

  paymentMethodId: selector(state, 'methodId')

});

const mapDispatch = {};

export default connect(mapState, mapDispatch)(PayoutConfirm);