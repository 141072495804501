import React, { Component } from 'react';
import PropTypes from 'prop-types';
import withStyles from 'isomorphic-style-loader/withStyles';
import { graphql } from 'react-apollo';
import { flowRight as compose } from 'lodash';
// style
import s from './Reviews.css';
import cx from 'classnames';
// components
import ManageRatings from '../../../components/SiteAdmin/ManageRatings';
import Loader from '../../../components/Common/Loader/Loader';
// graphql
import getReviews from './getReviews.graphql'
//meassage
import { FormattedMessage } from 'react-intl';
import messages from '../../../locale/messages';

export class Reviews extends Component {

    static propTypes = {
        title: PropTypes.string.isRequired
    }

    static defaultProps = {
        reviews: {
            loading: true
        }
    }

    render() {
        const { title, reviews, reviews: { loading } } = this.props;

        return (
            <Loader type={"page"} show={loading}>
                <div className={s.root}>
                    <div className={s.container}>
                        <div className={s.paddingRoutesSection}>
                            <div className={cx(s.heading, 'textAlignRightRTL')}>
                                <FormattedMessage {...messages.ratings} />
                            </div>
                            {
                                !loading && <ManageRatings reviews={reviews} />
                            }
                        </div>
                    </div>
                </div>
            </Loader>
        )
    }
}

export default compose(
    withStyles(s),
    graphql(getReviews, {
        name: 'reviews',
        options: {
            variables: {
                currentPage: 1
            },
            fetchPolicy: 'network-only'
        }
    })
)(Reviews);
