import React, { Component } from 'react';
import { graphql } from 'react-apollo';
import { flowRight as compose } from 'lodash';
import PropTypes from 'prop-types';
import withStyles from 'isomorphic-style-loader/withStyles';

import AddDeliveryTypeForm from '../../../../components/SiteAdmin/DeliveryModule/AddDeliveryType';
import Loader from '../../../../components/Common/Loader/Loader';
import cx from 'classnames';
import s from './EditDeliveryModule.css';
import getDeliveryType from './getDeliveryType.graphql';


export class EditDeliveryModule extends Component {
    static propTypes = {
        id: PropTypes.number.isRequired
    }
    static defaultProps = {
        data: {
            loading: true
        }
    }
    render() {
        const { title, data: { loading, getDeliveryType } } = this.props;

        let initialValues = {};

        if (!loading && getDeliveryType && getDeliveryType.result) {
            initialValues = {
                id: getDeliveryType.result.id,
                deliveryName: getDeliveryType.result.deliveryName,
                deliveryDescription: getDeliveryType.result.deliveryDescription,
                imageName: getDeliveryType.result.imageName,
                bannerName: getDeliveryType.result.bannerName,
                status: getDeliveryType.result.status,
                textColor: getDeliveryType.result.textColor,
                backgroundColor: getDeliveryType.result.backgroundColor,
                iconBackgroundColor: getDeliveryType.result.iconBackgroundColor,
            }
        }
        if (loading) {
            return <div><Loader type={"page"} show={loading}></Loader></div>
        } else {
            return (
                <div className={s.root}>
                    <div className={s.container}>
                        <div className={s.paddingRoutesSection}>
                            <div className={cx(s.heading, 'textAlignRightRTL')}>
                                {title}
                            </div>
                            <AddDeliveryTypeForm initialValues={initialValues} />
                        </div>
                    </div>
                </div>
            )
        }
    }
}

export default compose(withStyles(s), graphql(getDeliveryType, {
    options: (props) => ({
        variables: {
            id: props.id
        },
        fetchPolicy: 'network-only',
        ssr: true
    })
}))(EditDeliveryModule)
