import {
    HOMEPAGE_SETTINGS_UPLOAD_START,
    HOMEPAGE_SETTINGS_UPLOAD_ERROR,
    HOMEPAGE_SETTINGS_UPLOAD_SUCCESS
} from '../../constants/index'
import showToaster from '../../helpers/toasterMessages/showToaster';
import { setLoaderStart, setLoaderComplete } from '../loader/loader';
import { updateHomePageDriver as mutation } from '../../lib/graphql';

const updateHomepageSettingsDriver = (values) => {

    return async (dispatch, getState, { client }) => {
        try {

            dispatch({
                type: HOMEPAGE_SETTINGS_UPLOAD_START
            })

            dispatch(setLoaderStart('DriverSettingsForm'))
            const { data } = await client.mutate({
                mutation,
                variables: {
                    safetyGridImage4: values?.safetyGridImage4,
                    safetyGridImage5: values?.safetyGridImage5,
                    safetyGridImage6: values?.safetyGridImage6,
                    safetyGridTitle2: values?.safetyGridTitle2,
                    safetyGridContent2: values?.safetyGridContent2,
                    safetyGridLink3: values?.safetyGridLink3,
                    safetyGridLink4: values?.safetyGridLink4,
                    driverAppBgColor: values?.driverAppBgColor,
                }
            })

            dispatch(setLoaderComplete('DriverSettingsForm'))
            if (data?.updateHomePageDriver?.status == 200) {

                dispatch({
                    type: HOMEPAGE_SETTINGS_UPLOAD_SUCCESS
                })
                showToaster({ messageId: 'updateHomePageSettings', toasterType: 'success' })
            } else {
                dispatch({
                    type: HOMEPAGE_SETTINGS_UPLOAD_ERROR
                })
                showToaster({ messageId: 'settingsUploadError', toasterType: 'error' })
            }
        } catch (err) {
            dispatch({
                type: HOMEPAGE_SETTINGS_UPLOAD_ERROR
            })
            showToaster({ messageId: 'settingsUploadError', toasterType: 'error' })
        }
    }
}

export default updateHomepageSettingsDriver;