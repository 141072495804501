import React from 'react';
import withStyles from 'isomorphic-style-loader/withStyles';
import { injectIntl } from 'react-intl';

import s from './HomeBanner.css';
import cx from 'classnames';
import { Container } from 'react-bootstrap';

import Link from '../../Link';
import arrow from '../../../../public/Icons/right.svg';
import messages from '../../../locale/messages';

import { api } from '../../../config';
class StoreBanner extends React.Component {
    constructor(props) {
        super(props);
        this.scrollTop = this.scrollTop.bind(this);
    }

    scrollTop() {
        window.scrollTo({
            top: screen.height,
            behavior: 'smooth'
        })
    }

    render() {
        const { formatMessage } = this.props.intl;
        const { getSettings } = this.props;
        let path = "/images/homepage/";

        return (
            <div className={cx(s.layout4Container)}>
                <div className={cx(s.bannerBackgroundImage)}
                    style={{ backgroundImage: `url(${api.apiEndpoint + path + getSettings("homeSectionImage1")})` }} />
                <Container className={cx(s.bannerLayoutContainer)}>
                    <div className={s.bannerDisplayGrid}>
                        <div className={s.searchFormContainer}>
                            <div className={s.searchFormWrap}>
                                <h1>
                                    {getSettings("homeSectionTitle1")}
                                </h1>
                                <Link to='/store' className={cx(s.storeLink, 'storeLinkRTL')}>
                                    <span>
                                        {formatMessage(messages.registerStore)}
                                    </span>
                                    <span><img src={arrow} /></span>
                                </Link>
                            </div>
                        </div>
                        { /* <div className={s.mobileTop}>
                            <img src={api.apiEndpoint + path + getSettings("homeSectionImage2")} class="img-fluid" />
                        </div> */
                        }
                    </div>
                </Container>
            </div>
        );
    }
}

export default injectIntl(withStyles(s)(StoreBanner));