import React, { Component } from 'react';
import PreparationTime from '../../../components/Shop/PreparationTime/PreparationTime'
import { graphql } from 'react-apollo';
import { flowRight as compose } from 'lodash';
import withStyles from 'isomorphic-style-loader/withStyles';
import s from './PreparationTimeContainer.css';
import Loader from '../../../components/Common/Loader/Loader';
import getPreparationTime from './getPreparationTime.graphql';


export class PreparationTimeContainer extends Component {

    static defaultProps = {
        data: {
            loading: false
        }
    }
    render() {
        const { getPreparationTime: { loading, getPreparationTime } } = this.props;

        if (loading) {
            return <div><Loader type={"page"} show={loading}></Loader></div>
        } else {
            return (
                <div className={s.paddingRoutesSection}>
                    <PreparationTime  initialValues={getPreparationTime} />
                </div>
            )
        }
    }
}

export default compose(withStyles(s),
    graphql(getPreparationTime, {
        name: 'getPreparationTime',
        options: {
            fetchPolicy: 'network-only',
            ssr: false
        }
    })
)(PreparationTimeContainer)