import { closeSmsVerificationModal } from './smsVerificationModaAction';
import showToaster from '../../../helpers/toasterMessages/showToaster';
import { verifyOtp } from '../../../lib/graphql';

const checkOtp = (otp) => {
    
    return async (dispatch, getState, { client }) => {
        try {
            const { data } = await client.mutate({
                mutation: verifyOtp,
                variables: {
                    otp: otp
                }
            });

            if (data?.verifyOtpCode?.status === 200) {
                showToaster({ messageId: 'verifyOtp', toasterType: 'success' })
                await dispatch(closeSmsVerificationModal());
            } else {
                return false;
            }
        } catch (error) {
            return false;
        }
        return true;
    }
}

export default checkOtp;