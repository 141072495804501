import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage, injectIntl } from 'react-intl';
import withStyles from 'isomorphic-style-loader/withStyles';
import cx from 'classnames';
import s from './Login.css';
import messages from '../../../locale/messages';

import LoginForm from '../../../components/Shop/LoginForm/LoginForm';
class Login extends React.Component {

  static propTypes = {
    title: PropTypes.string.isRequired,
    
  };


  render() {
    
    return (
      <div className={s.root}>
        <div className={s.container}>
          
          <h1>{this.props.title}</h1>
          <LoginForm />
          
        </div>
      </div>
    )
  }
}

export default withStyles(s)(Login);