import React, { Component } from 'react'
import { Field, reduxForm, change, formValueSelector, initialize } from 'redux-form';
import { connect } from 'react-redux'
import withStyles from 'isomorphic-style-loader/withStyles';
import { injectIntl } from 'react-intl';
import cx from 'classnames';
import Form from 'react-bootstrap/Form';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Container from 'react-bootstrap/Container';
import Link from '../../../Link';

import ImageUploadComponent from '../../../Common/ImageUploadComponent/ImageUploadComponent';
import Loader from '../../../Common/Loader';

import updateDeliveryVehicleImage from '../../../../actions/siteadmin/DeliveryVehicle/updateDeliveryVehicleImage';

import validate from './validate';
import submit from './submit';
import { api, deliveryVehicleUploadDir } from '../../../../config';
import messages from '../../../../locale/messages';

import s from './DeliveryVehicleForm.css';
export class DeliveryVehicleForm extends Component {

    static defaultProps = {
        loading: false
    };

    renderField = ({ input, label, type, meta: { touched, error }, fieldClass, placeholder }) => {
        const { formatMessage } = this.props.intl
        return (
            <Form.Group className={cx(s.space5, 'positionRelative')}>
                <Form.Label>{label}</Form.Label>
                <Form.Control {...input} placeholder={placeholder} type={type} className={fieldClass} />
                {touched && error && <span className={cx(s.errorMessage, 'errorMessageRTL')}>{formatMessage(error)}</span>}
            </Form.Group>
        )
    }

    renderFormControlTextArea = ({ input, label, meta: { touched, error }, children, className }) => {
        const { formatMessage } = this.props.intl
        return (
            <Form.Group className={cx(s.space5, 'positionRelative')}>
                <label>{label}</label>
                <Form.Control
                    {...input}
                    className={className}
                    placeholder={label}
                    as="textarea"
                    rows="4"
                >
                    {children}
                </Form.Control>
                {touched && error && <span className={cx(s.errorMessage, 'errorMessageRTL')}>{formatMessage(error)}</span>}
            </Form.Group>
        );
    }

    handleDropzone = async (fileName, fieldName) => {
        const { change, updateDeliveryVehicleImage, id } = this.props;
        if (id) {
            await updateDeliveryVehicleImage(id, fileName, fieldName);
        }
        await change(fieldName, fileName);
    }

    render() {
        const { handleSubmit, vehicleImage, loading, submitting, id, imageLoader } = this.props;
        const { formatMessage } = this.props.intl;

        return (
            <div>
                <Container fluid>
                    <Form className={s.fullWidth} onSubmit={handleSubmit(submit)}>
                        <Row>
                            <Col lg={12} md={12} sm={12} xs={12} className={s.blackCenter}>
                                <div className={s.blackCenterSection}>
                                    <Row>
                                        <Col lg={6} md={12} sm={12} xs={12}>
                                            <Form.Group className={s.space5}>
                                                <div className={cx(s.centerFlex, s.profileImgSection)}>
                                                    <ImageUploadComponent
                                                        className={cx(s.btnSecondary, s.profileNoPadding)}
                                                        subTextClass={s.subText}
                                                        subText={formatMessage(messages.maximumUploadSizeLabel)}
                                                        defaultMessage={formatMessage(messages.chooseFile)}
                                                        loaderName={'deliveryVehicleImageLoader'}
                                                        postUrl={api.apiEndpoint + '/uploadDeliveryVehicleImage'}
                                                        loader={imageLoader}
                                                        fieldName={'vehicleImage'}
                                                        formName={'DeliveryVehicleForm'}
                                                        imageSrc={vehicleImage ? api.apiEndpoint + deliveryVehicleUploadDir + 'medium_' + vehicleImage : null}
                                                        label={formatMessage(messages.deliveryVehicleIcon)}
                                                        handleDropzone={this.handleDropzone}
                                                    />
                                                </div>
                                            </Form.Group>
                                        </Col>
                                        <Col lg={6} md={12} sm={12} xs={12}>
                                            <Field
                                                name="vehicleName"
                                                type="text"
                                                placeholder={formatMessage(messages.vehicleName)}
                                                component={this.renderField}
                                                label={formatMessage(messages.vehicleName)}
                                                labelClass={s.labelText}
                                                fieldClass={s.formControlInput}
                                            />
                                            <Form.Group className={cx(s.space5, 'positionRelative')}>
                                                <label>{formatMessage(messages.status)}</label>
                                                <Field name="isActive" className={cx(s.formControlSelect, s.formControlInput)} component="select">
                                                    <option value="true">{formatMessage(messages.active)}</option>
                                                    <option value="false">{formatMessage(messages.inactive)}</option>
                                                </Field>
                                            </Form.Group>
                                            <Field name="description" component={this.renderFormControlTextArea} label={formatMessage(messages.description)} />
                                        </Col>
                                        <Col lg={12} md={12} sm={12} xs={12} className={cx(s.textAlignRight, 'textAlignLeftRTL')}>
                                            <Form.Group className={s.noMargin}>
                                                <Link to={"/siteadmin/vehicle-type/list"} className={cx(s.backBtnTwo, s.btnSecondaryBorder, s.linkBtnInline, 'backBtnTwoRTL', s.backBtnTwoTop)} >{formatMessage(messages.goBack)}</Link>
                                                <div className={s.displayInlineBlock}>
                                                    <Loader
                                                        type={"button"}
                                                        label={id ? formatMessage(messages.update) : formatMessage(messages.submitButton)}
                                                        show={loading}
                                                        buttonType={'submit'}
                                                        className={cx(s.button, s.btnPrimary)}
                                                        disabled={submitting || loading}
                                                        isSuffix={true}
                                                    />
                                                </div>
                                            </Form.Group>
                                        </Col>
                                    </Row>
                                </div>
                            </Col>
                        </Row>
                    </Form>
                </Container>
            </div>
        )
    }
}

DeliveryVehicleForm = reduxForm({
    form: 'DeliveryVehicleForm',
    onSubmit: submit,
    validate
})(DeliveryVehicleForm);

const selector = formValueSelector('DeliveryVehicleForm');

const mapState = (state) => ({
    vehicleImage: selector(state, 'vehicleImage'),
    loading: state.loader.AddDeliveryVehicle,
    imageLoader: state.loader.deliveryVehicleImageLoader
})

const mapDispatch = {
    initialize,
    updateDeliveryVehicleImage,
    change
}

export default injectIntl(withStyles(s)(connect(mapState, mapDispatch)(DeliveryVehicleForm)));