import {
    CHECK_USER_AVAILABLE_START,
    CHECK_USER_AVAILABLE_SUCCESS,
    CHECK_USER_AVAILABLE_ERROR
} from '../../constants';
import { checkUserAvailabilityQuery as query } from '../../lib/graphql';

export const checkUserAvailability = (id) => {
    return async (dispatch, getState, { client }) => {
        dispatch({
            type: CHECK_USER_AVAILABLE_START,
        });
        try {

            const { data } = await client.query({
                query,
                variables: { id },
                fetchPolicy: 'network-only',
            });

            if (data && data.checkUserAvailability && data.checkUserAvailability.status == 200) {
                dispatch({
                    type: CHECK_USER_AVAILABLE_SUCCESS,
                });
                return true;
            } else {
                dispatch({
                    type: CHECK_USER_AVAILABLE_ERROR,
                });
                return false;
            }

        } catch (error) {
            dispatch({
                type: CHECK_USER_AVAILABLE_ERROR,
            });
            return false;
        }
    };
}