
import React from 'react';
import ModifierGroups from './modifierGroups';
import DashboardLayout from '../../../components/Shop/StoreLayout/Layout/DashboardLayout';
import NotFound from '../../not-found/NotFound';
import Layout from '../../../components/Layout';
import messages from '../../../locale/messages';

function action({ store, intl }) {
  const title = intl.formatMessage(messages.modifierGroup);

  let isStoreAuthenticated = store.getState().runtime.isStoreAuthenticated;
  let isEnableAddon = store.getState().siteSettings.data.modifiers;

  if (!isStoreAuthenticated) {
    return { redirect: '/store' };
  };

  if (isEnableAddon === '0') {
    return {
      title,
      component: <Layout><NotFound title='Page Not Found' /></Layout>,
      status: 404,
    };
  }

  return {
    title,
    component: <DashboardLayout><ModifierGroups title={title} /></DashboardLayout>,
  };
}

export default action;
