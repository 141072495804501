import {
    SHOP_USER_STATUS_CHANGE_START,
    SHOP_USER_STATUS_CHANGE_SUCCESS,
    SHOP_USER_STATUS_CHANGE_ERROR
} from '../../../constants/index';
import { loadAccount } from './userAccount';
import showToaster from '../../../helpers/toasterMessages/showToaster';
import { shopUserActivity as mutation } from '../../../lib/graphql';

const shopUserActivityChange = (isActive) => {
    return async (dispatch, getState, { client }) => {

        try {
            dispatch({
                type: SHOP_USER_STATUS_CHANGE_START
            });

            const { data } = await client.mutate({
                mutation,
                variables: {
                    isActive: isActive
                },
            });

            if (data?.shopUserActivityChange?.status === 200) {
                dispatch({
                    type: SHOP_USER_STATUS_CHANGE_SUCCESS
                })
                dispatch(loadAccount());

            } else {
                dispatch({
                    type: SHOP_USER_STATUS_CHANGE_ERROR
                });
                dispatch(loadAccount());
                showToaster({ messageId: 'commonToasterError', toasterType: 'error', requestMessage: data?.shopUserActivityChange?.errorMessage });
            }
        } catch (error) {
            dispatch({
                type: SHOP_USER_STATUS_CHANGE_ERROR
            });
        }
    }
}
export default shopUserActivityChange