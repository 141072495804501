import gql from "graphql-tag";

export const updateMenu = gql`mutation ($operationHours: String, $menuName: String, $id: Int, $isEnable: Boolean){
    updateMenu(operationHours: $operationHours, menuName: $menuName, id: $id, isEnable: $isEnable){
            status
            errorMessage
        }
    }`;

export const addMenuMutation = gql`mutation ($operationHours: String, $menuName: String, $isEnable: Boolean){
        addMenu(operationHours: $operationHours, menuName: $menuName, isEnable: $isEnable){
                status
                errorMessage
            }
       }`;

export const deleteItemMutation = gql`
            mutation deleteItem($id: Int) {
				deleteItem(id: $id) {
                  status
                  errorMessage
                }
              }
            `;
export const updateSubMenu = gql`
	mutation ($id: Int, $menuId: Int, $name: String, $isEnable: String){
        updateSubMenu(id: $id, menuId: $menuId, name: $name, isEnable: $isEnable){
                status
                errorMessage
            }
     }`

export const addSubMenuMutation = gql`mutation ($menuId: Int, $name: String, $isEnable: String){
        addSubMenu(menuId: $menuId, name: $name, isEnable: $isEnable){
                status
                errorMessage
            }
        }`;

export const subMenuStatusMutation = gql`
mutation updateSubMenuStatus($id: Int, $status: Boolean) {
	updateSubMenuStatus(id: $id, status: $status) {
	  status
	  errorMessage
	}
  }
  `;
export const deleteMenuMutation = gql`mutation deleteMenu($id: Int) {
	deleteMenu(id: $id) {
	  status
	  errorMessage
	}
  }
`
export const deleteSubMenuMutation = gql`mutation deleteSubMenu($id: Int) {
	deleteSubMenu(id: $id) {
	  status
	  errorMessage
	}
  }  
`;
export const updateItemMutation = gql`mutation ($id: Int, $menuId: Int, $subMenuId: Int, $itemName: String, $itemDescription: String, $price: Float, $tax: Float, $itemType: Int, $image: String, $isEnable: String, $modifiers: String) {
	updateItem(id: $id, menuId: $menuId, subMenuId: $subMenuId, itemName: $itemName, itemDescription: $itemDescription, price: $price, tax: $tax, itemType: $itemType, image: $image, isEnable: $isEnable, modifiers: $modifiers) {
	  status
	  errorMessage
	}
  }
`;

export const updateItemStatusMutation = gql`
mutation updateItemStatus($id: Int, $status: Boolean) {
	updateItemStatus(id: $id, status: $status) {
	  status
	  errorMessage
	}
  }
`
export const updateMenuStatusMutation = gql`mutation updateMenuStatus($id: Int, $status: Boolean) {
	updateMenuStatus(id: $id, status: $status) {
	  status
	  errorMessage
	}
  }
`;
export const getSubMenuQuery = gql`
query getSubMenu($id: Int!) {
	getSubMenu(id: $id) {
	  id
	  menuId
	  name
	  isEnable
	}
  }
`

export const getAllMenuQuery = gql`query ($currentPage: Int, $searchList: String) {
	getAllMenu(currentPage: $currentPage, searchList: $searchList) {
	  count
	  status
	  errorMessage
	  results {
		id
		shopId
		menuName
		isEnable
		menuAvailability {
		  menuId
		  operationDay
		  isOpen
		  isWholeDay
		}
	  }
	}
  }
`
export const getAllSubMenuQuery = gql`
	query($currentPage: Int, $searchList: String, $menuId: Int) {
		getAllSubMenu(currentPage: $currentPage, searchList: $searchList, menuId: $menuId){
		  status
		  errorMessage
		  menuName
		  count
		  results {
			id
			shopId
			menuId
			name
			isEnable
			item {
				id
				subMenuId
				menuId
				itemName
				itemDescription
				price
				tax
				image
				itemType
				isEnable
			}
		  }
		}  
	  }
	  `;

export const getItemQuery = gql`query getItem($id: Int!) {
	getItem(id: $id) {
	  id
	  menuId
	  itemName
	  itemDescription
	  price
	  tax
	  image
	  itemType
	  isEnable
	  subMenuId
	  modifiers {
		id
		itemId
		modifierGroupId
		modifierGroups {
		  id
		  modifierName
		  modifierType
		  minModifierItems
		  maxModifierItems
		  isModifierRequired
		  isModifierMultiple
		  maxItemCount
		  isActive
		  itemId
		  clonedBy
		  modifierCommonId
		  modifierItems {
			id
			modifierGroupId
			modifierItemName
			modifierItemPrice
		  }
		}
	  }
	}
  }
`