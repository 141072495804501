import {
    CATEGORY_UPDATE_SUCCESS,
    CATEGORY_UPDATE_START,
    CATEGORY_UPDATE_ERROR
} from '../../../constants';
import { updateCategoryStatus as mutation } from '../../../lib/graphql';
import showToaster from '../../../helpers/toasterMessages/showToaster';

const updateCategoryStatus = (id, isActive) => {
    return async (dispatch, getState, { client }) => {

        try {
            dispatch({
                type: CATEGORY_UPDATE_START
            });

            const { data } = await client.mutate({
                mutation,
                variables: {
                    id,
                    isActive: isActive == '1' ? 1 : 0
                }
            });

            if (data?.updateCategoryStatus?.status === 200) {
                dispatch({
                    type: CATEGORY_UPDATE_SUCCESS
                });
                showToaster({ messageId: 'updateCategoryStatus', toasterType: 'success' })
                return {
                    status: 200
                };
            } else {
                dispatch({
                    type: CATEGORY_UPDATE_ERROR
                });
                showToaster({ messageId: 'commonToasterError', toasterType: 'error', requestMessage: data?.updateCategoryStatus?.errorMessage })
                return {
                    status: 400
                };
            }
        } catch (err) {
            showToaster({ messageId: 'commonToasterError', toasterType: 'error', requestMessage: err })
            dispatch({
                type: CATEGORY_UPDATE_ERROR
            });
            return {
                status: 400
            };
        }
    }
};
export default updateCategoryStatus;