export default [
    {
        country: 'AL',
        fields: [
            {
                fieldName: 'routingNumber',
                fieldLabel: 'swiftBicCode',
                placeholder: 'AAAAALTXXXX'
            },
            {
                fieldName: 'accountNumber',
                fieldLabel: 'ibanNumber',
                placeholder: 'AL35202111090000000001234567'
            },
            {
                fieldName: 'confirmAccountNumber',
                fieldLabel: 'confirmIbanNumber',
                placeholder: 'AL35202111090000000001234567'
            }
        ]
    },
    {
        country: 'KZ',
        fields: [
            {
                fieldName: 'routingNumber',
                fieldLabel: 'swiftBicCode',
                placeholder: 'AAAAKZKZXXX'
            },
            {
                fieldName: 'accountNumber',
                fieldLabel: 'ibanNumber',
                placeholder: 'KZ221251234567890123'
            },
            {
                fieldName: 'confirmAccountNumber',
                fieldLabel: 'confirmIbanNumber',
                placeholder: 'KZ221251234567890123'
            }
        ]
    },
    {
        country: 'HU',
        fields: [
            {
                fieldName: 'accountNumber',
                fieldLabel: 'ibanNumber',
                placeholder: 'HU42117730161111101800000000'
            },
            {
                fieldName: 'confirmAccountNumber',
                fieldLabel: 'confirmIbanNumber',
                placeholder: 'HU42117730161111101800000000'
            }
        ]
    },
    {
        country: 'SA',
        fields: [
            {
                fieldName: 'routingNumber',
                fieldLabel: 'bic',
                placeholder: 'BCQ2411'
            },
            {
                fieldName: 'accountNumber',
                fieldLabel: 'ibanNumber',
                placeholder: 'SA0380000000608010167519'
            },
            {
                fieldName: 'confirmAccountNumber',
                fieldLabel: 'confirmIbanNumber',
                placeholder: 'SA0380000000608010167519'
            }
        ]
    },
    {
        country: 'GT',
        fields: [
            {
                fieldName: 'routingNumber',
                fieldLabel: 'bic',
                placeholder: 'AAAAGTGCXYZ'
            },
            {
                fieldName: 'accountNumber',
                fieldLabel: 'ibanNumber',
                placeholder: 'GT20AGRO00000000001234567890'
            },
            {
                fieldName: 'confirmAccountNumber',
                fieldLabel: 'confirmIbanNumber',
                placeholder: 'GT20AGRO00000000001234567890'
            }
        ]
    },
    {
        country: 'GI',
        fields: [
            {
                fieldName: 'accountNumber',
                fieldLabel: 'ibanNumber',
                placeholder: 'GI75NWBK000000007099453'
            },
            {
                fieldName: 'confirmAccountNumber',
                fieldLabel: 'confirmIbanNumber',
                placeholder: 'GI75NWBK000000007099453'
            }
        ]
    },
    {
        country: 'SM',
        fields: [
            {
                fieldName: 'routingNumber',
                fieldLabel: 'bic',
                placeholder: 'AAAASMSMXXX'
            },
            {
                fieldName: 'accountNumber',
                fieldLabel: 'ibanNumber',
                placeholder: 'SM86U0322509800000000270100'
            },
            {
                fieldName: 'confirmAccountNumber',
                fieldLabel: 'confirmIbanNumber',
                placeholder: 'SM86U0322509800000000270100'
            }
        ]
    },
    {
        country: 'PL',
        fields: [
            {
                fieldName: 'accountNumber',
                fieldLabel: 'ibanNumber',
                placeholder: 'PL61109010140000071219812874'
            },
            {
                fieldName: 'confirmAccountNumber',
                fieldLabel: 'confirmIbanNumber',
                placeholder: 'PL61109010140000071219812874'
            }
        ]
    },
    {
        country: 'IL',
        fields: [
            {
                fieldName: 'accountNumber',
                fieldLabel: 'ibanNumber',
                placeholder: 'IL620108000000099999999'
            },
            {
                fieldName: 'confirmAccountNumber',
                fieldLabel: 'confirmIbanNumber',
                placeholder: 'IL620108000000099999999'
            }
        ]
    },
    {
        country: 'SV',
        fields: [
            {
                fieldName: 'routingNumber',
                fieldLabel: 'swiftBicCode',
                placeholder: 'AAAASVS1XXX'
            },
            {
                fieldName: 'accountNumber',
                fieldLabel: 'ibanNumber',
                placeholder: 'SV44BCIE12345678901234567890'
            },
            {
                fieldName: 'confirmAccountNumber',
                fieldLabel: 'confirmIbanNumber',
                placeholder: 'SV44BCIE12345678901234567890'
            }
        ]
    },
    {
        country: 'AO',
        fields: [
            {
                fieldName: 'routingNumber',
                fieldLabel: 'swiftBicCode',
                placeholder: 'AAAAALTXXXX'
            },
            {
                fieldName: 'accountNumber',
                fieldLabel: 'ibanNumber',
                placeholder: 'AL35202111090000000001234567'
            },
            {
                fieldName: 'confirmAccountNumber',
                fieldLabel: 'confirmIbanNumber',
                placeholder: 'AL35202111090000000001234567'
            }
        ]
    },
    {
        country: 'JO',
        fields: [
            {
                fieldName: 'routingNumber',
                fieldLabel: 'swiftBicCode',
                placeholder: 'AAAAJOJOXXX'
            },
            {
                fieldName: 'accountNumber',
                fieldLabel: 'ibanNumber',
                placeholder: 'JO32ABCJ0010123456789012345678'
            },
            {
                fieldName: 'confirmAccountNumber',
                fieldLabel: 'confirmIbanNumber',
                placeholder: 'JO32ABCJ0010123456789012345678'
            }
        ]
    },
    {
        country: 'BS',
        fields: [
            {
                fieldName: 'routingNumber',
                fieldLabel: 'swiftBicCode',
                placeholder: 'AAAABSNSXXX'
            },
            {
                fieldName: 'accountNumber',
                fieldLabel: 'ibanNumber',
                placeholder: '0001234'
            },
            {
                fieldName: 'confirmAccountNumber',
                fieldLabel: 'confirmIbanNumber',
                placeholder: '0001234'
            }
        ]
    },
    {
        country: 'KW',
        fields: [
            {
                fieldName: 'routingNumber',
                fieldLabel: 'bic',
                placeholder: 'AAAAKWKWXYZ'
            },
            {
                fieldName: 'accountNumber',
                fieldLabel: 'ibanNumber',
                placeholder: 'KW81CBKU0000000000001234560101'
            },
            {
                fieldName: 'confirmAccountNumber',
                fieldLabel: 'confirmIbanNumber',
                placeholder: 'KW81CBKU0000000000001234560101'
            }
        ]
    },
    {
        country: 'MU',
        fields: [
            {
                fieldName: 'routingNumber',
                fieldLabel: 'bic',
                placeholder: 'AAAAMUMUXYZ'
            },
            {
                fieldName: 'accountNumber',
                fieldLabel: 'ibanNumber',
                placeholder: 'MU17BOMM0101101030300200000MUR'
            },
            {
                fieldName: 'confirmAccountNumber',
                fieldLabel: 'confirmIbanNumber',
                placeholder: 'MU17BOMM0101101030300200000MUR'
            }
        ]
    },
    {
        country: 'LI',
        fields: [
            {
                fieldName: 'accountNumber',
                fieldLabel: 'ibanNumber',
                placeholder: 'LI21088100002324013AA'
            },
            {
                fieldName: 'confirmAccountNumber',
                fieldLabel: 'confirmIbanNumber',
                placeholder: 'LI21088100002324013AA'
            }
        ]
    },
    {
        country: 'BH',
        fields: [
            {
                fieldName: 'routingNumber',
                fieldLabel: 'bic',
                placeholder: 'AAAABHBMXYZ'
            },
            {
                fieldName: 'accountNumber',
                fieldLabel: 'ibanNumber',
                placeholder: 'BH29BMAG1299123456BH00'
            },
            {
                fieldName: 'confirmAccountNumber',
                fieldLabel: 'confirmIbanNumber',
                placeholder: 'BH29BMAG1299123456BH00'
            }
        ]
    },
    {
        country: 'BA',
        fields: [
            {
                fieldName: 'routingNumber',
                fieldLabel: 'bic',
                placeholder: 'AAAABABAXXX'
            },
            {
                fieldName: 'accountNumber',
                fieldLabel: 'ibanNumber',
                placeholder: 'BA095520001234567812'
            },
            {
                fieldName: 'confirmAccountNumber',
                fieldLabel: 'confirmIbanNumber',
                placeholder: 'BA095520001234567812'
            }
        ]
    },
    {
        country: 'TR',
        fields: [
            {
                fieldName: 'routingNumber',
                fieldLabel: 'bic',
                placeholder: 'ADABTRIS'
            },
            {
                fieldName: 'accountNumber',
                fieldLabel: 'ibanNumber',
                placeholder: 'TR330006100519786457841326'
            },
            {
                fieldName: 'confirmAccountNumber',
                fieldLabel: 'confirmIbanNumber',
                placeholder: 'TR330006100519786457841326'
            }
        ]
    },
    {
        country: 'TW',
        fields: [
            {
                fieldName: 'routingNumber',
                fieldLabel: 'bic',
                placeholder: 'AAAATWTXXXX'
            },
            {
                fieldName: 'accountNumber',
                fieldLabel: 'ibanNumber',
                placeholder: '0001234567'
            },
            {
                fieldName: 'confirmAccountNumber',
                fieldLabel: 'confirmIbanNumber',
                placeholder: '0001234567'
            }
        ]
    },
    {
        country: 'RS',
        fields: [
            {
                fieldName: 'routingNumber',
                fieldLabel: 'bic',
                placeholder: 'BKCHRSBG'
            },
            {
                fieldName: 'accountNumber',
                fieldLabel: 'accountNumber',
                placeholder: 'RS35260005601001611379'
            },
            {
                fieldName: 'confirmAccountNumber',
                fieldLabel: 'confirmAccountNumber',
                placeholder: 'RS35260005601001611379'
            }
        ]
    },
    {
        country: 'BG',
        fields: [
            {
                fieldName: 'accountNumber',
                fieldLabel: 'ibanNumber',
                placeholder: 'BG80BNBG96611020345678'
            },
            {
                fieldName: 'confirmAccountNumber',
                fieldLabel: 'confirmIbanNumber',
                placeholder: 'BG80BNBG96611020345678'
            }
        ]
    },
    {
        country: 'EG',
        fields: [
            {
                fieldName: 'routingNumber',
                fieldLabel: 'bic',
                placeholder: 'NBEGEGCX331'
            },
            {
                fieldName: 'accountNumber',
                fieldLabel: 'ibanNumber',
                placeholder: 'EG573456789012345678901111111'
            },
            {
                fieldName: 'confirmAccountNumber',
                fieldLabel: 'confirmIbanNumber',
                placeholder: 'EG573456789012345678901111111'
            }
        ]
    },
    {
        country: 'CR',
        fields: [
            {
                fieldName: 'accountNumber',
                fieldLabel: 'ibanNumber',
                placeholder: 'CR04010212367856709123'
            },
            {
                fieldName: 'confirmAccountNumber',
                fieldLabel: 'confirmIbanNumber',
                placeholder: 'CR04010212367856709123'
            }
        ]
    },
    {
        country: 'GE',
        fields: [
            {
                fieldName: 'routingNumber',
                fieldLabel: 'bic',
                placeholder: 'AAAAGEGEXXX'
            },
            {
                fieldName: 'accountNumber',
                fieldLabel: 'ibanNumber',
                placeholder: 'GE13NB0123456789012345'
            },
            {
                fieldName: 'confirmAccountNumber',
                fieldLabel: 'confirmIbanNumber',
                placeholder: 'GE13NB0123456789012345'
            }
        ]
    },
    {
        country: 'GM',
        fields: [
            {
                fieldName: 'routingNumber',
                fieldLabel: 'bic',
                placeholder: 'AGIXGMGM'
            },
            {
                fieldName: 'accountNumber',
                fieldLabel: 'accountNumber',
                placeholder: '000123000456000789'
            },
            {
                fieldName: 'confirmAccountNumber',
                fieldLabel: 'confirmAccountNumber',
                placeholder: '000123000456000789'
            }
        ]
    },
    {
        country: 'DO',
        fields: [
            {
                fieldName: 'routingNumber',
                fieldLabel: 'payoutRouting',
                placeholder: '999'
            },
            {
                fieldName: 'bankCode',
                fieldLabel: 'bankCode',
                placeholder: '021'
            },
            {
                fieldName: 'branchCode',
                fieldLabel: 'branchCode',
                placeholder: '4232'
            },
            {
                fieldName: 'accountNumber',
                fieldLabel: 'accountNumber',
                placeholder: '000123456788'
            },
            {
                fieldName: 'confirmAccountNumber',
                fieldLabel: 'confirmAccountNumber',
                placeholder: '000123456788'
            }
        ]
    },
    {
        country: 'AT',
        fields: [
            {
                fieldName: 'accountNumber',
                fieldLabel: 'ibanNumber',
                placeholder: 'AT611904300234573201'
            },
            {
                fieldName: 'confirmAccountNumber',
                fieldLabel: 'confirmIbanNumber',
                placeholder: 'AT611904300234573201'
            }
        ]
    },
    {
        country: 'HR',
        fields: [
            {
                fieldName: 'accountNumber',
                fieldLabel: 'ibanNumber',
                placeholder: 'HR1210010051863000160'
            },
            {
                fieldName: 'confirmAccountNumber',
                fieldLabel: 'confirmIbanNumber',
                placeholder: 'HR1210010051863000160'
            }
        ]
    },
    {
        country: 'AR',
        fields: [
            {
                fieldName: 'accountNumber',
                fieldLabel: 'cbu',
                placeholder: '0110000600000000000000'
            },
            {
                fieldName: 'confirmAccountNumber',
                fieldLabel: 'confirmCbu',
                placeholder: '0110000600000000000000'
            }
        ]
    },
    {
        country: 'AM',
        fields: [
            {
                fieldName: 'routingNumber',
                fieldLabel: 'swiftBicCode',
                placeholder: 'AAAAAMNNXXX'
            },
            {
                fieldName: 'accountNumber',
                fieldLabel: 'accountNumber',
                placeholder: '00001234567'
            },
            {
                fieldName: 'confirmAccountNumber',
                fieldLabel: 'confirmAccountNumber',
                placeholder: '00001234567'
            }
        ]
    },
    {
        country: 'NG',
        fields: [
            {
                fieldName: 'routingNumber',
                fieldLabel: 'swiftBicCode',
                placeholder: 'AAAANGLAXXX'
            },
            {
                fieldName: 'accountNumber',
                fieldLabel: 'accountNumber',
                placeholder: '1111111112'
            },
            {
                fieldName: 'confirmAccountNumber',
                fieldLabel: 'confirmAccountNumber',
                placeholder: '1111111112'
            }
        ]
    },
    {
        country: 'MK',
        fields: [
            {
                fieldName: 'routingNumber',
                fieldLabel: 'swiftBicCode',
                placeholder: 'AAAAMK2XXXX'
            },
            {
                fieldName: 'accountNumber',
                fieldLabel: 'accountNumber',
                placeholder: 'MK49250120000058907'
            },
            {
                fieldName: 'confirmAccountNumber',
                fieldLabel: 'confirmAccountNumber',
                placeholder: 'MK49250120000058907'
            }
        ]
    },
    {
        country: 'GY',
        fields: [
            {
                fieldName: 'routingNumber',
                fieldLabel: 'bic',
                placeholder: 'AAAAGYGGXYZ'
            },
            {
                fieldName: 'accountNumber',
                fieldLabel: 'accountNumber',
                placeholder: '000123456789'
            },
            {
                fieldName: 'confirmAccountNumber',
                fieldLabel: 'confirmAccountNumber',
                placeholder: '000123456789'
            }
        ]
    },
    {
        country: 'AG',
        fields: [
            {
                fieldName: 'routingNumber',
                fieldLabel: 'bic',
                placeholder: 'AAAAAGAGXYZ'
            },
            {
                fieldName: 'accountNumber',
                fieldLabel: 'accountNumber',
                placeholder: '000123456789'
            },
            {
                fieldName: 'confirmAccountNumber',
                fieldLabel: 'confirmAccountNumber',
                placeholder: '000123456789'
            }
        ]
    },
    {
        country: 'KE',
        fields: [
            {
                fieldName: 'routingNumber',
                fieldLabel: 'bic',
                placeholder: 'BARCKENXMDR'
            },
            {
                fieldName: 'accountNumber',
                fieldLabel: 'accountNumber',
                placeholder: '0123456789'
            },
            {
                fieldName: 'confirmAccountNumber',
                fieldLabel: 'confirmAccountNumber',
                placeholder: '0123456789'
            }
        ]
    },
    {
        country: 'MO',
        fields: [
            {
                fieldName: 'routingNumber',
                fieldLabel: 'bic',
                placeholder: 'AAAAMOMXXXX'
            },
            {
                fieldName: 'accountNumber',
                fieldLabel: 'accountNumber',
                placeholder: '0000000001234567897'
            },
            {
                fieldName: 'confirmAccountNumber',
                fieldLabel: 'confirmAccountNumber',
                placeholder: '0000000001234567897'
            }
        ]
    },
    {
        country: 'OM',
        fields: [
            {
                fieldName: 'routingNumber',
                fieldLabel: 'bic',
                placeholder: 'AAAAOMOMXXX'
            },
            {
                fieldName: 'accountNumber',
                fieldLabel: 'accountNumber',
                placeholder: '000123456789'
            },
            {
                fieldName: 'confirmAccountNumber',
                fieldLabel: 'confirmAccountNumber',
                placeholder: '000123456789'
            }
        ]
    },
    {
        country: 'PK',
        fields: [
            {
                fieldName: 'routingNumber',
                fieldLabel: 'bic',
                placeholder: 'AAAAPKKAXXX'
            },
            {
                fieldName: 'accountNumber',
                fieldLabel: 'accountNumber',
                placeholder: 'PK36SCBL0000001123456702'
            },
            {
                fieldName: 'confirmAccountNumber',
                fieldLabel: 'confirmAccountNumber',
                placeholder: 'PK36SCBL0000001123456702'
            }
        ]
    },
    {
        country: 'TZ',
        fields: [
            {
                fieldName: 'routingNumber',
                fieldLabel: 'bic',
                placeholder: 'AAAATZTXXXX'
            },
            {
                fieldName: 'accountNumber',
                fieldLabel: 'accountNumber',
                placeholder: '0000123456789'
            },
            {
                fieldName: 'confirmAccountNumber',
                fieldLabel: 'confirmAccountNumber',
                placeholder: '0000123456789'
            }
        ]
    },
    {
        country: 'TH',
        fields: [
            {
                fieldName: 'routingNumber',
                fieldLabel: 'payoutRouting',
                placeholder: '999-0001'
            },
            {
                fieldName: 'bankName',
                fieldLabel: 'bankName',
                placeholder: 'Bangkok Bank Public Company Limited'
            },
            {
                fieldName: 'bankCode',
                fieldLabel: 'bankCode',
                placeholder: '002'
            },
            {
                fieldName: 'accountNumber',
                fieldLabel: 'accountNumber',
                placeholder: '12345678901236'
            },
            {
                fieldName: 'confirmAccountNumber',
                fieldLabel: 'confirmAccountNumber',
                placeholder: '12345678901236'
            }
        ]
    },
    {
        country: 'TT',
        fields: [
            {
                fieldName: 'routingNumber',
                fieldLabel: 'swiftBicCode',
                placeholder: '99900001'
            },
            {
                fieldName: 'bankCode',
                fieldLabel: 'bankCode',
                placeholder: '999'
            },
            {
                fieldName: 'branchCode',
                fieldLabel: 'branchCode',
                placeholder: '00001'
            },
            {
                fieldName: 'accountNumber',
                fieldLabel: 'accountNumber',
                placeholder: '00001234123456788'
            },
            {
                fieldName: 'confirmAccountNumber',
                fieldLabel: 'confirmAccountNumber',
                placeholder: '00001234123456788'
            }
        ]
    },
    {
        country: 'ZA',
        fields: [
            {
                fieldName: 'routingNumber',
                fieldLabel: 'bic',
                placeholder: 'FIRNZAJJ'
            },
            {
                fieldName: 'accountNumber',
                fieldLabel: 'accountNumber',
                placeholder: '2140000054'
            },
            {
                fieldName: 'confirmAccountNumber',
                fieldLabel: 'confirmAccountNumber',
                placeholder: '2140000054'
            }
        ]
    },
    {
        country: 'KR',
        fields: [
            {
                fieldName: 'routingNumber',
                fieldLabel: 'bic',
                placeholder: 'TESTKR00123'
            },
            {
                fieldName: 'accountNumber',
                fieldLabel: 'accountNumber',
                placeholder: '012345678912'
            },
            {
                fieldName: 'confirmAccountNumber',
                fieldLabel: 'confirmAccountNumber',
                placeholder: '012345678912'
            }
        ]
    },
    {
        country: 'LK',
        fields: [
            {
                fieldName: 'bankCode',
                fieldLabel: 'bankCode',
                placeholder: 'AAAALKLXXXX'
            },
            {
                fieldName: 'branchCode',
                fieldLabel: 'branchCode',
                placeholder: '7010999'
            },
            {
                fieldName: 'accountNumber',
                fieldLabel: 'accountNumber',
                placeholder: '0000012345'
            },
            {
                fieldName: 'confirmAccountNumber',
                fieldLabel: 'confirmAccountNumber',
                placeholder: '0000012345'
            }
        ]
    },
    {
        country: 'LC',
        fields: [
            {
                fieldName: 'routingNumber',
                fieldLabel: 'bic',
                placeholder: 'AAAALCLCXYZ'
            },
            {
                fieldName: 'accountNumber',
                fieldLabel: 'accountNumber',
                placeholder: '000123456789'
            },
            {
                fieldName: 'confirmAccountNumber',
                fieldLabel: 'confirmAccountNumber',
                placeholder: '000123456789'
            }
        ]
    },
    {
        country: 'QA',
        fields: [
            {
                fieldName: 'routingNumber',
                fieldLabel: 'bic',
                placeholder: 'AAAAMK2XXXX'
            },
            {
                fieldName: 'accountNumber',
                fieldLabel: 'accountNumber',
                placeholder: 'QA87CITI123456789012345678901'
            },
            {
                fieldName: 'confirmAccountNumber',
                fieldLabel: 'confirmAccountNumber',
                placeholder: 'QA87CITI123456789012345678901'
            }
        ]
    },
    {
        country: 'PH',
        fields: [
            {
                fieldName: 'routingNumber',
                fieldLabel: 'bic',
                placeholder: 'BOPIPHMM'
            },
            {
                fieldName: 'accountNumber',
                fieldLabel: 'accountNumber',
                placeholder: '0123456789010'
            },
            {
                fieldName: 'confirmAccountNumber',
                fieldLabel: 'confirmAccountNumber',
                placeholder: '0123456789010'
            }
        ]
    },
    {
        country: 'PA',
        fields: [
            {
                fieldName: 'routingNumber',
                fieldLabel: 'bic',
                placeholder: 'AAAAPAPAXXX'
            },
            {
                fieldName: 'accountNumber',
                fieldLabel: 'accountNumber',
                placeholder: '000123456789'
            },
            {
                fieldName: 'confirmAccountNumber',
                fieldLabel: 'confirmAccountNumber',
                placeholder: '000123456789'
            }
        ]
    },
    {
        country: 'BT',
        fields: [
            {
                fieldName: 'routingNumber',
                fieldLabel: 'swiftBicCode',
                placeholder: 'AAAABTBTXXX'
            },
            {
                fieldName: 'accountNumber',
                fieldLabel: 'accountNumber',
                placeholder: '0000123456789'
            },
            {
                fieldName: 'confirmAccountNumber',
                fieldLabel: 'confirmAccountNumber',
                placeholder: '0000123456789'
            }
        ]
    },
    {
        country: 'BO',
        fields: [
            {
                fieldName: 'routingNumber',
                fieldLabel: 'bankCode',
                placeholder: '040'
            },
            {
                fieldName: 'accountNumber',
                fieldLabel: 'accountNumber',
                placeholder: '000123456789'
            },
            {
                fieldName: 'confirmAccountNumber',
                fieldLabel: 'confirmAccountNumber',
                placeholder: '000123456789'
            }
        ]
    },
    {
        country: 'ET',
        fields: [
            {
                fieldName: 'routingNumber',
                fieldLabel: 'swiftBicCode',
                placeholder: 'AAAAETETXXX'
            },
            {
                fieldName: 'accountNumber',
                fieldLabel: 'accountNumber',
                placeholder: '0000000012345'
            },
            {
                fieldName: 'confirmAccountNumber',
                fieldLabel: 'confirmAccountNumber',
                placeholder: '0000000012345'
            }
        ]
    },
    {
        country: 'DZ',
        fields: [
            {
                fieldName: 'routingNumber',
                fieldLabel: 'swiftBicCode',
                placeholder: 'AAAADZDZXXX'
            },
            {
                fieldName: 'accountNumber',
                fieldLabel: 'accountNumber',
                placeholder: 'AL35202111090000000001234567'
            },
            {
                fieldName: 'confirmAccountNumber',
                fieldLabel: 'confirmAccountNumber',
                placeholder: 'AL35202111090000000001234567'
            }
        ]
    },
    {
        country: 'MD',
        fields: [
            {
                fieldName: 'routingNumber',
                fieldLabel: 'swiftBicCode',
                placeholder: 'AAAAMDMDXXX'
            },
            {
                fieldName: 'accountNumber',
                fieldLabel: 'accountNumber',
                placeholder: 'MD07AG123456789012345678'
            },
            {
                fieldName: 'confirmAccountNumber',
                fieldLabel: 'confirmAccountNumber',
                placeholder: 'MD07AG123456789012345678'
            }
        ]
    },
    {
        country: 'NA',
        fields: [
            {
                fieldName: 'routingNumber',
                fieldLabel: 'swiftBicCode',
                placeholder: 'AAAANANXXYZ'
            },
            {
                fieldName: 'accountNumber',
                fieldLabel: 'accountNumber',
                placeholder: '000123456789'
            },
            {
                fieldName: 'confirmAccountNumber',
                fieldLabel: 'confirmAccountNumber',
                placeholder: '000123456789'
            }
        ]
    },
    {
        country: 'BN',
        fields: [
            {
                fieldName: 'routingNumber',
                fieldLabel: 'swiftBicCode',
                placeholder: 'AAAABNBBXXX'
            },
            {
                fieldName: 'accountNumber',
                fieldLabel: 'accountNumber',
                placeholder: '0000123456789'
            },
            {
                fieldName: 'confirmAccountNumber',
                fieldLabel: 'confirmAccountNumber',
                placeholder: '0000123456789'
            }
        ]
    },
    {
        country: 'EC',
        fields: [
            {
                fieldName: 'routingNumber',
                fieldLabel: 'swiftBicCode',
                placeholder: 'AAAAECE1XXX'
            },
            {
                fieldName: 'accountNumber',
                fieldLabel: 'accountNumber',
                placeholder: '000123456789'
            },
            {
                fieldName: 'confirmAccountNumber',
                fieldLabel: 'confirmAccountNumber',
                placeholder: '000123456789'
            }
        ]
    },
    {
        country: 'KH',
        fields: [
            {
                fieldName: 'routingNumber',
                fieldLabel: 'swiftBicCode',
                placeholder: 'AAAAKHKHXXX'
            },
            {
                fieldName: 'accountNumber',
                fieldLabel: 'accountNumber',
                placeholder: '000123456789'
            },
            {
                fieldName: 'confirmAccountNumber',
                fieldLabel: 'confirmAccountNumber',
                placeholder: '000123456789'
            }
        ]
    },
    {
        country: 'AU',
        fields: [
            {
                fieldName: 'bsbNumber',
                fieldLabel: 'bsbNumber',
                placeholder: '123456'
            },
            {
                fieldName: 'routingNumber',
                fieldLabel: 'payoutRouting',
                placeholder: '110000'
            },
            {
                fieldName: 'accountNumber',
                fieldLabel: 'accountNumber',
                placeholder: '12345678'
            },
            {
                fieldName: 'confirmAccountNumber',
                fieldLabel: 'confirmAccountNumber',
                placeholder: '12345678'
            }
        ]
    },
    {
        country: 'CA',
        fields: [
            {
                fieldName: 'transitNumber',
                fieldLabel: 'transitNumber',
                placeholder: '11000'
            },
            {
                fieldName: 'institutionNumber',
                fieldLabel: 'institutionNumber',
                placeholder: '000'
            },
            {
                fieldName: 'accountNumber',
                fieldLabel: 'accountNumber',
                placeholder: '000123456789'
            },
            {
                fieldName: 'confirmAccountNumber',
                fieldLabel: 'confirmAccountNumber',
                placeholder: '000123456789'
            }
        ]
    },
    {
        country: 'DK',
        fields: [
            {
                fieldName: 'accountNumber',
                fieldLabel: 'ibanNumber',
                placeholder: 'DK5000400440116243'
            },
            {
                fieldName: 'confirmAccountNumber',
                fieldLabel: 'confirmIbanNumber',
                placeholder: 'DK5000400440116243'
            }
        ]
    },
    {
        country: 'BR',
        fields: [
           
            {
                fieldName: 'bankCode',
                fieldLabel: 'bankCode',
                placeholder: '110'
            },
            {
                fieldName: 'branchCode',
                fieldLabel: 'branchCode',
                placeholder: '0000'
            },
            {
                fieldName: 'accountNumber',
                fieldLabel: 'accountNumber',
                placeholder: '0001234'
            },
            {
                fieldName: 'confirmAccountNumber',
                fieldLabel: 'confirmAccountNumber',
                placeholder: '0001234'
            }
        ]
    },
    {
        country: 'HK',
        fields: [
            {
                fieldName: 'bankCode',
                fieldLabel: 'clearingCode',
                placeholder: '123'
            },
            {
                fieldName: 'branchCode',
                fieldLabel: 'branchCode',
                placeholder: '456'
            },
            {
                fieldName: 'routingNumber',
                fieldLabel: 'payoutRouting',
                placeholder: '110-000'
            },
            {
                fieldName: 'accountNumber',
                fieldLabel: 'accountNumber',
                placeholder: '123456-789'
            },
            {
                fieldName: 'confirmAccountNumber',
                fieldLabel: 'confirmAccountNumber',
                placeholder: '123456-789'
            }
        ]
    },
    {
        country: 'JP',
        fields: [
            {
                fieldName: 'bankName',
                fieldLabel: 'bankName',
                placeholder: 'いろは銀行'
            },
            {
                fieldName: 'branchName',
                fieldLabel: 'branchName',
                placeholder: '東京支店'
            },
            {
                fieldName: 'accountOwnerName',
                fieldLabel: 'accountOwnerName',
                placeholder: 'ヤマダハナコ'
            },
            {
                fieldName: 'bankCode',
                fieldLabel: 'bankCode',
                placeholder: '1100'
            },
            {
                fieldName: 'branchCode',
                fieldLabel: 'branchCode',
                placeholder: '000'
            },
            {
                fieldName: 'accountNumber',
                fieldLabel: 'accountNumber',
                placeholder: '0001234'
            },
            {
                fieldName: 'confirmAccountNumber',
                fieldLabel: 'confirmAccountNumber',
                placeholder: '0001234'
            },
        ]
    },
    {
        country: 'MY',
        fields: [
            {
                fieldName: 'routingNumber',
                fieldLabel: 'payoutRouting',
                placeholder: 'TESTMYKLXXX'
            },
            {
                fieldName: 'accountNumber',
                fieldLabel: 'accountNumber',
                placeholder: '1234567890'
            },
            {
                fieldName: 'confirmAccountNumber',
                fieldLabel: 'confirmAccountNumber',
                placeholder: '1234567890'
            }
        ]
    },
    {
        country: 'MX',
        fields: [
            {
                fieldName: 'accountNumber',
                fieldLabel: 'clabe',
                placeholder: '000000001234567897'
            },
            {
                fieldName: 'confirmAccountNumber',
                fieldLabel: 'confirmClabe',
                placeholder: '000000001234567897'
            }
        ]
    },
    {
        country: 'IN',
        fields: [
            {
                fieldName: 'routingNumber',
                fieldLabel: 'payoutRouting',
                placeholder: 'HDFC0004051'
            },
            {
                fieldName: 'accountNumber',
                fieldLabel: 'accountNumber',
                placeholder: 'AL35202111090000000001234567'
            },
            {
                fieldName: 'confirmAccountNumber',
                fieldLabel: 'confirmAccountNumber',
                placeholder: 'AL35202111090000000001234567'
            }
        ]
    },
    {
        country: 'NZ',
        fields: [
            {
                fieldName: 'accountNumber',
                fieldLabel: 'accountNumber',
                placeholder: 'AABBBB3456789YZZ'
            },
            {
                fieldName: 'confirmAccountNumber',
                fieldLabel: 'confirmAccountNumber',
                placeholder: 'AABBBB3456789YZZ'
            }
        ]
    },
    {
        country: 'NO',
        fields: [
            {
                fieldName: 'accountNumber',
                fieldLabel: 'ibanNumber',
                placeholder: 'NO9386011117947'
            },
            {
                fieldName: 'confirmAccountNumber',
                fieldLabel: 'confirmIbanNumber',
                placeholder: 'NO9386011117947'
            }
        ]
    },
    {
        country: 'PL',
        fields: [
            {
                fieldName: 'accountNumber',
                fieldLabel: 'ibanNumber',
                placeholder: 'PL61109010140000071219812874'
            },
            {
                fieldName: 'confirmAccountNumber',
                fieldLabel: 'confirmIbanNumber',
                placeholder: 'PL61109010140000071219812874'
            }
        ]
    },
    {
        country: 'RO',
        fields: [
            {
                fieldName: 'accountNumber',
                fieldLabel: 'ibanNumber',
                placeholder: 'RO49AAAA1B31007593840000'
            },
            {
                fieldName: 'confirmAccountNumber',
                fieldLabel: 'confirmIbanNumber',
                placeholder: 'RO49AAAA1B31007593840000'
            }
        ]
    },
    {
        country: 'SG',
        fields: [
            {
                fieldName: 'bankCode',
                fieldLabel: 'bankCode',
                placeholder: '1100'
            },
            {
                fieldName: 'branchCode',
                fieldLabel: 'branchCode',
                placeholder: '000'
            },
            {
                fieldName: 'accountNumber',
                fieldLabel: 'accountNumber',
                placeholder: '000123456'
            },
            {
                fieldName: 'confirmAccountNumber',
                fieldLabel: 'confirmAccountNumber',
                placeholder: '000123456'
            }
        ]
    },
    {
        country: 'SE',
        fields: [
            {
                fieldName: 'accountNumber',
                fieldLabel: 'ibanNumber',
                placeholder: 'SE3550000000054910000003'
            },
            {
                fieldName: 'confirmAccountNumber',
                fieldLabel: 'confirmIbanNumber',
                placeholder: 'SE3550000000054910000003'
            }
        ]
    },
    {
        country: 'CH',
        fields: [
            {
                fieldName: 'accountNumber',
                fieldLabel: 'ibanNumber',
                placeholder: 'CH9300762011623852957'
            },
            {
                fieldName: 'confirmAccountNumber',
                fieldLabel: 'confirmIbanNumber',
                placeholder: 'CH9300762011623852957'
            }
        ]
    },
    {
        country: 'ID',
        fields: [
            {
                fieldName: 'bankName',
                fieldLabel: 'bankName',
                placeholder: 'UOB Buana'
            },
            {
                fieldName: 'bankCode',
                fieldLabel: 'bankCode',
                placeholder: '023'
            },
            {
                fieldName: 'routingNumber',
                fieldLabel: 'payoutRouting',
                placeholder: '000'
            },
            {
                fieldName: 'accountNumber',
                fieldLabel: 'accountNumber',
                placeholder: '000123456789'
            },
            {
                fieldName: 'confirmAccountNumber',
                fieldLabel: 'confirmAccountNumber',
                placeholder: '000123456789'
            }
        ]
    },
    {
        country: 'PY',
        fields: [
            {
                fieldName: 'routingNumber',
                fieldLabel: 'payoutRouting',
                placeholder: '0'
            },
            {
                fieldName: 'bankCode',
                fieldLabel: 'bankCode',
                placeholder: '27'
            },
            {
                fieldName: 'accountNumber',
                fieldLabel: 'accountNumber',
                placeholder: '0000000123456789'
            },
            {
                fieldName: 'confirmAccountNumber',
                fieldLabel: 'confirmAccountNumber',
                placeholder: '0000000123456789'
            }
        ]
    },
    {
        country: 'GB',
        fields: [
            {
                fieldName: 'sortCode',
                fieldLabel: 'sortCode',
                placeholder: '108800'
            },
            {
                fieldName: 'accountNumber',
                fieldLabel: 'accountNumber',
                placeholder: 'GB82WEST12345698765432'
            },
            {
                fieldName: 'confirmAccountNumber',
                fieldLabel: 'confirmAccountNumber',
                placeholder: 'GB82WEST12345698765432'
            }
        ]
    },
    {
        country: 'PE',
        fields: [
            {
                fieldName: 'accountNumber',
                fieldLabel: 'accountNumber',
                placeholder: '99934500012345670024'
            },
            {
                fieldName: 'confirmAccountNumber',
                fieldLabel: 'confirmAccountNumber',
                placeholder: '99934500012345670024'
            }
        ]
    },
    {
        country: 'US',
        fields: [
            {
                fieldName: 'routingNumber',
                fieldLabel: 'payoutRouting',
                placeholder: '110000000'
            },
            {
                fieldName: 'accountNumber',
                fieldLabel: 'accountNumber',
                placeholder: '000123456789'
            },
            {
                fieldName: 'confirmAccountNumber',
                fieldLabel: 'confirmAccountNumber',
                placeholder: '000123456789'
            }
        ]
    },
    {
        country: 'AE',
        fields: [
            {
                fieldName: 'accountNumber',
                fieldLabel: 'ibanNumber',
                placeholder: 'AE070331234567890123456'
            },
            {
                fieldName: 'confirmAccountNumber',
                fieldLabel: 'confirmIbanNumber',
                placeholder: 'AE070331234567890123456'
            }
        ]
    },
    {
        country: 'UY',
        fields: [
            {
                fieldName: 'routingNumber',
                fieldLabel: 'payoutRouting',
                placeholder: '999'
            },
            {
                fieldName: 'bankCode',
                fieldLabel: 'bankCode',
                placeholder: '091'
            },
            {
                fieldName: 'accountNumber',
                fieldLabel: 'accountNumber',
                placeholder: '000123456788'
            },
            {
                fieldName: 'confirmAccountNumber',
                fieldLabel: 'confirmAccountNumber',
                placeholder: '000123456788'
            }
        ]
    },
    {
        country: 'UZ',
        fields: [
            {
                fieldName: 'bankCode',
                fieldLabel: 'bankCode',
                placeholder: 'AAAAUZUZXXX'
            },
            {
                fieldName: 'branchCode',
                fieldLabel: 'branchCode',
                placeholder: '00000'
            },
            {
                fieldName: 'accountNumber',
                fieldLabel: 'accountNumber',
                placeholder: '99934500012345670024'
            },
            {
                fieldName: 'confirmAccountNumber',
                fieldLabel: 'confirmAccountNumber',
                placeholder: '99934500012345670024'
            }
        ]
    },
    {
        country: 'VN',
        fields: [
            {
                fieldName: 'routingNumber',
                fieldLabel: 'payoutRouting',
                placeholder: '01101100'
            },
            {
                fieldName: 'bankCode',
                fieldLabel: 'bankCode',
                placeholder: '01101100'
            },
            {
                fieldName: 'accountNumber',
                fieldLabel: 'accountNumber',
                placeholder: '000123456789'
            },
            {
                fieldName: 'confirmAccountNumber',
                fieldLabel: 'confirmAccountNumber',
                placeholder: '000123456789'
            }
        ]
    }
]