import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Field, reduxForm } from 'redux-form';
import { injectIntl, FormattedMessage } from 'react-intl';
import withStyles from 'isomorphic-style-loader/withStyles';
import cx from 'classnames';
import { Form, Card, FormControl } from 'react-bootstrap';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';

import Loader from '../../Common/Loader';
import PlacesSuggest from '../PlacesSuggest';
import Link from '../../Link/Link';
import LoginModal from '../LoginModal/LoginModal';
import HeaderModal from '../../HeaderModal/HeaderModal';
import ForgotPasswordModal from '../ForgotPassword/ForgotPasswordModal';

import s from './SignupForm.css';
import rs from '../../storeCommon.css';

// Action
import { openLoginModal } from '../../../actions/modalActions';
import { openHeaderModal } from '../../../actions/siteadmin/modalActions';

import { formatLocale } from '../../../helpers/formatLocale';
import messages from '../../../locale/messages';
import validate from './validate';
import submit from './submit';
import { api, partnerHomepageUploadDir, logoUploadDir } from '../../../config';

// images
import languageIcon from '../../../../public/Icons/Language-black.svg';
import arrow from '../../../../public/Icons/right.svg';
import backArrow from '../../../../public/SiteImages/signupBackArrow.png';
import mainSiteIcon from '../../../../public/Icons/store.svg';

export class SignupForm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      countryCode: 'US',
      countryDialCode: '+1',
      phoneNumber: '',
      categories: [],
    };

    this.handleCountryChange = this.handleCountryChange.bind(this);
    this.handleLocationData = this.handleLocationData.bind(this);
    this.handleOnChange = this.handleOnChange.bind(this);
    this.changedeliveryType = this.changedeliveryType.bind(this);
    this.handleChange = this.handleChange.bind(this);
  }

  static defaultProps = {
    loading: false,
  };

  renderField = ({
    input,
    label,
    type,
    meta: { touched, error },
    fieldClass,
    placeholder,
  }) => {
    const { formatMessage } = this.props.intl;
    return (
      <>
        <label>{label}</label>
        <Form.Control
          {...input}
          placeholder={placeholder}
          type={type}
          className={fieldClass}
        />
        {touched && error && (
          <span className={rs.errorMessage}>{formatMessage(error)}</span>
        )}
      </>
    );
  };

  renderFormControlSelect = ({
    input,
    label,
    meta: { touched, error },
    children,
    className,
  }) => {
    const { formatMessage } = this.props.intl;
    return (
      <>
        <label>{label}</label>
        <FormControl as="select" {...input} className={className}>
          {children}
        </FormControl>
        {touched && error && (
          <span className={rs.errorMessage}>{formatMessage(error)}</span>
        )}
      </>
    );
  };

  renderAddress = ({
    input,
    label,
    type,
    meta: { touched, error },
    fieldClass,
    placeholder,
  }) => {
    const { formatMessage } = this.props.intl;
    return (
      <>
        <label>{label}</label>
        <PlacesSuggest
          form="signup"
          label={formatMessage(messages.address)}
          handleData={this.handleLocationData}
          error={error}
        />
        {touched && error && (
          <span className={rs.errorMessage}>{formatMessage(error)}</span>
        )}
      </>
    );
  };

  renderPhoneNumber = ({ meta: { touched, error }, label }) => {
    const { formatMessage } = this.props.intl;
    const { phoneNumber } = this.state;
    return (
      <>
        <label>{label}</label>
        <PhoneInput
          country="us"
          value={phoneNumber}
          onChange={this.handleOnChange}
          countryCodeEditable={false}
        />
        {touched && error && (
          <span className={rs.errorMessage}>{formatMessage(error)}</span>
        )}
      </>
    );
  };

  componentDidMount() {
    const { initialize, getCategory } = this.props;
    const { countryCode, countryDialCode } = this.state;

    initialize({
      phoneCountryCode: countryCode,
      phoneDialCode: countryDialCode,
    });
  }

  handleLocationData(location) {
    const { change } = this.props;

    const addressLabel = location && location.label;
    const addressPosition = location && location.location;
    const locationArray =
      addressLabel &&
      addressLabel
        .split(',')
        .slice(-3)
        .reverse();

    change('address', addressLabel);
    change(
      'city',
      locationArray && locationArray[2] && locationArray[2].trim(),
    );
    change(
      'state',
      locationArray && locationArray[1] && locationArray[1].trim(),
    );
    change(
      'country',
      locationArray && locationArray[0] && locationArray[0].trim(),
    );
    change('lat', addressPosition && addressPosition.lat);
    change('lng', addressPosition && addressPosition.lng);
  }

  handleCountryChange(country, event) {
    const { change } = this.props;

    this.setState({
      countryCode: country.countryCode,
      countryDialCode: country.dialCode,
    });

    change('phoneCountryCode', country.countryCode);
    change('phoneDialCode', country.dialCode);
  }

  handleOnChange(value, event) {
    const { change } = this.props;

    this.setState({ phoneNumber: value });
    const formattedNumber = value
      .replace(/[^0-9]+/g, '')
      .slice(event.dialCode.length);
    const dialCode = `+${event.dialCode}`;
    const countryCode = event.countryCode.toUpperCase();

    change('phoneCountryCode', countryCode);
    change('phoneDialCode', dialCode);
    change('phoneNumber', formattedNumber);
  }

  changedeliveryType(e) {
    const { change, getCategory } = this.props;
    const newValue = e.target.value;
    const data =
      getCategory &&
      getCategory.result &&
      getCategory.result.length > 0 &&
      getCategory.result.filter(o => o.deliveryType == newValue);
    this.setState({
      categories: data,
    });
  }

  handleChange(e) {
    const { openHeaderModal } = this.props;
    openHeaderModal('languageModal');
  }

  render() {
    const {
      handleSubmit,
      submitting,
      loading,
      error,
      deliveryTypes,
      homepage,
    } = this.props;
    const {
      openLoginModal,
      currentLocale,
      logo,
      logoHeight,
      logoWidth,
      siteName,
    } = this.props;
    const {
      countryCode,
      countryDialCode,
      phoneNumber,
      categories,
    } = this.state;
    const { formatMessage } = this.props.intl;
    return (
      <div className={cx('tabLabelText', 'placeHolderText')}>
        <LoginModal />
        <ForgotPasswordModal />
        <HeaderModal />
        <div className={s.displayGrid}>
          <div
            className={s.bgImage}
            style={{
              backgroundImage: `url(${api.apiEndpoint +
                partnerHomepageUploadDir +
                homepage.bannerImage2})`,
            }}
          >
            <div className={s.positionRelative}>
              <div className={s.innerSection}>
                <img
                  src={api.apiEndpoint + logoUploadDir + logo}
                  width={Number(logoWidth)}
                  height={Number(logoHeight)}
                  alt={siteName}
                />
                <div className={s.searchFormWrap}>
                  <h1 className={s.searchFormWrapTitleText}>
                    {homepage.bannerTitle1}
                  </h1>
                  <p className={s.searchFormWrapDescText}>
                    {homepage.bannerContent1}
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div>
            <div
              className={cx(
                rs.displayFlex,
                rs.alignItemCenter,
                rs.justifyContentEnd,
                s.headerLinkSection,
                rs.flexWrap,
              )}
            >
              <Link
                to="/"
                className={cx(
                  s.siteColor,
                  s.cursorPointer,
                  rs.displayFlex,
                  rs.alignItemCenter,
                  s.iconGap,
                  rs.textDecoration,
                )}
              >
                <img src={mainSiteIcon} className={s.backBtnImg} />
                <span>{formatMessage(messages.goToMainSite)}</span>
              </Link>
              <Link
                noLink
                onClick={e => this.handleChange(e)}
                className={cx(
                  s.siteColor,
                  s.cursorPointer,
                  rs.displayFlex,
                  rs.alignItemCenter,
                  s.iconGap,
                )}
              >
                <img src={languageIcon} className={s.languageIcon} />
                <span>{formatLocale(currentLocale)}</span>
              </Link>
              <Link
                noLink
                onClick={openLoginModal}
                className={cx(
                  rs.btnPrimary,
                  s.cursorPointer,
                  rs.displayFlex,
                  rs.alignItemCenter,
                  s.iconGap,
                  s.signInRadius,
                )}
              >
                <span>
                  <FormattedMessage {...messages.signin} />
                </span>
                <img src={arrow} className={s.backBtnImg} />
              </Link>
            </div>
            <div className={s.sectionTwo}>
              <Card className={s.card}>
                <Form className={s.fullWidth} onSubmit={handleSubmit(submit)}>
                  <Form.Group className={cx(rs.space4, 'signupCategorySelect')}>
                    <Field
                      name="deliveryType"
                      className={cx(s.formControlSelect, s.formControlInput)}
                      component={this.renderFormControlSelect}
                      label={formatMessage(messages.typeOfDeliverly)}
                      placeholder={formatMessage(messages.chooseDelivery)}
                      onChange={this.changedeliveryType}
                    >
                      <option value="">
                        {formatMessage(messages.typeOfDeliverly)}
                      </option>
                      {deliveryTypes &&
                        deliveryTypes.results &&
                        deliveryTypes.results.map((item, key) => (
                          <option value={item.id} key={key}>
                            {item.deliveryName}
                          </option>
                        ))}
                    </Field>
                    {error && (
                      <span className={rs.errorMessage}>
                        {error.defaultMessage}
                      </span>
                    )}
                  </Form.Group>
                  <Form.Group className={cx(rs.space4, 'signupCategorySelect')}>
                    <Field
                      name="categoryType"
                      className={cx(s.formControlSelect, s.formControlInput)}
                      component={this.renderFormControlSelect}
                      placeholder={formatMessage(messages.category)}
                      label={formatMessage(messages.chooseCategory)}
                    >
                      <option value="">
                        {formatMessage(messages.chooseCategory)}
                      </option>
                      {categories &&
                        categories.length > 0 &&
                        categories.map((item, key) => (
                          <option value={item.id} key={key}>
                            {item.categoryName}
                          </option>
                        ))}
                    </Field>
                    {error && (
                      <span className={rs.errorMessage}>
                        {error.defaultMessage}
                      </span>
                    )}
                  </Form.Group>
                  <Form.Group className={rs.space4}>
                    <Field
                      name="shopName"
                      type="text"
                      label={formatMessage(messages.shopName)}
                      placeholder={formatMessage(messages.shopName)}
                      component={this.renderField}
                      fieldClass={s.formControlInput}
                    />
                  </Form.Group>
                  <Form.Group
                    className={cx(rs.space4, 'singUpFormPlaceSuggest')}
                  >
                    <Field
                      name="address"
                      component={this.renderAddress}
                      label={formatMessage(messages.address)}
                      placeholder={formatMessage(messages.address)}
                    />
                  </Form.Group>
                  <Form.Group className={rs.space4}>
                    <Field
                      name="zipcode"
                      type="text"
                      label={formatMessage(messages.zipcode)}
                      placeholder={formatMessage(messages.zipcode)}
                      component={this.renderField}
                      fieldClass={s.formControlInput}
                    />
                  </Form.Group>
                  <div className={s.firstNameGridSection}>
                    <Form.Group className={rs.space4}>
                      <Field
                        name="firstName"
                        type="text"
                        label={formatMessage(messages.firstName)}
                        placeholder={formatMessage(messages.firstName)}
                        component={this.renderField}
                        fieldClass={s.formControlInput}
                      />
                    </Form.Group>
                    <Form.Group className={rs.space4}>
                      <Field
                        name="lastName"
                        type="text"
                        label={formatMessage(messages.lastName)}
                        placeholder={formatMessage(messages.lastName)}
                        component={this.renderField}
                        fieldClass={s.formControlInput}
                      />
                    </Form.Group>
                  </div>
                  <Form.Group className={rs.space4}>
                    <Field
                      name="email"
                      type="text"
                      label={formatMessage(messages.email)}
                      placeholder={formatMessage(messages.email)}
                      component={this.renderField}
                      fieldClass={s.formControlInput}
                    />
                  </Form.Group>
                  <Form.Group
                    className={cx(
                      rs.space4,
                      'storeActiveSelect',
                      'countryInputPhone',
                    )}
                  >
                    <Field
                      name="phoneNumber"
                      component={this.renderPhoneNumber}
                      label={formatMessage(messages.phoneNumber)}
                      placeholder={formatMessage(messages.phoneNumber)}
                    />
                  </Form.Group>
                  <Form.Group className={rs.space4}>
                    <Field
                      name="password"
                      type="password"
                      label={formatMessage(messages.password)}
                      placeholder={formatMessage(messages.password)}
                      component={this.renderField}
                      fieldClass={s.formControlInput}
                    />
                  </Form.Group>
                  <Form.Group className={rs.space3}>
                    <Field
                      name="confirmPassword"
                      type="password"
                      label={formatMessage(messages.confirmPassword)}
                      placeholder={formatMessage(messages.confirmPassword)}
                      component={this.renderField}
                      fieldClass={s.formControlInput}
                    />
                  </Form.Group>
                  <p className={cx(rs.space3, s.singupDescriction)}>
                    <FormattedMessage {...messages.singUpDesc} />
                  </p>
                  <Form.Group
                    className={cx(
                      rs.displayFlex,
                      rs.justifyContentEnd,
                      rs.space3,
                    )}
                  >
                    <Loader
                      type="button"
                      label={formatMessage(messages.signup)}
                      show={loading}
                      buttonType="submit"
                      className={cx(rs.btnPrimary, s.singUpBtn)}
                      disabled={submitting || loading}
                      isSuffix
                      containerClass={rs.fullWidth}
                    />
                  </Form.Group>
                </Form>
              </Card>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

SignupForm = reduxForm({
  form: 'SignupForm',
  onSubmit: submit,
  validate,
})(SignupForm);

const mapStateToProps = state => ({
  loading: state.loader.StoreSignup,
  siteName: state.siteSettings.data.siteName,
  currentLocale: state.intl.locale,
  logo: state.siteSettings.data.homeLogo,
  logoHeight: state.siteSettings.data.logoHeight,
  logoWidth: state.siteSettings.data.logoWidth,
});

const mapDispatchToProps = {
  openLoginModal,
  openHeaderModal,
};

export default injectIntl(
  withStyles(s, rs)(connect(mapStateToProps, mapDispatchToProps)(SignupForm)),
);
