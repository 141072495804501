import {
    UPDATE_DELIVERY_VEHICLE_STATUS_SUCCESS,
    UPDATE_DELIVERY_VEHICLE_STATUS_START,
    UPDATE_DELIVERY_VEHICLE_STATUS_ERROR
} from '../../../constants';
import { updateDeliveryVehicleStatus as mutation } from '../../../lib/graphql';
import showToaster from '../../../helpers/toasterMessages/showToaster';

const updateDeliveryVehicleStatus = (id, isActive) => {
    return async (dispatch, getState, { client }) => {

        try {

            dispatch({
                type: UPDATE_DELIVERY_VEHICLE_STATUS_START
            });

            const { data } = await client.mutate({
                mutation,
                variables: {
                    id,
                    isActive: isActive == '1' ? 1 : 0
                }
            });

            if (data?.updateDeliveryVehicleStatus?.status === 200) {
                dispatch({
                    type: UPDATE_DELIVERY_VEHICLE_STATUS_SUCCESS
                });
                showToaster({ messageId: 'updateDeliveryVehicleStatus', toasterType: 'success' })
                return {
                    status: 200
                };
            } else {
                dispatch({
                    type: UPDATE_DELIVERY_VEHICLE_STATUS_ERROR
                });
                showToaster({ messageId: 'commonToasterError', toasterType: 'error', requestMessage: data?.updateDeliveryVehicleStatus?.errorMessage })
                return {
                    status: 400
                };
            }
        } catch (err) {
            showToaster({ messageId: 'commonToasterError', toasterType: 'error', requestMessage: err })
            dispatch({
                type: UPDATE_DELIVERY_VEHICLE_STATUS_ERROR
            });
            return {
                status: 400
            };
        }
    }
};
export default updateDeliveryVehicleStatus