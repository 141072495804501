import messages from '../../../../../locale/messages';

const validate = (values) => {
    const error = {};

    if (!values.name) {
        error.name = messages.required;
    } else if (values.name.trim() == '') {
        error.name = messages.required;
    }

    return error;
}

export default validate;