import React from 'react';
import AdminLayout from '../../../../components/Layout/AdminLayout';
import PromoCode from './PromoCode';
import { restrictUrls } from '../../../../helpers/adminPrivileges';
import messages from '../../../../locale/messages';

function action({ store, intl }) {
  const title = intl.formatMessage(messages.managePromoCode);

  // From Redux Store
  let isAdminAuthenticated = store.getState().runtime.isAdminAuthenticated;
  let adminPrivileges = store.getState().account.privileges && store.getState().account.privileges.privileges;
  let privileges = store.getState().commonSettings && store.getState().commonSettings.privileges;
  if (!isAdminAuthenticated) {
    return { redirect: '/siteadmin/login' };
  }
  // Admin restriction
  if (!restrictUrls('/siteadmin/promo-code/list', adminPrivileges, privileges)) {
    return { redirect: '/siteadmin' };
  }
  let currency = store.getState().siteSettings.data.currency;

  return {
    title,
    component: (
      <AdminLayout>
        <PromoCode title={title} currency={currency} />
      </AdminLayout>
    ),
  };
}

export default action;
