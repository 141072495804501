
import React from 'react';
import AddItem from './AddItem';
import DashboardLayout from '../../../components/Shop/StoreLayout/Layout/DashboardLayout';
import messages from '../../../locale/messages';

function action({ store, params, intl }) {
  const title = intl.formatMessage(messages.item);

  let isStoreAuthenticated = store.getState().runtime.isStoreAuthenticated;

  if (!isStoreAuthenticated) {
    return { redirect: '/store' };
  };

  const menuId = Number(params.menuId);
  const subMenuId = Number(params.subMenuId);

  return {
    title,
    component: (
      <DashboardLayout>
        <AddItem title={title} menuId={menuId} subMenuId={subMenuId} />
      </DashboardLayout>
    ),
  };
}

export default action;
