
import React from 'react';
import EditItem from './EditItem';
import DashboardLayout from '../../../components/Shop/StoreLayout/Layout/DashboardLayout';
import editItem from '../../../actions/shop/menu/editItem';
import messages from '../../../locale/messages';

async function action({ store, params, intl }) {
  const title = intl.formatMessage(messages.item);

  let isStoreAuthenticated = store.getState().runtime.isStoreAuthenticated;

  if (!isStoreAuthenticated) {
    return { redirect: '/store' };
  };

  const id = Number(params.id);

  await store.dispatch(editItem(id));

  return {
    title,
    component: (
      <DashboardLayout>
        <EditItem title={title} />
      </DashboardLayout>
    ),
  };
}

export default action;
