import React from 'react';
import PropTypes from 'prop-types';
import withStyles from 'isomorphic-style-loader/withStyles';
import { reduxForm } from 'redux-form';
import s from './Category.css';
import cx from 'classnames';
import CategoryList from '../../../components/SiteAdmin/Category/CategoryList';
import getAllCategoryQuery from './getAllCategory.graphql';
import { graphql } from 'react-apollo';
import { flowRight as compose } from 'lodash';
import Loader from '../../../components/Common/Loader/Loader';
//meassage
import { FormattedMessage } from 'react-intl';
import messages from '../../../locale/messages';

class Category extends React.Component {
  static propTypes = {
    title: PropTypes.string.isRequired,
    getAllCategory: PropTypes.shape({
      getAllCategory: PropTypes.array
    }),
  };

  static defaultProps = {
    categoryDetails: {
      loading: true
    }
  }

  render() {
    const { title, categoryDetails, categoryDetails: { loading } } = this.props;

    return (
      <Loader type={"page"} show={loading}>
        <div className={s.root}>
          <div className={s.container}>
            <div className={s.paddingRoutesSection}>
              <div className={cx(s.heading, 'textAlignRightRTL')}>
                <FormattedMessage {...messages.manageCategoryHeading} />
              </div>
              {!loading && <CategoryList categoryDetails={categoryDetails} />}
            </div>
          </div>
        </div>
      </Loader>
    );
  }
}

export default compose(
  withStyles(s),
  graphql(getAllCategoryQuery, {
    name: 'categoryDetails',
    options: {
      ssr: true,
      fetchPolicy: 'network-only',
      variables: {
        currentPage: 1,
        searchList: ''
      }
    }
  })
)(Category);
