import React from 'react';
import Document from './Document';
import DashboardLayout from '../../../components/Shop/StoreLayout/Layout/DashboardLayout';
import messages from '../../../locale/messages';

function action({ store, params, intl }) {
    const title = intl.formatMessage(messages.document);

    let isStoreAuthenticated = store.getState().runtime.isStoreAuthenticated;

    if (!isStoreAuthenticated) {
        return { redirect: '/store' };
    };

    return {
        title,
        component: (
            <DashboardLayout>
                <Document title={title} />
            </DashboardLayout>
        )
    }
}

export default action;