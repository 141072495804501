import React from 'react';
import Layout from '../../../components/Shop/StoreLayout/Layout/Layout';
import Login from './Login';
import messages from '../../../locale/messages';

const title = 'Log In';

function action({ store, intl }) {
    const title = intl.formatMessage(messages.login);

    // From Redux Store
    let isAuthenticated = store.getState().user.store;

    if (isAuthenticated) {
        return { redirect: '/' };
    }

    return {
        title,
        component: <Layout><Login title={title} /></Layout>
    }
}

export default action;