exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ":root {\n  /*\n   * Typography\n   * ======================================================================== */\n\n  --font-family-base: 'Comfortaa', cursive;\n\n  /*\n   * Layout\n   * ======================================================================== */\n\n  --max-content-width: 1170px;\n\n  /*\n   * Media queries breakpoints\n   * ======================================================================== */\n\n  --screen-xs-min: 480px;\n  /* Extra small screen / phone */\n  --screen-sm-min: 768px;\n  /* Small screen / tablet */\n  --screen-md-min: 992px;\n  /* Medium screen / desktop */\n  --screen-lg-min: 1200px;\n  /* Large screen / wide desktop */\n\n  --btn-primary-bg: #FFB400;\n  --btn-boxShadow-bg: #FFB400;\n  --btn-bordercolor-bg: #FFB400;\n  --btn-text-color: #fff;\n  --btn-primary-color: #ffffff;\n  --common-color: #1C0303;\n  --btn-secondary-bg: #FFB400;\n  --title-color: #212121;\n  --content-color: #212121;\n  --chat-background-color: #F6F6F6;\n  --border-color: #CED4DA;\n\n}\n\n.hprJC {\n  padding-left: 20px;\n  padding-right: 20px;\n  padding-top: 100px;\n  padding-bottom: 30px;\n  text-align: center;\n}\n\n.m2ZQS {\n  margin: 0 auto;\n  padding: 0 0 40px;\n  max-width: 1170px;\n  max-width: var(--max-content-width);\n}\n\n.tuuq- {\n  width: 100%;\n  max-width: 450px;\n  margin: 0 auto;\n}\n\n._1WF-K {\n  display: block;\n  max-width: 200px;\n  margin: 0 auto;\n}\n\n.QEGN3 {\n  font-size: 30px;\n  font-weight: 700;\n}\n\n._1Qm8E {\n  font-size: 18px;\n  font-weight: 500;\n}", ""]);

// exports
exports.locals = {
	"root": "hprJC",
	"container": "m2ZQS",
	"notFoundImg": "tuuq-",
	"btnWidth": "_1WF-K",
	"notFoundTitle": "QEGN3",
	"notFoundSubTitle": "_1Qm8E"
};