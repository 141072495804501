exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "._2AWDi {\n  text-align: right;\n}\n\n.e7Oh6 {\n  text-align: center;\n}\n\n.WaL0p {\n  padding: 12px 27px;\n}\n\n._6Zsfk,\n._6Zsfk:hover,\n._6Zsfk:focus,\n._6Zsfk:active {\n  cursor: pointer;\n  color: var(--btn-secondary-bg);\n  position: relative;\n  z-index: 3;\n}\n\n._1-1be {\n  padding: 34px 34px 10px;\n}\n\n._3D9JD {\n  text-align: center;\n  color: #212121;\n  letter-spacing: 0.54px;\n  margin-top: 10px;\n}\n\n._2l9KR {\n  color: #FFB400 !important;\n  text-decoration: none !important;\n}\n\n._1y4jX {\n  position: relative;\n}\n\n._2T6eT {\n  display: block;\n  margin-bottom: 10px;\n}\n\n._1mXju {\n  margin-top: 24px;\n}", ""]);

// exports
exports.locals = {
	"textAlignRight": "_2AWDi",
	"textAlignCenter": "e7Oh6",
	"btnLarge": "WaL0p",
	"modalCaptionLink": "_6Zsfk",
	"padding": "_1-1be",
	"dontSignup": "_3D9JD",
	"signupColor": "_2l9KR",
	"formGroup": "_1y4jX",
	"errorMsg": "_2T6eT",
	"marginTop": "_1mXju"
};