import React, { Component } from 'react';
import { Field, reduxForm, formValueSelector } from 'redux-form';
import { connect } from 'react-redux';
import withStyles from 'isomorphic-style-loader/withStyles';
import { injectIntl } from 'react-intl';
import cx from 'classnames';
import Form from 'react-bootstrap/Form';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Container from 'react-bootstrap/Container';
import FormControl from 'react-bootstrap/FormControl';
import FormGroup from 'react-bootstrap/FormGroup';

import Loader from '../../../Common/Loader/Loader';
import ImageUploadComponent from '../../../Common/ImageUploadComponent/ImageUploadComponent';

import messages from '../../../../locale/messages';
import validate from './validate';
import submit from './submit';
import { api, partnerHomepageUploadDir } from '../../../../config';

import s from './BannerForm.css';
export class BannerForm extends Component {

    renderField = ({ input, label, type, meta: { touched, error }, labelClass, fieldClass, placeholder, maxlength }) => {
        const { formatMessage } = this.props.intl
        return (
            <Form.Group className={cx(s.space5, 'positionRelative')}>
                <Form.Label>{label}</Form.Label>
                <Form.Control {...input} placeholder={placeholder} type={type} className={cx(fieldClass, s.formControlInput)} maxlength={maxlength}
                />
                {touched && error && <span className={cx(s.errorMessage, 'errorMessageRTL')}>{formatMessage(error)}</span>}
            </Form.Group>
        )
    }

    renderFormControlTextArea = ({ input, label, meta: { touched, error }, children, className }) => {
        const { formatMessage } = this.props.intl
        return (
            <FormGroup className={cx(s.space5, 'positionRelative')}>
                <label>{label}</label>
                <FormControl
                    {...input}
                    className={className}
                    placeholder={label}
                    as="textarea"
                    rows="4"
                >
                    {children}
                </FormControl>
                {touched && error && <span className={cx(s.errorMessage, 'errorMessageRTL')}>{formatMessage(error)}</span>}
            </FormGroup>
        );
    }

    render() {
        const { handleSubmit, bannerImage1, loading, submitting, initialValues, bannerImage2, imageLoader, imageLoaderTwo } = this.props;
        const { formatMessage } = this.props.intl;

        return (
            <Container fluid>
                <h1 className={cx(s.heading, 'textAlignRightRTL')}>{formatMessage(messages.bannerSection)}</h1>
                <Form className={s.fullWidth} onSubmit={handleSubmit(submit)}>
                    <div className={s.blackCenterSection}>
                        <Row>
                            <Col lg={6} md={12} sm={12} xs={12} className={s.space2}>
                                <Form.Group className={s.space3}>
                                    <div className={s.profileImgSection}>
                                        <ImageUploadComponent
                                            className={cx(s.btnSecondary, s.profileNoPadding)}
                                            subTextClass={s.subText}
                                            subText={formatMessage(messages.maximumUploadSizeLabel)}
                                            defaultMessage={formatMessage(messages.chooseFile)}
                                            loaderName={'bannerImageLoader'}
                                            postUrl={api.apiEndpoint + '/uploadPartnerHomepageImage'}
                                            loader={imageLoader}
                                            fieldName={'bannerImage1'}
                                            formName={'BannerForm'}
                                            imageSrc={bannerImage1 ? api.apiEndpoint + partnerHomepageUploadDir + bannerImage1 : null}
                                            label={formatMessage(messages.homeImage1)}
                                        />
                                    </div>
                                </Form.Group>
                            </Col>
                            <Col lg={6} md={12} sm={12} xs={12} className={s.space2}>
                                <Form.Group className={s.space3}>
                                    <div className={s.profileImgSection}>
                                        <ImageUploadComponent
                                            className={cx(s.btnSecondary, s.profileNoPadding)}
                                            subTextClass={s.subText}
                                            subText={formatMessage(messages.maximumUploadSizeLabel)}
                                            defaultMessage={formatMessage(messages.chooseFile)}
                                            loaderName={'bannerImageLoaderTwo'}
                                            postUrl={api.apiEndpoint + '/uploadPartnerHomepageImage'}
                                            loader={imageLoaderTwo}
                                            fieldName={'bannerImage2'}
                                            formName={'BannerForm'}
                                            imageSrc={bannerImage2 ? api.apiEndpoint + partnerHomepageUploadDir + bannerImage2 : null}
                                            label={formatMessage(messages.homeImage2)}
                                        />
                                    </div>
                                </Form.Group>
                            </Col>
                            <Col lg={6} md={12} sm={12} xs={12}>
                                <Field name="bannerTitle1" type="text" component={this.renderField} label={formatMessage(messages.title1Label)} maxlength={250} />
                                <Field name="bannerContent1" type="text" component={this.renderFormControlTextArea} label={formatMessage(messages.description1Label)} />
                            </Col>
                            <Col lg={12} md={12} sm={12} xs={12} className={cx(s.textAlignRight, s.spaceTop1, 'textAlignLeftRTL')}>
                                <Form.Group className={s.noMargin}>
                                    <div className={s.displayInlineBlock}>
                                        <Loader
                                            type={"button"}
                                            label={formatMessage(messages.submitButton)}
                                            show={loading}
                                            buttonType={'submit'}
                                            className={cx(s.button, s.btnPrimary)}
                                            disabled={submitting || loading}
                                            isSuffix={true}
                                        />
                                    </div>
                                </Form.Group>
                            </Col>
                        </Row>
                    </div>
                </Form>
            </Container>
        )
    }
}

BannerForm = reduxForm({
    form: 'BannerForm',
    onSubmit: submit,
    validate
})(BannerForm);

const selector = formValueSelector('BannerForm')

const mapState = (state) => ({
    bannerImage1: selector(state, 'bannerImage1'),
    bannerImage2: selector(state, 'bannerImage2'),
    loading: state.loader.UpdatePartnerHomepage,
    imageLoader: state.loader.bannerImageLoader,
    imageLoaderTwo: state.loader.bannerImageLoaderTwo
})

const mapDispatch = {
}

export default injectIntl(withStyles(s)(connect(mapState, mapDispatch)(BannerForm)));