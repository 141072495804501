exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ":root {\n  /*\n   * Typography\n   * ======================================================================== */\n\n  --font-family-base: 'Comfortaa', cursive;\n\n  /*\n   * Layout\n   * ======================================================================== */\n\n  --max-content-width: 1170px;\n\n  /*\n   * Media queries breakpoints\n   * ======================================================================== */\n\n  --screen-xs-min: 480px;\n  /* Extra small screen / phone */\n  --screen-sm-min: 768px;\n  /* Small screen / tablet */\n  --screen-md-min: 992px;\n  /* Medium screen / desktop */\n  --screen-lg-min: 1200px;\n  /* Large screen / wide desktop */\n\n  --btn-primary-bg: #FFB400;\n  --btn-boxShadow-bg: #FFB400;\n  --btn-bordercolor-bg: #FFB400;\n  --btn-text-color: #fff;\n  --btn-primary-color: #ffffff;\n  --common-color: #1C0303;\n  --btn-secondary-bg: #FFB400;\n  --title-color: #212121;\n  --content-color: #212121;\n  --chat-background-color: #F6F6F6;\n  --border-color: #CED4DA;\n\n}\n.gRryC {\n  padding-left: 20px;\n  padding-right: 20px;\n}\n._1NQ8W {\n  margin: 0 auto;\n  padding: 0 0 40px;\n  max-width: 380px;\n}\n._2Wq5Y {\n    margin-bottom: 6px;\n    position: relative;\n  }\n.oh5-z {\n    overflow: hidden;\n  }\n._1TylH {\n    position: relative;\n    z-index: 1;\n    display: block;\n    margin: 15px 0px;\n    width: 100%;\n    color: #767676;\n    text-align: center;\n    font-size: 18px;\n  }", ""]);

// exports
exports.locals = {
	"root": "gRryC",
	"container": "_1NQ8W",
	"formGroup": "_2Wq5Y",
	"formSection": "oh5-z",
	"horizontalLineThrough": "_1TylH"
};