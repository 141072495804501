import React, { Component } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import withStyles from 'isomorphic-style-loader/withStyles';
import { Table, Row, Col, Accordion, Card } from 'react-bootstrap';
import { FormattedMessage, injectIntl } from 'react-intl';
import moment from 'moment';
import { connect } from 'react-redux';
import Collapsible from 'react-collapsible';
// style
import s from './ViewBookingDetails.css';
import rs from '../../../storeCommon.css';

// helpers
import messages from '../../../../locale/messages';
import { capitalizeFirstLetter } from '../../../../helpers/capitalizeFirst';
import { bookingStatus } from '../../../../helpers/bookingStatus';
import { getDeliveryName } from '../../../../helpers/getDeliveryName';
// components
import Link from '../../../Link';
import CurrencyConverter from '../../../CurrencyConverter';
import FailedTransactionModal from '../../FailedTransactionModal';
// redux actions
import { openTransactionModal } from '../../../../actions/siteadmin/modalActions';
import { updatePayoutStatus } from '../../../../actions/siteadmin/Payout/updatePayout';
// images
import downArrow from '../../../../../public/StoreIcon/Dropdownwithcircle20x20.svg';
import upArrow from '../../../../../public/StoreIcon/Uparrowwithcircle20x20.svg';


export class ViewBookingDetails extends Component {
	static propTypes = {
		title: PropTypes.string.isRequired,
	}

	static defaultProps = {
		locale: "en-US",
		pageType: null
	}

	constructor(props) {
		super(props);
		this.state = {
			collapseID: []
		};
		this.handleHoldPayout = this.handleHoldPayout.bind(this);
		this.isValueDefined = this.isValueDefined.bind(this);
		this.renderAmountInformation = this.renderAmountInformation.bind(this);
		this.renderInformation = this.renderInformation.bind(this);
		this.renderUserInformation = this.renderUserInformation.bind(this);
		this.toggleCollapse = this.toggleCollapse.bind(this);
	}

	isValueDefined(value) {
		if (value !== null && value !== undefined && value !== '') {
			return true;
		}
		return false;
	}

	renderAmountInformation(label, amount, currency, isMinus, isBold, afterContent) {
		if (label && this.isValueDefined(amount) && currency) {
			return (
				<tr>
					<td><b>{label}</b></td>
					{
						!isBold && <td>
							{isMinus ? '-' : ''}
							<CurrencyConverter from={currency} amount={amount} />
							{afterContent ? `(${afterContent})` : ''}
						</td>
					}
					{
						isBold && <td>
							<b>
								{isMinus ? '-' : ''}
								<CurrencyConverter from={currency} amount={amount} />
								{afterContent ? `(${afterContent})` : ''}
							</b>
						</td>
					}
				</tr>
			);
		}
	}

	renderInformation(label, information) {
		if (label && information) {
			return (
				<tr>
					<td className={s.tableLabelColumn}><b>{label}</b></td>
					<td className={s.lineBreak}>{information}</td>
				</tr>
			);
		}
	}

	renderUserInformation(label, information, userType) {
		if (label && information) {
			return (
				<tr>
					<td><b>{label}</b></td>
					<td>
						{userType !== 3 && capitalizeFirstLetter(information.firstName)}
						{userType === 3 && capitalizeFirstLetter(information.shopName)}<br />
						{information.phoneNumber}<br />
						{information.email}<br />
					</td>
				</tr>
			);
		}
	}

	handleHoldPayout(e, id, fieldName) {
		const { updatePayoutStatus, refetch } = this.props;
		let isHold = e.target.value;
		isHold = isHold == "true" ? true : false;
		updatePayoutStatus(id, isHold, fieldName);
		refetch(id)
	}


	toggleCollapse(id1, id2) {
		const { collapseID } = this.state;
		const index = collapseID.indexOf(id1 + '.' + id2);
		if (index > -1) {
			collapseID.splice(index, 1);
			this.setState({ collapseID });
		} else {
			collapseID.push(id1 + '.' + id2);
			this.setState({ collapseID });
		}
	}

	render() {
		const { title, data, pageType, openTransactionModal } = this.props;
		const { formatMessage, locale } = this.props.intl;
		const { collapseID } = this.state;

		let paymentTypeLabel = {
			1: formatMessage(messages.cashOnHand),
			2: formatMessage(messages.card),
			3: formatMessage(messages.wallet),
			4: formatMessage(messages.payPalLabel)
		};

		let link = '/siteadmin/bookings';
		if (pageType !== 'bookings') {
			link = `/siteadmin/${pageType}`;
		}

		let bookingData = data && data.result;

		let earnings = 0, adminEarnings = 0, adminOverdraft = 0;
		if (bookingData) {
			earnings = Number(bookingData.userServiceFare) + Number(bookingData.shopServiceFare) + Number(bookingData.driverServiceFare);
			adminEarnings = earnings;
			if (bookingData.isSpecialDelivery) {
				adminEarnings = (Number(earnings) > Number(bookingData.specialDeliveryFare)) ? Number(earnings) - Number(bookingData.specialDeliveryFare) : 0;
				adminOverdraft = (Number(earnings) < Number(bookingData.specialDeliveryFare)) ? Number(bookingData.specialDeliveryFare) - Number(earnings) : 0;
			}
		}


		return (
			<div className={cx(s.pagecontentWrapper, s.widthInner, s.space5, 'viewDetailsTableRTL')}>
				<div className={s.contentBox}>
					<Row>
						<Col lg={8} md={8} sm={8} xs={12}>
							<h1 className={cx(s.titleTextAdmin, s.space2, 'textAlignRightRTL')}><FormattedMessage {...messages.adminEarningDetails} /></h1>
						</Col>
						<Col lg={4} md={4} sm={4} xs={12} className={cx(s.textAlignRight, s.space2, 'textAlignLeftRTL')}>
							<Link to={link} className={cx('pull-right', s.backBtn,  s.btnSecondary, 'backBtnBorderRTL')}>
								<FormattedMessage {...messages.goBack} />
							</Link>
						</Col>
					</Row>
					<div className={cx('table-responsive')}>
						<Table>
							{
								bookingData && <tbody>
								
									{
										bookingData.orderId && this.renderInformation(formatMessage(messages.orderId), `#${bookingData.orderId}`)
									}
									{
										bookingData.userDetails && this.renderUserInformation(formatMessage(messages.userDetails), bookingData.userDetails)
									}
									{
										bookingData.shopDetails && this.renderUserInformation(formatMessage(messages.shopDetails), bookingData.shopDetails, 3)
									}
									{
										bookingData.deliveryPartnerDetails && this.renderUserInformation(formatMessage(messages.deliveryPartnerDetails), bookingData.deliveryPartnerDetails)
									}
									{
										this.renderInformation(formatMessage(messages.typeOfDeliverly), bookingData.deliveryName)
									}
									{
										this.renderInformation(formatMessage(messages.orderStatus), bookingStatus(bookingData.bookingStatus, locale))
									}
									{
										bookingData.notes && this.renderInformation(formatMessage(messages.remarks), bookingData.notes)
									}
									{
										this.renderInformation(formatMessage(messages.payment), (bookingData.payment === 'pending' ? formatMessage(messages.notPaidLabel) : formatMessage(messages.paidLabel)))
									}
									{
										this.renderInformation(formatMessage(messages.paymentType), paymentTypeLabel[bookingData.paymentType])
									}
									{
										this.renderInformation(formatMessage(messages.pickUpLocation), bookingData.pickUpLocation)
									}
									{
										bookingData.isDoorStepDelivery && this.renderInformation(formatMessage(messages.dropLocation), bookingData.dropOffLocation)
									}
									{
										this.renderInformation(formatMessage(messages.orderCreatedAt), moment(bookingData.bookingCreatedAt).format('MM-DD-YYYY hh:mm:ss a'))
									}
									{
										bookingData.bookingDropOffAt && this.renderInformation(formatMessage(messages.orderDeliveredAt), moment(bookingData.bookingDropOffAt).format('MM-DD-YYYY hh:mm:ss a'))
									}
									{
										this.renderInformation(formatMessage(messages.totalRideDistance), (bookingData.totalDistance + ' ' + (bookingData.distanceType === 'km' ? formatMessage(messages.kmLabel) : formatMessage(messages.milesLabel))))
									}
									{
										this.renderInformation(formatMessage(messages.totalDuration), `${bookingData.totalDuration} ${formatMessage(messages.minutes)}`)
									}
									{
										this.renderInformation(formatMessage(messages.totalOrderedItems), bookingData.orderItemsCount)
									}
									{
										bookingData.orderNotes && <tr>
											<td><b><FormattedMessage {...messages.orderNotes} /></b></td>
											<td className={s.lineBreak}>{bookingData.orderNotes}</td>
										</tr>
									}
									{
										bookingData.confirmationCode && <tr>
											<td><b><FormattedMessage {...messages.confirmationCode} /></b></td>
											<td className={s.lineBreak}>{bookingData.confirmationCode}</td>
										</tr>
									}
									{
										bookingData.orderItemDetails && bookingData.orderItemDetails.length > 0 && <tr>
											<td><b><FormattedMessage {...messages.orderedItems} /></b></td>
											<td>
												{
													bookingData.orderItemDetails && bookingData.orderItemDetails.map((orderItem, orderKey) => {
														return (
															<div key={orderKey}>
																<p className={cx(s.noMargin, s.paddingTop, { [s.noMargin]: (bookingData.orderItemDetails.length - 1) === orderKey })}>
																	<span className={cx(s.orderItem, 'viewItemOrderRTL', 'disPlayInlineRTL')}>{orderItem.quantity} x </span>
																	<span className={cx(s.orderText, 'disPlayInlineRTL')}>{orderItem.itemName}</span>{': '}
																	<CurrencyConverter
																		from={bookingData.currency}
																		amount={orderItem.total}
																	/>
																</p>


																{
																	orderItem.orderModifierGroup && orderItem.orderModifierGroup.length > 0 && orderItem.orderModifierGroup.map((groupData, key2) => {
																		return (
																			<div className='orderAccordion adminEarning orderAccordionAdmin'>
																				<Accordion defaultActiveKey="0">
																					<Card>
																						<Card.Header>
																							<Accordion.Toggle variant="link" eventKey="1" className={cx('arrowBtn', s.displayTable)} onClick={() => this.toggleCollapse(orderKey, key2)}>
																								<span className={cx(s.displayTableCell, s.mobDisplayTable)}><img src={collapseID.includes(orderKey + '.' + key2) ? upArrow : downArrow} /></span>
																								<span className={cx(s.choicsContent, s.displayTableCell, 'choicsContentRTL')}>
																									{groupData.modifierName} &nbsp;
																									{groupData.total > 0 && (groupData.total.toFixed(2) >= 0.01) && (<CurrencyConverter
																										from={groupData.currency}
																										amount={groupData.total}
																									/>)}
																								</span>
																							</Accordion.Toggle>
																						</Card.Header>
																						<Accordion.Collapse eventKey="1">
																							<Card.Body>
																								{
																									groupData.orderModifierItem && groupData.orderModifierItem.length > 0 && groupData.orderModifierItem.map((itemData, key) => {
																										return (
																											<div>
																												<span className={cx(s.orderItem, s.displayTableCell, s.orderWidth, 'orderViewItemRTL', 'disPlayInlineRTL')}>{itemData.quantity} x</span>
																												<span className={cx(s.itemBottom, s.displayTableCell, 'itemBottomRTL')}>{itemData.modifierItemName}
																													{itemData.modifierItemPrice && (itemData.modifierItemPrice.toFixed(2) >= 0.01) && <span> - (<CurrencyConverter
																														from={itemData.currency}
																														amount={itemData.modifierItemPrice}
																													/>)</span>}
																												</span>
																											</div>
																										)
																									})
																								}
																							</Card.Body>
																						</Accordion.Collapse>
																					</Card>
																				</Accordion>
																			</div>
																		);
																	})
																}

															</div>
														)
													})
												}
											</td>
										</tr>
									}
									{
										bookingData.isDoorStepDelivery && bookingData.deliveryOption && this.renderInformation(formatMessage(messages.deliveryOption), getDeliveryName(bookingData.deliveryOption) ? formatMessage(getDeliveryName(bookingData.deliveryOption)) : '')
									}
									{
										bookingData.isDoorStepDelivery && bookingData.deliveryInstruction && this.renderInformation(formatMessage(messages.deliveryInstruction), bookingData.deliveryInstruction)
									}
									{
										!bookingData.isDoorStepDelivery && bookingData.deliveryInstruction && this.renderInformation(formatMessage(messages.pickupInstruction), bookingData.deliveryInstruction)
									}
									<tr>
										<td colSpan={2}><b><FormattedMessage {...messages.userBillingInformation} />:</b></td>
									</tr>
									{
										this.renderAmountInformation(formatMessage(messages.subTotalLabel), bookingData.subTotal, bookingData.currency)
									}
									{
										bookingData.deliveryFee > 0 &&
										this.renderAmountInformation(formatMessage(messages.deliveryFee), bookingData.deliveryFee, bookingData.currency)
									}
									{
										bookingData.tips > 0 && this.renderAmountInformation(formatMessage(messages.tipsAmount), bookingData.tips, bookingData.currency, false)
									}
									{
										this.renderAmountInformation(formatMessage(messages.userServiceFee), bookingData.userServiceFare, bookingData.currency)
									}
									{
										bookingData.isSpecialDelivery === 1 && bookingData.specialDeliveryFare > 0 && this.renderAmountInformation(formatMessage(messages.specialTripPrice), bookingData.specialDeliveryFare, bookingData.currency, true)
									}
									{
										this.renderAmountInformation(formatMessage(messages.userTotalFare), bookingData.userPayableFare, bookingData.currency, false, true)
									}
									<tr>
										<td colSpan={2}><b><FormattedMessage {...messages.shopBillingInformation} />:</b></td>
									</tr>
									{
										this.renderAmountInformation(formatMessage(messages.subTotalLabel), bookingData.subTotal, bookingData.currency)
									}
									{
										this.renderAmountInformation(formatMessage(messages.shopFee), bookingData.shopServiceFare, bookingData.currency, true)
									}
									{
										this.renderAmountInformation(formatMessage(messages.shopTotalFare), bookingData.shopTotalFare, bookingData.currency, false, true)
									}

									{
										data.isDoorStepDelivery && <>
										<tr>
										<td colSpan={2}><b><FormattedMessage {...messages.driverBillingInformation} />:</b></td>
									    </tr>

									{
										this.renderAmountInformation(formatMessage(messages.deliveryFee), bookingData.deliveryFee, bookingData.currency)
									}
									{	
										bookingData.tips > 0 && this.renderAmountInformation(formatMessage(messages.tipsAmount), bookingData.tips, bookingData.currency, false)
									}
									{	
										this.renderAmountInformation(formatMessage(messages.driverServiceFee), bookingData.driverServiceFare, bookingData.currency, true)
									}
									{	
										this.renderAmountInformation(formatMessage(messages.driverTotalFare), bookingData.driverTotalFare, bookingData.currency, false, true)
									}
									</>
									}
									<tr>
										<td colSpan={2}><b><FormattedMessage {...messages.earningsMenu} />:</b></td>
									</tr>
									{
										this.renderAmountInformation(formatMessage(messages.userServiceFee), bookingData.userServiceFare, bookingData.currency)
									}
									{
										this.renderAmountInformation(formatMessage(messages.shopFee), bookingData.shopServiceFare, bookingData.currency)
									}

									{
										bookingData.driverServiceFare > 0 &&
										this.renderAmountInformation(formatMessage(messages.driverServiceFee), bookingData.driverServiceFare, bookingData.currency)
								    }


									{
										this.renderAmountInformation(formatMessage(messages.earningsMenu), adminEarnings, bookingData.currency, false, true)
									}
									{
										adminOverdraft > 0 && this.renderAmountInformation(formatMessage(messages.adminOverdraft), adminOverdraft, bookingData.currency, false, true)
									}
								</tbody>
							}
						</Table>
					</div>
				</div>
			</div>
		);
	}
}

const mapState = state => ({
	locale: state.intl.locale
});
const mapDispatch = {
	openTransactionModal,
	updatePayoutStatus
};

export default injectIntl(withStyles(s, rs)(connect(mapState, mapDispatch)(ViewBookingDetails)));