import React, { Component } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { injectStripe } from 'react-stripe-elements';
import {
  Field,
  reduxForm,
  formValueSelector,
  change,
  getFormValues,
} from 'redux-form';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import FormGroup from 'react-bootstrap/FormGroup';
import Col from 'react-bootstrap/Col';
import FormControl from 'react-bootstrap/FormControl';
import Container from 'react-bootstrap/Container';
import cx from 'classnames';
import withStyles from 'isomorphic-style-loader/withStyles';

import Loader from '../../../Common/Loader';

import messages from '../../../../locale/messages';

import validateStripe from './validateStripe';
import submit from './submit';
import accountFieldSettings from './accountFieldSettings';
import generateStripePayoutToken from '../../../../helpers/generateStripePayoutToken';
import {
  setLoaderStart,
  setLoaderComplete,
} from '../../../../actions/loader/loader';
import showToaster from '../../../../helpers/toasterMessages/showToaster';
import { isEuropeCountry } from '../../../../helpers/europeCountryHelpers';

import rs from '../../../storeCommon.css';
import s from '../Payout.css';

class Stripe extends Component {
  static defaultProps = {
    businessType: 'individual',
  };

  componentWillMount() {
    const { change } = this.props;
    change('businessType', 'individual');
  }

  renderField = ({
    input,
    label,
    type,
    meta: { touched, error },
    labelClass,
    fieldClass,
    placeholder,
  }) => {
    const { formatMessage } = this.props.intl;
    return (
      <Form.Group className={cx(rs.space5, 'positionRelative')}>
        <Form.Label>{label}</Form.Label>
        <Form.Control
          {...input}
          placeholder={placeholder}
          type={type}
          className={cx(rs.formControlInputStore, 'formControlInputStoreRTL')}
        />
        {touched && error && (
          <span className={cx(s.errorMessage, 'errorMessageRTL')}>
            {formatMessage(error)}
          </span>
        )}
      </Form.Group>
    );
  };

  renderSelectField = ({
    input,
    label,
    type,
    meta: { touched, error, dirty },
    children,
    placeholder,
    className,
  }) => {
    const { formatMessage } = this.props.intl;
    return (
      <FormGroup className={cx(rs.space5, 'positionRelative')}>
        <label>{label}</label>
        <FormControl
          {...input}
          as="select"
          placeholder={placeholder}
          className={className}
          type={type}
        >
          {children}
        </FormControl>
        {touched && error && (
          <span className={cx(s.errorMessage, 'errorMessageRTL')}>
            {formatMessage(error)}
          </span>
        )}
      </FormGroup>
    );
  };

  handleSubmitAction = async event => {
    event.preventDefault();
    const { stripe, change, handleSubmit } = this.props;
    const {
      formValues,
      errors,
      setLoaderStart,
      setLoaderComplete,
    } = this.props;
    let accountToken;
    let personToken;

    if (
      (!errors || (errors && JSON.stringify(errors) === '{}')) &&
      formValues &&
      stripe
    ) {
      await setLoaderStart('payoutLoading');
      const generateTokens = await generateStripePayoutToken(
        stripe,
        formValues,
      );
      if (generateTokens?.status === 200) {
        accountToken = generateTokens?.result?.accountToken;
        personToken = generateTokens?.result?.personToken || null;
        await change('isTokenGenerated', true);
        await change('accountToken', accountToken);
        await change('personToken', personToken);
        await handleSubmit();
      } else {
        showToaster({
          messageId: 'commonToasterError',
          toasterType: 'error',
          requestMessage: generateTokens.errorMessage,
        });
      }
      await setLoaderComplete('payoutLoading');
    }
  };

  render() {
    const {
      handleSubmit,
      pristine,
      previousPage,
      submitting,
      error,
      loading,
      payoutLoading,
    } = this.props;
    const { businessType, payoutCountry, payoutCurrency } = this.props;
    const { formatMessage } = this.props.intl;
    const countryField = accountFieldSettings.find(
      item => item.country == payoutCountry,
    );

    return (
      <div className={cx('mainContainer', 'payoutSelectRTL')}>
        <div className={s.container}>
          <Container fluid>
            <Row>
              <Col md={12} lg={12} sm={12} xs={12}>
                <h1 className={cx(rs.storeTitleText, 'textAlignRightRTL')}>
                  <FormattedMessage {...messages.addPayout} />
                </h1>
                <Form className={s.fullWidth} onSubmit={handleSubmit}>
                  <div className={cx(rs.storeNewBg, rs.space5)}>
                    <Row>
                      <Col
                        lg={12}
                        md={12}
                        sm={12}
                        xs={12}
                        className="countrySelectRTL"
                      >
                        <Field
                          name="businessType"
                          className={cx(
                            rs.formControlSelect,
                            rs.formControlInputStore,
                            'formControlInputStoreRTL',
                          )}
                          label={formatMessage(messages.payoutType)}
                          component={this.renderSelectField}
                          defaultValue="individual"
                        >
                          <option value="individual">
                            {formatMessage(messages.payoutIndividual)}
                          </option>
                          <option value="company">
                            {formatMessage(messages.payoutCompany)}
                          </option>
                        </Field>
                        <Field
                          name="firstname"
                          component={this.renderField}
                          label={
                            businessType === 'individual'
                              ? formatMessage(messages.payoutFirstName)
                              : formatMessage(messages.payoutCompanyName)
                          }
                          placeholder={
                            businessType === 'individual'
                              ? formatMessage(messages.payoutFirstName)
                              : formatMessage(messages.payoutCompanyName)
                          }
                        />
                        {businessType && businessType === 'individual' && (
                          <Field
                            name="lastname"
                            component={this.renderField}
                            label={formatMessage(messages.payoutLastName)}
                            placeholder={formatMessage(messages.payoutLastName)}
                          />
                        )}
                        {countryField?.fields?.length > 0 &&
                          countryField?.fields?.map(item => (
                            <Field
                              name={item.fieldName}
                              component={this.renderField}
                              label={formatMessage(messages[item.fieldLabel])}
                              placeholder={`eg: ${item.placeholder}`}
                            />
                          ))}
                        {/* )} */}
                        {!countryField && !isEuropeCountry(payoutCountry) && (
                          <Field
                            name="routingNumber"
                            component={this.renderField}
                            label={formatMessage(messages.payoutRouting)}
                            placeholder="eg: 110000000"
                          />
                        )}
                        {!countryField && (
                          <div>
                            <Field
                              name="accountNumber"
                              component={this.renderField}
                              label={formatMessage(
                                messages[
                                  isEuropeCountry(payoutCountry)
                                    ? 'ibanNumber'
                                    : 'accountNumber'
                                ],
                              )}
                              placeholder="eg: 000123456789"
                            />
                            <Field
                              name="confirmAccountNumber"
                              component={this.renderField}
                              label={formatMessage(
                                messages[
                                  isEuropeCountry(payoutCountry)
                                    ? 'confirmIbanNumber'
                                    : 'confirmAccountNumber'
                                ],
                              )}
                              placeholder="eg: 000123456789"
                            />
                          </div>
                        )}
                        {payoutCountry &&
                          payoutCountry === 'US' &&
                          businessType &&
                          businessType === 'individual' && (
                            <Field
                              name="ssn4Digits"
                              component={this.renderField}
                              label={formatMessage(messages.ssn4Digits)}
                              placeholder="1234"
                            />
                          )}
                        <div className={cx(s.infoBox)}>
                          {formatMessage(messages.payoutStripeInfo)}
                        </div>
                        <div className={s.btnDisplayFlex}>
                          <Button
                            onClick={previousPage}
                            className={cx(
                              rs.button,
                              rs.btnPrimaryBorder,
                              s.btnWidthMobile,
                            )}
                          >
                            <FormattedMessage {...messages.back} />
                          </Button>
                          <Loader
                            type="button"
                            buttonType="button"
                            className={cx(
                              rs.button,
                              rs.btnPrimary,
                              s.btnWidthMobile,
                            )}
                            disabled={
                              pristine || submitting || error || payoutLoading
                            }
                            show={loading}
                            label={formatMessage(messages.finish)}
                            handleClick={this.handleSubmitAction}
                          />
                        </div>
                      </Col>
                    </Row>
                  </div>
                </Form>
              </Col>
            </Row>
          </Container>
        </div>
      </div>
    );
  }
}

Stripe = reduxForm({
  form: 'PayoutForm',
  destroyOnUnmount: false,
  forceUnregisterOnUnmount: true,
  validate: validateStripe,
  onSubmit: submit,
})(Stripe);

const selector = formValueSelector('PayoutForm');

const mapState = state => ({
  loading: state.loader.addPayoutForm,
  businessType: selector(state, 'businessType'),
  payoutCountry: selector(state, 'country'),
  payoutCurrency: selector(state, 'currency'),
  formValues: getFormValues('PayoutForm')(state),
  payoutLoading: state.loader.payoutLoading,
});

const mapDispatch = {
  change,
  setLoaderStart,
  setLoaderComplete,
};

export default injectStripe(
  injectIntl(withStyles(s, rs)(connect(mapState, mapDispatch)(Stripe))),
);
