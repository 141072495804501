import React from 'react';
import PropTypes from 'prop-types';
import withStyles from 'isomorphic-style-loader/withStyles';
import s from './AddMenu.css';
import AddMenuForm from '../../../components/Shop/ManageMenu/AddMenu/AddMenuForm'

class AddMenu extends React.Component {
  static propTypes = {
    title: PropTypes.string.isRequired,
  };

  render() {
    let initialValues = {};
    // Prepare for MenuAvailability
    let menuAvailableSettings = [
      { operationDay: 'Sunday', isOpen: true, isWholeDay: "true" },
      { operationDay: 'Monday', isOpen: true, isWholeDay: "true" },
      { operationDay: 'Tuesday', isOpen: true, isWholeDay: "true" },
      { operationDay: 'Wednesday', isOpen: true, isWholeDay: "true" },
      { operationDay: 'Thursday', isOpen: true, isWholeDay: "true" },
      { operationDay: 'Friday', isOpen: true, isWholeDay: "true" },
      { operationDay: 'Saturday', isOpen: true, isWholeDay: "true" },
    ];
    initialValues = {
      menuAvailability: menuAvailableSettings
    };

    return (
      <div>
        <div>
          <AddMenuForm
            initialValues={initialValues}
          />
        </div>
      </div>
    );
  }
}

export default withStyles(s)(AddMenu);
