import {
    DELIVERY_TYPE_UPDATE_SUCCESS,
    DELIVERY_TYPE_UPDATE_START,
    DELIVERY_TYPE_UPDATE_ERROR
} from '../../../constants';
import showToaster from '../../../helpers/toasterMessages/showToaster';
import { updateDeliveryTypeStatus as mutation } from '../../../lib/graphql';

const updateDeliveryStatus = (id, status) => {

    return async (dispatch, getState, { client }) => {
        try {

            dispatch({
                type: DELIVERY_TYPE_UPDATE_START
            });

            const { data } = await client.mutate({
                mutation,
                variables: {
                    id,
                    status: status == '1' ? 1 : 0
                }
            });

            if (data?.updateDeliveryTypeStatus?.status === 200) {
                dispatch({
                    type: DELIVERY_TYPE_UPDATE_SUCCESS
                });
                showToaster({ messageId: 'updateDeliveryTypeStatus', toasterType: 'success' })
                return {
                    status: 200
                };
            } else {
                dispatch({
                    type: DELIVERY_TYPE_UPDATE_ERROR
                });
                showToaster({ messageId: 'commonToasterError', toasterType: 'error', requestMessage: data?.updateDeliveryTypeStatus?.errorMessage })
                return {
                    status: 400
                };
            }
        } catch (err) {
            showToaster({ messageId: 'commonToasterError', toasterType: 'error', requestMessage: err })
            dispatch({
                type: DELIVERY_TYPE_UPDATE_ERROR
            });
            return {
                status: 400
            };
        }
    }
};
export default updateDeliveryStatus;