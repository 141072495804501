import React, { Component } from 'react'
import { Field, reduxForm, formValueSelector, initialize } from 'redux-form';
import { connect } from 'react-redux'
import withStyles from 'isomorphic-style-loader/withStyles';
import { injectIntl } from 'react-intl';
import cx from 'classnames';
import Form from 'react-bootstrap/Form';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Container from 'react-bootstrap/Container';

import ColorPickerModal from '../ColorPickerModal/ColorPickerModal';
import ImageUploadComponent from '../../../Common/ImageUploadComponent/ImageUploadComponent';
import Loader from '../../../Common/Loader/Loader';
import Link from '../../../Link/Link';

import messages from '../../../../locale/messages';
import validate from './validate';
import submit from './submit';
import { openColorModal } from '../../../../actions/siteadmin/modalActions';
import { api, deliveryTypeImageUploadDir } from '../../../../config';
import { setLoaderComplete, setLoaderStart } from '../../../../actions/loader/loader';
import { inputTextLimit, textAreaLimit } from '../../../../helpers/inputTextLimit';

import colorPickerIcon from '../../../../../public/Icons/colorPickerIcon.svg';
import s from './AddDeliveryTypeForm.css';

export class AddDeliveryTypeForm extends Component {

	static defaultProps = {
		loading: false
	};

	renderField = ({ input, label, type, meta: { touched, error }, labelClass, fieldClass, placeholder }) => {
		const { formatMessage } = this.props.intl
		return (
			<Form.Group className={cx(s.space5, 'positionRelative')}>
				<Form.Label>{label}</Form.Label>
				<Form.Control {...input} placeholder={placeholder} type={type} className={fieldClass} />
				{touched && error && <span className={cx(s.errorMessage, 'errorMessageRTL')}>{formatMessage(error)}</span>}
			</Form.Group>
		)
	}

	renderFormControlTextArea = ({ input, label, meta: { touched, error }, children, className }) => {
		const { formatMessage } = this.props.intl
		return (
			<Form.Group className={cx(s.space5, 'positionRelative')}>
				<label>{label}</label>
				<Form.Control
					{...input}
					className={className}
					placeholder={label}
					as="textarea"
					rows="4"
				>
					{children}
				</Form.Control>
				{touched && error && <span className={cx(s.errorMessage, 'errorMessageRTL')}>{formatMessage(error)}</span>}
			</Form.Group >
		);
	}

	renderColorField = ({ input, label, type, meta: { touched, error }, fieldClass, placeholder }) => {
		const { formatMessage } = this.props.intl;
		return (
			<Form.Group className={cx(s.space5, 'positionRelative')}>
				<Form.Label>{label}</Form.Label>
				<Form.Control {...input} placeholder={placeholder} type={type} className={fieldClass} />
				{touched && error && <span className={cx(s.errorMessage, 'errorMessageRTL')}>{formatMessage(error)}</span>}
			</Form.Group>
		)
	}

	render() {
		const { handleSubmit, imageName, loading, submitting, openColorModal, deliveryDescription } = this.props;
		const { textColor, iconBackgroundColor, backgroundColor, deliveryName, imageLoader } = this.props;
		const { formatMessage } = this.props.intl;

		return (
			<div className={cx(s.space5, s.spaceTop5)}>
				<Container fluid>
					<ColorPickerModal formName={'AddDeliveryTypeForm'} />
					<Form className={s.fullWidth} onSubmit={handleSubmit(submit)} >
						<Row>
							<Col xl={7} lg={12} md={12} sm={12} xs={12} className={s.space5}>
								<div className={cx(s.centerFlex, s.profileImgSection, s.space5)}>
									<ImageUploadComponent
										className={cx(s.btnPrimary, 'fileNoPadding', s.btnWidthadmin)}
										subTextClass={s.subText}
										subText={formatMessage(messages.maximumUploadSizeLabel)}
										defaultMessage={formatMessage(messages.chooseFile)}
										loaderName={'deliveryTypeImageLoader'}
										postUrl={api.apiEndpoint + '/uploadDeliveryImage'}
										loader={imageLoader}
										fieldName={'imageName'}
										formName={'AddDeliveryTypeForm'}
										imageSrc={imageName ? api.apiEndpoint + deliveryTypeImageUploadDir + imageName : null}
										label={formatMessage(messages.image)}
									/>
								</div>
								<Form.Group className={s.space5}>
									<div className={s.positionRelative}>
										<Field
											name="iconBackgroundColor"
											type="text"
											placeholder={formatMessage(messages.iconBackgroundColor)}
											component={this.renderColorField}
											label={formatMessage(messages.iconBackgroundColor)}
											labelClass={s.labelText}
											fieldClass={cx(s.formControlInput, 'pickerInput')}
										/>
										<div className={cx(s.pickerIcon, 'pickerIconRTL')} onClick={(e) => openColorModal('iconBackgroundColor', iconBackgroundColor)}><img src={colorPickerIcon} /></div>
									</div>
								</Form.Group>
								<Form.Group className={s.space5}>
									<div className={s.positionRelative}>
										<Field
											name="textColor"
											type="text"
											placeholder={formatMessage(messages.textColor)}
											component={this.renderColorField}
											label={formatMessage(messages.textColor)}
											labelClass={s.labelText}
											fieldClass={cx(s.formControlInput, 'pickerInput')}
										/>
										<div className={cx(s.pickerIcon, 'pickerIconRTL')} onClick={(e) => openColorModal('textColor', textColor)}><img src={colorPickerIcon} /></div>
									</div>
								</Form.Group>
								<Form.Group className={s.space5}>
									<div className={s.positionRelative}>
										<Field
											name="backgroundColor"
											type="text"
											placeholder={formatMessage(messages.backgroundColor)}
											component={this.renderColorField}
											label={formatMessage(messages.backgroundColor)}
											labelClass={s.labelText}
											fieldClass={cx(s.formControlInput, 'pickerInput')}
										/>
										<div className={cx(s.pickerIcon, 'pickerIconRTL')} onClick={(e) => openColorModal('backgroundColor', backgroundColor)} ><img src={colorPickerIcon} /></div>
									</div>
								</Form.Group>
								<Form.Group className={s.space5}>
									<div>
										<Field
											name="deliveryName"
											type="text"
											placeholder={formatMessage(messages.deliveryName)}
											component={this.renderField}
											label={formatMessage(messages.deliveryName)}
											labelClass={s.labelText}
											fieldClass={s.formControlInput}
										/>
									</div>
								</Form.Group>
								<Field name="deliveryDescription" component={this.renderFormControlTextArea} label={formatMessage(messages.deliveryDescription)} />
								<Form.Group className={cx(s.space5, 'positionRelative')}>
									<label>{formatMessage(messages.status)}</label>
									<Field name="status" className={cx(s.formControlSelect, s.formControlInput)} component="select">
										<option value={true}>{formatMessage(messages.active)}</option>
										<option value={false}>{formatMessage(messages.inactive)}</option>
									</Field>
								</Form.Group>
								<div className={cx(s.textAlignRight, s.spaceTop5, 'textAlignLeftRTL')}>
									<Form.Group className={s.noMargin}>
										<Link to={"/siteadmin/delivery-type"} className={cx(s.backBtnTwo, s.btnSecondaryBorder, s.linkBtnInline, 'backBtnTwoRTL', s.backBtnTwoTop)} >{formatMessage(messages.goBack)}</Link>
										<div className={s.displayInlineBlock}>
											<Loader
												type={"button"}
												label={formatMessage(messages.submitButton)}
												show={loading}
												buttonType={'submit'}
												className={cx(s.button, s.btnPrimary)}
												disabled={submitting || loading}
												isSuffix={true}
											/>
										</div>
									</Form.Group>
								</div>
							</Col>
							<Col xl={5} lg={12} md={12} sm={12} xs={12} className={s.space5}>
								<div className={s.previewOutline}>
									<div className={s.previewBox}>
										<div className={s.previewTitle}>{formatMessage(messages.previewSample)}</div>
										<div className={cx('positionRelative', s.previewTextSection)}>
											<div className={s.previewIconBg} style={{ backgroundColor: iconBackgroundColor }}>
												{
													imageName && <div className={s.previewBgimage} style={{ backgroundImage: `url(${api.apiEndpoint + deliveryTypeImageUploadDir + 'medium_' + imageName})` }} />
												}
											</div>
											<div className={s.bgColor} style={{ backgroundColor: backgroundColor }}>
												<div className={s.previewHeading} style={{ color: textColor }}>{deliveryName}</div>
												<div className={s.previreContent} style={{ color: textColor }}>{deliveryDescription}</div>
											</div>
										</div>
									</div>
								</div>
							</Col>
						</Row>
					</Form>
				</Container>
			</div>
		)
	}
}

AddDeliveryTypeForm = reduxForm({
	form: 'AddDeliveryTypeForm',
	onSubmit: submit,
	validate
})(AddDeliveryTypeForm);

const selector = formValueSelector('AddDeliveryTypeForm');

const mapState = (state) => ({
	imageName: selector(state, 'imageName'),
	textColor: selector(state, 'textColor'),
	backgroundColor: selector(state, 'backgroundColor'),
	iconBackgroundColor: selector(state, 'iconBackgroundColor'),
	deliveryName: selector(state, 'deliveryName'),
	deliveryDescription: selector(state, 'deliveryDescription'),
	loading: state?.loader?.AddCategory,
	imageLoader: state?.loader?.deliveryTypeImageLoader
})

const mapDispatch = {
	initialize,
	openColorModal,
	setLoaderComplete,
	setLoaderStart
}

export default injectIntl(withStyles(s)(connect(mapState, mapDispatch)(AddDeliveryTypeForm)));