import {
    SHOP_PROFILE_UPDATE_ERROR,
    SHOP_PROFILE_UPDATE_START,
    SHOP_PROFILE_UPDATE_SUCCESS
} from '../../../constants';
import { setLoaderStart, setLoaderComplete } from '../../loader/loader';
import { loadAccount } from '../userAccount/userAccount';
import showToaster from '../../../helpers/toasterMessages/showToaster';
import { updateShopProfile as mutation } from '../../../lib/graphql';

const updateShopProfile = (values) => {
    return async (dispatch, getState, { client }) => {
        try {
            dispatch({
                type: SHOP_PROFILE_UPDATE_START
            });

            dispatch(setLoaderStart('StoreProfileUpdate'));

            const { data } = await client.mutate({
                mutation,
                variables: {
                    shopName: values?.shopName,
                    firstName: values?.firstName,
                    lastName: values?.lastName,
                    email: values?.email,
                    phoneDialCode: values?.phoneDialCode,
                    phoneNumber: values?.phoneNumber,
                    phoneCountryCode: values?.phoneCountryCode,
                    address: values?.address,
                    lat: values?.lat,
                    lng: values?.lng,
                    city: values?.city,
                    state: values?.state,
                    zipcode: values?.zipcode,
                    country: values?.country,
                    categoryType: JSON.stringify(values?.categoryType),
                    description: values?.description,
                    priceRangeId: values?.priceRange,
                    picture: values?.picture,
                    preferredCurrency: values?.preferredCurrency,
                    shopEstimatedTime: values?.shopEstimatedTime,
                    isDoorStepDelivery: values?.isDoorStepDelivery,
                    isTakeAway: values?.isTakeAway
                }
            });

            if (data?.updateShopProfile?.status == 200) {
                showToaster({ messageId: 'updateShopProfile', toasterType: 'success' });
                await dispatch({
                    type: SHOP_PROFILE_UPDATE_SUCCESS
                });
                await dispatch(setLoaderComplete('StoreProfileUpdate'));

                setTimeout(() => {
                    dispatch(loadAccount())
                }, 1000);

            } else {
                showToaster({ messageId: 'commonToasterError', toasterType: 'error', requestMessage: data?.updateShopProfile?.errorMessage });
                await dispatch({
                    type: SHOP_PROFILE_UPDATE_ERROR
                });
                dispatch(setLoaderComplete('StoreProfileUpdate'));
            }
        } catch (error) {
            showToaster({ messageId: 'commonToasterError', toasterType: 'error', requestMessage: error });
            await dispatch({
                type: SHOP_PROFILE_UPDATE_ERROR
            });
            dispatch(setLoaderComplete('StoreProfileUpdate'));
        }
    }
};

export default updateShopProfile;