import history from '../../../history';
import { updateMenu, addMenuMutation } from '../../../lib/graphql';
import showToaster from '../../../helpers/toasterMessages/showToaster';
import { loadAccount } from '../userAccount/userAccount';

const addMenu = (values) => {

	return async (dispatch, getState, { client }) => {

		try {
			let operationHours = values.menuAvailability ? JSON.stringify(values.menuAvailability) : JSON.stringify([]);

			if (values.id) {
				const { data } = await client.mutate({
					mutation: updateMenu,
					variables: {
						operationHours: operationHours,
						menuName: values.menuName,
						id: values.id,
						isEnable: values.isEnable == 'false' ? 0 : 1,
					}
				});

				if (data?.updateMenu?.status == 200) {
					showToaster({ messageId: 'menuUpdateChanges', toasterType: 'success' })
					history.push('/store/menu');
				} else {
					showToaster({ messageId: 'commonToasterError', toasterType: 'error', requestMessage: data.updateMenu.errorMessage })
				}
			} else {
				const { data } = await client.mutate({
					mutation: addMenuMutation,
					variables: {
						operationHours: operationHours,
						menuName: values.menuName,
						isEnable: values.isEnable == 'false' ? 0 : 1,
					}
				});

				if (data?.addMenu?.status == 200) {
					showToaster({ messageId: 'addMenu', toasterType: 'success' })
					history.push('/store/menu');
				} else {
					showToaster({ messageId: 'commonToasterError', toasterType: 'error', requestMessage: data.addMenu.errorMessage })
				}
			}
			dispatch(loadAccount());
		} catch (error) {
			showToaster({ messageId: 'commonToasterError', toasterType: 'error', requestMessage: err })
		}
	}
}

export default addMenu;