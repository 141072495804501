import gql from "graphql-tag";

export const removePayoutMutation = gql`
mutation removePayout($id: Int! ){
    removePayout(id: $id) {
        status
        errorMessage
    }
  }
`
export const setDefaultPayoutMutation = gql`
mutation setDefaultPayout($id: Int!){
  setDefaultPayout(id: $id) {
    status
        errorMessage
  }
}
`;
export const updatePayoutMutation = gql`
mutation updatePayout ($id: Int!, $requestStatus: Boolean!, $fieldName: String){
  updatePayout(id: $id, requestStatus: $requestStatus, fieldName: $fieldName){
      status
      errorMessage
    }
}`;
export const updatePaymentMutation = gql`
mutation updatePayment($id: Int, $status: Boolean) {
	updatePayment(id: $id, status: $status) {
		status
		errorMessage
	}
}
`
export const checkPaymentMethod = gql`
query getPaymentMethods {
  getPaymentMethods {
  results{
		id
      name
      processedIn
      fees
      currency
      details
      isEnable
      paymentType
      createdAt
      updatedAt
      status
	} }
  }
  `

export const getAllPaymentMethodsQuery = gql`
query getAllPaymentMethods {
	getAllPaymentMethods {
		results {
			id
			paymentName
			isEnable
			paymentType
		}
		status
		errorMessage
	}
}
  `
export const getPayoutQuery = gql`
query getPayouts($currentAccountId: String, $country: String) {
    getPayouts(currentAccountId: $currentAccountId, country: $country) {
      status
    results {
      id
      methodId
      paymentMethod{
        id
        name
      }
      userId
      payEmail
      address1
      address2
      city
      state
      country
      zipcode
      currency
      default
      createdAt
      isVerified
      last4Digits
      status
      firstName
      lastName
    }
    }
  }  
`
export const addPayoutMutation = gql`mutation addPayout($methodId: Int!, $payEmail: String!, $address1: String, $address2: String, $city: String!, $state: String!, $country: String!, $zipcode: String!, $currency: String!, $isVerified: Boolean) {
  addPayout(methodId: $methodId, payEmail: $payEmail, address1: $address1, address2: $address2, city: $city, state: $state, country: $country, zipcode: $zipcode, currency: $currency, isVerified: $isVerified) {
  result {
      id
    methodId
    userId
    payEmail
    last4Digits
    address1
    address2
    city
    state
    country
    zipcode
    currency
    createdAt
    status
  }
  }
}`;