import {
    CATEGORY_DELETE_SUCCESS,
    CATEGORY_DELETE_START,
    CATEGORY_DELETE_ERROR
} from '../../../constants';
import { deleteCategoryMutation as mutation } from '../../../lib/graphql';
import showToaster from '../../../helpers/toasterMessages/showToaster';

const deleteCategory = (id) => {
    return async (dispatch, getState, { client }) => {

        try {
            dispatch({
                type: CATEGORY_DELETE_START
            });

            const { data } = await client.mutate({
                mutation,
                variables: {
                    id
                }
            });

            if (data?.deleteCategory?.status === 200) {
                dispatch({
                    type: CATEGORY_DELETE_SUCCESS
                });

                showToaster({ messageId: 'deleteCategory', toasterType: 'success' })
                return {
                    status: 200
                };
            } else {
                dispatch({
                    type: CATEGORY_DELETE_ERROR
                });
                showToaster({ messageId: 'commonToasterError', toasterType: 'error', requestMessage: data?.deleteCategory?.errorMessage })
                return {
                    status: 400
                };
            }
        } catch (err) {
            showToaster({ messageId: 'commonToasterError', toasterType: 'error', requestMessage: err })
            dispatch({
                type: CATEGORY_DELETE_ERROR
            });
            return {
                status: 400
            };
        }
    }
};
export default deleteCategory;