import { addCategory } from '../../../../actions/siteadmin/Category/addCategory';
import showToaster from '../../../../helpers/toasterMessages/showToaster';

async function submit(values, dispatch) {
    if (!values.categoryImage) {
        showToaster({ messageId: 'categoryImageRequired', toasterType: 'error' })
        return;
    }

    await dispatch(
        addCategory(
            values?.categoryName,
            values?.isActive,
            values?.categoryImage,
            values?.description,
            values?.deliveryType,
            values?.id
        )
    )
}

export default submit;