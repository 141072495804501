import messages from '../../../locale/messages';

const validate = (values) => {
    let errors = {};
    let OperationHoursErrorArray = [];
    
    if (values.OperationHours && values.OperationHours.length > 0) {
        values.OperationHours.map((item, index) => {
            let timeSlotErrors = {};
            if (item && item.startTime && item.endTime 
                && ((Number(item.startTime) > Number(item.endTime)) || (Number(item.startTime) === Number(item.endTime)))) {
                timeSlotErrors.endTime = messages.endTimeValidateMessage;
                OperationHoursErrorArray[index] = timeSlotErrors;
            }
        });
    }

    if (OperationHoursErrorArray.length) {
        errors.OperationHours = OperationHoursErrorArray;
    }

    return errors;
}

export default validate;