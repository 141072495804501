import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import { flowRight as compose } from 'lodash';
import withStyles from 'isomorphic-style-loader/withStyles';
import s from './AddDeliveryModule.css';
import cx from 'classnames';
import AddDeliveryTypeForm from '../../../../components/SiteAdmin/DeliveryModule/AddDeliveryType';
//meassage
import messages from '../../../../locale/messages';

export class AddDeliveryModule extends Component {

    render() {
        const { title } = this.props;
        return (
            <div className={s.root}>
                <div className={s.container}>
                <div className={s.paddingRoutesSection}>
                   <div className={cx(s.heading, 'textAlignRightRTL')}>
                        {title}
                    </div>
                        <AddDeliveryTypeForm initialValues={{ status: "true" }} />
                    </div>
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state) => ({

})

const mapDispatchToProps = {

}

export default compose(withStyles(s), connect(mapStateToProps, mapDispatchToProps))(AddDeliveryModule)
