import gql from "graphql-tag";

export const deleteModifierMutation = gql`mutation deleteModifier($id: Int) {
  deleteModifier(id: $id) {
    status
    errorMessage
  }
}
`
export const updateModifierMutation = gql`
mutation(
    $modifierName: String!
    $modifierType: Int
    $minModifierItems: Int
    $maxModifierItems: Int
    $isModifierRequired: Boolean
    $isModifierMultiple: Boolean
    $maxItemCount: Int
    $isActive: Boolean
    $id: Int
    $modifierItems: String
  ) {
    updateModifierGroup(
      modifierName: $modifierName
      modifierType: $modifierType
      minModifierItems: $minModifierItems
      maxModifierItems: $maxModifierItems
      isModifierRequired: $isModifierRequired
      isModifierMultiple: $isModifierMultiple
      maxItemCount: $maxItemCount
      isActive: $isActive
      id: $id
      modifierItems: $modifierItems
    ) {
      status
      errorMessage
    }
  }
`
export const updateModifierStatusMutation = gql`
mutation updateModifierStatus($id: Int, $status: Boolean) {
  updateModifierStatus(id: $id, status: $status) {
    status
    errorMessage
  }
}
`
export const getExistingModifierQuery = gql`
query getModifier($id: Int!) {
  getModifier(id: $id) {
    status
    errorMessage
    result {
      id
      modifierName
      modifierType
      minModifierItems
      maxModifierItems
      isModifierRequired
      isModifierMultiple
      maxItemCount
      isActive
      itemId
      clonedBy
      modifierItems {
        id
        modifierGroupId
        modifierItemName
        modifierItemPrice
      }
      modifierCommonId
    }
  }
}
`

export const getCartModifierItemQuery = gql`
query($modifierItemId: Int) {
  getCartModifierItem(modifierItemId: $modifierItemId) {
    status
    errorMessage
    result {
      removeModifierItemId
    }
  }
}
`
export const getCartModifierGroupQuery = gql`
query($modifierGroupId: Int) {
  getCartModifierGroup(modifierGroupId: $modifierGroupId) {
    status
    errorMessage
    result {
      removeModifierGroupId
    }
  }
}
`
export const getAllShopModifierQuery = gql`
query {
  getAllShopModifier {
    status
    errorMessage
    results {
      id
      modifierName
      modifierType
      minModifierItems
      maxModifierItems
      isModifierRequired
      isModifierMultiple
      maxItemCount
      isActive
      itemId
      clonedBy
      modifierItems {
        id
        modifierGroupId
        modifierItemName
        modifierItemPrice
      }
    }
  }
}
`
export const getAllModifierQuery = gql`query($currentPage: Int, $searchList: String) {
  getAllModifier(currentPage: $currentPage, searchList: $searchList) {
    count
    status
    currentPage
    errorMessage
    results {
      id
      modifierName
      modifierType
      isActive
      itemId
      clonedBy
      modifierItems {
        id
        modifierGroupId
        modifierItemName
        modifierItemPrice
      }
    }
  }
}
`