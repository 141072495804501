import gql from "graphql-tag";

export const userAccountQuery = gql`
query userAccount{
    userAccount {
      id,
      email,
      phoneNumber,
      phoneDialCode,
      phoneCountryCode,
      userStatus,
      isActive,
      isBan,
      userType,
      createdAt,
      activeStatus,
      overallRating,
      deletedAt,
      profile {
        userId,
        firstName,
        lastName,
        preferredLanguage,
        preferredCurrency,
        country,
        city,
        state,
        zipcode,
        profileId,
        lat,
        lng,
        picture,
        shopName,
        description,
        priceRangeId,
        address
      }
      shopCategory {
        categoryId
        categoryName
      }
      
    }
  }
`
export const updateVehicleMutations = gql`mutation updateVehicle(
	$id: Int
	$vehicleName: String
	$vehicleType: Int
	$vehicleNumber: String
	$vehicleStatus: String
) {
	updateVehicle(
		id: $id
		vehicleName: $vehicleName
		vehicleType: $vehicleType
		vehicleNumber: $vehicleNumber
		vehicleStatus: $vehicleStatus
	) {
		status
	}
} `;

export const getAllVehiclesQuery = gql`query getAllVehicles($currentPage: Int) {
	getAllVehicles(currentPage: $currentPage) {
		count
		vehicleData {
			id
			vehicleType
			vehicleNumber
			vehicleName
			vehicleStatus
			category {
				categoryName
			}
			user {
				email
				profile {
					firstName
				}
			}
		}
	}
}`;



export const getAllDriversQuery = gql`
query getAllDrivers($currentPage: Int, $searchList: String){
  getAllDrivers(currentPage: $currentPage, searchList: $searchList){
    count
    userData {
      id
      email
      phoneDialCode
      phoneNumber
      isBan
      userStatus
      createdAt
      profile {
        profileId
        firstName
        lastName
        country
      }
    }   
  }
}
`;
export const updateDriverMutation = gql`mutation updateDriver(
	$id: ID
	$firstName: String
	$lastName: String
	$email: String
	$password: String
	$phoneDialCode: String
	$phoneNumber: String
	$vehicleName: String
	$vehicleNumber: String
	$vehicleType: Int
	$userStatus: String
	$isBan: Int
	$phoneCountryCode: String
) {
	updateDriver(
		id: $id
		firstName: $firstName
		lastName: $lastName
		email: $email
		password: $password
		phoneDialCode: $phoneDialCode
		phoneNumber: $phoneNumber
		vehicleName: $vehicleName
		vehicleNumber: $vehicleNumber
		vehicleType: $vehicleType
		userStatus: $userStatus
		isBan: $isBan
		phoneCountryCode: $phoneCountryCode
	) {
		status
		errorMessage
	}
}
        
        
        `;
export const deleteUserMutation = gql`
mutation deleteUser($id: String!) {
    deleteUser(id: $id) {
        status
        errorMessage
    }
}`;

export const updateUserMutation = gql`mutation updateUser(
	$id: ID
	$firstName: String
	$lastName: String
	$email: String
	$password: String
	$phoneDialCode: String
	$phoneNumber: String
	$userStatus: String
	$isBan: Int
	$phoneCountryCode: String
) {
	updateUser(
		id: $id
		firstName: $firstName
		lastName: $lastName
		email: $email
		password: $password
		phoneDialCode: $phoneDialCode
		phoneNumber: $phoneNumber
		userStatus: $userStatus
		isBan: $isBan
		phoneCountryCode: $phoneCountryCode
	) {
		status
		errorMessage
	}
}`;



