import {
  SET_DEFAULT_PAYOUT_START,
  SET_DEFAULT_PAYOUT_SUCCESS,
  SET_DEFAULT_PAYOUT_ERROR,
} from '../../../constants';
import { setLoaderStart, setLoaderComplete } from '../../loader/loader';
import { getPayouts } from './getPayouts';
import { setDefaultPayoutMutation } from '../../../lib/graphql';
import showToaster from '../../../helpers/toasterMessages/showToaster';

export const setDefaultPayout = (id) => {

  return async (dispatch, getState, { client }) => {
    try {

      dispatch({
        type: SET_DEFAULT_PAYOUT_START,
      });

      dispatch(setLoaderStart('payoutDefault'));

      const { data } = await client.mutate({
        mutation: setDefaultPayoutMutation,
        variables: {
          id
        }
      });

      if (data?.setDefaultPayout?.status === 200) {
        await dispatch({
          type: SET_DEFAULT_PAYOUT_SUCCESS
        });
        await dispatch(getPayouts());
        showToaster({ messageId: 'setDefaultPayout', toasterType: 'success' })
        await dispatch(setLoaderComplete('payoutDefault'));
      } else {
        dispatch({
          type: SET_DEFAULT_PAYOUT_ERROR
        });

        showToaster({ messageId: 'commonToasterError', toasterType: 'error', requestMessage: data?.setDefaultPayout?.errorMessage })
        dispatch(setLoaderComplete('payoutDefault'));
      }
    } catch (error) {
      dispatch({
        type: SET_DEFAULT_PAYOUT_ERROR
      });

      showToaster({ messageId: 'defaultError', toasterType: 'error' })
      dispatch(setLoaderComplete('payoutDefault'));
      return false;
    };

    return true;
  };
};
