import React, { Component } from 'react'
import withStyles from 'isomorphic-style-loader/withStyles';
import { graphql } from 'react-apollo';
import { flowRight as compose } from 'lodash';

import Loader from '../../../components/Common/Loader/Loader';
import ManageNotificationsForm from '../../../components/SiteAdmin/ManageNotificationsForm'

import getSiteSettings from './getSiteSettings.graphql';

import s from './ManageNotifications.css';

export class ManageNotifications extends Component {

  render() {
    const { title, getSiteSettings: { loading, getSiteSettings } } = this.props;

    return (
      <Loader type={"page"} show={loading} >
        <div>
          {!loading &&
            <div className={s.root}>
              <div className={s.container}>
                <ManageNotificationsForm />
              </div>
            </div>}
        </div>
      </Loader>
    )
  }
}


export default compose(
  withStyles(s),
  graphql(getSiteSettings, {
    name: 'getSiteSettings',
    options: {
      fetchPolicy: 'network-only',
    }
  })
)(ManageNotifications)
