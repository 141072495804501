import React, { Component } from 'react';
import SliderForm from '../../../../components/SiteAdmin/ShopHomepageSettings/SliderForm';
import { graphql } from 'react-apollo';
import { flowRight as compose } from 'lodash';
import getAllPartnerHomepage from './getAllPartnerHomepage.graphql';
import withStyles from 'isomorphic-style-loader/withStyles';
import s from './SliderSettings.css';
import Loader from '../../../../components/Common/Loader/Loader';


export class SliderSettings extends Component {

    static defaultProps = {
        data: {
            loading: true
        }
    }
    render() {
        const { data: { loading, getAllPartnerHomepage } } = this.props;
        let initialValues = {};
        let homepageCollection = {};

        getAllPartnerHomepage && getAllPartnerHomepage.homePageData.map((item) => {
            homepageCollection[item.name] = item.value
        })

        return (
            <div>
                <Loader type={"page"} show={loading}>
                    {!loading && <SliderForm initialValues={homepageCollection} />}
                </Loader>
            </div>
        )
    }
}

export default compose(
    withStyles(s),
    graphql(getAllPartnerHomepage, {
        options: (props) => ({
            fetchPolicy: 'network-only',
            ssr: false
        })
    })
)(SliderSettings)
