import {
    SHOP_OPERATION_HOURS_SUCCESS,
    
} from '../constants';

export default function operationHours(state = {}, action) {
    switch (action.type) {
        case SHOP_OPERATION_HOURS_SUCCESS:
            return {
                ...state,
                operationHoursDataLoad: action.payload.operationHoursDataLoad
            }
        default:
            return {
                ...state
            }
    }
}

