import { toastr } from 'react-redux-toastr';
import errorMessage from '../errorMessages/errorMessage_en';

const showToaster = async ({ messageId, toasterType, requestMessage, language }) => {

    try {
        let message, lang, title;
        lang = language ? language : "en";
        if (lang == "en") {
            title = await errorMessage(toasterType);
            message = await errorMessage(messageId, requestMessage)
        }
        return toastr[toasterType](title, message)
    } catch (error) {
        console.log(error);
        return false;
    }
}
export default showToaster;
