import React from 'react';
import PropTypes from 'prop-types';
import OrderManagement from '../../../components/Shop/OrderManagement/OrderManagement';
import SocketContext from '../../../components/SocketContext';

class OrderManageSection extends React.Component {
  static propTypes = {
    title: PropTypes.string.isRequired,
  };

  constructor(props) {
    super(props);

    this.state = {
      searchList: ''
    };
  }

  changeKeyword = (searchList) => {
    this.setState({ searchList });
  }

  render() {
    const { type } = this.props;
    const { searchList } = this.state;

    return (
      <SocketContext.Consumer>
        {
          socket => (<div>
            <div>
              <OrderManagement type={type} changeKeyword={this.changeKeyword} searchList={searchList} socket={socket} />
            </div>
          </div>)
        }
      </SocketContext.Consumer>
    );
  }
}

export default OrderManageSection;
