import React from 'react';
import PropTypes from 'prop-types';
import withStyles from 'isomorphic-style-loader/withStyles';
import s from './AddModifierGroups.css';
import ModifierGroupForm from '../../../components/Shop/ModifierGroups/ModifierGroupForm/ModifierGroupForm';


class AddModifierGroups extends React.Component {
  static propTypes = {
    title: PropTypes.string.isRequired,
  };

  render() {
    let initialValues = {
      modifierItems: [{
        modifierItemName: null,
        modifierItemPrice: null
      }],
      modifierType: "1"
    }
    return (
      <div>
        <ModifierGroupForm initialValues={initialValues} />
      </div >
    );
  }
}

export default withStyles(s)(AddModifierGroups);
