exports = module.exports = require("../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "._36cDa,\n._36cDa:hover,\n._36cDa:focus,\n._36cDa:active:hover,\n._36cDa:active:focus {\n    padding: 7px 10px !important;\n}\n\n._1HNG2 {\n    position: relative;\n    top: 4px;\n    width: 20px;\n    height: 20px;\n    margin-right: 3px;\n}\n\n._3VBLT {\n    color: #FFB400 !important;\n    text-decoration: none !important;\n    display: -ms-flexbox;\n    display: flex;\n    -ms-flex-align: baseline;\n        align-items: baseline;\n    vertical-align: middle;\n}\n\n._3lpsf {\n    opacity: inherit;\n    cursor: not-allowed;\n}\n\n._19067 {\n    background-color: #D8D8D8 !important;\n    border: 1px solid #D8D8D8 !important;\n    opacity: inherit;\n    color: var(--title-color) !important;\n}\n\n._19067:hover,\n._19067:focus,\n._19067:active,\n._19067:active:hover,\n._19067:active:focus {\n    background-color: #D8D8D8 !important;\n    border: 1px solid #D8D8D8 !important;\n    padding: 7px 10px !important;\n    color: var(--title-color) !important;\n}\n\n._3RuBc {\n    text-align: center !important;\n}\n\n._2aTeO {\n    padding: 0 !important;\n}\n\n.ULXbO {\n    display: inline-block;\n}", ""]);

// exports
exports.locals = {
	"btnPadding": "_36cDa",
	"iconWidth": "_1HNG2",
	"linkColor": "_3VBLT",
	"inactive": "_3lpsf",
	"btnInactive": "_19067",
	"alignCenter": "_3RuBc",
	"noPadding": "_2aTeO",
	"displayInlineBlock": "ULXbO"
};