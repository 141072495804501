import React, { Component } from 'react'
import PropTypes from 'prop-types';
import withStyles from 'isomorphic-style-loader/withStyles';
import s from './CompletedBooking.css';
import cx from 'classnames';
import getCompletedBookingsQuery from './getCompletedBookings.graphql';
import { graphql } from 'react-apollo';
import { flowRight as compose } from 'lodash';
import BookingList from '../../../components/SiteAdmin/Booking/BookingList';
import Loader from '../../../components/Common/Loader/Loader';
//meassage
import { FormattedMessage } from 'react-intl';
import messages from '../../../locale/messages';

export class CompletedBooking extends Component {

    static propTypes = {
        title: PropTypes.string.isRequired
    }

    static defaultProps = {
        bookingDetails: {
            loading: true
        }
    };

    render() {
        const { title, bookingDetails, bookingDetails: { loading } } = this.props;

        return (
            <Loader type={"page"} show={loading}>
                <div className={s.root}>
                    <div className={s.container}>
                        <div className={s.paddingRoutesSection}>
                            <div className={cx(s.heading, 'textAlignRightRTL')}>
                                <FormattedMessage {...messages.manageCompletedTrips} />
                            </div>
                            {
                                !loading && <BookingList
                                    bookingDetails={bookingDetails}
                                    requestType={'completed'}
                                />
                            }
                        </div>
                    </div>
                </div>
            </Loader>
        );
    }
}

export default compose(
    withStyles(s),
    graphql(getCompletedBookingsQuery, {
        name: 'bookingDetails',
        options: {
            variables: {
                currentPage: 1,
                requestType: 'completed'
            },
            fetchPolicy: 'network-only'
        }
    })
)(CompletedBooking);