import histroy from '../../../history';
import { setLoaderStart, setLoaderComplete } from '../../../actions/loader/loader';
import { updateCategoryMutation as mutation } from '../../../lib/graphql';
import showToaster from '../../../helpers/toasterMessages/showToaster';

export const addCategory = (categoryName, isActive, categoryImage, description, deliveryType, id) => {

  return async (dispatch, getState, { client }) => {

    try {
      let successMessage, status;

      dispatch(setLoaderStart('AddCategory'));

      const { data } = await client.mutate({
        mutation,
        variables: {
          categoryName,
          isActive: (isActive === true || isActive === 'true') ? 1 : 0,
          categoryImage,
          description,
          deliveryType,
          id
        }
      });

      dispatch(setLoaderComplete('AddCategory'));

      successMessage = id ? 'updateCategory' : 'addCategory'
      status = data?.updateCategory?.status

      if (status === 200) {
        showToaster({ messageId: successMessage, toasterType: 'success' })
      } else {
        showToaster({ messageId: 'commonToasterError', toasterType: 'error', requestMessage: data?.updateCategory?.errorMessage })
      }

      histroy.push('/siteadmin/category');
    } catch (error) {
      showToaster({ messageId: 'commonToasterError', toasterType: 'error', requestMessage: error })
    }
  }
}