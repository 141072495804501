import React, { Component } from 'react'
import { graphql } from 'react-apollo';
import { flowRight as compose } from 'lodash';
import withStyles from 'isomorphic-style-loader/withStyles';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';

import AddCategoryForm from '../../../components/SiteAdmin/Category/AddCategoryForm';
import Loader from '../../../components/Common/Loader/Loader';
import s from './AddCategory.css';
import cx from 'classnames';

//meassage
import messages from '../../../locale/messages';
import getActiveDeliveryType from './getActiveDeliveryType.graphql';

export class AddCategory extends Component {


    render() {
        const { deliveryTypes, deliveryTypes: { loading } } = this.props;
        return (
            <Loader type={"page"} show={loading}>
                <div className={s.paddignLeftRight}>
                    <div className={cx(s.heading, 'textAlignRightRTL', s.contentPageMargin)}>
                        <FormattedMessage {...messages.addCategoryHeading} />
                    </div>
                    {!loading && <AddCategoryForm deliveryTypes={deliveryTypes} initialValues={{ isActive: 'true' }} />}
                </div>
            </Loader>
        )
    }
}

const mapStateToProps = (state) => ({})

const mapDispatchToProps = {}

export default compose(
    withStyles(s),
    connect(mapStateToProps, mapDispatchToProps),
    graphql(getActiveDeliveryType, {
        name: 'deliveryTypes',
        options: {
            ssr: true,
            fetchPolicy: 'network-only'
        }
    })
)(AddCategory);