import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'react-apollo';
import { flowRight as compose } from 'lodash';
import s from './Category.css';
import withStyles from 'isomorphic-style-loader/withStyles';
import MenuList from '../../../components/Shop/ManageMenu/ManageList/MenuList'
import Loader from '../../../components/Common/Loader/Loader';
import getAllMenuQuery from './getAllMenu.graphql';
class Category extends React.Component {

  static defaultProps = {
    menuDetails: {
      loading: true
    }
  }

  render() {
    const { title, menuDetails, menuDetails: { loading } } = this.props;

    return (

      <Loader type={"page"} show={loading}>
        <div>
          <div>
            <div>
              {!loading && <MenuList menuDetails={menuDetails} />}
            </div>
          </div>
        </div>
      </Loader>
    );
  }
}

export default compose(
  withStyles(s),
  graphql(getAllMenuQuery, {
    name: 'menuDetails',
    options: {
      ssr: true,
      fetchPolicy: 'network-only',
      variables: {
        currentPage: 1,
        searchList: ''
      }
    }
  })
)(Category);
