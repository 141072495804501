import React from 'react';
import PropTypes from 'prop-types';
import withStyles from 'isomorphic-style-loader/withStyles';
import s from './ChangeAdmin.css';
import ChangeAdminForm from '../../../components/SiteAdmin/ChangeAdminForm'
import { graphql } from 'react-apollo';
import { compose } from 'redux';


class ChangeAdmin extends React.Component {
  static propTypes = {
    title: PropTypes.string.isRequired
  };

  render() {
    const { title } = this.props
    return (
      <div className={s.root}>
        <div className={s.container}>
          <ChangeAdminForm />
        </div>
      </div>
    );
  }
}

export default compose(
  withStyles(s)
)(ChangeAdmin);
