import React from 'react';
import withStyles from 'isomorphic-style-loader/withStyles';
import { Button, FormControl, Table } from 'react-bootstrap';
import { connect } from 'react-redux';
import { FormattedMessage, injectIntl } from 'react-intl';
import cx from 'classnames';
import moment from 'moment';

// style
import s from './StoresList.css';

// Helpers
import messages from '../../../../locale/messages';
import debounce from '../../../../helpers/debounce';

// components
import CustomPagination from '../../../CustomPagination/CustomPagination';
import Link from '../../../Link/Link';

// Images
import EditIcon from '../../../../../public/Icons/edit.png';
import TrashIcon from '../../../../../public/Icons/bin.svg';
import ExportImage from '../../../../../public/Icons/export.png';

// Redux
import deleteShop from '../../../../actions/siteadmin/ManageShop/deleteShop';
import updateShopStatus from '../../../../actions/siteadmin/ManageShop/updateShopStatus';

class StoresList extends React.Component {

  constructor(props) {
    super(props)

    this.state = {
      currentPage: 1,
      searchList: '',
    }

    this.paginationData = this.paginationData.bind(this);
    this.handleKeywordSearch = debounce(this.handleKeywordSearch.bind(this));
    this.handleStatus = this.handleStatus.bind(this)
    this.handleDelete = this.handleDelete.bind(this)
  }

  handleKeywordSearch(searchList) {
    const { storeDetails: { refetch } } = this.props;

    let variables = {
      currentPage: 1,
      searchList
    };

    this.setState({ searchList, currentPage: 1 });
    refetch(variables);
  }

  async handleDelete(id) {
    const { deleteShop, storeDetails: { refetch } } = this.props;
    const { searchList } = this.state;
    let variables = { currentPage: 1, searchList };
    const deleteShopResponse = await deleteShop(id);
    if (deleteShopResponse && deleteShopResponse.status === 200) {
      this.setState({ currentPage: 1 });
      refetch(variables);
    }
  };

  paginationData(currentPage) {
    const { storeDetails: { refetch } } = this.props;
    const { searchList } = this.state;
    let variables = { currentPage, searchList };
    this.setState({ currentPage });
    refetch(variables);
  };

  async handleStatus(updateField, id, status, currentPage) {
    const { updateShopStatus, storeDetails: { refetch } } = this.props;
    const { searchList } = this.state;
    let variables = { currentPage, searchList };
    const updateShopStatusResponse = await updateShopStatus(updateField, id, status, null, 3);
    if (updateShopStatusResponse && updateShopStatusResponse.status === 200) {
      refetch(variables);
    }
  };

  render() {
    const { storeDetails } = this.props;
    const { formatMessage } = this.props.intl;
    const { currentPage, searchList } = this.state;

    return (
      <div className={cx(s.widthInner, s.tableSection, 'whiteDropdown', 'widthInnerNoBorder')}>
        <div className={cx(s.padding5, 'adminSearchInputSection')}>
          <FormControl type='text' placeholder={formatMessage(messages.search)}
            onChange={(event) => this.handleKeywordSearch(event.target && event.target.value)}
            className={cx(s.formControlInput, s.searchInput)} />
          {
            storeDetails && storeDetails.getAllShops && storeDetails.getAllShops.userData && storeDetails.getAllShops.userData.length > 0 && <a
              href={`/export-admin-data?type=shops&keyword=${searchList}`}
              className={s.exportText}>
              <span className={cx(s.vtrMiddle)}><FormattedMessage {...messages.exportDataIntoCSV} /></span>
              <span className={s.vtrTextBottom}>
                <img src={ExportImage} className={s.exportImg} />
              </span>
            </a>
          }
        </div>
        <div className={cx(s.tableCss, 'tableCss', 'tableSticky', 'NewAdminResponsiveTable', 'topScrollbarTable')}>
          <Table className={cx("table", 'topScrollbarTableContent')}>
            <thead>
              <tr>
                <th scope="col"><FormattedMessage {...messages.id} /></th>
                <th scope="col"><FormattedMessage {...messages.shopName} /></th>
                <th scope="col"><FormattedMessage {...messages.deliveryName} /></th>
                <th scope="col"><FormattedMessage {...messages.ownerName} /></th>
                <th scope="col"><FormattedMessage {...messages.email} /></th>
                <th scope="col"><FormattedMessage {...messages.phoneNumber} /></th>
                <th scope="col"><FormattedMessage {...messages.address} /></th>
                <th scope="col"><FormattedMessage {...messages.createdAt} /></th>
                <th scope="col"><FormattedMessage {...messages.userStatus} /></th>
                <th scope="col"><FormattedMessage {...messages.banStatus} /></th>
                <th scope="col" className={s.minWidth}><FormattedMessage {...messages.action} /></th>
                <th scope="col"><FormattedMessage {...messages.document} /></th>
              </tr>
            </thead>
            <tbody>
              {
                storeDetails && storeDetails.getAllShops && storeDetails.getAllShops.userData && storeDetails.getAllShops.userData.length > 0 && storeDetails.getAllShops.userData.map((item, index) => {
                  let address = '';
                  if (item.profile.city) {
                    address = item.profile.city + ', ';
                  }
                  if (item.profile.state) {
                    address = address + item.profile.state + ', ';
                  }
                  if (item.profile.country) {
                    address = address + item.profile.country + '.';
                  }

                  return (
                    <tr key={index}>
                      <td data-label={formatMessage(messages.id)}>{item.profile.profileId}</td>
                      <td data-label={formatMessage(messages.shopName)}>{item.profile && item.profile.shopName}</td>
                      <td data-label={formatMessage(messages.deliveryName)}>{item.profile && item.profile.deliveryName}</td>
                      <td data-label={formatMessage(messages.ownerName)}>{item.profile.firstName + ' ' + item.profile.lastName}</td>
                      <td data-label={formatMessage(messages.email)}>{item.email}</td>
                      <td data-label={formatMessage(messages.phoneNumber)}>{item.phoneDialCode + item.phoneNumber}</td>
                      <td data-label={formatMessage(messages.address)}>{address}</td>
                      <td data-label={formatMessage(messages.createdAt)}>{moment(item.createdAt).format('DD-MM-YYYY HH:mm:ss')}</td>
                      <td data-label={formatMessage(messages.userStatus)}>
                        <select value={item.userStatus} onChange={(e) => { this.handleStatus('userStatus', item.id, e.target.value, currentPage) }} className={s.selectInput}>
                          <option value={'pending'}>{formatMessage(messages.pending)}</option>
                          <option value={'active'}>{formatMessage(messages.approve)}</option>
                          <option value={'inactive'}>{formatMessage(messages.decline)}</option>
                        </select>
                      </td>
                      <td data-label={formatMessage(messages.banStatus)}>
                        <select value={item.isBan} onChange={(e) => { this.handleStatus('isBan', item.id, e.target.value, currentPage) }} className={s.selectInput}>
                          <option value={1}>{formatMessage(messages.ban)}</option>
                          <option value={0}>{formatMessage(messages.permit)}</option>
                        </select>
                      </td>
                      <td data-label={formatMessage(messages.action)} className={s.minWidth}>
                        <Link to={'/siteadmin/store/edit/' + item.profile.profileId} className={cx('editAlignIcon', s.editColorIcon)}>
                          <span><img src={EditIcon} className={cx(s.editIcon, 'editIconRTL')} /></span>
                          <span className={s.vtrMiddle}>
                            <FormattedMessage {...messages.editAction} />
                          </span>
                        </Link>
                        <Button onClick={() => this.handleDelete(item.id, currentPage)} className={cx(s.iconBtn, 'iconBtnRTL')}>
                          <img src={TrashIcon} className={cx(s.editIcon, 'editIconRTL')} />
                          <span className={s.vtrMiddle}>
                            <FormattedMessage {...messages.deleteAction} />
                          </span>
                        </Button>
                      </td>
                      <td className={s.trashPadding} data-label={formatMessage(messages.deleteAction)}>

                        <Link to={'/siteadmin/store/document/' + item.profile.profileId} className={'editAlignIcon'}>
                          <span><img src={EditIcon} className={cx(s.editIcon, 'editIconRTL')} /></span>
                          <span className={s.vtrMiddle}>
                            <FormattedMessage {...messages.document} />
                          </span>
                        </Link>
                      </td>
                    </tr>
                  )
                })
              }
              {
                storeDetails && storeDetails.getAllShops && storeDetails.getAllShops.userData.length == 0 && (
                  <tr>
                    <td colspan="12" className={s.noRecords}><FormattedMessage {...messages.noResult} /></td>
                  </tr>
                )
              }
            </tbody>
          </Table>
        </div>
        {
          storeDetails && storeDetails.getAllShops && storeDetails.getAllShops.userData && storeDetails.getAllShops.userData.length > 0
          && <div className={cx(s.space5, s.spaceTop5)}>
            <CustomPagination
              total={storeDetails.getAllShops.count}
              currentPage={currentPage}
              defaultCurrent={1}
              defaultPageSize={10}
              change={this.paginationData}
              paginationLabel={formatMessage(messages.stores)}
            />
          </div>
        }
      </div >
    );
  }
}

const mapDispatch = {
  deleteShop,
  updateShopStatus
};
const mapState = (state) => ({});

export default injectIntl(withStyles(s)(connect(mapState, mapDispatch)(StoresList)));