import React from 'react';
import PropTypes from 'prop-types';
import withStyles from 'isomorphic-style-loader/withStyles';
import { graphql } from 'react-apollo';
import { flowRight as compose } from 'lodash';
import cx from 'classnames';
import DeliveryTypeList from '../../../../components/SiteAdmin/DeliveryModule/DeliveryTypeList';
import getAllDeliveryTypeQuery from './getAllDeliveryType.graphql';
import Loader from '../../../../components/Common/Loader/Loader';

import s from './DeliveryModuleList.css';


class DeliveryModuleList extends React.Component {
  static propTypes = {
    title: PropTypes.string.isRequired,
    getAllDeliveryType: PropTypes.shape({
      getAllDeliveryType: PropTypes.array
    }),
  };

  static defaultProps = {
    deliveryType: {
      loading: true
    }
  }

  render() {
    const { title, deliveryType, deliveryType: { loading } } = this.props;

    return (
      <Loader type={"page"} show={loading}>
        <div className={s.root}>
          <div className={s.container}>
            <div className={s.paddingRoutesSection}>
              <div className={cx(s.heading, 'textAlignRightRTL')}>
                {title}
              </div>
              {!loading && <DeliveryTypeList deliveryType={deliveryType} />}
            </div>
          </div>
        </div>
      </Loader>
    );
  }
}

export default compose(
  withStyles(s),
  graphql(getAllDeliveryTypeQuery, {
    name: 'deliveryType',
    options: {
      ssr: true,
      fetchPolicy: 'network-only',
      variables: {
        currentPage: 1,
        searchList: ''
      }
    }
  })
)(DeliveryModuleList);
