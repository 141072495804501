import React, { Component } from 'react';
import { Field, reduxForm, formValueSelector } from 'redux-form';
import { connect } from 'react-redux';
import withStyles from 'isomorphic-style-loader/withStyles';
import { injectIntl } from 'react-intl';
import cx from 'classnames';
import Form from 'react-bootstrap/Form';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Container from 'react-bootstrap/Container';
import FormControl from 'react-bootstrap/FormControl';
import FormGroup from 'react-bootstrap/FormGroup';

import Loader from '../../../Common/Loader/Loader';
import ImageUploadComponent from '../../../Common/ImageUploadComponent/ImageUploadComponent';

import submit from './submit';
import validate from './validate';
import messages from '../../../../locale/messages';
import { api, partnerHomepageUploadDir } from '../../../../config';

import s from './SliderForm.css';
export class SliderForm extends Component {

    renderField = ({ input, label, type, meta: { touched, error }, labelClass, fieldClass, placeholder, maxlength }) => {
        const { formatMessage } = this.props.intl
        return (
            <Form.Group className={cx(s.space5, 'positionRelative')}>
                <Form.Label>{label}</Form.Label>
                <Form.Control {...input} placeholder={placeholder} type={type} className={cx(fieldClass, s.formControlInput)} maxlength={maxlength}
                />
                {touched && error && <span className={cx(s.errorMessage, 'errorMessageRTL')}>{formatMessage(error)}</span>}
            </Form.Group>
        )
    }

    renderFormControlTextArea = ({ input, label, meta: { touched, error }, children, className }) => {
        const { formatMessage } = this.props.intl
        return (
            <FormGroup className={cx(s.space5, 'positionRelative')}>
                <label>{label}</label>
                <FormControl
                    {...input}
                    className={className}
                    placeholder={label}
                    as="textarea"
                    rows="4"
                >
                    {children}
                </FormControl>
                {touched && error && <span className={cx(s.errorMessage, 'errorMessageRTL')}>{formatMessage(error)}</span>}
            </FormGroup>
        );
    }

    render() {
        const { handleSubmit, sliderImage1, sliderImage2, sliderImage3, loading, submitting, sliderimageOne, sliderimageTwo, sliderimageThree } = this.props;
        const { formatMessage } = this.props.intl;
        return (

            <div>
                <Container fluid>
                    <h1 className={cx(s.heading, 'textAlignRightRTL')}>{formatMessage(messages.sliderSection)}</h1>
                    <Form className={s.fullWidth} onSubmit={handleSubmit(submit)}>
                        <div className={s.blackCenterSection}>
                            <Row>
                                <Col lg={4} md={12} sm={12} xs={12} className={s.space1}>
                                    <Form.Group className={s.space5}>
                                        <div className={cx(s.profileImgSection, s.profileImgWidth)}>
                                            <ImageUploadComponent
                                                className={cx(s.btnSecondary, s.profileNoPadding)}
                                                subTextClass={s.subText}
                                                subText={formatMessage(messages.maximumUploadSizeLabel)}
                                                defaultMessage={formatMessage(messages.chooseFile)}
                                                loaderName={'sliderOneImageLoader'}
                                                postUrl={api.apiEndpoint + '/uploadPartnerHomepageImage'}
                                                loader={sliderimageOne}
                                                fieldName={'sliderImage1'}
                                                formName={'SliderForm'}
                                                imageSrc={sliderImage1 ? api.apiEndpoint + partnerHomepageUploadDir + sliderImage1 : null}
                                                label={formatMessage(messages.sliderImage1Label)}
                                            />
                                        </div>
                                    </Form.Group>
                                    <Field name="sliderTitle1" type="text" component={this.renderField} label={formatMessage(messages.sliderTitle1Label)} maxlength={250} />
                                    <Field name="sliderContent1" type="text" component={this.renderFormControlTextArea} label={formatMessage(messages.sliderDescription1Label)} />
                                </Col>
                                <Col lg={4} md={12} sm={12} xs={12} className={s.space1}>
                                    <Form.Group className={s.space5}>
                                        <div className={cx(s.profileImgSection, s.profileImgWidth)}>
                                            <ImageUploadComponent
                                                className={cx(s.btnSecondary, s.profileNoPadding)}
                                                subTextClass={s.subText}
                                                subText={formatMessage(messages.maximumUploadSizeLabel)}
                                                defaultMessage={formatMessage(messages.chooseFile)}
                                                loaderName={'sliderTwoImageLoader'}
                                                postUrl={api.apiEndpoint + '/uploadPartnerHomepageImage'}
                                                loader={sliderimageTwo}
                                                fieldName={'sliderImage2'}
                                                formName={'SliderForm'}
                                                imageSrc={sliderImage2 ? api.apiEndpoint + partnerHomepageUploadDir + sliderImage2 : null}
                                                label={formatMessage(messages.sliderImage2Label)}
                                            />
                                        </div>
                                    </Form.Group>
                                    <Field name="sliderTitle2" type="text" component={this.renderField} label={formatMessage(messages.sliderTitle2Label)} maxlength={250} />
                                    <Field name="sliderContent2" type="text" component={this.renderFormControlTextArea} label={formatMessage(messages.sliderDescription2Label)} />
                                </Col>
                                <Col lg={4} md={12} sm={12} xs={12} className={s.space1}>
                                    <Form.Group className={s.space5}>
                                        <div className={cx(s.profileImgSection, s.profileImgWidth)}>
                                            <ImageUploadComponent
                                                className={cx(s.btnSecondary, s.profileNoPadding)}
                                                subTextClass={s.subText}
                                                subText={formatMessage(messages.maximumUploadSizeLabel)}
                                                defaultMessage={formatMessage(messages.chooseFile)}
                                                loaderName={'sliderThreeImage3Loader'}
                                                postUrl={api.apiEndpoint + '/uploadPartnerHomepageImage'}
                                                loader={sliderimageThree}
                                                fieldName={'sliderImage3'}
                                                formName={'SliderForm'}
                                                imageSrc={sliderImage3 ? api.apiEndpoint + partnerHomepageUploadDir + sliderImage3 : null}
                                                label={formatMessage(messages.sliderImage3Label)}
                                            />
                                        </div>
                                    </Form.Group>
                                    <Field name="sliderTitle3" type="text" component={this.renderField} label={formatMessage(messages.sliderImage3Label)} maxlength={250} />
                                    <Field name="sliderContent3" type="text" component={this.renderFormControlTextArea} label={formatMessage(messages.sliderDescription3Label)} />
                                </Col>
                                <Col lg={12} md={12} sm={12} xs={12} className={cx(s.textAlignRight, s.spaceTop1, 'textAlignLeftRTL')}>
                                    <Form.Group className={s.noMargin}>
                                        <div className={s.displayInlineBlock}>
                                            <Loader
                                                type={"button"}
                                                label={formatMessage(messages.submitButton)}
                                                show={loading}
                                                buttonType={'submit'}
                                                className={cx(s.button, s.btnPrimary)}
                                                disabled={submitting || loading}
                                                isSuffix={true}
                                            />
                                        </div>
                                    </Form.Group>
                                </Col>
                            </Row>
                        </div>
                    </Form>
                </Container>
            </div>
        )
    }
}

SliderForm = reduxForm({
    form: 'SliderForm',
    onSubmit: submit,
    validate
})(SliderForm);

const selector = formValueSelector('SliderForm')

const mapState = (state) => ({
    sliderImage1: selector(state, 'sliderImage1'),
    sliderImage2: selector(state, 'sliderImage2'),
    sliderImage3: selector(state, 'sliderImage3'),
    loading: state.loader.UpdatePartnerHomepage,
    sliderimageOne: state.loader.sliderOneImageLoader,
    sliderimageTwo: state.loader.sliderTwoImageLoader,
    sliderimageThree: state.loader.sliderThreeImage3Loader
})

const mapDispatch = {
}

export default injectIntl(withStyles(s)(connect(mapState, mapDispatch)(SliderForm)));